import { ConversationPlatform } from "@redotech/redo-model/conversation";
import { Condition as ModelCondition } from "@redotech/redo-model/return-flow/condition";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { MultiSelectDropdown } from "@redotech/redo-web/select-dropdown";
import { channelDisplayName } from "../../support/utils";
import { ConditionType } from "../return-flow/condition";
import * as selectCss from "../return-flow/select.module.css";

const options = [
  ConversationPlatform.EMAIL,
  ConversationPlatform.FACEBOOK,
  ConversationPlatform.INSTAGRAM,
  ConversationPlatform.INSTAGRAM_COMMENTS,
  ConversationPlatform.FACEBOOK_COMMENTS,
  ConversationPlatform.REDO_CHAT,
  ConversationPlatform.ATTENTIVE,
];

export const TICKET_CHANNEL: ConditionType<
  ConversationPlatform[],
  ModelCondition.TicketChannel
> = {
  name: "Ticket channel",
  description() {
    return "Which channel the ticket came from";
  },
  Details({ state, setState, disabled }) {
    return (
      <LabeledInput label="Channel">
        <MultiSelectDropdown
          disabled={disabled}
          options={options}
          value={state}
          valueChange={(e) => {
            if (e) {
              setState([...e]);
            }
          }}
        >
          {(channel) => (
            <div className={selectCss.value}>
              <div className={selectCss.title}>
                {channelDisplayName(channel)}
              </div>
            </div>
          )}
        </MultiSelectDropdown>
      </LabeledInput>
    );
  },
  empty: [],
  fromModel(model) {
    return model.channels;
  },
  toModel(state) {
    return { type: ModelCondition.TICKET_CHANNEL, channels: state };
  },
  valid() {
    return true;
  },
};
