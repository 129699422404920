import {
  arrayJsonFormat,
  dateJsonFormat,
  JsonFormat,
  JsonFormatError,
  nullableJsonFormat,
  objectJsonFormat,
  stringEnumJsonFormat,
  stringJsonFormat,
  typedStringJsonFormat,
} from "@redotech/json/format";
import { Json } from "@redotech/json/json";
import {
  cartLineJsonFormat,
  partialCartJsonFormat,
  partialCustomerJsonFormat,
  productVariantJsonFormat,
} from "../common/webpixel-events-json-format-util";
import {
  isProductAddedToCartEvent,
  isProductViewedEvent,
} from "../customer-activity/customer-activity-definition";
import {
  IProductAddedToCartShoppingEvent,
  IProductViewedShoppingEvent,
  IShoppingEvent,
  SHOPPING_EVENT_DISCRIMINATOR_KEY,
  ShoppingEventType,
} from "./shopping-event-definition";

const shoppingEventCommonFields = {
  _id: stringJsonFormat,
  team: stringJsonFormat,
  email: stringJsonFormat,
  timestamp: dateJsonFormat,
  eventId: stringJsonFormat,
  eventType: stringEnumJsonFormat(ShoppingEventType),
};

export const productViewedShoppingEventJsonFormat: JsonFormat<IProductViewedShoppingEvent> =
  objectJsonFormat(
    {
      ...shoppingEventCommonFields,
      eventType: typedStringJsonFormat(ShoppingEventType.PRODUCT_VIEWED),
      productVariant: productVariantJsonFormat,
      cart: nullableJsonFormat(partialCartJsonFormat),
      customer: nullableJsonFormat(partialCustomerJsonFormat),
    },
    {},
  );

export const productAddedToCartShoppingEventJsonFormat: JsonFormat<IProductAddedToCartShoppingEvent> =
  objectJsonFormat(
    {
      ...shoppingEventCommonFields,
      eventType: typedStringJsonFormat(ShoppingEventType.PRODUCT_ADDED_TO_CART),
      cartLine: cartLineJsonFormat,
      customer: nullableJsonFormat(partialCustomerJsonFormat),
    },
    {},
  );

export const shoppingEventJsonFormat: JsonFormat<IShoppingEvent> = {
  read(json: Json): IShoppingEvent {
    if (typeof json !== "object" || json instanceof Array || !json) {
      throw new JsonFormatError("Expected object");
    }
    if (!(SHOPPING_EVENT_DISCRIMINATOR_KEY in json)) {
      throw new JsonFormatError(
        `Expected key ${SHOPPING_EVENT_DISCRIMINATOR_KEY}`,
      );
    }

    switch (json[SHOPPING_EVENT_DISCRIMINATOR_KEY]) {
      case ShoppingEventType.PRODUCT_VIEWED:
        return productViewedShoppingEventJsonFormat.read(json);
      case ShoppingEventType.PRODUCT_ADDED_TO_CART:
        return productAddedToCartShoppingEventJsonFormat.read(json);
      default:
        throw new JsonFormatError(
          `Unknown event type ${json[SHOPPING_EVENT_DISCRIMINATOR_KEY]}`,
        );
    }
  },
  write(value: IShoppingEvent): Json {
    if (isProductViewedEvent(value)) {
      return productViewedShoppingEventJsonFormat.write(value);
    } else if (isProductAddedToCartEvent(value)) {
      return productAddedToCartShoppingEventJsonFormat.write(value);
    } else {
      throw new JsonFormatError(
        `Unknown event type ${value[SHOPPING_EVENT_DISCRIMINATOR_KEY]}`,
      );
    }
  },
};

export const arrayShoppingEventJsonFormat: JsonFormat<IShoppingEvent[]> =
  arrayJsonFormat(shoppingEventJsonFormat);
