import { ConversationPlatform } from "@redotech/redo-model/conversation";
import {
  BadgeColor,
  BadgeSize,
  RedoBadge,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import Mail01Svg from "@redotech/redo-web/arbiter-icon/mail-01.svg";
import AttentiveLogoIcon from "@redotech/redo-web/icon-old/attentive-logo.svg";
import ChatBubbleIcon from "@redotech/redo-web/icon-old/chat-bubble.svg";
import FacebookIcon from "@redotech/redo-web/icon-old/facebook-color.svg";
import InstagramIcon from "@redotech/redo-web/icon-old/instagram-color.svg";
import LogoIcon from "@redotech/redo-web/icon-old/logo-icon.svg";
import { JSXElementConstructor, memo } from "react";

const platformMappings: Record<
  ConversationPlatform,
  { text: string; icon: JSXElementConstructor<unknown>; color: BadgeColor }
> = {
  redoChat: { text: "Redo Chat", icon: LogoIcon, color: "brand" },
  instagram: { text: "DM", icon: InstagramIcon, color: "pink" },
  instagramComments: {
    text: "Comments",
    icon: InstagramIcon,
    color: "pink",
  },
  facebook: { text: "Messenger", icon: FacebookIcon, color: "blueLight" },
  facebookComments: {
    text: "Comments",
    icon: FacebookIcon,
    color: "blueLight",
  },
  email: { text: "Email", icon: Mail01Svg, color: "gray" },
  sms: { text: "SMS", icon: ChatBubbleIcon, color: "success" },
  attentive: {
    text: "Attentive",
    icon: AttentiveLogoIcon,
    color: "warning",
  },
};

export const RedoSupportChannelBadge = memo(function RedoSupportChannelBadge({
  platform,
  size = "sm",
}: {
  platform: ConversationPlatform;
  size?: BadgeSize;
}) {
  const { text, icon, color } = platformMappings[platform];

  return (
    <RedoBadge
      color={color}
      iconLeading={{ Icon: icon }}
      size={size}
      text={text
        .split("")
        .map((k, i) => (i === 0 ? k.toUpperCase() : k.toLowerCase()))
        .join("")}
      type="pillColor"
    />
  );
});
