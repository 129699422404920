import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { useScrolled } from "@redotech/react-util/scroll";
import { CreateConversationModal } from "@redotech/redo-merchant-app/support/create-conversation-modal";
import { FilterModal } from "@redotech/redo-merchant-app/support/filter-modal";
import { SnoozeModal } from "@redotech/redo-merchant-app/support/snooze-modal";
import { getNextBatch } from "@redotech/redo-merchant-app/support/utils";
import {
  ConversationPlatform,
  ExpandedConversation,
} from "@redotech/redo-model/conversation";
import { CreateConversationBody } from "@redotech/redo-model/createconversationbody";
import {
  defaultConversationsTableSort,
  SortableConversationTableColumn,
  SortDirection,
} from "@redotech/redo-model/table";
import { Permission, permitted } from "@redotech/redo-model/user";
import { FilterOptions, FiltersStatus, View } from "@redotech/redo-model/view";
import Edit04Icon from "@redotech/redo-web/arbiter-icon/edit-04.svg";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  IconButton,
} from "@redotech/redo-web/button";
import { ButtonDropdown } from "@redotech/redo-web/button-dropdown";
import { ButtonSelect } from "@redotech/redo-web/button-select";
import { DropdownOption } from "@redotech/redo-web/dropdown";
import ArchiveIcon from "@redotech/redo-web/icon-old/archive.svg";
import CheckCircle from "@redotech/redo-web/icon-old/check-circle.svg";
import EnvelopeOpen from "@redotech/redo-web/icon-old/envelope-open.svg";
import FilterIcon from "@redotech/redo-web/icon-old/filter.svg";
import EnvelopeIcon from "@redotech/redo-web/icon-old/mail.svg";
import MergeIcon from "@redotech/redo-web/icon-old/merge.svg";
import PlusIcon from "@redotech/redo-web/icon-old/plus.svg";
import SearchIcon from "@redotech/redo-web/icon-old/search.svg";
import SnoozeClockIcon from "@redotech/redo-web/icon-old/snooze-clock.svg";
import SpamIcon from "@redotech/redo-web/icon-old/spam.svg";
import TagIcon from "@redotech/redo-web/icon-old/tag.svg";
import ThreeDotsVerticalIcon from "@redotech/redo-web/icon-old/three-dots-vertical.svg";
import TrashIcon from "@redotech/redo-web/icon-old/trash.svg";
import UndoArrow from "@redotech/redo-web/icon-old/undo-arrow.svg";
import UserPlusIcon from "@redotech/redo-web/icon-old/user-plus.svg";
import XIcon from "@redotech/redo-web/icon-old/x.svg";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { ActionPortalContext } from "@redotech/redo-web/page";
import { ProgressBar } from "@redotech/redo-web/progress-bar";
import { TextInput } from "@redotech/redo-web/text-input";
import * as classNames from "classnames";
import {
  Dispatch,
  memo,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebounce } from "usehooks-ts";
import { ReloadConversationTagsContext } from "../app/conversation-tags-context";
import { TeamContext } from "../app/team";
import { UserContext } from "../app/user";
import { ReloadViewsContext } from "../app/views";
import { RedoMerchantClientContext } from "../client/context";
import {
  bulkUpdateViewed,
  createConversation,
  filtersToQueryParams,
  getConversations,
} from "../client/conversations";
import { createView } from "../client/view";
import { ArchiveTicketModal } from "./archive-ticket-modal";
import { AssignTicketModal } from "./assign-ticket-modal";
import { ChangeSubjectModal } from "./change-subject-modal";
import { CloseTicketModal, handleCloseTickets } from "./close-ticket-modal";
import { ConversationFetcher } from "./conversation-fetcher";
import {
  ConversationsTable,
  ConversationsTableSelectionStateUtil,
  ConversationTableDropdownAction,
} from "./conversations-table";
import * as conversationsTableCss from "./conversations-table.module.css";
import {
  markConversationAsRead,
  markConversationAsUnread,
} from "./conversations/mark-conversation-read-manager";
import { DeleteViewModal } from "./delete-view-modal";
import { EditViewModal } from "./edit-view-modal";
import { MarkSpamModal } from "./mark-spam-modal";
import { MergeModal } from "./merge-modal";
import { ReopenTicketModal } from "./reopen-ticket-modal";
import { StatusDropdown } from "./status-dropdown";
import {
  ConversationCountsContext,
  FiltersContext,
  SetFiltersContext,
} from "./support";
import { TagsModal } from "./tags-modal";

export const FullConversationsTable = memo(function FullConversationsTable({
  view,
  viewName,
  setActiveConversation,
  fetcher,
  initialFilters,
  tableRef,
  selectedConversations,
  setSelectedConversations,
  deselectedConversations,
  setDeselectedConversations,
  selectAllMode,
  setSelectAllMode,
  statusFilter,
  setStatusFilter,
  setBlockRefresh,
  pageLoaded,
}: {
  view?: View; // details for custom views
  viewName: string;
  setActiveConversation: (
    conversation: ExpandedConversation | undefined,
  ) => void;
  fetcher: ConversationFetcher;
  initialFilters: FilterOptions;
  tableRef: React.MutableRefObject<any>;
  selectedConversations: ExpandedConversation[];
  setSelectedConversations: (conversations: ExpandedConversation[]) => void;
  deselectedConversations: ExpandedConversation[];
  setDeselectedConversations: (conversations: ExpandedConversation[]) => void;
  selectAllMode: boolean;
  setSelectAllMode: (selectAllMode: boolean) => void;
  statusFilter: FiltersStatus | "all";
  setStatusFilter: Dispatch<SetStateAction<FiltersStatus | "all">>;
  setBlockRefresh: (block: boolean) => void;
  pageLoaded: boolean;
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const conversationCounts = useContext(ConversationCountsContext);
  const filters = useRequiredContext(FiltersContext);
  const setFilters = useRequiredContext(SetFiltersContext);
  const reloadViews = useContext(ReloadViewsContext);
  const actionsPortal = useContext(ActionPortalContext);
  const reloadConversationTags = useContext(ReloadConversationTagsContext);
  const team = useContext(TeamContext);
  const user = useRequiredContext(UserContext);

  const [searchString, setSearchString] = useState<string | undefined>();
  const [abort, setAbort] = useState(new AbortController());
  const [isBulkAction, setIsBulkAction] = useState(false);
  const [closeModalOpen, setCloseModalOpen] = useState(false);
  const [reopenModalOpen, setReopenModalOpen] = useState(false);
  const [mergeModalOpen, setMergeModalOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [markSpamModalOpen, setMarkSpamModalOpen] = useState(false);
  const [changeSubjectModalOpen, setChangeSubjectModalOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [createConversationModalOpen, setCreateConversationModalOpen] =
    useState(false);
  const [bulkActionIterator, setBulkActionIterator] = useState<
    AsyncIterator<{
      conversations: ExpandedConversation[];
    }>
  >();
  const [iteratorRefresh, setIteratorRefresh] = useState(Symbol());
  const [
    performSelectAllActionImmediately,
    setPerformSelectAllActionImmediately,
  ] = useState(false);
  const [progressModalInfo, setProgressModalInfo] = useState<{
    open: boolean;
    progress: number; // out of 100
    title: string;
  }>({
    open: false,
    progress: 0,
    title: "",
  });
  const [editViewModalOpen, setEditViewModalOpen] = useState(false);
  const [deleteViewModalOpen, setDeleteViewModalOpen] = useState(false);
  const [snoozeModalOpen, setSnoozeModalOpen] = useState(false);
  // Stores conversation that needs an action (e.g. close, snooze, etc.) from the main view page
  const [conversationNeedingAction, setConversationNeedingAction] =
    useState<ExpandedConversation>();

  const location = useLocation();
  const debouncedSearch = useDebounce(searchString, 500);
  const navigate = useNavigate();
  const scrolled = useScrolled(document.documentElement);
  const scrollAreaRef = useRef(document.documentElement);

  const canReply =
    !!user && permitted(user.permissions, Permission.CREATE_REPLY);
  const canCloseTicket =
    !!user && permitted(user.permissions, Permission.CLOSE_CONVERSATION);
  const canAssignTag =
    !!user && permitted(user.permissions, Permission.ASSIGN_TAG);
  const canArchiveTicket =
    !!user && permitted(user.permissions, Permission.ARCHIVE_CONVERSATION);
  const canEditAssignee =
    !!user && permitted(user.permissions, Permission.EDIT_ASSIGNEE);
  const canEditSettings =
    !!user && permitted(user.permissions, Permission.EDIT_SETTINGS);

  const selectAllCount = conversationCounts
    ? conversationCounts[`${viewName}-${statusFilter}`]
    : 0;

  async function* bulkActionFetcher(
    pageSize?: number,
    signal?: AbortSignal,
    passThroughValues?: FilterOptions,
  ): AsyncIterator<{
    conversations: ExpandedConversation[];
  }> {
    if (pageSize === undefined) {
      pageSize = 10;
    }
    for (let pageContinue: string | undefined; ; ) {
      const { data, pageNext } = await getConversations(client, {
        pageContinue,
        pageSize,
        filters: passThroughValues,
        signal,
      });
      yield { conversations: data };
      if (pageNext === undefined) {
        break;
      }
      pageContinue = pageNext;
    }
  }

  const dropdownActions = (
    conversation: ExpandedConversation,
    setDropdownOpen: (open: boolean) => void,
  ) => {
    const items: ConversationTableDropdownAction[] = [
      {
        show: canCloseTicket && conversation.status !== "closed",
        component: (
          <DropdownOption
            action={async () => {
              setDropdownOpen(false);
              setIsBulkAction(false);
              setConversationNeedingAction(conversation);
              if (
                localStorage.getItem("redo.doNotShowCloseTicketModal") ===
                  "true" ||
                conversation.platform !== ConversationPlatform.REDO_CHAT
              ) {
                await handleCloseTickets({
                  client: client,
                  conversations: [conversation],
                  setActiveConversation,
                  inDetailView: false,
                  cleanupFn: singleCleanupFn,
                });
              } else {
                amplitude.logEvent("view-closeConversationModal", {
                  mode: "single",
                  conversationIds: [conversation._id],
                  channels: [conversation.platform],
                });
                setCloseModalOpen(true);
              }
            }}
            stopPropagation
          >
            <div className={conversationsTableCss.actionButton}>
              <CheckCircle className={conversationsTableCss.icon} />
              Close
            </div>
          </DropdownOption>
        ),
      },
      {
        show: canCloseTicket && conversation.status === "closed",
        component: (
          <DropdownOption
            action={() => {
              amplitude.logEvent("view-reopenConversationModal", {
                mode: "single",
                conversationIds: [conversation._id],
                channels: [conversation.platform],
              });
              setDropdownOpen(false);
              setIsBulkAction(false);
              setConversationNeedingAction(conversation);
              setReopenModalOpen(true);
            }}
            stopPropagation
          >
            <div className={conversationsTableCss.actionButton}>
              <UndoArrow className={conversationsTableCss.icon} />
              Reopen ticket
            </div>
          </DropdownOption>
        ),
      },
      {
        show: conversation.read ?? false,
        component: (
          <DropdownOption
            action={async () => {
              setDropdownOpen(false);
              await handleMarkedUnread(conversation);
              tableRef.current?.refresh();
            }}
            stopPropagation
          >
            <div className={conversationsTableCss.actionButton}>
              <EnvelopeIcon className={conversationsTableCss.icon} />
              Mark unread
            </div>
          </DropdownOption>
        ),
      },
      {
        show: !conversation.read,
        component: (
          <DropdownOption
            action={async () => {
              setDropdownOpen(false);
              await onConversationsViewed(conversation);
              tableRef.current?.refresh();
            }}
            stopPropagation
          >
            <div className={conversationsTableCss.actionButton}>
              <EnvelopeOpen className={conversationsTableCss.icon} />
              Mark read
            </div>
          </DropdownOption>
        ),
      },
      {
        show: canReply,
        component: (
          <DropdownOption
            action={() => {
              amplitude.logEvent("view-snoozeConversationModal", {
                mode: "single",
                conversationIds: [conversation._id],
                channels: [conversation.platform],
              });
              setDropdownOpen(false);
              setIsBulkAction(false);
              setConversationNeedingAction(conversation);
              setSnoozeModalOpen?.(true);
            }}
            stopPropagation
          >
            <div className={conversationsTableCss.actionButton}>
              <SnoozeClockIcon className={conversationsTableCss.icon} />
              Snooze
            </div>
          </DropdownOption>
        ),
      },
      {
        show: canCloseTicket,
        component: (
          <DropdownOption
            action={() => {
              amplitude.logEvent("view-mergeConversationModal", {
                mode: "single",
                conversationIds: [conversation._id],
                channels: [conversation.platform],
              });
              setDropdownOpen(false);
              setConversationNeedingAction(conversation);
              setMergeModalOpen(true);
            }}
            stopPropagation
          >
            <div className={conversationsTableCss.actionButton}>
              <MergeIcon className={conversationsTableCss.icon} />
              Merge
            </div>
          </DropdownOption>
        ),
      },
      {
        show: canArchiveTicket,
        component: (
          <DropdownOption
            action={() => {
              amplitude.logEvent("view-archiveConversationModal", {
                mode: "single",
                conversationIds: [conversation._id],
                channels: [conversation.platform],
              });
              setDropdownOpen(false);
              setIsBulkAction(false);
              setConversationNeedingAction(conversation);
              setArchiveModalOpen(true);
            }}
            stopPropagation
          >
            <div className={conversationsTableCss.actionButton}>
              <ArchiveIcon className={conversationsTableCss.icon} />
              Delete
            </div>
          </DropdownOption>
        ),
      },
      {
        show: canEditAssignee,
        component: (
          <DropdownOption
            action={() => {
              amplitude.logEvent("view-assignConversationModal", {
                mode: "single",
                conversationIds: [conversation._id],
                channels: [conversation.platform],
              });
              setDropdownOpen(false);
              setIsBulkAction(false);
              setConversationNeedingAction(conversation);
              setAssignModalOpen(true);
            }}
            stopPropagation
          >
            <div className={conversationsTableCss.actionButton}>
              <UserPlusIcon className={conversationsTableCss.icon} />
              Assign
            </div>
          </DropdownOption>
        ),
      },
      {
        show: canAssignTag,
        component: (
          <DropdownOption
            action={() => {
              amplitude.logEvent(`view-tagDropdown`, {
                mode: "single",
                conversationIds: [conversation._id],
                channels: [conversation.platform],
              });
              setDropdownOpen(false);
              setIsBulkAction(false);
              setConversationNeedingAction(conversation);
              setTagModalOpen(true);
            }}
            stopPropagation
          >
            <div className={conversationsTableCss.actionButton}>
              <TagIcon className={conversationsTableCss.icon} />
              {(conversation.tagIds || []).length > 0
                ? "Edit tags"
                : "Add tags"}
            </div>
          </DropdownOption>
        ),
      },
      {
        show:
          canCloseTicket &&
          conversation.platform === ConversationPlatform.EMAIL,
        component: (
          <DropdownOption
            action={() => {
              amplitude.logEvent("view-markSpamModal", {
                mode: "single",
                conversationIds: [conversation._id],
              });
              setDropdownOpen(false);
              setConversationNeedingAction(conversation);
              setMarkSpamModalOpen(true);
            }}
            stopPropagation
          >
            <div className={conversationsTableCss.actionButton}>
              <SpamIcon className={conversationsTableCss.icon} />
              Mark spam
            </div>
          </DropdownOption>
        ),
      },
      {
        show: canReply && conversation.platform === ConversationPlatform.EMAIL,
        component: (
          <DropdownOption
            action={() => {
              amplitude.logEvent("view-changeSubjectModal", {
                mode: "single",
                conversationIds: [conversation._id],
              });
              setDropdownOpen(false);
              handleChangeSubject(conversation);
            }}
            stopPropagation
          >
            <div className={conversationsTableCss.actionButton}>
              <Edit04Icon className={conversationsTableCss.icon} />
              Change subject
            </div>
          </DropdownOption>
        ),
      },
    ];
    return items;
  };

  const handleSearchAll = () => {
    amplitude.logEvent("search-conversation", { mode: "all", searchString });
    navigate({
      pathname: "../table/all",
      search: `?initialSearch=${encodeURIComponent(searchString || "")}`,
    });
  };

  const handleBulkSnooze = () => {
    if (selectAllMode) {
      amplitude.logEvent("view-snoozeConversationModal", { mode: "all" });
      setSnoozeModalOpen(true);
    } else if (selectedConversations.length > 0) {
      const channels = [
        ...new Set(
          selectedConversations.map((conversation) => conversation.platform),
        ),
      ];
      amplitude.logEvent("view-snoozeConversationModal", {
        mode: "multiple",
        conversationIds: selectedConversations.map(
          (conversation) => conversation._id,
        ),
        channels,
      });
      setIsBulkAction(true);
      setSnoozeModalOpen(true);
    }
  };

  const handleBulkArchive = () => {
    if (selectAllMode) {
      amplitude.logEvent("view-archiveConversationModal", { mode: "all" });
      setArchiveModalOpen(true);
    } else if (selectedConversations.length > 0) {
      const channels = [
        ...new Set(
          selectedConversations.map((conversation) => conversation.platform),
        ),
      ];
      amplitude.logEvent("view-archiveConversationModal", {
        mode: "multiple",
        conversationIds: selectedConversations.map(
          (conversation) => conversation._id,
        ),
        channels,
      });
      setIsBulkAction(true);
      setArchiveModalOpen(true);
    }
  };

  const handleMarkedUnread = async (
    conversationToMark: ExpandedConversation | undefined,
  ) => {
    if (conversationToMark && team) {
      await markConversationAsUnread(
        client,
        conversationToMark,
        team,
        user._id,
      );
      setActiveConversation(undefined);
    }
  };

  const handleBulkReopen = async () => {
    if (selectAllMode) {
      amplitude.logEvent("view-reopenConversationModal", { mode: "all" });
      setReopenModalOpen(true);
    } else if (selectedConversations.length > 0) {
      const channels = [
        ...new Set(
          selectedConversations.map((conversation) => conversation.platform),
        ),
      ];
      amplitude.logEvent("view-reopenConversationModal", {
        mode: "multiple",
        conversationIds: selectedConversations.map(
          (conversation) => conversation._id,
        ),
        channels,
      });
      setIsBulkAction(true);
      setReopenModalOpen(true);
    }
  };

  const handleBulkClose = async () => {
    if (selectAllMode) {
      if (localStorage.getItem("redo.doNotShowCloseTicketModal") === "true") {
        setPerformSelectAllActionImmediately(true);
      }
      amplitude.logEvent("view-closeConversationModal", { mode: "all" });
      setCloseModalOpen(true);
    } else if (selectedConversations.length > 0) {
      const channels = [
        ...new Set(
          selectedConversations.map((conversation) => conversation.platform),
        ),
      ];
      amplitude.logEvent("view-closeConversationModal", {
        mode: "multiple",
        conversationIds: selectedConversations.map(
          (conversation) => conversation._id,
        ),
        channels,
      });
      setIsBulkAction(true);
      if (localStorage.getItem("redo.doNotShowCloseTicketModal") === "true") {
        await handleCloseTickets({
          client,
          conversations: selectedConversations,
          setActiveConversation,
          inDetailView: false,
          cleanupFn: bulkCleanupFn,
        });
      } else {
        setCloseModalOpen(true);
      }
    }
  };

  const handleBulkMerge = () => {
    if (selectAllMode) {
      amplitude.logEvent("view-mergeConversationModal", { mode: "all" });
      setMergeModalOpen(true);
    } else if (selectedConversations.length > 0) {
      const channels = [
        ...new Set(
          selectedConversations.map((conversation) => conversation.platform),
        ),
      ];
      amplitude.logEvent("view-mergeConversationModal", {
        mode: "multiple",
        conversationIds: selectedConversations.map(
          (conversation) => conversation._id,
        ),
        channels,
      });
      setIsBulkAction(true);
      setMergeModalOpen(true);
    }
  };

  const updateBatchedViewed = async (
    markRead: boolean,
  ): Promise<{
    batchSize: number;
    done: boolean;
  }> => {
    if (!bulkActionIterator) {
      return { batchSize: 0, done: true };
    }
    const { done, value } = await getNextBatch(bulkActionIterator);
    if (done) {
      return { batchSize: 0, done: true };
    }
    const conversationIds = value.conversations
      .filter(
        (conversation) =>
          !deselectedConversations.some(
            (deselectedConversation) =>
              deselectedConversation._id === conversation._id,
          ),
      )
      .map((conversation) => conversation._id);
    if (conversationIds.length > 0) {
      await bulkUpdateViewed(client, {
        markRead,
        conversationIds,
      });
    }
    return { batchSize: conversationIds.length, done: false };
  };

  const handleBulkMarkUnread = async () => {
    if (selectAllMode) {
      if (!selectAllCount || !bulkActionIterator) {
        return;
      }
      setProgressModalInfo({
        open: true,
        progress: 0,
        title: "Marking unread",
      });
      setBlockRefresh(true);
      let done = false;
      do {
        const result = await updateBatchedViewed(false);
        setProgressModalInfo((prev) => ({
          ...prev,
          progress: prev.progress + (result.batchSize / selectAllCount) * 100,
        }));
        done = result.done;
      } while (!done);
      setProgressModalInfo({
        open: false,
        progress: 0,
        title: "",
      });
      setBlockRefresh(false);
      bulkCleanupFn(true);
    } else if (selectedConversations.length > 0) {
      const conversationIds = selectedConversations.map(
        (conversation) => conversation._id,
      );
      if (conversationIds.length > 0) {
        await bulkUpdateViewed(client, {
          markRead: false,
          conversationIds,
        });
      }
      bulkCleanupFn(true);
      await tableRef.current?.refresh();
    }
  };

  const handleBulkMarkRead = async () => {
    if (selectAllMode) {
      if (!selectAllCount || !bulkActionIterator) {
        return;
      }
      setProgressModalInfo({
        open: true,
        progress: 0,
        title: "Marking read",
      });
      setBlockRefresh(true);
      let done = false;
      do {
        const result = await updateBatchedViewed(true);
        setProgressModalInfo((prev) => ({
          ...prev,
          progress: prev.progress + (result.batchSize / selectAllCount) * 100,
        }));
        done = result.done;
      } while (!done);
      setProgressModalInfo({
        open: false,
        progress: 0,
        title: "",
      });
      setBlockRefresh(false);
      bulkCleanupFn(true);
    } else if (selectedConversations.length > 0) {
      const conversationIds = selectedConversations.map(
        (conversation) => conversation._id,
      );
      if (conversationIds.length > 0) {
        await bulkUpdateViewed(client, {
          markRead: true,
          conversationIds,
        });
      }
      bulkCleanupFn(true);
      await tableRef.current?.refresh();
    }
  };

  const handleBulkAssign = () => {
    if (selectAllMode) {
      amplitude.logEvent("view-assignConversationModal", { mode: "all" });
      setAssignModalOpen(true);
    } else if (selectedConversations.length > 0) {
      const channels = [
        ...new Set(
          selectedConversations.map((conversation) => conversation.platform),
        ),
      ];
      amplitude.logEvent("view-assignConversationModal", {
        mode: "multiple",
        conversationIds: selectedConversations.map(
          (conversation) => conversation._id,
        ),
        channels,
      });
      setIsBulkAction(true);
      setAssignModalOpen(true);
    }
  };

  const handleBulkAddTags = () => {
    if (selectAllMode) {
      amplitude.logEvent("view-tagDropdown", { mode: "all" });
      setTagModalOpen(true);
    } else if (selectedConversations.length > 0) {
      const channels = [
        ...new Set(
          selectedConversations.map((conversation) => conversation.platform),
        ),
      ];
      amplitude.logEvent("view-tagDropdown", {
        mode: "multiple",
        conversationIds: selectedConversations.map(
          (conversation) => conversation._id,
        ),
        channels,
      });
      setIsBulkAction(true);
      setTagModalOpen(true);
    }
  };

  const handleBulkMarkSpam = () => {
    if (selectAllMode) {
      amplitude.logEvent("view-markSpamModal", { mode: "all" });
      setMarkSpamModalOpen(true);
    } else if (selectedConversations.length > 0) {
      amplitude.logEvent("view-markSpamModal", {
        mode: "multiple",
        conversationIds: selectedConversations.map(
          (conversation) => conversation._id,
        ),
      });
      setIsBulkAction(true);
      setMarkSpamModalOpen(true);
    }
  };

  const onConversationsViewed = async (conversation: ExpandedConversation) => {
    if (!team) {
      return;
    }
    return await markConversationAsRead(client, conversation, team, user._id);
  };

  const applyFilters = (filters: FilterOptions) => {
    amplitude.logEvent("filter-conversation", { filters });
    const filterQueryParams = filtersToQueryParams(filters);
    const queryParams = new URLSearchParams(
      filters.modified
        ? filterQueryParams
          ? `?modified=true&${filterQueryParams}`
          : `?modified=true`
        : "",
    );
    const existingQueryParams = new URLSearchParams(location.search);
    const searchParam = existingQueryParams.get("search");
    if (searchParam) {
      queryParams.set("search", searchParam);
    }

    navigate({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
    setFilters({
      ...filters,
      status:
        statusFilter === "all" ? Object.values(FiltersStatus) : [statusFilter],
    });
  };

  const handleCreateView = async (
    filters: FilterOptions,
    name: string,
    isPrivate: boolean,
  ) => {
    if (team) {
      amplitude.logEvent("create-view", { view: name });
      const view = await createView(client, { filters, name, isPrivate });
      if (reloadViews) {
        reloadViews();
      }
      window.location.href = `/stores/${team._id}/support/table/${view.name}`;
    }
  };

  const handleCreateConversation = async ({
    body,
  }: {
    body: CreateConversationBody;
  }) => {
    amplitude.logEvent("create-conversation");
    const createdConversation = await createConversation(client, body);
    tableRef.current?.refresh();
    navigate(
      `/stores/${team?._id}/support/table/all?activeConversationId=${createdConversation._id}`,
    );
  };

  const bulkCleanupFn = (clearSelected?: boolean) => {
    if (selectAllMode) {
      setIteratorRefresh(Symbol());
    }
    if (clearSelected) {
      setSelectedConversations([]);
      if (selectAllMode) {
        setDeselectedConversations([]);
        setSelectAllMode(false);
      }
    }
    tableRef.current?.refresh();
    setPerformSelectAllActionImmediately(false);
  };

  const handleChangeSubject = (conversation: ExpandedConversation) => {
    setIsBulkAction(false);
    setConversationNeedingAction(conversation);
    setChangeSubjectModalOpen(true);
  };

  const singleCleanupFn = (clearSelected?: boolean) => {
    if (clearSelected) {
      setSelectedConversations(
        selectedConversations.filter(
          (conversation) => conversation._id !== conversationNeedingAction?._id,
        ),
      );
    }
    tableRef.current?.refresh();
    setConversationNeedingAction(undefined);
  };

  useEffect(() => {
    let signal = abort.signal;
    if (abort) {
      abort.abort();
      const newAbort = new AbortController();
      setAbort(newAbort);
      signal = newAbort.signal;
    }
    setBulkActionIterator(bulkActionFetcher(10, signal, filters));
    if (bulkActionIterator?.return) {
      void bulkActionIterator.return();
    }
  }, [filters, iteratorRefresh]);

  useEffect(() => {
    if (deselectedConversations.length === selectAllCount) {
      setSelectAllMode(false);
      setSelectedConversations([]);
      setDeselectedConversations([]);
    }
  }, [deselectedConversations.length, selectAllCount]);

  useEffect(() => {
    if (!tagModalOpen) {
      reloadConversationTags();
    }
  }, [tagModalOpen]);

  useEffect(() => {
    if (searchString !== undefined) {
      amplitude.logEvent("search-conversation", {
        mode: "view",
        searchString,
        view,
      });
      setFilters({
        ...filters,
        search: debouncedSearch,
        status:
          statusFilter === "all"
            ? Object.values(FiltersStatus)
            : [statusFilter],
      });
    }
  }, [debouncedSearch]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const initialSearch = queryParams.get("initialSearch");

    let newFilters: FilterOptions = {
      ...filters,
      sort: {
        key:
          (queryParams.get("sort") as SortableConversationTableColumn) ||
          view?.filters.sort?.key ||
          defaultConversationsTableSort.key,
        direction:
          (queryParams.get("direction") as SortDirection) ||
          view?.filters.sort?.direction ||
          defaultConversationsTableSort.direction,
      },
    };

    if (initialSearch) {
      setSearchString(initialSearch);
      newFilters =
        Object.keys(filters).length > 0
          ? newFilters
          : view?.filters || initialFilters;
      setFilters({
        ...newFilters,
        status: Object.values(FiltersStatus),
      });
      setStatusFilter("all");

      queryParams.delete("initialSearch");
      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    } else {
      setFilters(newFilters);
    }
  }, [location, navigate]);

  useEffect(() => {
    if (filters.search !== searchString) {
      setSearchString(filters.search);
    }
    amplitude.logEvent("view-supportTable", { view });
  }, []);

  const isShowingClose =
    (canCloseTicket &&
      !selectAllMode &&
      selectedConversations.some(
        (conversation) => conversation.status !== "closed",
      )) ||
    (selectAllMode && selectAllCount && statusFilter !== "closed");
  const actionButtons = [
    // Assign
    {
      show:
        canEditAssignee &&
        ((!selectAllMode && selectedConversations.length > 0) ||
          (selectAllMode && selectAllCount)),
      dropdownComponent: (
        <DropdownOption action={handleBulkAssign} key="Assign">
          <div className={conversationsTableCss.actionButton}>
            <UserPlusIcon className={conversationsTableCss.icon} />
            Assign
          </div>
        </DropdownOption>
      ),
      buttonComponent: (
        <Button
          onClick={handleBulkAssign}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          <div className={conversationsTableCss.actionButton}>
            <UserPlusIcon className={conversationsTableCss.icon} />
            Assign
          </div>
        </Button>
      ),
    },
    // Tag
    {
      show: canAssignTag,
      dropdownComponent: (
        <DropdownOption action={handleBulkAddTags} key="Edit tags">
          <div className={conversationsTableCss.actionButton}>
            <TagIcon className={conversationsTableCss.icon} />
            {selectedConversations.length > 1 || selectAllMode
              ? "Add tags"
              : "Edit tags"}
          </div>
        </DropdownOption>
      ),
      buttonComponent: (
        <Button
          onClick={handleBulkAddTags}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          <div className={conversationsTableCss.actionButton}>
            <TagIcon className={conversationsTableCss.icon} />
            {selectedConversations.length > 1 || selectAllMode
              ? "Add tags"
              : "Edit tags"}
          </div>
        </Button>
      ),
    },
    // Mark unread
    {
      show: true,
      dropdownComponent: (
        <DropdownOption action={handleBulkMarkUnread} key="Mark unread">
          <div className={conversationsTableCss.actionButton}>
            <EnvelopeIcon className={conversationsTableCss.icon} />
            Mark unread
          </div>
        </DropdownOption>
      ),
      buttonComponent: (
        <Button
          onClick={handleBulkMarkUnread}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          <div className={conversationsTableCss.actionButton}>
            <EnvelopeIcon className={conversationsTableCss.icon} />
            Mark unread
          </div>
        </Button>
      ),
    },
    // Mark read
    {
      show: true,
      dropdownComponent: (
        <DropdownOption action={handleBulkMarkRead} key="Mark read">
          <div className={conversationsTableCss.actionButton}>
            <EnvelopeOpen className={conversationsTableCss.icon} />
            Mark read
          </div>
        </DropdownOption>
      ),
      buttonComponent: (
        <Button
          onClick={handleBulkMarkRead}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          <div className={conversationsTableCss.actionButton}>
            <EnvelopeOpen className={conversationsTableCss.icon} />
            Mark read
          </div>
        </Button>
      ),
    },
    // Mark as spam
    {
      show:
        canCloseTicket &&
        ((!selectAllMode &&
          selectedConversations.some(
            (conversation) =>
              conversation.platform === ConversationPlatform.EMAIL,
          )) ||
          (selectAllMode && selectAllCount && false)), // How can we check that we're only looking at emails?
      dropdownComponent: (
        <DropdownOption action={handleBulkMarkSpam} key="Mark spam">
          <div className={conversationsTableCss.actionButton}>
            <SpamIcon className={conversationsTableCss.icon} />
            Mark spam
          </div>
        </DropdownOption>
      ),
      buttonComponent: (
        <Button
          onClick={handleBulkMarkSpam}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          <div className={conversationsTableCss.actionButton}>
            <SpamIcon className={conversationsTableCss.icon} />
            Mark spam
          </div>
        </Button>
      ),
    },
    // Change subject
    {
      show:
        canReply &&
        !selectAllMode &&
        selectedConversations.length === 1 &&
        selectedConversations[0].platform === ConversationPlatform.EMAIL,
      dropdownComponent: (
        <DropdownOption
          action={() => handleChangeSubject(selectedConversations[0])}
          key="Change subject"
        >
          <div className={conversationsTableCss.actionButton}>
            <Edit04Icon className={conversationsTableCss.icon} />
            Change subject
          </div>
        </DropdownOption>
      ),
      buttonComponent: (
        <Button
          onClick={() => handleChangeSubject(selectedConversations[0])}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          <div className={conversationsTableCss.actionButton}>
            <Edit04Icon className={conversationsTableCss.icon} />
            Change subject
          </div>
        </Button>
      ),
    },
    // Merge
    {
      show:
        canCloseTicket &&
        ((!selectAllMode &&
          selectedConversations.length > 1 &&
          selectedConversations.length <= 20 &&
          !selectedConversations.some((conversation) =>
            [
              ConversationPlatform.INSTAGRAM_COMMENTS,
              ConversationPlatform.FACEBOOK_COMMENTS,
            ].includes(conversation.platform),
          )) ||
          (selectAllMode &&
            selectAllCount &&
            selectAllCount - deselectedConversations.length > 1 &&
            selectAllCount - deselectedConversations.length <= 20)),
      dropdownComponent: (
        <DropdownOption action={handleBulkMerge} key="Merge">
          <div className={conversationsTableCss.actionButton}>
            <MergeIcon className={conversationsTableCss.icon} />
            Merge
          </div>
        </DropdownOption>
      ),
      buttonComponent: (
        <Button
          onClick={handleBulkMerge}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          <div className={conversationsTableCss.actionButton}>
            <MergeIcon className={conversationsTableCss.icon} />
            Merge
          </div>
        </Button>
      ),
    },
    // Archive
    {
      show:
        canArchiveTicket &&
        ((!selectAllMode && selectedConversations.length > 0) ||
          (selectAllMode && selectAllCount)),
      dropdownComponent: (
        <DropdownOption action={handleBulkArchive} key="Delete">
          <div className={conversationsTableCss.actionButton}>
            <ArchiveIcon className={conversationsTableCss.icon} />
            Delete
          </div>
        </DropdownOption>
      ),
      buttonComponent: (
        <Button
          onClick={handleBulkArchive}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          <div className={conversationsTableCss.actionButton}>
            <ArchiveIcon className={conversationsTableCss.icon} />
            Delete
          </div>
        </Button>
      ),
    },
    // Snooze
    {
      show:
        canReply &&
        ((!selectAllMode &&
          selectedConversations.some(
            (conversation) => conversation.status !== "closed",
          )) ||
          (selectAllMode && selectAllCount && statusFilter !== "closed")),
      dropdownComponent: (
        <DropdownOption action={handleBulkSnooze} key="Snooze">
          <div className={conversationsTableCss.actionButton}>
            <SnoozeClockIcon className={conversationsTableCss.icon} />
            Snooze
          </div>
        </DropdownOption>
      ),
      buttonComponent: (
        <Button
          onClick={handleBulkSnooze}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          <div className={conversationsTableCss.actionButton}>
            <SnoozeClockIcon className={conversationsTableCss.icon} />
            Snooze
          </div>
        </Button>
      ),
    },
    // Reopen
    {
      show:
        canCloseTicket &&
        ((!selectAllMode &&
          selectedConversations.some(
            (conversation) => conversation.status === "closed",
          )) ||
          (selectAllMode &&
            selectAllCount &&
            ["closed", "all"].includes(statusFilter))),
      buttonComponent: (
        <Button
          onClick={handleBulkReopen}
          size={ButtonSize.SMALL}
          theme={isShowingClose ? ButtonTheme.OUTLINED : ButtonTheme.PRIMARY}
        >
          <div className={conversationsTableCss.actionButton}>
            <UndoArrow className={conversationsTableCss.icon} />
            Reopen
          </div>
        </Button>
      ),
    },
    // Close
    {
      show: isShowingClose,
      buttonComponent: (
        <Button
          onClick={handleBulkClose}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.PRIMARY}
        >
          <div className={conversationsTableCss.actionButton}>
            <CheckCircle className={conversationsTableCss.icon} />
            Close
          </div>
        </Button>
      ),
    },
  ];

  const enabledActionButtons = pageLoaded
    ? actionButtons.filter((actionButton) => actionButton.show)
    : [];

  const actionsDropdown = enabledActionButtons.length > 2 && (
    <>
      <div className={conversationsTableCss.dropdownTitle}>Other actions</div>
      {enabledActionButtons
        .slice(0, enabledActionButtons.length - 2)
        .map((actionButton) => actionButton.dropdownComponent)}
    </>
  );

  const conversationsTableSelectionStateUtil: ConversationsTableSelectionStateUtil =
    {
      selectedConversations,
      setSelectedConversations,
      deselectedConversations,
      setDeselectedConversations,
      selectAllMode,
      setSelectAllMode,
      selectAllCount,
    };

  return (
    <>
      <div className={conversationsTableCss.contentContainer}>
        <div
          className={classNames(conversationsTableCss.contentHeader, {
            [conversationsTableCss.scrolledTop]: scrolled.top,
          })}
        >
          <div className={conversationsTableCss.search}>
            <TextInput
              button={
                viewName === "all" && statusFilter === "all"
                  ? undefined
                  : !!searchString && (
                      <Button
                        onClick={handleSearchAll}
                        size={ButtonSize.MICRO}
                        theme={ButtonTheme.SOLID_LIGHT}
                      >
                        Search all
                      </Button>
                    )
              }
              icon={
                searchString ? (
                  <XIcon onClick={() => setSearchString("")} />
                ) : (
                  <SearchIcon />
                )
              }
              onChange={setSearchString}
              placeholder="Search"
              value={searchString || ""}
            />
            <IconButton
              onClick={() => {
                amplitude.logEvent("view-filterModal");
                setFilterModalOpen(true);
              }}
              size={ButtonSize.SMALL}
            >
              <FilterIcon />
            </IconButton>
          </div>
          <div className={conversationsTableCss.actions}>
            {team && (
              <StatusDropdown
                conversationCounts={conversationCounts || {}}
                setStatusFilter={setStatusFilter}
                statusFilter={statusFilter}
                viewName={viewName}
              />
            )}
            {!selectAllMode && selectedConversations.length > 0 ? (
              <div className={conversationsTableCss.actionButtons}>
                <div
                  className={conversationsTableCss.selectedConversationsCount}
                >
                  {selectedConversations.length} ticket
                  {selectedConversations.length > 1 ? "s" : ""} selected
                </div>
                {actionsDropdown && (
                  <div className={conversationsTableCss.threeDots}>
                    <ButtonDropdown
                      dropdown={actionsDropdown}
                      restrictDropdownSizeToParent={false}
                      size={ButtonSize.MICRO}
                      theme={ButtonTheme.OUTLINED}
                    >
                      <div className={conversationsTableCss.actionButton}>
                        <ThreeDotsVerticalIcon
                          className={conversationsTableCss.icon}
                        />
                      </div>
                    </ButtonDropdown>
                  </div>
                )}
                {enabledActionButtons
                  .slice(enabledActionButtons.length - 2)
                  .map((actionButton) => actionButton.buttonComponent)}
              </div>
            ) : selectAllMode ? (
              selectAllCount ? (
                <div className={conversationsTableCss.actionButtons}>
                  <div
                    className={conversationsTableCss.selectedConversationsCount}
                  >
                    {selectAllCount - deselectedConversations.length} ticket
                    {selectAllCount - deselectedConversations.length > 1
                      ? "s"
                      : ""}{" "}
                    selected
                  </div>
                  {actionsDropdown && (
                    <div className={conversationsTableCss.threeDots}>
                      <ButtonDropdown
                        dropdown={actionsDropdown}
                        restrictDropdownSizeToParent={false}
                        size={ButtonSize.MICRO}
                        theme={ButtonTheme.OUTLINED}
                      >
                        <div className={conversationsTableCss.actionButton}>
                          <ThreeDotsVerticalIcon
                            className={conversationsTableCss.icon}
                          />
                        </div>
                      </ButtonDropdown>
                    </div>
                  )}
                  {enabledActionButtons
                    .slice(enabledActionButtons.length - 2)
                    .map((actionButton) => actionButton.buttonComponent)}
                </div>
              ) : (
                <div className={conversationsTableCss.spinner}>
                  <LoadingRedoAnimation />
                </div>
              )
            ) : (
              canEditSettings &&
              view && (
                <ButtonSelect
                  dropdownDisplay={() => (
                    <div
                      className={classNames(
                        conversationsTableCss.actionButton,
                        conversationsTableCss.red,
                      )}
                    >
                      <TrashIcon className={conversationsTableCss.icon} />
                      Delete view
                    </div>
                  )}
                  onClick={() => setEditViewModalOpen(true)}
                  onValueChange={() => setDeleteViewModalOpen(true)}
                  options={["Delete view"]}
                  size={ButtonSize.SMALL}
                  staticValue="Edit view"
                  theme={ButtonTheme.PRIMARY}
                />
              )
            )}
          </div>
        </div>
        <ConversationsTable
          dropdownActions={dropdownActions}
          fetcher={fetcher}
          filters={
            Object.keys(filters).length > 0
              ? filters
              : view?.filters || initialFilters
          }
          handleCardClick={setActiveConversation}
          onConversationsViewed={onConversationsViewed}
          scrollAreaRef={scrollAreaRef}
          selectionStateUtil={conversationsTableSelectionStateUtil}
          tableRef={tableRef}
          view={view}
        />
      </div>
      <FilterModal
        applyFilters={applyFilters}
        createView={handleCreateView}
        current={
          Object.keys(filters).length > 0
            ? filters
            : view?.filters || initialFilters
        }
        defaults={initialFilters}
        open={filterModalOpen}
        setOpen={setFilterModalOpen}
      />
      <CreateConversationModal
        createConversation={handleCreateConversation}
        open={createConversationModalOpen}
        setOpen={setCreateConversationModalOpen}
      />
      {closeModalOpen &&
        (selectedConversations.length > 0 ||
          conversationNeedingAction ||
          (selectAllMode && selectAllCount && bulkActionIterator)) && (
          <CloseTicketModal
            cleanupFn={
              isBulkAction || selectAllMode ? bulkCleanupFn : singleCleanupFn
            }
            conversations={
              isBulkAction
                ? selectedConversations
                : selectAllMode
                  ? []
                  : [conversationNeedingAction!]
            }
            inDetailView={false}
            open={closeModalOpen}
            selectAllInfo={
              selectAllMode && selectAllCount && bulkActionIterator
                ? {
                    count: selectAllCount,
                    deselectedConversations,
                    bulkActionIterator,
                    setBlockRefresh,
                    closeImmediately: performSelectAllActionImmediately,
                  }
                : undefined
            }
            setActiveConversation={setActiveConversation}
            setOpen={setCloseModalOpen}
          />
        )}
      {reopenModalOpen &&
        (selectedConversations.length > 0 ||
          conversationNeedingAction ||
          (selectAllMode && selectAllCount && bulkActionIterator)) && (
          <ReopenTicketModal
            cleanupFn={
              isBulkAction || selectAllMode ? bulkCleanupFn : singleCleanupFn
            }
            conversations={
              isBulkAction
                ? selectedConversations
                : selectAllMode
                  ? []
                  : [conversationNeedingAction!]
            }
            inDetailView={false}
            open={reopenModalOpen}
            selectAllInfo={
              selectAllMode && selectAllCount && bulkActionIterator
                ? {
                    count: selectAllCount,
                    deselectedConversations,
                    bulkActionIterator,
                    setBlockRefresh,
                  }
                : undefined
            }
            setActiveConversation={setActiveConversation}
            setOpen={setReopenModalOpen}
          />
        )}
      {snoozeModalOpen &&
        setSnoozeModalOpen !== undefined &&
        (selectedConversations.length > 0 ||
          conversationNeedingAction ||
          (selectAllMode && selectAllCount && bulkActionIterator)) && (
          <SnoozeModal
            cleanupFn={
              isBulkAction || selectAllMode ? bulkCleanupFn : singleCleanupFn
            }
            conversations={
              isBulkAction
                ? selectedConversations
                : selectAllMode
                  ? []
                  : [conversationNeedingAction!]
            }
            inDetailView={false}
            open={snoozeModalOpen}
            selectAllInfo={
              selectAllMode && selectAllCount && bulkActionIterator
                ? {
                    count: selectAllCount,
                    deselectedConversations,
                    bulkActionIterator,
                    setBlockRefresh,
                  }
                : undefined
            }
            setActiveConversation={setActiveConversation}
            setOpen={setSnoozeModalOpen}
          />
        )}
      {archiveModalOpen &&
        (selectedConversations.length > 0 ||
          conversationNeedingAction ||
          (selectAllMode && selectAllCount && bulkActionIterator)) && (
          <ArchiveTicketModal
            cleanupFn={
              isBulkAction || selectAllMode ? bulkCleanupFn : singleCleanupFn
            }
            conversations={
              isBulkAction
                ? selectedConversations
                : selectAllMode
                  ? []
                  : [conversationNeedingAction!]
            }
            inDetailView={false}
            open={archiveModalOpen}
            selectAllInfo={
              selectAllMode && selectAllCount && bulkActionIterator
                ? {
                    count: selectAllCount,
                    deselectedConversations,
                    bulkActionIterator,
                    setBlockRefresh,
                  }
                : undefined
            }
            setActiveConversation={setActiveConversation}
            setOpen={setArchiveModalOpen}
          />
        )}
      {mergeModalOpen &&
        (selectedConversations.length > 0 ||
          conversationNeedingAction ||
          (selectAllMode && selectAllCount && bulkActionIterator)) && (
          <MergeModal
            conversations={
              isBulkAction
                ? selectedConversations
                : selectAllMode
                  ? []
                  : [conversationNeedingAction!]
            }
            open={mergeModalOpen}
            selectAllInfo={
              selectAllMode && selectAllCount && bulkActionIterator
                ? {
                    count: selectAllCount,
                    deselectedConversations,
                    filters,
                    setBlockRefresh,
                  }
                : undefined
            }
            setActiveConversation={setActiveConversation}
            setOpen={setMergeModalOpen}
          />
        )}
      {assignModalOpen &&
        (selectedConversations.length > 0 ||
          conversationNeedingAction ||
          (selectAllMode && selectAllCount && bulkActionIterator)) && (
          <AssignTicketModal
            cleanupFn={
              isBulkAction || selectAllMode ? bulkCleanupFn : singleCleanupFn
            }
            conversations={
              isBulkAction
                ? selectedConversations
                : selectAllMode
                  ? []
                  : [conversationNeedingAction!]
            }
            open={assignModalOpen}
            selectAllInfo={
              selectAllMode && selectAllCount && bulkActionIterator
                ? {
                    count: selectAllCount,
                    deselectedConversations,
                    bulkActionIterator,
                    setBlockRefresh,
                  }
                : undefined
            }
            setOpen={setAssignModalOpen}
          />
        )}
      {tagModalOpen &&
        (selectedConversations.length > 0 ||
          conversationNeedingAction ||
          (selectAllMode && selectAllCount && bulkActionIterator)) && (
          <TagsModal
            cleanupFn={
              isBulkAction || selectAllMode ? bulkCleanupFn : singleCleanupFn
            }
            conversations={
              isBulkAction
                ? selectedConversations
                : selectAllMode
                  ? []
                  : [conversationNeedingAction!]
            }
            open={tagModalOpen}
            selectAllInfo={
              selectAllMode && selectAllCount && bulkActionIterator
                ? {
                    count: selectAllCount,
                    deselectedConversations,
                    bulkActionIterator,
                    setBlockRefresh,
                  }
                : undefined
            }
            setOpen={setTagModalOpen}
          />
        )}
      {markSpamModalOpen && (
        <MarkSpamModal
          cleanupFn={
            isBulkAction || selectAllMode ? bulkCleanupFn : singleCleanupFn
          }
          conversations={
            isBulkAction
              ? selectedConversations
              : selectAllMode
                ? []
                : [conversationNeedingAction!]
          }
          inDetailView={false}
          open={markSpamModalOpen}
          setActiveConversation={setActiveConversation}
          setOpen={setMarkSpamModalOpen}
        />
      )}
      {changeSubjectModalOpen && (
        <ChangeSubjectModal
          conversation={conversationNeedingAction!}
          resolve={() => setChangeSubjectModalOpen(false)}
        />
      )}
      <ProgressModal
        open={progressModalInfo.open}
        progress={progressModalInfo.progress}
        setOpen={(open: boolean) =>
          setProgressModalInfo({ ...progressModalInfo, open })
        }
        title={progressModalInfo.title}
      />
      {view && (
        <>
          <EditViewModal
            open={editViewModalOpen}
            setOpen={setEditViewModalOpen}
            view={view}
          />
          <DeleteViewModal
            open={deleteViewModalOpen}
            setOpen={setDeleteViewModalOpen}
            view={view}
          />
        </>
      )}
      {actionsPortal &&
        canReply &&
        createPortal(
          <Button
            onClick={() => {
              amplitude.logEvent("view-createConversationModal");
              setCreateConversationModalOpen(true);
            }}
            size={ButtonSize.SMALL}
            theme={ButtonTheme.GHOST}
          >
            <div className={conversationsTableCss.actionButton}>
              <PlusIcon className={conversationsTableCss.icon} />
              Create new ticket
            </div>
          </Button>,
          actionsPortal,
        )}
    </>
  );
});

const ProgressModal = memo(function ProgressModal({
  open,
  setOpen,
  title,
  progress,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  progress: number;
}) {
  return (
    <Modal
      onClose={() => setOpen(false)}
      open={open}
      size={ModalSize.SMALL}
      title={title}
    >
      <ProgressBar value={progress} />
    </Modal>
  );
});
