import { z } from "zod";

export const updateReviewSchema = {
  input: z.object({
    _id: z.string(),
    teamId: z.string(),
    status: z.optional(
      z.enum(["pending", "approved", "rejected", "manualReview"]),
    ),
    verifiedPurchase: z.optional(z.boolean()),
    rating: z.optional(z.enum(["1", "2", "3", "4", "5"])),
  }),
  output: z.literal("success"),
};
