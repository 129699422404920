import { useHandler } from "@redotech/react-util/hook";
import { Attachment } from "@redotech/redo-model/createconversationbody";
import { ActionType, AutoReply } from "@redotech/redo-model/return-flow/action";
import { Permission, permitted } from "@redotech/redo-model/user";
import InfoSvg from "@redotech/redo-web/arbiter-icon/info-circle.svg";
import { LabeledInput, LabelPosition } from "@redotech/redo-web/labeled-input";
import { Switch } from "@redotech/redo-web/switch";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import Quill from "quill";
import { useContext, useEffect, useRef, useState } from "react";
import { TeamContext } from "../../app/team";
import { UserContext } from "../../app/user";
import { ClientUploader } from "../../client/upload";
import { StepRichTextEditor } from "../chat-flow/step-rich-text";
import * as autoReplyCss from "./auto-reply.module.css";
import { ActionTypeSetup } from "./multiple-actions";

export const AUTO_REPLY: ActionTypeSetup<
  { textContent: string; htmlContent: string; attachments?: Attachment[] },
  AutoReply
> = {
  name: "Send message",
  description() {
    return "Automatically replies with your provided message";
  },
  Details({ state, setState, disabled }) {
    const signatureMarker = "\u200B";
    const user = useContext(UserContext);
    const team = useContext(TeamContext);
    const [showPlaintext, setShowPlaintext] = useState(false);
    const canEditSettings =
      !!user && permitted(user.permissions, Permission.EDIT_SETTINGS);

    const quillRef = useRef<Quill | undefined>(undefined);

    const handleMessage = useHandler(() => {
      if (!quillRef.current) {
        return;
      }
      let textContent = quillRef.current.getText();
      let htmlContent: string | undefined = quillRef.current.getSemanticHTML();
      if (textContent === "\n") {
        textContent = "";
      }
      if (htmlContent === "<p></p>") {
        htmlContent = "";
      }

      if (
        // Quill converts img tags to base 64, which won't match the url-format
        // img tags we have stored, so just check that img tags exist in the same
        // location, not what they contain
        neuterImgTags(htmlContent) !== neuterImgTags(state.htmlContent) ||
        textContent !== state.textContent
      ) {
        setState((state) => ({ ...state, textContent, htmlContent }));
      }
    });

    const handleAttachmentUpload = (attachment: Attachment) => {
      setState((state) => ({
        ...state,
        attachments: [...(state.attachments || []), attachment],
      }));
    };

    const handleAttachmentRemoval = (url: string) => {
      setState((state) => ({
        ...state,
        attachments: (state.attachments || []).filter(
          (attachment: Attachment) => attachment.url !== url,
        ),
      }));
    };

    useEffect(() => {
      if (quillRef.current && team?.settings?.support?.emailSignature) {
        // We use unicode character \u200B (zero-width space) to mark the start of the signature.
        const signature = `<br><br><p>&#x200B;--</p>${team?.settings?.support?.emailSignature}`;
        const quill: Quill = quillRef.current;
        const signatureIndex = quill.getText().indexOf(signatureMarker);
        if (signatureIndex === -1) {
          quill.clipboard.dangerouslyPasteHTML(
            quill.getLength() - 1,
            signature,
          );
        }
      }
    }, [team]);

    return (
      <ClientUploader>
        <LabeledInput
          label={
            <div className={autoReplyCss.labelContainer}>
              <p>Message to customer</p>
              <LabeledInput
                label={
                  <div className={autoReplyCss.label}>
                    <Tooltip
                      placement="bottom-start"
                      title="The formatted version of the message will be used when replying to email messages. When responding to other message types such as Faceboook or SMS, the plaintext message will be sent."
                    >
                      <div className={autoReplyCss.infoIcon}>
                        <InfoSvg />
                      </div>
                    </Tooltip>
                    Show plaintext
                  </div>
                }
                position={LabelPosition.LEFT}
              >
                <Switch onChange={setShowPlaintext} value={showPlaintext} />
              </LabeledInput>
            </div>
          }
        >
          {showPlaintext && (
            <p className={autoReplyCss.readonlyText}>
              {stripSignature(state.textContent)}
            </p>
          )}
          <div
            className={
              showPlaintext ? autoReplyCss.hideEditor : autoReplyCss.showEditor
            }
          >
            <StepRichTextEditor
              attachments={state.attachments}
              enableAttachments
              htmlValue={state.htmlContent}
              onAttachmentRemoval={handleAttachmentRemoval}
              onAttachmentUpload={handleAttachmentUpload}
              onTextChange={handleMessage}
              quillRef={quillRef}
              readOnly={!canEditSettings}
              textValue={state.textContent}
            />
          </div>
        </LabeledInput>
      </ClientUploader>
    );
  },
  empty: { textContent: "", htmlContent: "", attachments: [] },
  fromModel(model) {
    return {
      textContent: model.textContent,
      htmlContent: model.htmlContent,
      attachments: model.attachments,
    };
  },
  toModel(state) {
    return {
      type: ActionType.AutoReply,
      htmlContent: state.htmlContent,
      textContent: state.textContent,
      attachments: state.attachments,
    };
  },
  valid(state) {
    return !!(state.textContent && state.htmlContent);
  },
};

const stripSignature = (textContent: string) => {
  const signatureMarker = "\u200B";
  const signatureIndex = textContent.indexOf(signatureMarker);
  if (signatureIndex !== -1) {
    textContent = textContent.substring(0, signatureIndex) + "\n";
    const i = 2;
    while (textContent.endsWith("\n\n") && i > 0) {
      textContent = textContent.substring(0, textContent.length - 1);
    }
  }
  return textContent;
};

const neuterImgTags = (htmlContent: string) => {
  const imgRegex = /<img.*?>/g;
  return htmlContent.replace(imgRegex, "<img />");
};
