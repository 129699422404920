// binarySearch returns the element in the array that is closest to the target value.
export function binarySearch<T>(
  items: T[],
  target: number,
  getValue: (item: T) => number,
): T {
  let start = 0;
  let end = items.length - 1;
  while (start <= end) {
    const mid = Math.floor((start + end) / 2);
    const value = getValue(items[mid]);
    if (value === target) {
      start = mid;
      break;
    } else if (value < target) {
      start = mid + 1;
    } else {
      end = mid - 1;
    }
  }
  if (start >= items.length) {
    start = items.length - 1;
  }
  return items[start];
}

export function partition<T>(
  items: T[],
  condition: (item: T) => boolean,
): [T[], T[]] {
  const truePartition: T[] = [];
  const falsePartition: T[] = [];

  items.forEach((item) => {
    if (condition(item)) {
      truePartition.push(item);
    } else {
      falsePartition.push(item);
    }
  });
  return [falsePartition, truePartition];
}

export function unique<T>(items: T[], uniqueKey?: (item: T) => string): T[] {
  if (uniqueKey) {
    const map = new Map<string, T>();
    items.forEach((item) => {
      map.set(uniqueKey(item), item);
    });
    return Array.from(map.values());
  } else {
    return Array.from(new Set(items));
  }
}

/**
 * Filter on javascript's definition of truthy. Use with care as it filters out 0 and empty strings.
 */
export function filterTruthy<T>(items: (T | null | undefined | false)[]): T[] {
  return items.filter(Boolean) as T[];
}
