import { countries } from "@redotech/locale/countries";
import { Order } from "@redotech/redo-model/order";
import EditPencilIcon from "@redotech/redo-web/icon-old/edit-pencil2.svg";
import { memo } from "react";
import { ButtonSize, IconButton } from "./button";
import * as shippingContactInfoCss from "./shipping-contact-info.module.css";

const shippingLinesToAddress = (shippingLines: any) => {
  return {
    street1: shippingLines.address1,
    street2: shippingLines.address2,
    state: shippingLines.province_code,
    city: shippingLines.city,
    country: shippingLines.country_code,
    zip: shippingLines.zip,
    firstName: shippingLines.first_name,
    lastName: shippingLines.last_name,
  };
};

const orderToContactInfo = (order: Order) => {
  return {
    name: "",
    phone: order.shopify.phone,
    email: order.shopify.email,
  };
};

export const ShippingContactInfo = memo(function ShippingContactInfo({
  order,
  onShippingButtonClick,
  onContactButtonClick,
}: {
  order: Order;
  onShippingButtonClick?: () => void;
  onContactButtonClick?: () => void;
}) {
  const shippingLines = order.shopify?.shipping_address || {};
  const address = shippingLinesToAddress(shippingLines);
  const contactInfo = orderToContactInfo(order);
  return (
    <div className={shippingContactInfoCss.infoSectionContainer}>
      <div className={shippingContactInfoCss.infoSection}>
        <div className={shippingContactInfoCss.infoSectionTitleRow}>
          <p className={shippingContactInfoCss.strong}>Shipping address</p>
          {onShippingButtonClick && (
            <IconButton onClick={onShippingButtonClick} size={ButtonSize.SMALL}>
              <EditPencilIcon />
            </IconButton>
          )}
        </div>
        <div className={shippingContactInfoCss.multiLineInfo}>
          <p>
            {address.firstName}
            {address.lastName && ` ${address.lastName}`}
          </p>
          <p>{address.street1}</p>
          {address.street2 && <p>{address.street2}</p>}
          <p>
            {address.city}, {address.state} {address.zip}
          </p>
          <p>
            {countries.find((country: any) => address.country === country.code)
              ?.name || address.country}
          </p>
        </div>
      </div>
      <div className={shippingContactInfoCss.infoSection}>
        <div className={shippingContactInfoCss.infoSectionTitleRow}>
          <p className={shippingContactInfoCss.strong}>Contact information</p>
          {onContactButtonClick && (
            <IconButton onClick={onContactButtonClick} size={ButtonSize.SMALL}>
              <EditPencilIcon />
            </IconButton>
          )}
        </div>
        <div className={shippingContactInfoCss.multiLineInfo}>
          <p>{contactInfo.email}</p>
          {contactInfo.phone && <p>{contactInfo.phone}</p>}
        </div>
      </div>
    </div>
  );
});
