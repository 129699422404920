export type ExceptionalStatus =
  | "failure"
  | "cancelled"
  | "error"
  | "delayed"
  | "return_to_sender";

export const exceptionalStatuses: ExceptionalStatus[] = [
  "failure",
  "cancelled",
  "error",
  "delayed",
  "return_to_sender",
];

export const exceptionalStatusVerbiage: Record<
  ExceptionalStatus,
  (merchantName: string) => string
> = {
  failure: (_) => "Delivery has failed. Please contact carrier for details",
  cancelled: (merchantName) =>
    `Your order has been cancelled. Please contact ${merchantName} for more information.`,
  error: (_) =>
    "Unknown delivery error, please contact the carrier for more details.",
  delayed: (_) => "Carrier has marked your package as a delayed delivery.",
  return_to_sender: (merchantName) =>
    `Carrier was not able to deliver package. Please contact ${merchantName} for more information.`,
};

// when a failure status is reached, it stays as that status until
// one of the following statuses follows it, after which it is unfrozen
export const recoveryStatuses: Record<ExceptionalStatus, string[]> = {
  failure: ["delivered"],
  cancelled: [],
  error: ["delivered"],
  delayed: ["out_for_delivery", "delivered"],
  return_to_sender: [],
};

export function getMostRecentUnrecoveredExceptionalStatus(
  trackingEvents: {
    status: string;
  }[],
) {
  return trackingEvents.find(
    (detail, index) =>
      (exceptionalStatuses as string[]).includes(detail.status) &&
      !trackingEvents
        .slice(index + 1)
        .some((d) => recoveryStatuses[detail.status].includes(d.status)),
  );
}
