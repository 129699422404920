import { useHover } from "@redotech/react-util/hover";
import { ConversationTagId } from "@redotech/redo-model/conversation";
import { Permission, permitted } from "@redotech/redo-model/user";
import DotsVerticalIcon from "@redotech/redo-web/arbiter-icon/dots-vertical_filled.svg";
import { IconButton } from "@redotech/redo-web/button";
import { Flex } from "@redotech/redo-web/flex";
import CheckIcon from "@redotech/redo-web/icon-old/check.svg";
import PlusIcon from "@redotech/redo-web/icon-old/plus.svg";
import { Text } from "@redotech/redo-web/text";
import * as classNames from "classnames";
import { memo, useContext, useState } from "react";
import { UserContext } from "../../app/user";
import * as conversationTagInputCss from "./conversation-tag-input.module.css";
import { ConversationTagPill } from "./conversation-tag-pill";

export const AvailableConversationTagsDropdown = memo(
  function AvailableConversationTagsDropdown({
    availableTags,
    selectedTags,
    tagSelected,
    editTagRequested,
    createTagRequested,
  }: {
    availableTags: ConversationTagId[];
    selectedTags: ConversationTagId[];
    tagSelected(tag: ConversationTagId): void;
    editTagRequested(tag: ConversationTagId): void;
    createTagRequested(): void;
  }) {
    const user = useContext(UserContext);
    const canCreateTags =
      !!user && permitted(user.permissions, Permission.MANAGE_TAG);

    const [hoverAnchor, setHoverAnchor] = useState<HTMLElement | null>(null);

    const hovered = useHover(hoverAnchor);

    const tagOptions = availableTags.map((tag, index) => (
      <TagPillOption
        editSelected={() => editTagRequested(tag)}
        key={tag.name}
        manuallyFocus={hovered ? false : index === 0}
        optionSelected={() => tagSelected(tag)}
        selected={selectedTags.some(
          (selectedTag) => selectedTag.name === tag.name,
        )}
        tag={tag}
      />
    ));

    if (canCreateTags) {
      tagOptions.push(
        <CreateTagOption
          createClicked={createTagRequested}
          key="%create-tag"
        />,
      );
    }

    return (
      <Flex dir="column" gap="none" ref={setHoverAnchor}>
        {[...tagOptions]}
      </Flex>
    );
  },
);

const TagPillOption = memo(function TagPillOption({
  tag,
  optionSelected,
  editSelected,
  manuallyFocus,
  selected,
}: {
  selected: boolean;
  tag: ConversationTagId;
  optionSelected(): void;
  editSelected(): void;
  manuallyFocus: boolean;
}) {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const isHovered = useHover(anchor);

  const rightIcon = isHovered ? (
    <IconButton onClick={editSelected}>
      <DotsVerticalIcon />
    </IconButton>
  ) : selected ? (
    <Flex p="md">
      <CheckIcon className={conversationTagInputCss.check} />
    </Flex>
  ) : null;

  return (
    <Flex
      align="center"
      as="button"
      className={classNames(
        manuallyFocus && conversationTagInputCss.manualFocus,
        conversationTagInputCss.optionButton,
      )}
      gap="none"
      justify="space-between"
      onClick={optionSelected}
      p="xs"
      radius="md"
      ref={setAnchor}
    >
      <ConversationTagPill tag={tag} />

      {rightIcon}
    </Flex>
  );
});

const CreateTagOption = memo(function CreateTagOption({
  createClicked,
}: {
  createClicked(): void;
}) {
  return (
    <Flex
      align="center"
      as="button"
      className={conversationTagInputCss.optionButton}
      gap="xxs"
      onClick={createClicked}
      pl="sm"
      radius="md"
    >
      <PlusIcon className={conversationTagInputCss.icon} />
      <Text fontSize="xs">New tag</Text>
    </Flex>
  );
});
