// extracted by mini-css-extract-plugin
export var createdDate = "_68-1";
export var divider = "_68-2";
export var headerDivider = "_68-3";
export var messagesCard = "_68-0";
export var messagesContainer = "_68-6";
export var messagesContainerWrapper = "_68-4";
export var showLeftPanelButton = "_68-9";
export var topButtons = "_68-8";
export var typingIndicator = "_68-7";
export var withPadding = "_68-5";