import { RouteObject } from "react-router-dom";

export const loginRoutes: RouteObject[] = [
  {
    index: true,
    async lazy() {
      const { Login } = await import("./login");
      return { Component: Login };
    },
  },
  {
    path: "token",
    async lazy() {
      const { TokenLogin } = await import("./token-login");
      return { Component: TokenLogin };
    },
  },
  {
    path: "add",
    async lazy() {
      const { AddLogin } = await import("./add-login");
      return { Component: AddLogin };
    },
  },
];
