// extracted by mini-css-extract-plugin
export var accent = "_2c-3";
export var add = "_2c-h";
export var addIcon = "_2c-i";
export var deleteIcon = "_2c-g";
export var disabled = "_2c-8";
export var ellipsis = "_2c-d";
export var error = "_2c-2";
export var form = "_2c-4";
export var hideFocus = "_2c-1";
export var icon = "_2c-6";
export var input = "_2c-c";
export var listItem = "_2c-f";
export var mediumHeight = "_2c-a";
export var noBorder = "_2c-7";
export var shortHeight = "_2c-9";
export var tallHeight = "_2c-b";
export var textInput = "_2c-0";
export var vertical = "_2c-e";
export var widget = "_2c-5";