import {
  MutableRefObject,
  ReactNode,
  createContext,
  memo,
  useRef,
} from "react";

export const WindowContext = createContext<MutableRefObject<Window>>({
  current: undefined,
});

export const WindowProvider = memo(function WindowProvider({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const ref = useRef<Window>();
  return (
    <WindowContext.Provider value={ref}>{children}</WindowContext.Provider>
  );
});
