import { IterableMap } from "@redotech/react-util/component";
import { useHandler } from "@redotech/react-util/hook";
import { Step as ModelStep } from "@redotech/redo-model/return-flow";
import { Permission, permitted } from "@redotech/redo-model/user";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  IconButton,
} from "@redotech/redo-web/button";
import { Divider } from "@redotech/redo-web/divider";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import LeftArrowIcon from "@redotech/redo-web/icon-old/left-arrow.svg";
import QuestionIcon from "@redotech/redo-web/icon-old/question.svg";
import TrashIcon from "@redotech/redo-web/icon-old/trash.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { Switch } from "@redotech/redo-web/switch";
import {
  InputLines,
  InputTheme,
  TextInput,
} from "@redotech/redo-web/text-input";
import { groupBy } from "@redotech/util/map";
import { produce } from "immer";
import { memo, useContext } from "react";
import { UserContext } from "../../app/user";
import * as multipleChoiceCss from "./multiple-choice.module.css";
import { RequiredMessage } from "./required-message";
import { StepDownstream, StepId, StepType, StepTypeDetailsProps } from "./step";

export interface ChoiceState {
  next: StepId | undefined;
  name: string;
}

export interface State {
  choices: ChoiceState[];
  prompt: string;
  reason: boolean;
  shuffle?: boolean;
  attempted?: boolean;
  customTitle?: string;
}

const Details = memo(function Details({
  stepSelect,
  state,
  setState,
}: StepTypeDetailsProps<State>) {
  const user = useContext(UserContext);
  const canEditSettings =
    !!user && permitted(user.permissions, Permission.EDIT_SETTINGS);
  const handleAdd = useHandler(() =>
    setState((state) => ({
      ...state,
      choices: [...state.choices, { name: "", next: undefined }],
    })),
  );
  const handlePrompt = useHandler((prompt: string) =>
    setState((state) => ({
      ...state,
      prompt,
    })),
  );
  const handleShuffle = useHandler((shuffle: boolean) =>
    setState((state) => ({
      ...state,
      shuffle,
    })),
  );
  const handleFocus = useHandler((focused: boolean) => {
    if (!focused) {
      setState((state) => ({ ...state, attempted: true }));
    }
  });

  return (
    <>
      <LabeledInput
        description="Whether to shuffle the order that return reasons are displayed for each individual order"
        label="Shuffle options"
      >
        <Switch
          disabled={!canEditSettings}
          onChange={handleShuffle}
          value={state.shuffle ?? false}
        />
      </LabeledInput>
      <LabeledInput label="Prompt">
        <TextInput
          error={state.attempted && !state.prompt}
          lines={InputLines.MULTI}
          onChange={handlePrompt}
          onFocus={(focused: boolean) => {
            if (!state.prompt) {
              handleFocus(focused);
            }
          }}
          readonly={!canEditSettings}
          theme={InputTheme.FORM}
          value={state.prompt}
        />
      </LabeledInput>
      <IterableMap items={state.choices} keyFn={(_, index) => index}>
        {(choice, index) => (
          <StateChoiceComponent
            canEditSettings={canEditSettings}
            choice={choice}
            handleFocus={handleFocus}
            index={index}
            setState={setState}
            state={state}
            stepSelect={stepSelect}
          />
        )}
      </IterableMap>
      <Divider />
      <div>
        <Button
          disabled={!canEditSettings}
          onClick={handleAdd}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          + Add option
        </Button>
      </div>
      {!state.choices.length && (
        <RequiredMessage>Must have at least one option</RequiredMessage>
      )}
    </>
  );
});

const StateChoiceComponent = memo(function StateChoiceComponent({
  state,
  setState,
  stepSelect,
  handleFocus,
  canEditSettings,
  choice,
  index,
}: {
  state: StepTypeDetailsProps<State>["state"];
  setState: StepTypeDetailsProps<State>["setState"];
  stepSelect: StepTypeDetailsProps<State>["stepSelect"];
  handleFocus(focused: boolean): void;
  canEditSettings: boolean;
  choice: ChoiceState;
  index: number;
}) {
  const setName = useHandler((name: string) =>
    setState((state) =>
      produce(state, (state) => {
        state.choices[index].name = name;
      }),
    ),
  );
  const setStep = useHandler((next: StepId | undefined) =>
    setState((state) =>
      produce(state, (state) => {
        state.choices[index].next = next;
      }),
    ),
  );
  const handleUp = useHandler(() => {
    setState((state) =>
      produce(state, (state) => {
        const choices = state.choices.splice(index, 1);
        state.choices.splice(index - 1, 0, ...choices);
      }),
    );
  });
  const handleDown = useHandler(() => {
    setState((state) =>
      produce(state, (state) => {
        const choices = state.choices.splice(index, 1);
        state.choices.splice(index + 1, 0, ...choices);
      }),
    );
  });
  const handleDelete = useHandler(() => {
    setState((state) =>
      produce(state, (state) => {
        state.choices.splice(index, 1);
      }),
    );
  });
  return (
    <>
      <Divider />
      <LabeledInput
        label={
          <div className={multipleChoiceCss.label}>
            <div>Option #{index + 1}</div>
            {canEditSettings && (
              <>
                <IconButton
                  disabled={!index}
                  onClick={handleUp}
                  size={ButtonSize.SMALL}
                >
                  <LeftArrowIcon className={multipleChoiceCss.upArrow} />
                </IconButton>
                <IconButton
                  disabled={index === state.choices.length - 1}
                  onClick={handleDown}
                  size={ButtonSize.SMALL}
                >
                  <LeftArrowIcon className={multipleChoiceCss.downArrow} />
                </IconButton>
                <IconButton onClick={handleDelete} size={ButtonSize.SMALL}>
                  <TrashIcon />
                </IconButton>
              </>
            )}
          </div>
        }
      >
        <TextInput
          error={state.attempted && !choice.name}
          onChange={setName}
          onFocus={(focused: boolean) => {
            if (!choice.name) {
              handleFocus(focused);
            }
          }}
          readonly={!canEditSettings}
          theme={InputTheme.FORM}
          value={choice.name}
        />
      </LabeledInput>
      <LabeledInput
        errors={state.attempted && !choice.next ? ["Required"] : []}
        label={`Next step #${index + 1}`}
      >
        {stepSelect({ value: choice.next, valueChange: setStep })}
      </LabeledInput>
    </>
  );
});

export const MULTIPLE_CHOICE: StepType<State, ModelStep.MultipleChoice> = {
  Details: Details,
  customTitle(state) {
    return state.customTitle;
  },
  title: "Multiple choice",
  valid(state: State) {
    return (
      !!state.prompt &&
      !!state.choices.length &&
      state.choices.every((choice) => choice.name && choice.next)
    );
  },
  Icon: QuestionIcon,
  description(state) {
    return state.prompt;
  },
  downstream(state) {
    const choices = groupBy(
      state.choices.filter((choice) => choice.next !== undefined),
      (choice) => choice.next,
    );
    const result: StepDownstream[] = [];
    for (const [next, c] of choices.entries()) {
      result.push({
        id: next!,
        label: c.length === 1 ? c[0].name : `${c.length} choices`,
      });
    }
    return result;
  },
  empty: { choices: [], prompt: "", reason: false, shuffle: true },
  layout() {
    return BlockLayout.FULL;
  },
  stepDeleted(state, stepId) {
    return produce(state, (state) => {
      for (const choice of state.choices) {
        if (choice.next === stepId) {
          choice.next = undefined;
        }
      }
    });
  },
  toModel(state, id): ModelStep.MultipleChoice {
    return {
      customTitle: state.customTitle,
      type: ModelStep.MULTIPLE_CHOICE,
      message: state.prompt,
      options: state.choices.map((choice) => ({
        name: choice.name,
        next: id(choice.next!),
      })),
      reason: state.reason,
      shuffle: state.shuffle,
    };
  },
  fromModel(model: ModelStep.MultipleChoice, id: (id: number) => StepId) {
    return {
      customTitle: model.customTitle,
      choices: model.options.map((option) => ({
        name: option.name,
        next: id(option.next),
      })),
      prompt: model.message,
      reason: model.reason,
      shuffle: model.shuffle,
    };
  },
};

export function getReturnReasonStep(
  returnReasonTitle: string,
): StepType<State, ModelStep.MultipleChoice> {
  return {
    ...MULTIPLE_CHOICE,
    title: returnReasonTitle,
    toModel(state, id): ModelStep.MultipleChoice {
      return { ...MULTIPLE_CHOICE.toModel(state, id), reason: true };
    },
  };
}
