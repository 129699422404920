import { ConversationStatus } from "../conversation";
import { ReturnStatus } from "../return-new";
import { CompleteOrderFulfillmentStatus } from "../shopify-order";

export enum CustomerEventType {
  ORDER_CREATED = "ORDER_CREATED",
  ORDER_STATUS_UPDATED = "ORDER_STATUS_UPDATED",
  ORDER_UPDATED = "ORDER_UPDATED",

  RETURN_CREATED = "RETURN_CREATED",
  RETURN_STATUS_UPDATED = "RETURN_STATUS_UPDATED",
  RETURN_COMMENT_CREATED = "RETURN_COMMENT_CREATED",

  CLAIM_CREATED = "CLAIM_CREATED",
  CLAIM_STATUS_UPDATED = "CLAIM_STATUS_UPDATED",
  CLAIM_COMMENT_CREATED = "CLAIM_COMMENT_CREATED",

  CONVERSATION_CREATED = "CONVERSATION_CREATED",
  CONVERSATION_STATUS_UPDATED = "CONVERSATION_STATUS_UPDATED",
  CONVERSATION_MESSAGE_CREATED = "CONVERSATION_MESSAGE_CREATED",

  EXTERNAL_PLATFORM_CUSTOMER_CREATED = "EXTERNAL_PLATFORM_CUSTOMER_CREATED",

  STOREFRONT_LOGIN = "STOREFRONT_LOGIN",
}

export const CUSTOMER_EVENT_DISCRIMINATOR_KEY = "eventType";

export enum ExternalCustomerPlatform {
  SHOPIFY = "shopify",
}

export interface ICustomerEvent {
  _id: string;
  team: string;
  email: string;
  timestamp: Date;
  [CUSTOMER_EVENT_DISCRIMINATOR_KEY]: CustomerEventType;
}

// ###
// OrderCustomerEvents
// ###
export interface IOrderCustomerEvent extends ICustomerEvent {
  orderId: string; // id of the Redo Order document
  shopifyId: string; // id of the Shopify order
}

export interface IOrderCreatedCustomerEvent extends IOrderCustomerEvent {
  eventType: CustomerEventType.ORDER_CREATED;
  itemCount: number;
  orderName: string;
  totalPrice: string;
}

export interface IOrderUpdatedCustomerEvent extends IOrderCustomerEvent {
  eventType: CustomerEventType.ORDER_UPDATED;
}

export interface IOrderStatusUpdatedCustomerEvent extends IOrderCustomerEvent {
  eventType: CustomerEventType.ORDER_STATUS_UPDATED;
  newStatus: CompleteOrderFulfillmentStatus;
}

// ###
// ReturnCustomerEvents
// ###
export interface IReturnCustomerEvent extends ICustomerEvent {
  returnId: string;
}

export interface IReturnCreatedCustomerEvent extends IReturnCustomerEvent {
  eventType: CustomerEventType.RETURN_CREATED;
}

export interface IReturnStatusUpdatedCustomerEvent
  extends IReturnCustomerEvent {
  eventType: CustomerEventType.RETURN_STATUS_UPDATED;
  newStatus: ReturnStatus;
}

export interface IReturnCommentCreatedCustomerEvent
  extends IReturnCustomerEvent {
  eventType: CustomerEventType.RETURN_COMMENT_CREATED;
  messageId: string;
}

// ###
// ClaimCustomerEvents
// ###
export interface IClaimCustomerEvent extends ICustomerEvent {
  claimId: string;
}

export interface IClaimCreatedCustomerEvent extends IClaimCustomerEvent {
  eventType: CustomerEventType.CLAIM_CREATED;
}

export interface IClaimStatusUpdatedCustomerEvent extends IClaimCustomerEvent {
  eventType: CustomerEventType.CLAIM_STATUS_UPDATED;
  newStatus: ReturnStatus;
}

export interface IClaimCommentCreatedCustomerEvent extends IClaimCustomerEvent {
  eventType: CustomerEventType.CLAIM_COMMENT_CREATED;
  messageId: string;
}

// ###
// ConversationCustomerEvents
// ###
export interface IConversationCustomerEvent extends ICustomerEvent {
  conversationId: string;
}

export interface IConversationCreatedCustomerEvent
  extends IConversationCustomerEvent {
  eventType: CustomerEventType.CONVERSATION_CREATED;
}

export interface IConversationStatusUpdatedCustomerEvent
  extends IConversationCustomerEvent {
  eventType: CustomerEventType.CONVERSATION_STATUS_UPDATED;
  newStatus: ConversationStatus;
}

export interface IConversationMessageCreatedCustomerEvent
  extends IConversationCustomerEvent {
  eventType: CustomerEventType.CONVERSATION_MESSAGE_CREATED;
  messageId: string;
}

// ###
// ExternalPlatformCustomerCreatedEvent
// ###
export interface IExternalPlatformCustomerCreatedEvent extends ICustomerEvent {
  eventType: CustomerEventType.EXTERNAL_PLATFORM_CUSTOMER_CREATED;
  externalPlatform: ExternalCustomerPlatform;
  externalPlatformCustomerId: string;
}

// ###
// StorefrontLoginEvent
// ###
export interface IStorefrontLoginEvent extends ICustomerEvent {
  eventType: CustomerEventType.STOREFRONT_LOGIN;
  multipassLogin: boolean;
}
