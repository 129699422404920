import { PillTheme } from "@redotech/redo-model/pill-theme";
import {
  ReturnStatus,
  returnStatusToReadableName,
} from "@redotech/redo-model/return-new";
import { Pill, PillSize } from "@redotech/redo-web/pill";
import { memo } from "react";

/**
 * Returns and claims are have the same set of statuses. Feel free to split this out if that changes.
 */
export const ReturnClaimStatusPill = memo(function ReturnClaimStatusPill({
  returnClaimStatus,
}: {
  returnClaimStatus: ReturnStatus;
}) {
  const getPillTheme = (): PillTheme => {
    if (returnClaimStatus === ReturnStatus.OPEN) {
      return PillTheme.SUCCESS;
    } else {
      return PillTheme.NORMAL;
    }
  };

  const getPillText = (): string => {
    return returnStatusToReadableName(returnClaimStatus);
  };

  return (
    <Pill size={PillSize.NEAR_SQUARE} theme={getPillTheme()}>
      {getPillText()}
    </Pill>
  );
});
