import { ConversationPlatform } from "./conversation";
import { SortableConversationTableColumn, TableSort } from "./table";

export enum FiltersStatus {
  OPEN = "open",
  CLOSED = "closed",
  SNOOZED = "snoozed",
}

export interface View {
  _id: string;
  team: any;
  name: string;
  filters: FilterOptions;
  user?: string;
}

export interface FilterOptions {
  modified?: boolean;
  status?: FiltersStatus[];
  channels?: ConversationPlatform[];
  assignees?: (string | null)[];
  read?: boolean | null;
  createdDateStart?: string;
  createdDateEnd?: string;
  closedDateStart?: string;
  closedDateEnd?: string;
  lastResponseAtStart?: string;
  lastResponseAtEnd?: string;
  words?: string[];
  conversationTags?: string[];
  customerTags?: string[];
  search?: string;
  sort?: TableSort<SortableConversationTableColumn>;
  customerEmail?: string | undefined;
}
