// extracted by mini-css-extract-plugin
export var animationContainer = "_5p-0";
export var conversationContainer = "_5p-1";
export var detailsContent = "_5p-2";
export var message = "_5p-3";
export var modalContent = "_5p-4";
export var modalFooter = "_5p-6";
export var modalHeader = "_5p-5";
export var name = "_5p-7";
export var optionContainer = "_5p-8";
export var optionTitle = "_5p-9";
export var pillContainer = "_5p-a";
export var selectedConversationsContainer = "_5p-b";
export var selectedOptionContainer = "_5p-c";