// extracted by mini-css-extract-plugin
export var buttons = "_3s-0";
export var buttonsActive = "_3s-h";
export var cancelConfirm = "_3s-1";
export var closeButton = "_3s-2";
export var column = "_3s-3";
export var container = "_3s-4";
export var detailHeader = "_3s-6";
export var detailTitle = "_3s-7";
export var detailsContent = "_3s-5";
export var header = "_3s-8";
export var headerWithClose = "_3s-9";
export var icon = "_3s-a";
export var infoIcon = "_3s-c";
export var informationContainer = "_3s-b";
export var sideDrawer = "_3s-d";
export var sideDrawerOpen = "_3s-e";
export var stickyFooter = "_3s-f";
export var typeOption = "_3s-g";