import { graphlib, layout } from "@dagrejs/dagre";
import { IterableMap } from "@redotech/react-util/component";
import * as classnames from "classnames";
import { ReactNode, memo, useEffect, useRef, useState } from "react";
import { Button, ButtonSize, ButtonTheme } from "./button";
import * as flowchartCss from "./flowchart.module.css";
import LightbulbIcon from "./icon-old/lightbulb.svg";
// import { Smoothing } from "./curve";
import RecenterIcon from "./icon-old/recenter.svg";
import ZoomInIcon from "./icon-old/zoom-in.svg";
import ZoomOutIcon from "./icon-old/zoom-out.svg";

interface Position {
  x: number;
  y: number;
}

const BLOCK_WIDTH = 280;
const BLOCK_FULL_HEIGHT = 72;
const BLOCK_COMPACT_HEIGHT = 48;
const EDGE_LABEL_WIDTH = 80;
const EDGE_LABEL_HEIGHT = 20;

export enum BlockLayout {
  COMPACT = 0,
  FULL = 1,
}

export namespace BlockLayout {
  export function size(layout: BlockLayout): { width: number; height: number } {
    switch (layout) {
      case BlockLayout.COMPACT:
        return { width: BLOCK_WIDTH, height: BLOCK_COMPACT_HEIGHT };
      case BlockLayout.FULL:
        return { width: BLOCK_WIDTH, height: BLOCK_FULL_HEIGHT };
    }
  }
}

enum BlockStatus {
  DISABLED = 0,
  HIGHLIGHTED = 1,
  NORMAL = 2,
  SELECTED = 3,
}

const blockStatusClass = new Map<BlockStatus, string>();
blockStatusClass.set(BlockStatus.HIGHLIGHTED, flowchartCss.highlighted);
blockStatusClass.set(BlockStatus.SELECTED, flowchartCss.selected);

const Block = memo(function Block({
  error,
  hover,
  layout,
  icon,
  title,
  description,
  position,
  status,
  onClick,
}: {
  error: boolean;
  hover: boolean;
  layout: BlockLayout;
  icon: ReactNode;
  title: string;
  description: string | ReactNode;
  position: Position;
  status: BlockStatus;
  onClick?(): void;
}) {
  const size = BlockLayout.size(layout);
  const left = position.x - size.width / 2;
  const top = position.y - size.height / 2;
  return (
    <button
      className={classnames(flowchartCss.block, blockStatusClass.get(status), {
        [flowchartCss.hover]: hover,
        [flowchartCss.small]: layout === BlockLayout.COMPACT,
      })}
      disabled={status === BlockStatus.DISABLED}
      onClick={onClick}
      style={{ top: `${top}px`, left: `${left}px` }}
    >
      {layout === BlockLayout.FULL && (
        <div className={flowchartCss.blockIcon}>{icon}</div>
      )}
      <div className={flowchartCss.blockContent}>
        <h3 className={flowchartCss.blockTitle}>{title}</h3>
        {layout === BlockLayout.FULL && (
          <div className={flowchartCss.blockDescription}>{description}</div>
        )}
      </div>
      {error && <LightbulbIcon className={flowchartCss.error} />}
    </button>
  );
});

const EDGE_THICKNESS = 6;

// const SMOOTHING = new Smoothing(0.2);

const Edge = memo(function Edge({
  labelPosition,
  path,
  children,
}: {
  children?: string;
  labelPosition?: { x: number; y: number };
  path: Position[];
}) {
  const start = path[0];
  const end = path[path.length - 1];

  // const d = svgSmoothPath(SMOOTHING, path.map((position) => [position.x, position.y]));
  const minX = Math.min(...path.map((position) => position.x)) - EDGE_THICKNESS;
  const maxX = Math.max(...path.map((position) => position.x)) + EDGE_THICKNESS;
  const minY = Math.min(...path.map((position) => position.y)) - EDGE_THICKNESS;
  const maxY = Math.max(...path.map((position) => position.y)) + EDGE_THICKNESS;

  const angle = Math.atan2(end.y - start.y, end.x - start.x) + Math.PI;
  const arrow1 = angle + Math.PI / 8;
  const arrow2 = angle - Math.PI / 8;

  const arrowD = `M ${end.x + Math.cos(arrow1) * 10} ${
    end.y + Math.sin(arrow1) * 10
  } L ${end.x} ${end.y} L ${end.x + Math.cos(arrow2) * 10} ${
    end.y + Math.sin(arrow2) * 10
  }`;

  return (
    <>
      <svg
        className={flowchartCss.edge}
        style={{
          width: `${maxX - minX}px`,
          height: `${maxY - minY}px`,
          top: `${minY}px`,
          left: `${minX}px`,
        }}
        viewBox={`${minX} ${minY} ${maxX - minX} ${maxY - minY}`}
      >
        <line
          className={flowchartCss.edgePath}
          x1={start.x}
          x2={end.x}
          y1={start.y}
          y2={end.y}
        />
        <path className={flowchartCss.edgePath} d={arrowD} />
      </svg>
      {labelPosition && (
        <div
          className={flowchartCss.edgeLabel}
          style={{
            left: `${labelPosition.x - EDGE_LABEL_WIDTH / 2}px`,
            top: `${labelPosition.y - EDGE_LABEL_HEIGHT / 2}px`,
          }}
        >
          {children}
        </div>
      )}
    </>
  );
});

export enum SelectionTheme {
  HIGHLIGHT = 0,
  NORMAL = 1,
}

export interface SelectionMode {
  enabled(id: string): boolean;
  theme: SelectionTheme;
}

export type Selection = string | undefined;

export interface BlockConfig {
  error: boolean;
  icon: ReactNode;
  id: string;
  layout: BlockLayout;
  title: string;
  description: string | ReactNode;
}

export interface EdgeConfig {
  id: string;
  from: string;
  label?: string;
  to: string;
}

const GAP = 4;

const MIN_ZOOM = 0.2;
const MAX_ZOOM = 1.5;

export const Flowchart = memo(function Flowchart({
  blocks,
  hover = [],
  edges,
  selectionMode,
  selected,
  onSelect,
  showBorder = false,
}: {
  blocks: BlockConfig[];
  edges: EdgeConfig[];
  hover?: string[];
  selectionMode: SelectionMode;
  selected?: Selection;
  onSelect?(selection: Selection): void;
  showBorder?: boolean;
}) {
  const flowchartRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(1);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const flowchartElement = flowchartRef.current;
    if (!flowchartElement) {
      return;
    }

    const handleWheel = (event: WheelEvent) => {
      event.preventDefault();
      const { deltaY } = event;
      const scaleChange = deltaY > 0 ? 0.95 : 1.05;
      const newScale = Math.min(
        Math.max(scale * scaleChange, MIN_ZOOM),
        MAX_ZOOM,
      );
      if (newScale === scale) {
        return;
      }

      adjustTranslateForScale(newScale, flowchartElement);
      setScale(newScale);
    };

    flowchartElement.addEventListener("wheel", handleWheel, {
      passive: false,
    });

    return () => {
      flowchartElement.removeEventListener("wheel", handleWheel);
    };
  }, [scale]);

  useEffect(() => {
    handleRecenter();
  }, [flowchartRef.current]);

  if (!blocks.length) {
    return null;
  }

  const graph = new graphlib.Graph();
  graph.setGraph({});
  graph.setDefaultEdgeLabel(function () {
    return {};
  });
  for (const block of blocks) {
    const size = BlockLayout.size(block.layout);
    graph.setNode(block.id, {
      width: size.width + GAP * 2,
      height: size.height + GAP * 2,
    });
  }
  for (const edge of edges) {
    graph.setEdge(edge.from, edge.to, {
      label: edge.label,
      id: edge.id,
      width: edge.label && EDGE_LABEL_WIDTH,
      height: edge.label && EDGE_LABEL_HEIGHT,
      labelpos: "c",
    });
  }
  layout(graph);

  const min = (prop: "x" | "y", size: "width" | "height") =>
    Math.min(
      ...graph
        .nodes()
        .map((node) => graph.node(node)[prop] - graph.node(node)[size] / 2),
      ...graph
        .edges()
        .flatMap((edge) => graph.edge(edge).points)
        .map((edge) => edge[prop]),
    );
  const max = (prop: "x" | "y", size: "width" | "height") =>
    Math.max(
      ...graph
        .nodes()
        .map((node) => graph.node(node)[prop] + graph.node(node)[size] / 2),
      ...graph
        .edges()
        .flatMap((edge) => graph.edge(edge).points)
        .map((edge) => edge[prop]),
    );
  const minX = min("x", "width");
  const maxX = max("x", "width");
  const minY = min("y", "height");
  const maxY = max("y", "height");

  function adjustTranslateForScale(
    newScale: number,
    flowchartElement: HTMLDivElement,
  ) {
    setTranslate((prevTranslate) => {
      const centerX = flowchartElement.clientWidth / 2;
      const centerY = flowchartElement.clientHeight / 2;

      const scaleChange = newScale / scale;
      const newTranslateX =
        prevTranslate.x + (centerX - prevTranslate.x) * (1 - scaleChange);
      const newTranslateY =
        prevTranslate.y + (centerY - prevTranslate.y) * (1 - scaleChange);

      return {
        x: newTranslateX,
        y: newTranslateY,
      };
    });
  }

  function handleMouseDown(event: React.MouseEvent) {
    if (event.button !== 0) {
      return;
    }
    const startX = event.clientX;
    const startY = event.clientY;
    const initialTranslate = { ...translate };

    function handleMouseMove(moveEvent: MouseEvent) {
      setTranslate({
        x: initialTranslate.x + (moveEvent.clientX - startX),
        y: initialTranslate.y + (moveEvent.clientY - startY),
      });
    }

    const handleMouseUp = (e: MouseEvent) => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  }

  function handleZoomOutClick() {
    const flowchartElement = flowchartRef.current;
    if (!flowchartElement) {
      return;
    }

    const newScale = Math.max(scale * 0.9, MIN_ZOOM);
    adjustTranslateForScale(newScale, flowchartElement);
    setScale(newScale);
  }

  function handleZoomInClick() {
    const flowchartElement = flowchartRef.current;
    if (!flowchartElement) {
      return;
    }

    const newScale = Math.min(scale * 1.1, MAX_ZOOM);
    adjustTranslateForScale(newScale, flowchartElement);
    setScale(newScale);
  }

  function handleRecenter() {
    const flowchartElement = flowchartRef.current;
    if (!flowchartElement) {
      return;
    }

    const centerX = flowchartElement.clientWidth / 2;
    const centerY = flowchartElement.clientHeight / 2;
    setTranslate({
      x: centerX - ((maxX - minX) * scale) / 2,
      y: centerY - ((maxY - minY) * scale) / 2,
    });
  }

  return (
    <div
      className={classnames(flowchartCss.flowchart, {
        [flowchartCss.border]: showBorder,
      })}
      onMouseDown={handleMouseDown}
      ref={flowchartRef}
    >
      <div className={flowchartCss.navButtons}>
        <Button
          className={classnames(flowchartCss.navButton, flowchartCss.left)}
          disabled={scale === MIN_ZOOM}
          onClick={handleZoomOutClick}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          <ZoomOutIcon className={flowchartCss.icon} />
        </Button>
        <Button
          className={classnames(flowchartCss.navButton, flowchartCss.center)}
          disabled={scale === MAX_ZOOM}
          onClick={handleZoomInClick}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          <ZoomInIcon className={flowchartCss.icon} />
        </Button>
        <Button
          className={classnames(flowchartCss.navButton, flowchartCss.right)}
          onClick={handleRecenter}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          <RecenterIcon className={flowchartCss.icon} />
        </Button>
      </div>
      <div
        className={flowchartCss.flowchartContent}
        style={{
          transform: `translate(${translate.x}px, ${translate.y}px) scale(${scale})`,
          height: `${maxY - minY}px`,
          width: `${maxX - minX}px`,
        }}
      >
        {graph.edges().map((e) => {
          const edge = graph.edge(e);
          const path: Position[] = edge.points.map((point) => ({
            x: point.x - minX,
            y: point.y - minY,
          }));
          return (
            <Edge
              key={edge.id}
              labelPosition={
                edge.label !== undefined
                  ? { x: edge.x - minX, y: edge.y - minY }
                  : undefined
              }
              path={path}
            >
              {edge.label}
            </Edge>
          );
        })}
        <IterableMap items={blocks} keyFn={(block) => `block.${block.id}`}>
          {(block) => {
            const node = graph.node(block.id);
            const position: Position = {
              x: node.x - minX,
              y: node.y - minY,
            };
            function onClick() {
              onSelect?.(block.id);
            }
            const status = !selectionMode.enabled(block.id)
              ? BlockStatus.DISABLED
              : selected === block.id
                ? BlockStatus.SELECTED
                : selectionMode.theme === SelectionTheme.HIGHLIGHT
                  ? BlockStatus.HIGHLIGHTED
                  : BlockStatus.NORMAL;
            return (
              <Block
                description={block.description}
                error={block.error}
                hover={hover.includes(block.id)}
                icon={block.icon}
                layout={block.layout}
                onClick={onClick}
                position={position}
                status={status}
                title={block.title}
              />
            );
          }}
        </IterableMap>
      </div>
    </div>
  );
});
