import { useRequiredContext } from "@redotech/react-util/context";
import { LoadState, useLoad } from "@redotech/react-util/load";
import { CustomerActivityFilterOptions } from "@redotech/redo-model/customer-activity/customer-activity-filters";
import FilterFunnelIcon from "@redotech/redo-web/arbiter-icon/filter-funnel-02.svg";
import { IconButton } from "@redotech/redo-web/button";
import { Flex } from "@redotech/redo-web/flex";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { Header, Text } from "@redotech/redo-web/text";
import { memo, useEffect, useState } from "react";
import { RedoMerchantClientContext } from "../../../client/context";
import { getCustomerEvents } from "../../../client/customer";
import { CustomerActivityCard } from "./cards/customer-activity-card";
import { CustomerActivityFilterDrawer } from "./customer-activity-filter-drawer";
import * as customerActivityCss from "./customer-activity.module.css";
import { CustomerActivityType } from "./util";

enum CustomerActivityPanelContentState {
  LOADING = 0,
  EMPTY_WITHOUT_FILTER_APPLIED = 1,
  EMPTY_WITH_FILTER_APPLIED = 2,
  HAS_EVENTS = 3,
}

const CustomerActivityPanelContent = memo(
  function CustomerActivityPanelContent({
    customerActivityLoad,
    hasCustomerActivity,
    isFilterApplied,
  }: {
    customerActivityLoad: LoadState<CustomerActivityType[]>;
    hasCustomerActivity: boolean;
    isFilterApplied: boolean;
  }) {
    function getCustomerActivityPanelContentState(): CustomerActivityPanelContentState {
      if (customerActivityLoad.pending) {
        return CustomerActivityPanelContentState.LOADING;
      } else if (!hasCustomerActivity && !isFilterApplied) {
        return CustomerActivityPanelContentState.EMPTY_WITHOUT_FILTER_APPLIED;
      } else if (!hasCustomerActivity && isFilterApplied) {
        return CustomerActivityPanelContentState.EMPTY_WITH_FILTER_APPLIED;
      } else {
        return CustomerActivityPanelContentState.HAS_EVENTS;
      }
    }

    function getCustomerActivityPanelContent() {
      switch (getCustomerActivityPanelContentState()) {
        case CustomerActivityPanelContentState.LOADING:
          return <LoadingRedoAnimation />;
        case CustomerActivityPanelContentState.EMPTY_WITHOUT_FILTER_APPLIED:
          return (
            <Flex dir="column" gap="xs" pt="3xl">
              <Text
                className={customerActivityCss.emptyStateText}
                fontSize="md"
              >
                No activity to display
              </Text>
              <Text
                className={customerActivityCss.emptyStateText}
                fontSize="sm"
                textColor="tertiary"
              >
                As your customer browses your shop, places orders, or contacts
                your team for support all of that will show up here.
              </Text>
            </Flex>
          );
        case CustomerActivityPanelContentState.EMPTY_WITH_FILTER_APPLIED:
          return (
            <Flex dir="column" gap="xs" pt="3xl">
              <Text
                className={customerActivityCss.emptyStateText}
                fontSize="md"
              >
                No activity items match your filters criteria
              </Text>
              <Text
                className={customerActivityCss.emptyStateText}
                fontSize="sm"
                textColor="tertiary"
              >
                Adjust your filters to see activity items here.
              </Text>
            </Flex>
          );
        case CustomerActivityPanelContentState.HAS_EVENTS:
          return customerActivityLoad.value?.map((customerActivity) => (
            <CustomerActivityCard
              customerActivity={customerActivity}
              key={customerActivity._id.toString()}
            />
          ));
      }
    }

    return (
      <Flex
        className={customerActivityCss.contentContainer}
        dir="column"
        gap="xl"
        pl="3xl"
        pr="3xl"
        pt="xl"
      >
        {getCustomerActivityPanelContent()}
      </Flex>
    );
  },
);

export const CustomerActivityPanel = memo(function CustomerActivityPanel({
  customerId,
  headerText = "Activity",
}: {
  customerId: string;
  headerText?: string;
}) {
  const client = useRequiredContext(RedoMerchantClientContext);

  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [customerActivityFilterOptions, setCustomerActivityFilterOptions] =
    useState<CustomerActivityFilterOptions | undefined>(undefined);
  const [hasCustomerActivity, setHasCustomerActivity] = useState(false);

  const customerActivityLoad = useLoad(
    async (signal): Promise<CustomerActivityType[]> => {
      const customerActivity = await getCustomerEvents(client, {
        customerId,
        filterOptions: customerActivityFilterOptions,
        signal,
      });
      const allCustomerActivity = [
        ...customerActivity.customerEvents,
        ...customerActivity.shoppingEvents,
      ].sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
      return allCustomerActivity;
    },
    [customerActivityFilterOptions, customerId],
  );

  useEffect(() => {
    setHasCustomerActivity(
      !customerActivityLoad.pending &&
        !!customerActivityLoad.value &&
        customerActivityLoad.value.length > 0,
    );
  }, [customerActivityLoad]);

  const onClose = (
    customerActivityFilterOptions: CustomerActivityFilterOptions,
  ) => {
    setFilterDrawerOpen(false);
    setCustomerActivityFilterOptions(customerActivityFilterOptions);
  };

  return (
    <>
      <Flex
        className={customerActivityCss.panelContainer}
        dir="column"
        gap="none"
      >
        <Flex
          className={customerActivityCss.headerContainer}
          justify="space-between"
          pb="2xl"
          pl="3xl"
          pr="3xl"
          pt="3xl"
        >
          <Header fontFamily="inherit" fontSize="xxs">
            {headerText}
          </Header>
          {(customerActivityFilterOptions || hasCustomerActivity) && (
            <IconButton onClick={() => setFilterDrawerOpen(true)}>
              <FilterFunnelIcon />
            </IconButton>
          )}
        </Flex>
        <CustomerActivityPanelContent
          customerActivityLoad={customerActivityLoad}
          hasCustomerActivity={hasCustomerActivity}
          isFilterApplied={!!customerActivityFilterOptions}
        />
      </Flex>
      <CustomerActivityFilterDrawer
        initialFilterOptions={customerActivityFilterOptions}
        onClose={onClose}
        open={filterDrawerOpen}
      />
    </>
  );
});
