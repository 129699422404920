import { TooltipProps } from "@mui/material/Tooltip";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { memo } from "react";
import { Flex } from "../flex";

interface DisabledTooltipProps {
  disabledMessage: string | undefined | false;
  children: React.ReactElement;
  otherProps?: Omit<TooltipProps, "title">;
}

export const DisabledTooltip = memo(function DisabledTooltip({
  disabledMessage,
  children,
  otherProps,
}: DisabledTooltipProps): React.ReactElement {
  if (disabledMessage) {
    return (
      <Tooltip title={disabledMessage} {...otherProps}>
        <Flex as="span">{children}</Flex>
      </Tooltip>
    );
  } else {
    return children;
  }
});
