// extracted by mini-css-extract-plugin
export var centered = "_2b-6";
export var flexible = "_2b-5";
export var footer = "_2b-k";
export var footerBorder = "_2b-l";
export var header = "_2b-c";
export var headerBorder = "_2b-d";
export var headerDefault = "_2b-g";
export var headerMedium = "_2b-h";
export var headerPadding = "_2b-e";
export var iconAndTitle = "_2b-f";
export var large = "_2b-4";
export var largePadding = "_2b-a";
export var medium = "_2b-3";
export var mediumPadding = "_2b-9";
export var modal = "_2b-0";
export var modalContainer = "_2b-b";
export var noPadding = "_2b-7";
export var skinny = "_2b-1";
export var small = "_2b-2";
export var smallPadding = "_2b-8";
export var subtitle = "_2b-j";
export var title = "_2b-i";