import { ConversationStatus } from "@redotech/redo-model/conversation";
import { PillTheme } from "@redotech/redo-model/pill-theme";
import { Pill, PillSize } from "@redotech/redo-web/pill";
import { memo } from "react";

export const ConversationStatusPill = memo(function ConversationStatusPill({
  conversationStatus,
}: {
  conversationStatus: ConversationStatus;
}) {
  const getPillTheme = (): PillTheme => {
    if (conversationStatus === ConversationStatus.CLOSED) {
      return PillTheme.NORMAL;
    } else if (conversationStatus === ConversationStatus.SNOOZED) {
      return PillTheme.NEUTRAL_YELLOW;
    } else {
      return PillTheme.PRIMARY_LIGHT;
    }
  };

  const getPillText = (): string => {
    if (conversationStatus === ConversationStatus.CLOSED) {
      return "Closed";
    } else if (conversationStatus === ConversationStatus.SNOOZED) {
      return "Snoozed";
    } else if (conversationStatus === ConversationStatus.OPEN) {
      return "Open";
    } else {
      return "";
    }
  };

  return (
    <Pill size={PillSize.NEAR_SQUARE} theme={getPillTheme()}>
      {getPillText()}
    </Pill>
  );
});
