import { useHandler } from "@redotech/react-util/hook";
import {
  InputPartType,
  InputValidation,
  Step as ModelStep,
} from "@redotech/redo-model/return-flow";
import { Permission, permitted } from "@redotech/redo-model/user";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import QuestionIcon from "@redotech/redo-web/icon-old/question.svg";
import { ImageUpload } from "@redotech/redo-web/image-upload";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import { Switch } from "@redotech/redo-web/switch";
import {
  InputLines,
  InputTheme,
  TextInput,
} from "@redotech/redo-web/text-input";
import { produce } from "immer";
import { memo, useContext, useId } from "react";
import { UserContext } from "../../app/user";
import { ClientUploader } from "../../client/upload";
import { StepDownstream, StepId, StepType, StepTypeDetailsProps } from "./step";

export interface State {
  next: StepId | undefined;
  prompt: string;
  description: string;
  type: InputPartType | undefined;
  validation: InputValidation;
  exampleImage: URL | undefined;
  attempted?: boolean;
  customTitle?: string;
}

function valid(state: State) {
  return state.next !== undefined && !!state.prompt && state.type !== undefined;
}

const typeOptions: InputPartType[] = [
  InputPartType.IMAGES,
  InputPartType.TEXTAREA,
  InputPartType.VIDEOS,
];

function typeLabel(type: InputPartType) {
  switch (type) {
    case InputPartType.IMAGES:
      return "Images";
    case InputPartType.VIDEOS:
      return "Videos";
    case InputPartType.TEXTAREA:
      return "Text";
  }
}

const Details = memo(function Details({
  stepSelect,
  state,
  setState,
}: StepTypeDetailsProps<State>) {
  const user = useContext(UserContext);
  const canEditSettings =
    !!user && permitted(user.permissions, Permission.EDIT_SETTINGS);
  const handlePrompt = useHandler((prompt: string) => {
    setState((state) => ({ ...state, prompt }));
  });
  const handleDescription = useHandler((description: string) => {
    setState((state) => ({ ...state, description }));
  });
  const handleType = useHandler((type: InputPartType | undefined) => {
    setState((state) => ({ ...state, type }));
  });
  const handleRequired = useHandler((value: boolean) => {
    setState((state) => ({
      ...state,
      validation: value ? InputValidation.REQUIRED : InputValidation.OPTIONAL,
    }));
  });
  const handleFocus = useHandler((focused: boolean) => {
    if (!focused) {
      setState((state) => ({ ...state, attempted: true }));
    }
  });
  const handleExampleImage = useHandler((exampleImage: URL | undefined) => {
    console.log(exampleImage);
    setState((state) => ({ ...state, exampleImage: exampleImage }));
  });
  const exampleImageId = useId();

  return (
    <ClientUploader>
      <LabeledInput label="Prompt">
        <TextInput
          error={state.attempted && !state.prompt}
          lines={InputLines.MULTI}
          onChange={handlePrompt}
          onFocus={handleFocus}
          readonly={!canEditSettings}
          theme={InputTheme.FORM}
          value={state.prompt}
        />
      </LabeledInput>
      <LabeledInput
        description="Additional help or explanation."
        label="Description"
      >
        <TextInput
          lines={InputLines.MULTI}
          onChange={handleDescription}
          readonly={!canEditSettings}
          theme={InputTheme.FORM}
          value={state.description}
        />
      </LabeledInput>
      <LabeledInput label="Answer type">
        <SelectDropdown
          disabled={!canEditSettings}
          options={typeOptions}
          value={state.type}
          valueChange={handleType}
        >
          {typeLabel}
        </SelectDropdown>
      </LabeledInput>
      {state.type === InputPartType.IMAGES && (
        <LabeledInput
          description="Optional. Upload an image to show the customer an example of what you're looking for."
          label="Image"
        >
          <ImageUpload
            disabled={!canEditSettings}
            id={exampleImageId}
            onChange={handleExampleImage}
            value={state.exampleImage}
          />
        </LabeledInput>
      )}
      <LabeledInput label="Required">
        <Switch
          disabled={!canEditSettings}
          onChange={handleRequired}
          value={state.validation === InputValidation.REQUIRED}
        />
      </LabeledInput>
      <LabeledInput errors={!state.next ? ["Required"] : []} label="Next">
        {stepSelect({
          value: state.next,
          valueChange: (next) => setState({ ...state, next }),
        })}
      </LabeledInput>
    </ClientUploader>
  );
});

export const INPUT: StepType<State, ModelStep.Input> = {
  Details,
  customTitle(state: State) {
    return state.customTitle;
  },
  title: "Input",
  valid,
  Icon: QuestionIcon,
  description(state: State) {
    return state.prompt;
  },
  downstream(state: State) {
    const result: StepDownstream[] = [];
    if (state.next !== undefined) {
      result.push({ id: state.next });
    }
    return result;
  },
  layout() {
    return BlockLayout.FULL;
  },
  empty: {
    prompt: "",
    description: "",
    type: InputPartType.TEXTAREA,
    validation: InputValidation.OPTIONAL,
    next: undefined,
    exampleImage: undefined,
  },
  stepDeleted(state, stepId) {
    return produce(state, (state) => {
      if (state.next === stepId) {
        state.next = undefined;
      }
    });
  },
  toModel(state: State, id: (id: StepId) => number): ModelStep.Input {
    return {
      customTitle: state.customTitle,
      type: ModelStep.INPUT,
      next: id(state.next!),
      parts: [
        {
          description: state.description,
          message: state.prompt,
          validation: state.validation,
          type: state.type!,
          exampleImage: state.exampleImage,
        },
      ],
    };
  },
  fromModel(model: ModelStep.Input, id: (id: number) => StepId) {
    return {
      customTitle: model.customTitle,
      next: id(model.next),
      prompt: model.parts[0].message,
      description: model.parts[0].description,
      validation: model.parts[0].validation,
      type: model.parts[0].type,
      exampleImage: (model.parts[0].exampleImage as URL) || undefined,
    };
  },
};
