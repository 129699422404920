import { z } from "zod";

export const reviewBodySchema = z.object({
  _id: z.string(),
  teamId: z.string(),
  status: z.enum(["pending", "approved", "rejected", "manualReview"]),
  date: z.date(),
  verifiedPurchase: z.boolean(),
  shopifyProductId: z.string(),
  customer: z.object({ email: z.string() }),
  rating: z.enum(["1", "2", "3", "4", "5"]),
  review: z.optional(z.object({ title: z.string(), description: z.string() })),
});
