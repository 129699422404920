// extracted by mini-css-extract-plugin
export var actionButton = "_2y-0";
export var attachmentLine = "_2y-1";
export var fileCarousel = "_2y-2";
export var fileContainer = "_2y-4";
export var fileInput = "_2y-5";
export var fileUploadWrapper = "_2y-7";
export var footer = "_2y-6";
export var inputLabel = "_2y-8";
export var macroButton = "_2y-9";
export var modalContent = "_2y-a";
export var paperclip = "_2y-b";
export var quillContainer = "_2y-c";
export var removeFileButton = "_2y-3";