import { Card } from "@redotech/redo-web/card";
import * as classNames from "classnames";
import { memo } from "react";

import * as returnStatus from "./return-status.module.css";

export const DeadlineCard = memo(function DeadlineCard({
  deadlineDays,
}: {
  deadlineDays: number;
}) {
  return (
    <Card
      className={classNames(returnStatus.card, returnStatus.cardWhite)}
      title="Deadline"
    >
      <div>
        You must ship your package within {deadlineDays} day
        {deadlineDays !== 1 && "s"}.
      </div>
    </Card>
  );
});
