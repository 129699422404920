import { z } from "zod";

export const issueStoreCreditToCustomerSchema = {
  input: z.object({
    teamId: z.string(),
    customerId: z.string(),
    amount: z.number(),
  }),
  output: z.null(),
};
