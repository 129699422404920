import { useRequiredContext } from "@redotech/react-util/context";
import Quill from "quill";
import QuillResizeImage from "quill-resize-image";
import { Delta, Range } from "quill/core";
import * as React from "react";
import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  BaseEmoji,
  CustomEmoji,
  EmojiPicker,
  EmojiPickerCloseCause,
} from "./emoji-picker";
import * as quillEditorCss from "./quill-editor.module.css";
import { ThemeContext } from "./theme-provider";

export type OnEditorChangeArgs =
  | ["text-change", Delta, Delta, string]
  | ["selection-change", Range, Range, string];

export type QuillEditorProps = {
  toolbarElementId?: string;
  readOnly?: boolean;
  defaultValue?: any;

  /** Called when the text changes. https://quilljs.com/docs/api#text-change */
  onTextChange?: (delta: Delta, oldContents: Delta, source: string) => void;

  /** Called when the selection changes. https://quilljs.com/docs/api#selection-change */
  onSelectionChange?: (...args: any[]) => void;

  /** Called when the selection or text changes, even if they changed silently,
   * as in the case of the selection-change event fired after a text-change.
   * https://quilljs.com/docs/api#editor-change */
  onEditorChange?: (args: OnEditorChangeArgs) => void;

  placeholder?: string;
  disabled?: boolean;
  setDisabled?: (disabled: boolean) => void;

  cursorIndexRef?: React.MutableRefObject<number | undefined>;
  setEmojiPickerOpenRef?: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<boolean>> | undefined
  >;

  /**
   * To make font size portable, you may want the output of the editor to be inline styles instead of classes.
   * Everything except the settings -> email builder's text field works this way.
   */
  inlineFontSizeStyles?: boolean;
};

/**
 * The text area alongside a quill instance that fired text changed callbacks.
 * Customize your editor's behavior by grabbing the quill instance from the ref.
 */
export const QuillEditor = forwardRef<Quill, QuillEditorProps>(
  function QuillEditor(
    {
      toolbarElementId,
      readOnly,
      defaultValue,
      onTextChange,
      onSelectionChange,
      onEditorChange,
      placeholder,
      disabled,
      setDisabled,

      cursorIndexRef,
      setEmojiPickerOpenRef,

      inlineFontSizeStyles: inlineFontStyles = true,
    }: QuillEditorProps,
    ref: ForwardedRef<Quill>,
  ) {
    const containerRef = useRef(null);
    const defaultValueRef = useRef(defaultValue);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);
    const onEditorChangeRef = useRef(onEditorChange);

    const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
    setEmojiPickerOpenRef &&
      (setEmojiPickerOpenRef.current = setEmojiPickerOpen);
    const emojiButtonRef = useRef<HTMLButtonElement | null>(null);
    const [emojiPickerPositionOverride, setEmojiPickerPositionOverride] =
      useState<{
        top: number;
        left: number;
      } | null>(null);
    const { theme } = useRequiredContext(ThemeContext);

    const [quill, setQuill] = useState<Quill | null>(null);
    const [cursorIndex, setCursorIndex] = useState(0);

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
      onEditorChangeRef.current = onEditorChange;
    });

    useEffect(() => {
      if (containerRef.current == null) {
        return;
      }
      const container = containerRef.current as HTMLElement;
      const Font: any = Quill.import("formats/font");
      Font.whitelist = [
        "monospace",
        "serif",
        "sans-serif",
        "arial",
        "courier-new",
        "georgia",
        "lucida-sans",
        "tahoma",
        "times-new-roman",
        "verdana",
      ];
      Quill.register(Font, true);

      // Use inline styling for font size instead of classes
      if (inlineFontStyles) {
        const SizeStyle: any = Quill.import("attributors/style/size");
        SizeStyle.whitelist = ["12px", "16px", "24px", "36px"];
        Quill.register(SizeStyle, true);
      }

      Quill.register("modules/resize", QuillResizeImage as any);

      const quill = new Quill(container, {
        placeholder: readOnly ? "" : placeholder || "",
        theme: "snow",
        readOnly: !!readOnly,
        modules: {
          toolbar: toolbarElementId
            ? {
                container: "#" + toolbarElementId,
              }
            : false,
          resize: {
            locale: {},
          },
          clipboard: {
            matchers: [
              [
                "img",
                (node: HTMLImageElement, delta: Delta) => {
                  if (node.style.width) {
                    delta.ops[0].attributes = delta.ops[0].attributes || {};
                    delta.ops[0].attributes.width = node.style.width;
                  }
                  if (node.style.height) {
                    delta.ops[0].attributes = delta.ops[0].attributes || {};
                    delta.ops[0].attributes.height = node.style.height;
                  }
                  return delta;
                },
              ],
            ],
          },
        },
      });
      setQuill(quill);
      cursorIndexRef && (cursorIndexRef.current = 0);

      if (ref) {
        if (typeof ref === "function") {
          ref(quill);
        } else {
          ref.current = quill;
        }
      }

      const emojiButton = document.querySelector(
        `#${toolbarElementId} .rql-emoji`,
      );
      if (emojiButton) {
        emojiButtonRef.current = emojiButton as HTMLButtonElement;
        emojiButton.addEventListener("click", () =>
          setEmojiPickerOpen((emojiPickerOpen) => {
            if (!emojiPickerOpen) setEmojiPickerPositionOverride(null);
            return !emojiPickerOpen;
          }),
        );
      }

      if (defaultValueRef.current) {
        quill.setContents(defaultValueRef.current);
      }

      quill.on(Quill.events.TEXT_CHANGE, (...args) => {
        onTextChangeRef.current?.(...args);
      });

      quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
        onSelectionChangeRef.current?.(...args);
      });

      quill.on(Quill.events.EDITOR_CHANGE, (...args) => {
        handleEditorChange(args);
        onEditorChangeRef.current?.(args);
        if (setDisabled) {
          setDisabled(quill.getLength() <= 1);
        }
      });

      return () => {
        if (typeof ref === "function") {
          ref(null);
        } else if (ref) {
          ref.current = null;
        }
        container.innerHTML = "";
        quill.off(Quill.events.TEXT_CHANGE);
        quill.off(Quill.events.SELECTION_CHANGE);
        quill.off(Quill.events.EDITOR_CHANGE);
      };
    }, [ref, readOnly]);

    function handleEditorChange([name, ...rest]: OnEditorChangeArgs) {
      if (name === "selection-change") {
        const range = rest[0] as Range;
        if (range?.index !== undefined) {
          cursorIndexRef && (cursorIndexRef.current = range.index);
          setCursorIndex(range.index);
        }
      }
    }

    function handleEmojiSelected(emoji: BaseEmoji | CustomEmoji) {
      if (!("native" in emoji)) return; // Rule out CustomEmoji

      if (!quill) return;
      const selection = quill.getSelection();

      if (selection && selection.length) {
        quill.deleteText(cursorIndex, selection.length);
      }
      quill.insertText(cursorIndex, emoji.native);

      const newCursorIndex = cursorIndex + emoji.native.length;
      quill.setSelection(newCursorIndex, 0);
      cursorIndexRef && (cursorIndexRef.current = newCursorIndex);
      setCursorIndex(newCursorIndex);
    }

    function handleHotkey(event: React.KeyboardEvent) {
      if (!event.ctrlKey && !event.metaKey) {
        return;
      }
      if (event.key === "3") {
        const bounds = quill?.getBounds(cursorIndex);
        if (bounds) {
          // Leave some space between the cursor and the emoji picker
          setEmojiPickerPositionOverride({
            top: bounds.top - 2,
            left: bounds.left - 2,
          });
        }
        setEmojiPickerOpen(true);
        event.preventDefault();
      }
    }

    function closeEmojiPicker(cause: EmojiPickerCloseCause) {
      setEmojiPickerPositionOverride(null);
      setEmojiPickerOpen(false);
      if (cause !== "clickOutside") {
        quill?.focus();
      }
    }

    return (
      <>
        {emojiPickerOpen && (
          <div
            className={quillEditorCss.fakeCursor}
            style={{
              left: quill?.getBounds(cursorIndex)?.left,
              top: quill?.getBounds(cursorIndex)?.top,
              height: quill?.getBounds(cursorIndex)?.height,
            }}
          />
        )}
        <EmojiPicker
          anchor={emojiButtonRef.current}
          handleEmojiSelected={handleEmojiSelected}
          onClose={closeEmojiPicker}
          open={emojiPickerOpen}
          theme={theme}
          {...{ positionOverride: emojiPickerPositionOverride || undefined }}
        />
        <div onKeyDown={handleHotkey} ref={containerRef} />
      </>
    );
  },
);
