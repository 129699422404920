import { OverlapTransitionStateContext } from "@redotech/react-animation/outlet-transition";
import { OverlapTransitionState } from "@redotech/react-animation/transition";
import { useLayoutUpdateSubject } from "@redotech/react-util/observable";
import * as classnames from "classnames";
import { useObservableState } from "observable-hooks";
import { Fragment, memo, useContext, useMemo } from "react";
import { Link, useMatches } from "react-router-dom";
import { BehaviorSubject, Observable, Subject, combineLatest, of } from "rxjs";
import { map } from "rxjs/operators";
import * as breadcrumbCss from "./breadcrumb.module.css";
import ChevronDown from "./icon-old/chevron-down.svg";

export interface Breadcrumb {
  id: string;
  name: string;
  url: string | null;
}

const BREADCRUMB_DEFAULT = "";

export function breadcrumbSlot(): BreadcrumbSlot {
  return new BehaviorSubject(BREADCRUMB_DEFAULT);
}

export type BreadcrumbSlot = Subject<string>;

export function useBreadcrumb(
  breadcrumb: BreadcrumbSlot,
  value: string | undefined,
) {
  useLayoutUpdateSubject(
    breadcrumb,
    value || BREADCRUMB_DEFAULT,
    BREADCRUMB_DEFAULT,
  );
}

export function useBreadcrumbs(): Breadcrumb[] | undefined {
  const matches = useMatches();
  const transitionState = useContext(OverlapTransitionStateContext);

  const breadcrumbs = useMemo(() => {
    const observables: Observable<Breadcrumb>[] = [];
    for (const match of matches) {
      const handle: any = match.handle;
      if (handle?.breadcrumb === undefined) {
        continue;
      }
      const name: Observable<string> =
        typeof handle.breadcrumb === "string"
          ? of(handle.breadcrumb)
          : handle.breadcrumb;
      const url =
        handle.breadcrumbNavigate !== false
          ? match.pathname.replace(/\/$/, "")
          : null;
      const id = match.id;
      observables.push(name.pipe(map((name) => ({ id, name, url }))));
    }

    return combineLatest(observables);
  }, [matches]);

  const observable = useObservableState(breadcrumbs);

  if (transitionState === OverlapTransitionState.EXIT) {
    return undefined;
  }

  return observable;
}

export interface BreadcrumbsProps {
  size?: "small" | "large";
}

export const Breadcrumbs = memo(function Breadcrumbs({
  size = "large",
}: BreadcrumbsProps) {
  const breadcrumbs = useBreadcrumbs();
  if (!breadcrumbs) {
    return null;
  }

  const className = classnames(breadcrumbCss.breadcrumbs, {
    [breadcrumbCss.small]: size === "small",
  });

  return (
    <div className={className}>
      {breadcrumbs.map((breadcrumb, i) => (
        <Fragment key={breadcrumb.id}>
          {0 < i && <ChevronDown className={breadcrumbCss.breadcrumbArrow} />}
          {breadcrumb.url ? (
            <Link
              className={classnames(
                breadcrumbCss.breadcrumb,
                breadcrumbCss.link,
              )}
              onClick={(e) => (e.target as HTMLElement).blur()}
              to={breadcrumb.url}
            >
              {breadcrumb.name}
            </Link>
          ) : (
            <span className={breadcrumbCss.breadcrumb}>{breadcrumb.name}</span>
          )}
        </Fragment>
      ))}
    </div>
  );
});
