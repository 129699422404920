import { useRequiredContext } from "@redotech/react-util/context";
import { uploadFile } from "@redotech/redo-api-client/conversations";
import { Attachment } from "@redotech/redo-model/createconversationbody";
import { conversationFileUploadErrorMessages } from "@redotech/redo-model/support/conversations/conversation-file-upload-error";
import { toast } from "@redotech/redo-web/alert";
import FaceSmile from "@redotech/redo-web/arbiter-icon/face-smile.svg";
import { AttachmentThumbnail } from "@redotech/redo-web/attachment-thumbnail";
import { Button, ButtonTheme, IconButton } from "@redotech/redo-web/button";
import { RedoClientContext } from "@redotech/redo-web/client";
import {
  BaseEmoji,
  CustomEmoji,
  EmojiPicker,
} from "@redotech/redo-web/emoji-picker";
import { Flex } from "@redotech/redo-web/flex";
import PaperclipIcon from "@redotech/redo-web/icon-old/paperclip.svg";
import XIcon from "@redotech/redo-web/icon-old/x.svg";
import { OnEditorChangeArgs } from "@redotech/redo-web/quill-editor";
import * as quillEditorCss from "@redotech/redo-web/quill-editor.module.css";
import { ThemeContext } from "@redotech/redo-web/theme-provider";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import Quill, { Range } from "quill";
import { KeyboardEvent, memo, useEffect, useRef, useState } from "react";
import * as messageInputCss from "../../support/message-input.module.css";
import { doFileDrop } from "../../support/utils";
import * as stepRichTextCss from "./step-rich-text.module.css";

export const StepRichTextEditor = memo(function StepRichTextEditor({
  quillRef,
  htmlValue,
  textValue,
  onTextChange,
  readOnly,
  enableAttachments,
  attachments,
  onAttachmentUpload,
  onAttachmentRemoval,
}: {
  quillRef: React.MutableRefObject<Quill | undefined>;
  htmlValue?: string;
  textValue: string;
  onTextChange: () => void;
  readOnly?: boolean;
  enableAttachments?: boolean;
  attachments?: Attachment[];
  onAttachmentUpload?: (attachment: Attachment) => void;
  onAttachmentRemoval?: (url: string) => void;
}) {
  const toolbarRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const apiClient = useRequiredContext(RedoClientContext);
  const [localAttachments, setLocalAttachments] = useState<Attachment[]>(
    attachments || [],
  );

  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const emojiPickerAnchorRef = useRef<HTMLDivElement | null>(null);
  const { theme } = useRequiredContext(ThemeContext);
  const [cursorIndex, setCursorIndex] = useState(0);

  useEffect(() => {
    if (!quillRef.current && containerRef.current) {
      const Font: any = Quill.import("formats/font");
      Font.whitelist = [
        "monospace",
        "serif",
        "sans-serif",
        "arial",
        "courier-new",
        "georgia",
        "lucida-sans",
        "tahoma",
        "times-new-roman",
        "verdana",
      ];
      Quill.register(Font, true);

      const SizeStyle: any = Quill.import("attributors/style/size");
      SizeStyle.whitelist = ["12px", "16px", "24px", "36px"];
      Quill.register(SizeStyle, true);

      quillRef.current = new Quill(containerRef.current, {
        theme: "snow",
        readOnly: !!readOnly,
        modules: {
          toolbar: toolbarRef.current,
        },
      });

      const emojiButton = document.querySelector(".rql-emoji");
      if (emojiButton) {
        emojiButton.addEventListener("click", () =>
          setEmojiPickerOpen((emojiPickerOpen) => !emojiPickerOpen),
        );
      }

      quillRef.current.on(Quill.events.TEXT_CHANGE, onTextChange);
      quillRef.current.on(Quill.events.EDITOR_CHANGE, (...args) => {
        handleEditorChange(args);
      });
    }
  }, [quillRef.current, containerRef.current]);

  function handleEditorChange([name, ...rest]: OnEditorChangeArgs) {
    if (name === "selection-change") {
      const range = rest[0] as Range;
      if (range?.index !== undefined) {
        setCursorIndex(range.index);
      }
    }
  }

  function handleEmojiSelected(emoji: BaseEmoji | CustomEmoji) {
    if (!("native" in emoji)) return; // Rule out CustomEmoji

    const quill = quillRef.current;
    if (!quill) return;
    const selection = quill.getSelection();

    if (selection && selection.length) {
      quill.deleteText(cursorIndex, selection.length);
    }
    quill.insertText(cursorIndex, emoji.native);

    const newCursorIndex = cursorIndex + emoji.native.length;
    quill.setSelection(newCursorIndex, 0);
    setCursorIndex(newCursorIndex);
  }

  function handleEmojiPickerClosed(
    cause: "clickOutside" | "select" | "escape",
  ) {
    setEmojiPickerOpen(false);
    if (cause !== "clickOutside") {
      quillRef.current?.focus();
    }
  }

  useEffect(() => {
    if (!quillRef.current) {
      return;
    }

    if (htmlValue && htmlValue !== quillRef.current.getSemanticHTML()) {
      quillRef.current.clipboard.dangerouslyPasteHTML(htmlValue);
    } else if (textValue !== quillRef.current.getText()) {
      quillRef.current.setText(textValue);
    }
  }, [htmlValue, textValue]);

  const handleUpload = async ({
    event,
    file,
  }: {
    event?: any;
    file?: File;
  }) => {
    const fileToUpload = file || event.target?.files?.[0];
    if (!fileToUpload) {
      return;
    }

    const form = new FormData();
    form.append("file", fileToUpload);
    form.append("fileName", fileToUpload.name);
    const response = await uploadFile(apiClient, form);
    if (response.success) {
      const body = response.body;
      const newAttachment = {
        url: body.url,
        description: body.description,
        mimeType: body.mimeType,
      };
      if (onAttachmentUpload) {
        onAttachmentUpload(newAttachment);
      }
      setLocalAttachments((oldAttachments) => {
        return [newAttachment, ...oldAttachments];
      });
    } else {
      toast(
        conversationFileUploadErrorMessages?.[response.error] ??
          "Unknown error uploading file.",
        {
          variant: "error",
        },
      );
    }
  };

  const clearInput = (e: any) => {
    // This makes the onChange always get triggered.
    e.target.value = "";
  };

  const removeFileFromAttachments = (url: string) => {
    if (onAttachmentRemoval) {
      onAttachmentRemoval(url);
    }
    setLocalAttachments((oldAttachments) => {
      return oldAttachments.filter((attachment) => attachment.url !== url);
    });
  };

  function handleHotkey(event: KeyboardEvent) {
    if (!event.ctrlKey && !event.metaKey) {
      return;
    }
    if (event.key === "3") {
      setEmojiPickerOpen(true);
      event.preventDefault();
    }
  }

  const isMac = navigator.userAgent.includes("mac");

  return (
    <div className={stepRichTextCss.richTextInput} ref={emojiPickerAnchorRef}>
      <div
        className={quillEditorCss.quillToolbar}
        id="toolbar"
        ref={toolbarRef}
      >
        <div className={quillEditorCss.quillFormatButtons}>
          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-underline" />
          <button className="ql-link" />
          <button className="ql-strike" />
          <button className="ql-list" value="ordered" />
          <button className="ql-list" value="bullet" />
          <select className="ql-size" defaultValue="16px">
            <option value="12px">Small</option>
            <option value="16px">Normal</option>
            <option value="24px">Large</option>
            <option value="36px">Huge</option>
          </select>
          <select className="ql-color" />
          {enableAttachments && (
            <>
              {/* TODO: re-enable when we can figure out how to get the file picker to show */}
              {/* <button className="ql-image" /> */}
              <label htmlFor="file-upload" id="file-upload-label">
                <Button
                  onClick={() => {
                    document.getElementById(`file-upload`)?.click();
                  }}
                  theme={ButtonTheme.GHOST}
                >
                  <div className={messageInputCss.actionButton}>
                    <PaperclipIcon className={messageInputCss.paperclip} />
                  </div>
                </Button>
              </label>
              <input
                className={messageInputCss.fileInput}
                id="file-upload"
                onChange={(e) => handleUpload({ event: e })}
                onClick={(e) => clearInput(e)}
                type="file"
              />
            </>
          )}
          <Tooltip title={`Insert emoji (${isMac ? "⌘" : "Ctrl"}+3)`}>
            <IconButton className="rql-emoji">
              <FaceSmile />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <Flex position="relative">
        {/* We're not using QuillEditor from redo/web because of a bug. Switching flow steps can
         *  cause the toolbar options to disappear. This happens when switching directly between
         *  steps that include a Quill editor and when switching quickly from one that does to
         *  one that doesn't, then back to one that does. My guess is that Quill initializes too
         *  quickly and ends up targeting the old toolbar div before it's removed, causing the
         *  new one never to be populated. @javaroc
         * */}
        <div
          className={quillEditorCss.quillContainerSmall}
          id="container"
          onDrop={(e) => doFileDrop(e, handleUpload)}
          onKeyDown={handleHotkey}
          ref={containerRef}
        />
        {emojiPickerOpen && (
          <div
            className={quillEditorCss.fakeCursor}
            style={{
              top: quillRef.current?.getBounds(cursorIndex)?.top,
              left: quillRef.current?.getBounds(cursorIndex)?.left,
              height: quillRef.current?.getBounds(cursorIndex)?.height,
            }}
          />
        )}
        <EmojiPicker
          anchor={emojiPickerAnchorRef.current}
          handleEmojiSelected={handleEmojiSelected}
          onClose={handleEmojiPickerClosed}
          open={emojiPickerOpen}
          theme={theme}
        />
      </Flex>
      {!!localAttachments.length && (
        <div className={stepRichTextCss.fileList}>
          {localAttachments.map((attachment, index) => (
            <div className={stepRichTextCss.attachmentContainer} key={index}>
              <AttachmentThumbnail
                description={attachment.description}
                mimeType={attachment.mimeType}
                restrictWidth
                url={attachment.url}
              />
              <div className={stepRichTextCss.removeFileButton}>
                <IconButton
                  onClick={() => removeFileFromAttachments(attachment.url)}
                >
                  <XIcon />
                </IconButton>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
});

export const containsImg = (html: string | undefined) => {
  if (!html) {
    return false;
  }

  const div = document.createElement("div");
  div.innerHTML = html;
  return !!div.querySelector("img");
};
