import { TeamUser } from "@redotech/redo-model/team";
import { GetUser as User } from "@redotech/redo-model/user";
import { Button, ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { FormEventHandler, memo, useState } from "react";
import { AssigneePicker } from "./assignee-picker";
import * as editAssigneeModalCss from "./edit-assignee-modal.module.css";

export const EditAssigneeModal = memo(function EditAssigneeModal({
  open,
  setOpen,
  currentAssignee,
  users,
  updateAssignee,
}: {
  open: boolean;
  setOpen(value: boolean): void;
  currentAssignee: User | null;
  users: TeamUser[];
  updateAssignee(user: User | null): Promise<void>;
}) {
  const [localUser, setLocalUser] = useState<User | null>(currentAssignee);
  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    await updateAssignee(localUser);
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        onClose={handleCancel}
        open={open}
        size={ModalSize.SMALL}
        title="Select assignee"
      >
        <form
          className={editAssigneeModalCss.editAssigneeForm}
          onSubmit={handleSubmit}
        >
          <AssigneePicker
            assignee={localUser}
            onChange={setLocalUser}
            users={users}
          />
          <Button
            className={editAssigneeModalCss.saveButton}
            size={ButtonSize.MEDIUM}
            theme={ButtonTheme.PRIMARY}
            type="submit"
          >
            Save
          </Button>
        </form>
      </Modal>
    </div>
  );
});
