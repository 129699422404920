// extracted by mini-css-extract-plugin
export var addressCard = "_2-4";
export var addressFields = "_2-1p";
export var addressText = "_2-x";
export var adjustment = "_2-1t";
export var adjustmentBonus = "_2-1k";
export var adjustmentFee = "_2-1l";
export var bonus = "_2-1h";
export var brand = "_2-g";
export var button = "_2-13";
export var card = "_2-j";
export var cardImage = "_2-q";
export var cardInformation = "_2-k";
export var cardItemTitle = "_2-1n";
export var cardItems = "_2-v";
export var cards = "_2-i";
export var center = "_2-b";
export var code = "_2-l";
export var contentWrapper = "_2-1";
export var covered = "_2-f";
export var edit = "_2-1o";
export var error = "_2-14";
export var exchangeBox = "_2-1e";
export var exchangeBoxSelected = "_2-1f";
export var exchangeBoxes = "_2-1d";
export var fee = "_2-1i";
export var flex = "_2-z";
export var greenReturnAdjustment = "_2-1j";
export var infoIcon = "_2-m";
export var input = "_2-12";
export var inputRow = "_2-11";
export var left = "_2-c";
export var leftContent = "_2-2";
export var line = "_2-10";
export var logo = "_2-h";
export var modal = "_2-o";
export var modalContainer = "_2-n";
export var modalTitle = "_2-p";
export var orderMethodText = "_2-1g";
export var page = "_2-0";
export var pickupButton = "_2-t";
export var pickupForm = "_2-8";
export var pickupInputDescription = "_2-1b";
export var pickupLocationDropdown = "_2-1q";
export var pickupPrice = "_2-s";
export var price = "_2-r";
export var reminderTextItem = "_2-1r";
export var returningDisclaimer = "_2-y";
export var subheader = "_2-w";
export var summaryCard = "_2-5";
export var summaryCardContent = "_2-9";
export var summaryCardHorizonal = "_2-6";
export var summaryCardItem = "_2-a";
export var summaryCardItemHorizontal = "_2-d";
export var summaryCardItemPayment = "_2-e";
export var summaryCardPickup = "_2-7";
export var summaryCards = "_2-3";
export var summaryDisclaimer = "_2-1a";
export var summaryHeader = "_2-15";
export var summarySubheader = "_2-16";
export var summarySubtext = "_2-1c";
export var summaryText = "_2-17";
export var summaryTextBold = "_2-19";
export var summaryTextLight = "_2-18";
export var title = "_2-1s";
export var variantTitle = "_2-1m";
export var voided = "_2-u";