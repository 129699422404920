import { useTriggerLoad } from "@redotech/react-util/load";
import { InteractiveMapLocation } from "@redotech/redo-model/interactive-map-location";
import {
  Button,
  ButtonBorder,
  ButtonSize,
  ButtonTheme,
} from "@redotech/redo-web/button";
import { Flex } from "@redotech/redo-web/flex";
import LocationPin from "@redotech/redo-web/icon-old/location-pin.svg";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { Modal } from "@redotech/redo-web/modal";
import { TextInput } from "@redotech/redo-web/text-input";
import { memo, useEffect, useState } from "react";
import { getCarrierLocations } from "../../api";
import { InteractiveMap } from "../interactive-map";

import * as returnStatus from "./return-status.module.css";

const supportedCarrierLocations = ["USPS", "USPSReturns", "FedEx", "PBCS"];

export const ReturnLocationsButton = memo(function ReturnLocationsButton({
  returnData,
}: {
  returnData: any;
}) {
  const [open, setOpen] = useState(false);
  const [zip, setZip] = useState(returnData?.shipping_address?.zip);
  const [locationsReloadCount, setLocationsReloadCount] = useState(0);
  const carrier: string | undefined =
    returnData?.shipment?._shipment?.selected_rate?.carrier;

  const [locationsLoad, getLocations] = useTriggerLoad<
    InteractiveMapLocation[]
  >(async () => {
    const { data } = await getCarrierLocations(carrier.toLowerCase(), {
      zip,
      country: returnData?.shipping_address?.country_code,
    });
    setLocationsReloadCount((count) => count + 1);
    return data.locations;
  });

  useEffect(() => {
    if (carrier && open && !locationsLoad.value) {
      getLocations();
    }
  }, [carrier, open]);

  if (carrier && supportedCarrierLocations.includes(carrier)) {
    return (
      <>
        <div className={returnStatus.stepButtons}>
          <Button
            border={ButtonBorder.LIGHT}
            onClick={() => setOpen(true)}
            size={ButtonSize.MICRO}
            theme={ButtonTheme.OUTLINED}
          >
            <LocationPin className={returnStatus.buttonIcon} /> Drop off
            locations
          </Button>
        </div>
        <Modal
          className={returnStatus.locationsModal}
          onClose={() => setOpen(false)}
          open={open}
          title="Drop off locations"
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              getLocations();
            }}
          >
            <Flex>
              <TextInput
                fullwidth
                onChange={setZip}
                placeholder="Zip code"
                value={zip}
              />
              <Button type="submit">Search</Button>
            </Flex>
          </form>
          {locationsLoad.pending ? (
            <LoadingRedoAnimation />
          ) : locationsLoad.error ? (
            <div>Error loading locations</div>
          ) : locationsLoad.value ? (
            <InteractiveMap
              key={locationsReloadCount}
              locations={locationsLoad.value}
            />
          ) : null}
        </Modal>
      </>
    );
  }
  return null;
});
