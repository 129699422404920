import { CustomerEventType } from "../customer-event/customer-event-definition";
import { ShoppingEventType } from "../shopping-event/shopping-event-definition";

/**
 * Customer events have event types. Categories are useful groupings of these
 * event types that are convenient for users to filter with.
 */
export enum CustomerActivityCategory {
  CLAIMS = "claims",
  ORDERS = "orders",
  RETURNS = "returns",
  SUPPORT = "support",
  SHOPPING = "shopping",
}

export type CustomerEventCategory =
  | CustomerActivityCategory.CLAIMS
  | CustomerActivityCategory.ORDERS
  | CustomerActivityCategory.RETURNS
  | CustomerActivityCategory.SUPPORT;
export function isCustomerEventCategory(
  category: CustomerActivityCategory,
): category is CustomerEventCategory {
  return (
    category === CustomerActivityCategory.CLAIMS ||
    category === CustomerActivityCategory.ORDERS ||
    category === CustomerActivityCategory.RETURNS ||
    category === CustomerActivityCategory.SUPPORT
  );
}
export type ShoppingEventCategory = CustomerActivityCategory.SHOPPING;
export function isShoppingEventCategory(
  category: CustomerActivityCategory,
): category is ShoppingEventCategory {
  return category === CustomerActivityCategory.SHOPPING;
}

export interface CustomerActivityFilterOptions {
  activityCategories?: CustomerActivityCategory[] | undefined;
  timestampStart?: Date | undefined;
  timestampEnd?: Date | undefined;
}

export function customerEventFilterOptionsToQueryParams(
  filterOptions: CustomerActivityFilterOptions | undefined,
): string | undefined {
  if (!filterOptions) {
    return undefined;
  }
  const queryParams: string[] = [];

  if (filterOptions.activityCategories) {
    queryParams.push(
      ...filterOptions.activityCategories.map(
        (category: CustomerActivityCategory) =>
          `eventCategories=${encodeURIComponent(category)}`,
      ),
    );
  }

  if (filterOptions.timestampStart) {
    queryParams.push(
      `timestampStart=${encodeURIComponent(filterOptions.timestampStart.toISOString())}`,
    );
  }

  if (filterOptions.timestampEnd) {
    queryParams.push(
      `timestampEnd=${encodeURIComponent(filterOptions.timestampEnd.toISOString())}`,
    );
  }

  return queryParams.join("&");
}

export const categoryToCustomerActivityTypes: {
  [key in CustomerActivityCategory]: (CustomerEventType | ShoppingEventType)[];
} = {
  [CustomerActivityCategory.CLAIMS]: [
    CustomerEventType.CLAIM_CREATED,
    CustomerEventType.CLAIM_COMMENT_CREATED,
    CustomerEventType.CLAIM_STATUS_UPDATED,
  ],
  [CustomerActivityCategory.ORDERS]: [
    CustomerEventType.ORDER_CREATED,
    CustomerEventType.ORDER_UPDATED,
    CustomerEventType.ORDER_STATUS_UPDATED,
  ],
  [CustomerActivityCategory.RETURNS]: [
    CustomerEventType.RETURN_CREATED,
    CustomerEventType.RETURN_COMMENT_CREATED,
    CustomerEventType.RETURN_STATUS_UPDATED,
  ],
  [CustomerActivityCategory.SUPPORT]: [
    CustomerEventType.CONVERSATION_CREATED,
    CustomerEventType.CONVERSATION_MESSAGE_CREATED,
    CustomerEventType.CONVERSATION_STATUS_UPDATED,
  ],
  [CustomerActivityCategory.SHOPPING]: [
    ShoppingEventType.PRODUCT_VIEWED,
    ShoppingEventType.PRODUCT_ADDED_TO_CART,
  ],
};
