import { useLoad } from "@redotech/react-util/load";
import DeliveryTruckIcon from "@redotech/redo-web/icon-old/delivery-truck.svg";
import { DATE_FORMAT } from "@redotech/redo-web/time";
import * as classNames from "classnames";
import { getProductEstimate } from "../../api";
import * as productDeliveryCss from "./product-delivery-range.module.css";

export function ProductDeliveryRange({
  shippingAddress,
}: {
  shippingAddress?: {
    country_code: string;
    province_code?: string;
  };
}) {
  const estimateLoad = useLoad(async () => {
    try {
      if (!shippingAddress) {
        return undefined;
      }

      const countryCode = shippingAddress.country_code;
      const provinceCode = shippingAddress?.province_code;
      const result = await getProductEstimate({
        countryCode: countryCode,
        provinceCode: provinceCode,
      });
      return result;
    } catch (e) {
      console.error("Error fetching fallback estimated product delivery", e);
      return undefined;
    }
  }, []);

  const maxDate: string = estimateLoad.value
    ? estimateLoad.value.data.maxDate
    : new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toISOString();

  return (
    <div className={productDeliveryCss.container}>
      <span
        className={classNames(
          productDeliveryCss.smolText,
          productDeliveryCss.bold,
        )}
      >
        <span className={productDeliveryCss.deliveryIcon}>
          <DeliveryTruckIcon />
        </span>
        {`Get it by ${DATE_FORMAT.format(new Date(maxDate))}`}
      </span>
    </div>
  );
}
