import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { Modal } from "@redotech/redo-web/modal";
import { memo, useState } from "react";
import * as review from "../return-flow/review.module.css";
import { ReturnInStoreLocationsModal } from "../return-flow/review/return-in-store";

export const ReturnInStoreLocationsButton = memo(
  function ReturnInStoreLocationsButton() {
    const [mapModalOpen, setMapModalOpen] = useState(false);
    return (
      <>
        <div className={review.summaryCardItem}>
          <Button
            onClick={() => setMapModalOpen(true)}
            theme={ButtonTheme.OUTLINED}
          >
            See Available Locations
          </Button>
        </div>
        <Modal
          onClose={() => setMapModalOpen(false)}
          open={mapModalOpen}
          title="Find a store that accepts drop offs:"
        >
          <ReturnInStoreLocationsModal />
        </Modal>
      </>
    );
  },
);
