import { TooltipProps } from "@mui/material/Tooltip";
import { OverflowDirection, useOverflow } from "@redotech/react-util/overflow";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { memo } from "react";
import { Flex } from "../flex";

interface OverflowTooltipProps {
  children: React.ReactElement;
  overflowRef: HTMLElement | null;
  tooltipProps: Omit<TooltipProps, "children">;
  direction: OverflowDirection;
}

export const OverflowTooltip = memo(function OverflowTooltip({
  overflowRef,
  children,
  tooltipProps,
  direction,
}: OverflowTooltipProps): React.ReactElement {
  const isOverflowing = useOverflow(overflowRef, direction);

  if (isOverflowing) {
    return (
      <Tooltip {...tooltipProps}>
        <Flex as="span">{children}</Flex>
      </Tooltip>
    );
  } else {
    return children;
  }
});
