import {
  FlowType,
  Step as ModelStep,
  StepId as ModelStepId,
} from "@redotech/redo-model/return-flow";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import { Dispatch, ReactElement, ReactNode, SetStateAction } from "react";

export type StepId = string;

export interface StepSelectFn {
  (props: {
    value: StepId | undefined;
    valueChange: (value: StepId | undefined) => void;
    type?: Symbol;
  }): ReactNode;
}

export interface StepTypeDetailsProps<T> {
  /** Step selector */
  stepSelect({
    value,
    valueChange,
    type,
  }: {
    value: StepId | undefined | null;
    valueChange(value: StepId | undefined): void;
    type?: Symbol;
  }): ReactNode;
  /** Set new state */
  setState: Dispatch<SetStateAction<T>>;
  /** State */
  state: T;
  /** Flow type */
  flowType: FlowType;
  /** Used by Multiple Conditions and Multiple Actions steps when a child Condition or Action is deleted */
  onDeleteChild?: () => void;
}

export interface StepType<T, M> {
  readonly empty: T;
  readonly title: string;
  readonly information?: string;
  readonly ignore?: boolean;
  displayTitle?(state: T): string;
  customTitle(state: T): string | undefined;
  description(state: T): string;
  // React component names must start with an uppercase letter
  Details(props: StepTypeDetailsProps<T>): ReactElement | null;
  downstream(state: T): StepDownstream[];
  fromModel(model: M, id: (id: ModelStepId) => StepId): T;
  Icon(props: any): ReactElement | null;
  layout(state: T): BlockLayout;
  start?(state: T): string | undefined;
  stepDeleted(state: T, step: StepId): T;
  toModel(state: T, id: (id: StepId) => ModelStepId): M;
  valid(state: T, flowType?: FlowType): boolean;
}

export type Step = TypedStep<any, any>;

export class TypedStep<T, M extends ModelStep> implements Step {
  constructor(
    readonly type: StepType<T, M>,
    private state: T,
  ) {}

  /**
   * The starting step ID
   * Undefined if this step never specifies a starting step.
   */
  get start(): StepId | null | undefined {
    return this.type.start ? this.type.start(this.state) || null : undefined;
  }

  details({
    stepSelect,
    setStep,
    flowType,
  }: {
    stepSelect: StepSelectFn;
    setStep: Dispatch<SetStateAction<Step | undefined>>;
    flowType: FlowType;
  }) {
    const setState: Dispatch<SetStateAction<T>> = (state) => {
      setStep(
        (step) =>
          step &&
          new TypedStep(
            (step as TypedStep<T, M>).type,
            state instanceof Function
              ? state((step as TypedStep<T, M>).state)
              : state,
          ),
      );
    };

    return (
      this.type && (
        <this.type.Details
          flowType={flowType}
          setState={setState}
          state={this.state!}
          stepSelect={stepSelect}
        />
      )
    );
  }

  get Icon(): (props: { className?: string }) => ReactElement | null {
    return this.type.Icon;
  }

  setCustomTitle(
    title: string,
    setStep: Dispatch<SetStateAction<Step | undefined>>,
  ) {
    setStep(
      (step) =>
        step &&
        new TypedStep((step as TypedStep<T, M>).type, {
          ...this.state,
          customTitle: title,
        }),
    );
  }

  description(): string {
    return this.type.description(this.state!);
  }

  downstream(): StepDownstream[] {
    return this.type.downstream(this.state!) || [];
  }

  layout(): BlockLayout {
    return this.type.layout(this.state);
  }

  stepDeleted(step: StepId): Step {
    return new TypedStep(this.type, this.type.stepDeleted(this.state, step));
  }

  sidebarTitle(): string {
    const customTitle =
      this.type.customTitle && this.type.customTitle(this.state);
    return customTitle || this.type.title;
  }

  title(): string {
    const customTitle =
      this.type.customTitle && this.type.customTitle(this.state);
    if (customTitle) {
      return customTitle;
    }
    if (this.type.displayTitle) {
      return this.type.displayTitle(this.state);
    }
    return this.type.title;
  }

  ignore(): boolean {
    return this.type.ignore || false;
  }

  toModel(id: (id: StepId) => ModelStepId): M {
    return this.type.toModel(this.state, id);
  }

  valid(flowType: FlowType): boolean {
    return this.type.valid(this.state, flowType);
  }

  information(): string {
    return this.type.information || "";
  }
}

export interface StepDownstream {
  id: StepId;
  label?: string;
}
