// extracted by mini-css-extract-plugin
export var createdDate = "_6c-1";
export var divider = "_6c-2";
export var headerDivider = "_6c-3";
export var messagesCard = "_6c-0";
export var messagesContainer = "_6c-7";
export var messagesContainerWrapper = "_6c-4";
export var pointerCapturingButtonBoxes = "_6c-b";
export var showLeftPanelButton = "_6c-a";
export var showMoreButton = "_6c-d";
export var showMoreButtonContainer = "_6c-c";
export var summaryTitle = "_6c-6";
export var systemMessageCardWrapper = "_6c-e";
export var topButtons = "_6c-9";
export var typingIndicator = "_6c-8";
export var withPadding = "_6c-5";