import * as amplitude from "@amplitude/analytics-browser";
import Gravatar from "@gravatar/js";
import { useRequiredContext } from "@redotech/react-util/context";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import {
  Permission,
  permitted,
  GetUser as User,
} from "@redotech/redo-model/user";
import { RedoBadge } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { Button } from "@redotech/redo-web/button";
import { Flex } from "@redotech/redo-web/flex";
import ChatBotIcon from "@redotech/redo-web/icon-old/chat-bot.svg";
import EditPencil from "@redotech/redo-web/icon-old/edit-pencil.svg";
import { Text } from "@redotech/redo-web/text";
import { memo, useContext, useMemo, useState } from "react";
import { TeamContext } from "../../app/team";
import { UserContext } from "../../app/user";
import * as assigneeDetailsCss from "./assignee-details.module.css";
import { EditAssigneeModal } from "./edit-assignee-modal";

export const AssigneeDetails = memo(function AssigneeDetails({
  updateConversationDetails,
  conversation,
}: {
  updateConversationDetails: ({ assignee }: { assignee: User | null }) => void;
  conversation: ExpandedConversation;
}) {
  const team = useRequiredContext(TeamContext);
  const currentUser = useContext(UserContext);
  const users = team.users;
  const [editAssigneeModalOpen, setEditAssigneeModalOpen] = useState(false);
  const canEditAssignee =
    !!currentUser &&
    permitted(currentUser.permissions, Permission.EDIT_ASSIGNEE);
  const gravatarUrl = useMemo(() => {
    if (!conversation.assignee?.email) {
      return null;
    }
    return Gravatar({
      email: conversation.assignee.email,
      defaultImage: "404",
      protocol: location.protocol === "https:" ? "https" : undefined,
      size: Math.ceil(devicePixelRatio * 32),
    });
  }, [devicePixelRatio, conversation.assignee?.email]);

  const isChatbot = (assignee: User | null) => {
    return assignee?._id === process.env.CHATBOT_USER_ID;
  };

  const updateAssignee = async (user: User | null) => {
    await updateConversationDetails({ assignee: user });
  };
  return (
    <>
      <div className={assigneeDetailsCss.assigneeDetails}>
        <Flex dir="column" gap="xs">
          <Flex align="center" justify="space-between">
            <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
              Assignee
            </Text>
            {canEditAssignee && (
              <div className={assigneeDetailsCss.assigneeInfo}>
                <Button
                  className={assigneeDetailsCss.editAssigneeInfo}
                  onClick={() => {
                    amplitude.logEvent("view-updateAssigneeModal", {
                      conversationId: conversation._id,
                      channel: conversation.platform,
                    });
                    setEditAssigneeModalOpen(true);
                  }}
                >
                  <EditPencil />
                </Button>
              </div>
            )}
          </Flex>

          <RedoBadge
            avatar={
              !isChatbot(conversation.assignee)
                ? {
                    alt: "Asignee profile picture",
                    imageUrl: gravatarUrl,
                    name: conversation.assignee?.name,
                  }
                : undefined
            }
            color="gray"
            iconLeading={
              isChatbot(conversation.assignee)
                ? { Icon: ChatBotIcon }
                : undefined
            }
            size="sm"
            text={conversation.assignee?.name || "none"}
          />
        </Flex>
      </div>
      {editAssigneeModalOpen && (
        <div className={assigneeDetailsCss.editAssigneeModalContainer}>
          <EditAssigneeModal
            currentAssignee={conversation.assignee}
            open={editAssigneeModalOpen}
            setOpen={setEditAssigneeModalOpen}
            updateAssignee={updateAssignee}
            users={users}
          />
        </div>
      )}
    </>
  );
});
