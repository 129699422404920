import Gravatar from "@gravatar/js";
import { Tooltip } from "@mui/material";
import {
  ConversationPlatform,
  ExpandedConversation,
  ExpandedConversationMessage,
  MessageVisibility,
} from "@redotech/redo-model/conversation";
import { Permission, permitted } from "@redotech/redo-model/user";
import { AttachmentThumbnail } from "@redotech/redo-web/attachment-thumbnail";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { formatTimeAgo } from "@redotech/redo-web/date-utils";
import { Flex } from "@redotech/redo-web/flex";
import MeatballIcon from "@redotech/redo-web/icon-old/meatballs.svg";
import { ImageLightbox } from "@redotech/redo-web/image-lightbox";
import { ExternalLink } from "@redotech/redo-web/link";
import { QuillEditor } from "@redotech/redo-web/quill-editor";
import * as quillEditorCss from "@redotech/redo-web/quill-editor.module.css";
import { Text } from "@redotech/redo-web/text";
import { formatDateTime } from "@redotech/redo-web/time";
import { UserImage } from "@redotech/redo-web/user-image";
import * as classNames from "classnames";
import * as capitalize from "lodash/capitalize";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import {
  Fragment,
  SetStateAction,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { UserContext } from "../app/user";
import { convertPlainTextToHtml } from "./conversation-email-view/format-email-message-content";
import * as messageCss from "./message.module.css";
import { SystemMessage } from "./system-message";
import {
  getCustomerName,
  parseHtml,
  removeHtmlComments,
  removeSimpleHTMLTags,
} from "./utils";

const urlRegex =
  /((?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$]))/gim;
const replaceLongLinks = (text: string) => {
  const pieces = text.split(urlRegex);
  const replacedPieces = pieces.map((piece, index) => {
    if (urlRegex.test(piece) && piece.length > 30) {
      // If the URL is part of an href, don't touch it
      if (index > 0 && pieces[index - 1].endsWith('href="')) {
        return piece;
        // If the URL is the child of a tag like an <a> tag, shorten it
      } else if (index > 0 && pieces[index - 1].endsWith('">')) {
        return `${piece.slice(0, 25)}...`;
        // If it's a bare URL, wrap it in an <a> tag
      } else {
        return `<a href="${piece}">${piece.slice(0, 25)}...</a>`;
      }
    } else {
      return piece;
    }
  });
  return replacedPieces.join("").replaceAll("\n", "<br/>");
};

const removeReplyThread = (
  htmlString: string,
  setShowViewReplyButton: {
    (value: SetStateAction<boolean>): void;
    (arg0: boolean): void;
  },
) => {
  // Just going to add the gmail_quote class (temporary front end only) to anything we want to hide to standardize it.
  // replace any number of x_*gmail_quote classes with just gmail_quote -> outlook
  htmlString = htmlString.replaceAll(
    'gmail_quote"',
    'gmail_quote" class="gmail_quote"',
  );

  // Yahoo mail
  htmlString = htmlString.replaceAll(
    // regex to match class= anything then yahoo_quoted
    /class="[^"]*yahoo_quoted"/g,
    'class="gmail_quote"',
  );

  // Thunderbird
  htmlString = htmlString.replaceAll(
    'class="moz-cite-prefix"',
    'class="gmail_quote"',
  );

  // Adds the class "gmail_quote" to blockquotes with type="cite" to have them be collapsible.
  htmlString = htmlString.replaceAll(
    'type="cite"',
    'type="cite" class="gmail_quote"',
  );
  // Hide any reply threads in the email
  const htmlObject = document.createElement("div");
  htmlObject.innerHTML = htmlString;

  // Remove outlook reply threads
  const firstThreadOutlook = htmlObject.querySelector("[id$='divRplyFwdMsg']");
  if (firstThreadOutlook) {
    let currentElement: Element | null = firstThreadOutlook;
    let nextElement: Element | null = firstThreadOutlook.nextElementSibling;
    while (currentElement) {
      currentElement.remove();
      currentElement = nextElement;
      nextElement = nextElement?.nextElementSibling || null;
    }
    setShowViewReplyButton(true);
  }

  const elementsToRemove = htmlObject.querySelectorAll(
    "[class$='gmail_quote']",
  );

  if (elementsToRemove.length > 0) {
    setShowViewReplyButton(true);
  }
  for (let i = 0; i < elementsToRemove.length; i++) {
    elementsToRemove[i].remove();
  }
  return htmlObject;
};

export const Message = memo(function Message({
  message,
  conversation,
  index,
  profilePictures,
  setPrivateReplyModalOpen,
  setPrivateReplyMessage,
}: {
  message: ExpandedConversationMessage;
  conversation: ExpandedConversation;
  index: number;
  profilePictures?: Record<string, string>;
  setPrivateReplyModalOpen: (value: boolean) => void;
  setPrivateReplyMessage: (value: ExpandedConversationMessage) => void;
}) {
  const [quill, setQuill] = useState<Quill | null>(null);
  const user = useContext(UserContext);
  const [timeSinceMessage, setTimeSinceMessage] = useState<string>(
    formatTimeAgo(message.sentAt),
  );
  const [selectedImage, setSelectedImage] = useState<string>("");
  const [isLightboxOpen, setIsLightboxOpen] = useState<boolean>(false);
  const [hideReplyThreads, setHideReplyThreads] = useState<boolean>(true);
  const [showViewReplyButton, setShowViewReplyButton] =
    useState<boolean>(false);
  const [showPrivateReplyOption, setShowPrivateReplyOption] =
    useState<boolean>(false);
  const canReply =
    !!user && permitted(user.permissions, Permission.CREATE_REPLY);

  const typeClassname =
    message.visibility === MessageVisibility.INTERNAL
      ? messageCss.internal
      : message.type === "merchant"
        ? messageCss.merchant
        : messageCss.customer;
  const senderClassname =
    message.type === "merchant" ? messageCss.merchant : messageCss.customer;

  // TODO: Do more than just use gravatar. We may want a way for support reps to upload profile photos,
  // and we may want something to get better photos for customers as well.
  // Removing profile pictures for now
  let senderImage = useMemo(() => {
    const senderEmail =
      message.type === "merchant" || message.type === "system"
        ? message.user?.email
        : message.customer.email;
    if (senderEmail) {
      return Gravatar({
        email: senderEmail,
        defaultImage: "404",
        protocol: location.protocol === "https:" ? "https" : undefined,
        size: Math.ceil(devicePixelRatio * 32),
      });
    } else return null;
  }, [devicePixelRatio, message]);
  const senderName =
    message.type === "merchant"
      ? message.user?._id === process.env.ANONYMOUS_USER_ID
        ? `${capitalize(conversation.platform)} User`
        : message.user?.name
      : ![
            ConversationPlatform.INSTAGRAM_COMMENTS,
            ConversationPlatform.FACEBOOK_COMMENTS,
          ].includes(conversation.platform) ||
          message.customer === conversation.customer.customer
        ? getCustomerName(message.customer, conversation)
        : undefined;

  const senderIdentifiers = [];
  if (senderName) {
    senderIdentifiers.push(<span>{senderName}</span>);
  }
  if (
    message.type === "customer" &&
    [
      ConversationPlatform.INSTAGRAM,
      ConversationPlatform.INSTAGRAM_COMMENTS,
    ].includes(conversation.platform) &&
    message.instagram?.comment?.username
  ) {
    const instagramUrl = `https://instagram.com/${message.instagram.comment.username}`;
    senderIdentifiers.push(
      <ExternalLink
        className={messageCss.username}
        showIcon={false}
        url={instagramUrl}
      >
        @{message.instagram.comment.username}
      </ExternalLink>,
    );
  }
  if (
    message.type === "customer" &&
    conversation.platform === ConversationPlatform.FACEBOOK_COMMENTS &&
    message.facebook?.comment?.facebookName &&
    !senderIdentifiers.length
  ) {
    senderIdentifiers.push(
      <span>{message.facebook.comment.facebookName}</span>,
    );
  }

  if (message.type === "customer") {
    if (message.customer === conversation.customer.customer) {
      if (conversation.customer?.image?.url) {
        senderImage = conversation.customer.image.url;
      }

      if (
        [
          ConversationPlatform.INSTAGRAM,
          ConversationPlatform.INSTAGRAM_COMMENTS,
        ].includes(conversation.platform) &&
        conversation.customer?.instagram?.profilePic
      ) {
        senderImage = conversation.customer.instagram.profilePic;
      } else if (
        [
          ConversationPlatform.FACEBOOK,
          ConversationPlatform.FACEBOOK_COMMENTS,
        ].includes(conversation.platform) &&
        conversation.customer?.facebook?.profilePic
      ) {
        senderImage = conversation.customer.facebook.profilePic;
      }
    } else if (profilePictures && profilePictures[message.customer]) {
      senderImage = profilePictures[message.customer];
    }
  }

  useEffect(() => {
    setTimeSinceMessage(formatTimeAgo(message.sentAt));
    const intervalId = setInterval(() => {
      setTimeSinceMessage(formatTimeAgo(message.sentAt));
    }, 60 * 1000);
    return () => clearInterval(intervalId);
  }, [message.sentAt]);

  const handleImageClick = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setIsLightboxOpen(true);
  };

  const messageArray = removeSimpleHTMLTags(message.content).split(urlRegex);
  const images: string[] = [];
  const contentElements: any = [];
  // Display an image instead of the url for image urls not in <img> tags
  messageArray.forEach(async (word: any, index: number) => {
    if (urlRegex.test(word)) {
      // Check if the url is preceeded by "src", indicating it's already displaying inline
      if (
        contentElements[contentElements.length - 1]
          .slice(contentElements[contentElements.length - 1].length - 5)
          .includes("src=")
      ) {
        return;
      }
      if (
        word.match(/(https?:\/\/\S+(\.png|\.jpg|\.gif|\.jpeg|\.webp))/g) ||
        word.includes("ig_messaging_cdn")
      ) {
        images.push(word);
      } else {
        contentElements.push(`<a href="${word}" target="_blank">${word}</a>`);
      }
    } else {
      contentElements.push(word);
    }
  });

  useEffect(() => {
    if (quill) {
      if (message.email?.htmlBody) {
        // Tables don't get good formatting with quill, so we will extract the table,
        // parse it to get the text content, then put it back in the message with some basic formatting
        const tableRegex = /<table.*\/table>/g;
        let htmlString = removeHtmlComments(message.email.htmlBody).replace(
          /(\r\n|\n|\r)/gm,
          "",
        );
        const tableMatches = htmlString.match(tableRegex);
        if (tableMatches) {
          for (const match of tableMatches) {
            htmlString = htmlString.replace(
              match,
              replaceLongLinks(parseHtml(match).replace("\n", "<br />")),
            );
          }
        }
        if (hideReplyThreads) {
          const htmlObject = removeReplyThread(
            htmlString,
            setShowViewReplyButton,
          );
          htmlString = htmlObject.outerHTML;
        }
        quill.clipboard.dangerouslyPasteHTML(htmlString);
      } else if (message.chatFlow?.htmlContent) {
        quill.clipboard.dangerouslyPasteHTML(message.chatFlow.htmlContent);
      } else if (contentElements.length > 0) {
        const htmlString = convertPlainTextToHtml(contentElements.join(""));
        quill.clipboard.dangerouslyPasteHTML(htmlString);
      } else {
        // Message.content should just be plaintext, with our own <a> tags added in to preserve links
        quill.clipboard.dangerouslyPasteHTML(
          convertPlainTextToHtml(message.content),
        );
      }
    }
  }, [quill, hideReplyThreads, contentElements.length]);

  return message.type !== "system" ? (
    <>
      {(contentElements.length > 0 ||
        images.length > 0 ||
        message.email?.htmlBody) && (
        <div className={classNames(messageCss.message, senderClassname)}>
          {message.type === "customer" &&
            [
              ConversationPlatform.INSTAGRAM,
              ConversationPlatform.INSTAGRAM_COMMENTS,
              ConversationPlatform.FACEBOOK,
              ConversationPlatform.FACEBOOK_COMMENTS,
            ].includes(conversation.platform) && (
              <div className={messageCss.profilePicture}>
                {senderImage ? (
                  <img
                    alt="Customer profile picture"
                    src={senderImage ?? undefined}
                  />
                ) : (
                  <UserImage
                    alt="Customer profile picture"
                    imageUrl={null}
                    name={senderName}
                  />
                )}
              </div>
            )}
          <div
            className={classNames(messageCss.bubbleContext, senderClassname)}
            onMouseEnter={() => {
              if (
                message.type !== "customer" ||
                ![
                  ConversationPlatform.INSTAGRAM_COMMENTS,
                  ConversationPlatform.FACEBOOK_COMMENTS,
                ].includes(conversation.platform) ||
                !canReply
              ) {
                return;
              }
              setShowPrivateReplyOption(true);
            }}
            onMouseLeave={() => setShowPrivateReplyOption(false)}
          >
            <div className={messageCss.infoAbove}>
              <div
                className={
                  message.type === "merchant"
                    ? messageCss.senderDate
                    : messageCss.senderDateCustomer
                }
              >
                <Flex align="baseline">
                  {senderIdentifiers.length > 0 && (
                    <>
                      {senderIdentifiers.map((identifier, index) => (
                        <>
                          {index > 0 && " - "}
                          <Fragment key={index}>
                            <Text fontSize="sm" fontWeight="medium">
                              {identifier}
                            </Text>
                          </Fragment>
                        </>
                      ))}
                    </>
                  )}
                  <Tooltip
                    title={formatDateTime(
                      Temporal.Instant.from(message.sentAt),
                    )}
                  >
                    <Text fontSize="xs">{timeSinceMessage}</Text>
                  </Tooltip>
                </Flex>
              </div>
              {!!message.instagram?.repliedStoryUrl && (
                <div>
                  Replied to your{" "}
                  <ExternalLink
                    showIcon={false}
                    url={message.instagram.repliedStoryUrl}
                  >
                    story
                  </ExternalLink>
                </div>
              )}
              {!!message.instagram?.comment &&
                conversation.platform === ConversationPlatform.INSTAGRAM && (
                  <div>Commented on your post</div>
                )}
              {(message.instagram?.privateRepliedComment ||
                message.facebook?.privateRepliedComment) && (
                <div>
                  Private reply to their comment on your{" "}
                  {message.instagram?.privateRepliedComment?.permalink ? (
                    <ExternalLink
                      showIcon={false}
                      url={message.instagram.privateRepliedComment.permalink}
                    >
                      post
                    </ExternalLink>
                  ) : message.facebook?.privateRepliedComment?.permalink ? (
                    <ExternalLink
                      showIcon={false}
                      url={message.facebook.privateRepliedComment.permalink}
                    >
                      post
                    </ExternalLink>
                  ) : (
                    "post"
                  )}
                </div>
              )}
            </div>
            {images.map((image: string) => (
              <Fragment key={image}>
                {message.instagram?.mentionedStoryUrl && (
                  <div>Mentioned you in their story</div>
                )}
                <div
                  className={classNames(
                    messageCss.bubble,
                    typeClassname,
                    conversation.platform,
                  )}
                >
                  <div
                    className={classNames(
                      messageCss.bubbleItems,
                      senderClassname,
                    )}
                  >
                    <img
                      className={messageCss.uploadedImage}
                      onClick={(e) => handleImageClick(e.currentTarget.src)}
                      onError={(e) => {
                        e.currentTarget.style.display = "none";
                        e.currentTarget.parentElement?.appendChild(
                          document.createTextNode(
                            message.instagram?.mentionedStoryUrl
                              ? "Story expired"
                              : "Image unavailable",
                          ),
                        );
                      }}
                      src={image}
                    />
                  </div>
                </div>
              </Fragment>
            ))}
            {(contentElements.length > 0 || message.email?.htmlBody) && (
              <div
                className={classNames(
                  messageCss.bubble,
                  typeClassname,
                  conversation.platform === "email" && messageCss.email,
                )}
              >
                <div
                  className={classNames(
                    quillEditorCss.quillContainerSmall,
                    quillEditorCss.removePadding,
                  )}
                >
                  <QuillEditor
                    readOnly
                    ref={setQuill}
                    toolbarElementId={
                      showViewReplyButton ? `messageToolbar${index}` : undefined
                    }
                  />
                </div>
                {!!message.files.length && (
                  <div className={messageCss.fileList}>
                    {message.files.map((file) => (
                      <AttachmentThumbnail
                        description={file.description}
                        key={file._id}
                        mimeType={file.mimeType}
                        url={file.url}
                      />
                    ))}
                  </div>
                )}
                {showViewReplyButton && (
                  <div className={messageCss.quillToolbarContainer}>
                    <div
                      className={quillEditorCss.quillToolbar}
                      id={`messageToolbar${index}`}
                    >
                      <Button
                        onClick={() => {
                          setHideReplyThreads(!hideReplyThreads);
                        }}
                        theme={ButtonTheme.GHOST}
                      >
                        <div
                          className={
                            message.visibility === MessageVisibility.PUBLIC &&
                            message.type === "merchant"
                              ? messageCss.lightActionButton
                              : messageCss.actionButton
                          }
                        >
                          <MeatballIcon />
                        </div>
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
            {showPrivateReplyOption && (
              <div className={messageCss.senderDateCustomer}>
                {message.instagram?.comment?.hasBeenRepliedTo ||
                message.facebook?.comment?.hasBeenRepliedTo ? (
                  <p>Already replied</p>
                ) : (
                  <ExternalLink
                    className={messageCss.username}
                    onClick={() => {
                      setPrivateReplyMessage(message);
                      setPrivateReplyModalOpen(true);
                    }}
                    showIcon={false}
                  >
                    Reply with DM
                  </ExternalLink>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      <ImageLightbox
        imageSrc={selectedImage}
        onClose={() => setIsLightboxOpen(false)}
        open={isLightboxOpen}
      />
    </>
  ) : (
    <SystemMessage message={message.content} />
  );
});
