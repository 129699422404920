import { ClientOptions, createRpcClient } from "@redotech/rpc/client";
import { InferRpcDefinition } from "@redotech/rpc/definition";
import { getCustomerAccountsMetricsForOverviewSchema } from "./get-customer-accounts-metrics-for-overview-schema";
import { getEmailTemplateNamesSchema } from "./get-email-template-names-schema";
import { getOrdersSchema } from "./get-orders-schema";
import { getReturnsInstantExchangeRecoveryHistorySchema } from "./get-returns-instant-exchange-recovery-history-schema";
import { getReviewsAverageByProjectSchema } from "./get-reviews-average-by-project";
import { getReviewsByProductIdSchema } from "./get-reviews-by-product-id-schema";
import { getReviewsByTeamIdSchema } from "./get-reviews-by-team-id-schema";
import { getReviewsSchema } from "./get-reviews-schema";
import { issueStoreCreditToCustomerSchema } from "./issue-store-credit-to-customer-schema";
import { resendEmailSchema } from "./resend-email-schema";
import { saveNewReviewSchema } from "./save-new-review-schema";
import { updateReviewSchema } from "./update-review-schema";

export const redoMerchantRPC = {
  issueStoreCreditToCustomer: issueStoreCreditToCustomerSchema,
  getCustomerAccountsMetricsForOverview:
    getCustomerAccountsMetricsForOverviewSchema,
  resendEmail: resendEmailSchema,
  saveNewReview: saveNewReviewSchema,
  getReviewsByProductId: getReviewsByProductIdSchema,
  getEmailTemplateNames: getEmailTemplateNamesSchema,
  getReviewsByTeamId: getReviewsByTeamIdSchema,
  getReviews: getReviewsSchema,
  updateReview: updateReviewSchema,
  getReviewsAverageByProject: getReviewsAverageByProjectSchema,
  getReturnsInstantExchangeRecoveryHistory:
    getReturnsInstantExchangeRecoveryHistorySchema,
  getOrders: getOrdersSchema,
};

export type RedoMerchantRpcDefinition = InferRpcDefinition<
  typeof redoMerchantRPC
>;

export const createRedoMerchantRpcClient = (
  options: ClientOptions<typeof redoMerchantRPC>,
) => createRpcClient(redoMerchantRPC, options);

export type RedoMerchantRpcClient = ReturnType<
  typeof createRedoMerchantRpcClient
>;
