// extracted by mini-css-extract-plugin
export var actions = "_2k-0";
export var actionsContainer = "_2k-3";
export var border = "_2k-1";
export var collapse = "_2k-2";
export var compact = "_2k-b";
export var content = "_2k-4";
export var contentChild = "_2k-8";
export var fullscreen = "_2k-6";
export var header = "_2k-9";
export var headerContainer = "_2k-c";
export var hidePadding = "_2k-5";
export var noScrollX = "_2k-d";
export var noScrollY = "_2k-e";
export var optOutTopPadding = "_2k-7";
export var profile = "_2k-f";
export var profileContainer = "_2k-g";
export var profileImage = "_2k-h";
export var profileMain = "_2k-i";
export var profileSubtitle = "_2k-k";
export var profileTitle = "_2k-j";
export var scrolledTop = "_2k-a";
export var title = "_2k-l";