// extracted by mini-css-extract-plugin
export var border = "_41-5";
export var button = "_41-f";
export var center = "_41-7";
export var centered = "_41-3";
export var closeButton = "_41-9";
export var disabled = "_41-g";
export var error = "_41-j";
export var fill = "_41-c";
export var footer = "_41-b";
export var footerAction = "_41-d";
export var footerButtons = "_41-e";
export var header = "_41-4";
export var icon = "_41-i";
export var info = "_41-m";
export var inline = "_41-6";
export var large = "_41-2";
export var main = "_41-a";
export var modal = "_41-0";
export var none = "_41-n";
export var right = "_41-h";
export var small = "_41-1";
export var success = "_41-l";
export var titleIcon = "_41-8";
export var warn = "_41-k";