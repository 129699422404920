import { objectJsonFormat } from "@redotech/json/format";
import {
  CustomerEventType,
  IClaimCommentCreatedCustomerEvent,
  IClaimCreatedCustomerEvent,
  IClaimCustomerEvent,
  IClaimStatusUpdatedCustomerEvent,
  IConversationCreatedCustomerEvent,
  IConversationCustomerEvent,
  IConversationMessageCreatedCustomerEvent,
  IConversationStatusUpdatedCustomerEvent,
  ICustomerEvent,
  IExternalPlatformCustomerCreatedEvent,
  IOrderCreatedCustomerEvent,
  IOrderCustomerEvent,
  IOrderStatusUpdatedCustomerEvent,
  IOrderUpdatedCustomerEvent,
  IReturnCommentCreatedCustomerEvent,
  IReturnCreatedCustomerEvent,
  IReturnCustomerEvent,
  IReturnStatusUpdatedCustomerEvent,
  IStorefrontLoginEvent,
} from "../customer-event/customer-event-definition";
import { arrayCustomerEventJsonFormat } from "../customer-event/customer-event-json-format";
import {
  IProductAddedToCartShoppingEvent,
  IProductViewedShoppingEvent,
  IShoppingEvent,
  ShoppingEventType,
} from "../shopping-event/shopping-event-definition";
import { arrayShoppingEventJsonFormat } from "../shopping-event/shopping-event-json-format";

export type CustomerActivityType = ICustomerEvent | IShoppingEvent;
export interface ICustomerActivity {
  customerEvents: ICustomerEvent[];
  shoppingEvents: IShoppingEvent[];
}

export const customerActivityJsonFormat = objectJsonFormat<ICustomerActivity>(
  {
    customerEvents: arrayCustomerEventJsonFormat,
    shoppingEvents: arrayShoppingEventJsonFormat,
  },
  {},
);

export function isOrderCreatedCustomerEvent(
  customerActivity: CustomerActivityType,
): customerActivity is IOrderCreatedCustomerEvent {
  return customerActivity.eventType === CustomerEventType.ORDER_CREATED;
}

export function isOrderUpdatedCustomerEvent(
  customerActivity: CustomerActivityType,
): customerActivity is IOrderUpdatedCustomerEvent {
  return customerActivity.eventType === CustomerEventType.ORDER_UPDATED;
}

export function isOrderStatusUpdatedCustomerEvent(
  customerActivity: CustomerActivityType,
): customerActivity is IOrderStatusUpdatedCustomerEvent {
  return customerActivity.eventType === CustomerEventType.ORDER_STATUS_UPDATED;
}

export function isOrderCustomerEvent(
  customerActivity: CustomerActivityType,
): customerActivity is IOrderCustomerEvent {
  return (
    isOrderCreatedCustomerEvent(customerActivity) ||
    isOrderStatusUpdatedCustomerEvent(customerActivity) ||
    isOrderUpdatedCustomerEvent(customerActivity)
  );
}

export function isReturnCreatedCustomerEvent(
  customerActivity: CustomerActivityType,
): customerActivity is IReturnCreatedCustomerEvent {
  return customerActivity.eventType === CustomerEventType.RETURN_CREATED;
}

export function isReturnStatusUpdatedCustomerEvent(
  customerActivity: CustomerActivityType,
): customerActivity is IReturnStatusUpdatedCustomerEvent {
  return customerActivity.eventType === CustomerEventType.RETURN_STATUS_UPDATED;
}

export function isReturnCommentCreatedCustomerEvent(
  customerActivity: CustomerActivityType,
): customerActivity is IReturnCommentCreatedCustomerEvent {
  return (
    customerActivity.eventType === CustomerEventType.RETURN_COMMENT_CREATED
  );
}

export function isReturnCustomerEvent(
  customerActivity: CustomerActivityType,
): customerActivity is IReturnCustomerEvent {
  return (
    isReturnCreatedCustomerEvent(customerActivity) ||
    isReturnStatusUpdatedCustomerEvent(customerActivity) ||
    isReturnCommentCreatedCustomerEvent(customerActivity)
  );
}

export function isClaimCreatedCustomerEvent(
  customerActivity: CustomerActivityType,
): customerActivity is IClaimCreatedCustomerEvent {
  return customerActivity.eventType === CustomerEventType.CLAIM_CREATED;
}

export function isClaimStatusUpdatedCustomerEvent(
  customerActivity: CustomerActivityType,
): customerActivity is IClaimStatusUpdatedCustomerEvent {
  return customerActivity.eventType === CustomerEventType.CLAIM_STATUS_UPDATED;
}

export function isClaimCommentCreatedCustomerEvent(
  customerActivity: CustomerActivityType,
): customerActivity is IClaimCommentCreatedCustomerEvent {
  return customerActivity.eventType === CustomerEventType.CLAIM_COMMENT_CREATED;
}

export function isClaimCustomerEvent(
  customerActivity: CustomerActivityType,
): customerActivity is IClaimCustomerEvent {
  return (
    isClaimCreatedCustomerEvent(customerActivity) ||
    isClaimStatusUpdatedCustomerEvent(customerActivity) ||
    isClaimCommentCreatedCustomerEvent(customerActivity)
  );
}

export function isConversationCreatedCustomerEvent(
  customerActivity: CustomerActivityType,
): customerActivity is IConversationCreatedCustomerEvent {
  return customerActivity.eventType === CustomerEventType.CONVERSATION_CREATED;
}

export function isConversationStatusUpdatedCustomerEvent(
  customerActivity: CustomerActivityType,
): customerActivity is IConversationStatusUpdatedCustomerEvent {
  return (
    customerActivity.eventType === CustomerEventType.CONVERSATION_STATUS_UPDATED
  );
}

export function isConversationMessageCreatedCustomerEvent(
  customerActivity: CustomerActivityType,
): customerActivity is IConversationMessageCreatedCustomerEvent {
  return (
    customerActivity.eventType ===
    CustomerEventType.CONVERSATION_MESSAGE_CREATED
  );
}

export function isConversationCustomerEvent(
  customerActivity: CustomerActivityType,
): customerActivity is IConversationCustomerEvent {
  return (
    isConversationCreatedCustomerEvent(customerActivity) ||
    isConversationMessageCreatedCustomerEvent(customerActivity) ||
    isConversationStatusUpdatedCustomerEvent(customerActivity)
  );
}

export function isExternalPlatformCustomerCreatedCustomerEvent(
  customerActivity: CustomerActivityType,
): customerActivity is IExternalPlatformCustomerCreatedEvent {
  return (
    customerActivity.eventType ===
    CustomerEventType.EXTERNAL_PLATFORM_CUSTOMER_CREATED
  );
}

export function isStorefrontLoginCustomerEvent(
  customerActivity: CustomerActivityType,
): customerActivity is IStorefrontLoginEvent {
  return customerActivity.eventType === CustomerEventType.STOREFRONT_LOGIN;
}

export function isCustomerEvent(
  customerActivity: CustomerActivityType,
): customerActivity is ICustomerEvent {
  return (
    isOrderCustomerEvent(customerActivity) ||
    isReturnCustomerEvent(customerActivity) ||
    isClaimCustomerEvent(customerActivity) ||
    isConversationCustomerEvent(customerActivity)
  );
}

export function isProductViewedEvent(
  customerActivity: CustomerActivityType,
): customerActivity is IProductViewedShoppingEvent {
  return customerActivity.eventType === ShoppingEventType.PRODUCT_VIEWED;
}

export function isProductAddedToCartEvent(
  customerActivity: CustomerActivityType,
): customerActivity is IProductAddedToCartShoppingEvent {
  return customerActivity.eventType === ShoppingEventType.PRODUCT_ADDED_TO_CART;
}

export function isShoppingEvent(
  customerActivity: CustomerActivityType,
): customerActivity is IShoppingEvent {
  return (
    isProductViewedEvent(customerActivity) ||
    isProductAddedToCartEvent(customerActivity)
  );
}
