import { CurrencyContext } from "@redotech/redo-web/currency";
import { memo, useContext } from "react";

import * as returnStatus from "./return-status.module.css";

export const Product = memo(function Product({
  imageSrc,
  price,
  title,
  variantTitle,
  returnType,
  last,
}: {
  imageSrc: string;
  price: string;
  title: string;
  variantTitle: string;
  returnType: string;
  last: boolean;
}) {
  const { formatCurrency } = useContext(CurrencyContext);

  return (
    <div>
      <div className={returnStatus.product}>
        <div className={returnStatus.productImageContainer}>
          <img className={returnStatus.productImage} src={imageSrc} />
        </div>
        <div className={returnStatus.productInfoContainer}>
          <div className={returnStatus.productInfo}>
            {price && (
              <div className={returnStatus.productPrice}>
                {formatCurrency(parseFloat(price))}
              </div>
            )}
            <div>{title}</div>
            <div className={returnStatus.productVariant}>{variantTitle}</div>
          </div>
          {!!returnType && (
            <div className={returnStatus.productReturnTypeContainer}>
              <div className={returnStatus.productReturnType}>{returnType}</div>
            </div>
          )}
        </div>
      </div>
      {!last && <div className={returnStatus.productSeparator} />}
    </div>
  );
});
