// extracted by mini-css-extract-plugin
export var button = "_6w-0";
export var buttonFlexContainer = "_6w-4";
export var disabled = "_6w-7";
export var hierarchyPrimary = "_6w-6";
export var hierarchySecondary = "_6w-8";
export var hierarchyTertiary = "_6w-9";
export var iconContainer = "_6w-b";
export var large = "_6w-c";
export var multiActionLeft = "_6w-e";
export var multiActionRight = "_6w-f";
export var only = "_6w-a";
export var regular = "_6w-3";
export var small = "_6w-2";
export var themeNormal = "_6w-5";
export var xl = "_6w-d";
export var xs = "_6w-1";