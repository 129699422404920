import {
  arrayJsonFormat,
  booleanJsonFormat,
  dateJsonFormat,
  JsonFormat,
  JsonFormatError,
  numberJsonFormat,
  objectJsonFormat,
  stringEnumJsonFormat,
  stringJsonFormat,
  typedStringJsonFormat,
} from "@redotech/json/format";
import { Json } from "@redotech/json/json";
import { ConversationStatus } from "../conversation";
import {
  isClaimCommentCreatedCustomerEvent,
  isClaimCreatedCustomerEvent,
  isClaimStatusUpdatedCustomerEvent,
  isConversationCreatedCustomerEvent,
  isConversationMessageCreatedCustomerEvent,
  isConversationStatusUpdatedCustomerEvent,
  isExternalPlatformCustomerCreatedCustomerEvent,
  isOrderCreatedCustomerEvent,
  isOrderStatusUpdatedCustomerEvent,
  isOrderUpdatedCustomerEvent,
  isReturnCommentCreatedCustomerEvent,
  isReturnCreatedCustomerEvent,
  isReturnStatusUpdatedCustomerEvent,
  isStorefrontLoginCustomerEvent,
} from "../customer-activity/customer-activity-definition";
import { returnStatusJsonFormat } from "../return-new";
import { CompleteOrderFulfillmentStatus } from "../shopify-order";
import {
  CUSTOMER_EVENT_DISCRIMINATOR_KEY,
  CustomerEventType,
  ExternalCustomerPlatform,
  IClaimCommentCreatedCustomerEvent,
  IClaimCreatedCustomerEvent,
  IClaimStatusUpdatedCustomerEvent,
  IConversationCreatedCustomerEvent,
  IConversationMessageCreatedCustomerEvent,
  IConversationStatusUpdatedCustomerEvent,
  ICustomerEvent,
  IExternalPlatformCustomerCreatedEvent,
  IOrderCreatedCustomerEvent,
  IOrderStatusUpdatedCustomerEvent,
  IOrderUpdatedCustomerEvent,
  IReturnCommentCreatedCustomerEvent,
  IReturnCreatedCustomerEvent,
  IReturnStatusUpdatedCustomerEvent,
  IStorefrontLoginEvent,
} from "./customer-event-definition";

const customerEventCommonFields = {
  _id: stringJsonFormat,
  team: stringJsonFormat,
  email: stringJsonFormat,
  timestamp: dateJsonFormat,
  eventType: stringEnumJsonFormat(CustomerEventType),
};

const orderCreatedCustomerEventJsonFormat: JsonFormat<IOrderCreatedCustomerEvent> =
  objectJsonFormat(
    {
      ...customerEventCommonFields,
      eventType: typedStringJsonFormat(CustomerEventType.ORDER_CREATED),
      orderId: stringJsonFormat,
      shopifyId: stringJsonFormat,
      itemCount: numberJsonFormat,
      totalPrice: stringJsonFormat,
      orderName: stringJsonFormat,
    },
    {},
  );
const orderUpdatedCustomerEventJsonFormat: JsonFormat<IOrderUpdatedCustomerEvent> =
  objectJsonFormat(
    {
      ...customerEventCommonFields,
      eventType: typedStringJsonFormat(CustomerEventType.ORDER_UPDATED),
      orderId: stringJsonFormat,
      shopifyId: stringJsonFormat,
    },
    {},
  );
const orderStatusUpdatedCustomerEventJsonFormat: JsonFormat<IOrderStatusUpdatedCustomerEvent> =
  objectJsonFormat(
    {
      ...customerEventCommonFields,
      eventType: typedStringJsonFormat(CustomerEventType.ORDER_STATUS_UPDATED),
      orderId: stringJsonFormat,
      shopifyId: stringJsonFormat,
      newStatus: CompleteOrderFulfillmentStatus.jsonFormat,
    },
    {},
  );
const returnCreatedCustomerEventJsonFormat: JsonFormat<IReturnCreatedCustomerEvent> =
  objectJsonFormat(
    {
      ...customerEventCommonFields,
      eventType: typedStringJsonFormat(CustomerEventType.RETURN_CREATED),
      returnId: stringJsonFormat,
    },
    {},
  );
const returnStatusUpdatedCustomerEventJsonFormat: JsonFormat<IReturnStatusUpdatedCustomerEvent> =
  objectJsonFormat(
    {
      ...customerEventCommonFields,
      eventType: typedStringJsonFormat(CustomerEventType.RETURN_STATUS_UPDATED),
      returnId: stringJsonFormat,
      newStatus: returnStatusJsonFormat,
    },
    {},
  );
const returnCommentCreatedCustomerEventJsonFormat: JsonFormat<IReturnCommentCreatedCustomerEvent> =
  objectJsonFormat(
    {
      ...customerEventCommonFields,
      eventType: typedStringJsonFormat(
        CustomerEventType.RETURN_COMMENT_CREATED,
      ),
      returnId: stringJsonFormat,
      messageId: stringJsonFormat,
    },
    {},
  );
const claimCreatedCustomerEventJsonFormat: JsonFormat<IClaimCreatedCustomerEvent> =
  objectJsonFormat(
    {
      ...customerEventCommonFields,
      eventType: typedStringJsonFormat(CustomerEventType.CLAIM_CREATED),
      claimId: stringJsonFormat,
    },
    {},
  );
const claimStatusUpdatedCustomerEventJsonFormat: JsonFormat<IClaimStatusUpdatedCustomerEvent> =
  objectJsonFormat(
    {
      ...customerEventCommonFields,
      eventType: typedStringJsonFormat(CustomerEventType.CLAIM_STATUS_UPDATED),
      claimId: stringJsonFormat,
      newStatus: returnStatusJsonFormat,
    },
    {},
  );
const claimCommentCreatedCustomerEventJsonFormat: JsonFormat<IClaimCommentCreatedCustomerEvent> =
  objectJsonFormat(
    {
      ...customerEventCommonFields,
      eventType: typedStringJsonFormat(CustomerEventType.CLAIM_COMMENT_CREATED),
      claimId: stringJsonFormat,
      messageId: stringJsonFormat,
    },
    {},
  );
const conversationCreatedCustomerEventJsonFormat: JsonFormat<IConversationCreatedCustomerEvent> =
  objectJsonFormat(
    {
      ...customerEventCommonFields,
      eventType: typedStringJsonFormat(CustomerEventType.CONVERSATION_CREATED),
      conversationId: stringJsonFormat,
    },
    {},
  );
const conversationStatusUpdatedCustomerEventJsonFormat: JsonFormat<IConversationStatusUpdatedCustomerEvent> =
  objectJsonFormat(
    {
      ...customerEventCommonFields,
      eventType: typedStringJsonFormat(
        CustomerEventType.CONVERSATION_STATUS_UPDATED,
      ),
      conversationId: stringJsonFormat,
      newStatus: stringEnumJsonFormat(ConversationStatus),
    },
    {},
  );
const conversationMessageCreatedCustomerEventJsonFormat: JsonFormat<IConversationMessageCreatedCustomerEvent> =
  objectJsonFormat(
    {
      ...customerEventCommonFields,
      eventType: typedStringJsonFormat(
        CustomerEventType.CONVERSATION_MESSAGE_CREATED,
      ),
      conversationId: stringJsonFormat,
      messageId: stringJsonFormat,
    },
    {},
  );
const externalPlatformCustomerCreatedCustomerEventJsonFormat: JsonFormat<IExternalPlatformCustomerCreatedEvent> =
  objectJsonFormat(
    {
      ...customerEventCommonFields,
      eventType: typedStringJsonFormat(
        CustomerEventType.EXTERNAL_PLATFORM_CUSTOMER_CREATED,
      ),
      externalPlatform: stringEnumJsonFormat(ExternalCustomerPlatform),
      externalPlatformCustomerId: stringJsonFormat,
    },
    {},
  );
const storefrontLoginCustomerEventJsonFormat: JsonFormat<IStorefrontLoginEvent> =
  objectJsonFormat(
    {
      ...customerEventCommonFields,
      eventType: typedStringJsonFormat(CustomerEventType.STOREFRONT_LOGIN),
      multipassLogin: booleanJsonFormat,
    },
    {},
  );

export const customerEventJsonFormat: JsonFormat<ICustomerEvent> = {
  read(json: Json): ICustomerEvent {
    if (typeof json !== "object" || json instanceof Array || !json) {
      throw new JsonFormatError("Expected object");
    }
    if (!(CUSTOMER_EVENT_DISCRIMINATOR_KEY in json)) {
      throw new JsonFormatError(
        `Expected key ${CUSTOMER_EVENT_DISCRIMINATOR_KEY}`,
      );
    }

    switch (json[CUSTOMER_EVENT_DISCRIMINATOR_KEY]) {
      case CustomerEventType.ORDER_CREATED:
        return orderCreatedCustomerEventJsonFormat.read(json);
      case CustomerEventType.ORDER_UPDATED:
        return orderUpdatedCustomerEventJsonFormat.read(json);
      case CustomerEventType.ORDER_STATUS_UPDATED:
        return orderStatusUpdatedCustomerEventJsonFormat.read(json);
      case CustomerEventType.RETURN_CREATED:
        return returnCreatedCustomerEventJsonFormat.read(json);
      case CustomerEventType.RETURN_STATUS_UPDATED:
        return returnStatusUpdatedCustomerEventJsonFormat.read(json);
      case CustomerEventType.RETURN_COMMENT_CREATED:
        return returnCommentCreatedCustomerEventJsonFormat.read(json);
      case CustomerEventType.CLAIM_CREATED:
        return claimCreatedCustomerEventJsonFormat.read(json);
      case CustomerEventType.CLAIM_STATUS_UPDATED:
        return claimStatusUpdatedCustomerEventJsonFormat.read(json);
      case CustomerEventType.CLAIM_COMMENT_CREATED:
        return claimCommentCreatedCustomerEventJsonFormat.read(json);
      case CustomerEventType.CONVERSATION_CREATED:
        return conversationCreatedCustomerEventJsonFormat.read(json);
      case CustomerEventType.CONVERSATION_STATUS_UPDATED:
        return conversationStatusUpdatedCustomerEventJsonFormat.read(json);
      case CustomerEventType.CONVERSATION_MESSAGE_CREATED:
        return conversationMessageCreatedCustomerEventJsonFormat.read(json);
      case CustomerEventType.EXTERNAL_PLATFORM_CUSTOMER_CREATED:
        return externalPlatformCustomerCreatedCustomerEventJsonFormat.read(
          json,
        );
      case CustomerEventType.STOREFRONT_LOGIN:
        return storefrontLoginCustomerEventJsonFormat.read(json);
      default:
        throw new JsonFormatError(
          `Unknown event type ${json[CUSTOMER_EVENT_DISCRIMINATOR_KEY]}`,
        );
    }
  },
  write(value: ICustomerEvent): Json {
    if (isOrderCreatedCustomerEvent(value)) {
      return orderCreatedCustomerEventJsonFormat.write(value);
    } else if (isOrderUpdatedCustomerEvent(value)) {
      return orderUpdatedCustomerEventJsonFormat.write(value);
    } else if (isOrderStatusUpdatedCustomerEvent(value)) {
      return orderStatusUpdatedCustomerEventJsonFormat.write(value);
    } else if (isReturnCreatedCustomerEvent(value)) {
      return returnCreatedCustomerEventJsonFormat.write(value);
    } else if (isReturnStatusUpdatedCustomerEvent(value)) {
      return returnStatusUpdatedCustomerEventJsonFormat.write(value);
    } else if (isReturnCommentCreatedCustomerEvent(value)) {
      return returnCommentCreatedCustomerEventJsonFormat.write(value);
    } else if (isClaimCreatedCustomerEvent(value)) {
      return claimCreatedCustomerEventJsonFormat.write(value);
    } else if (isClaimStatusUpdatedCustomerEvent(value)) {
      return claimStatusUpdatedCustomerEventJsonFormat.write(value);
    } else if (isClaimCommentCreatedCustomerEvent(value)) {
      return claimCommentCreatedCustomerEventJsonFormat.write(value);
    } else if (isConversationCreatedCustomerEvent(value)) {
      return conversationCreatedCustomerEventJsonFormat.write(value);
    } else if (isConversationStatusUpdatedCustomerEvent(value)) {
      return conversationStatusUpdatedCustomerEventJsonFormat.write(value);
    } else if (isConversationMessageCreatedCustomerEvent(value)) {
      return conversationMessageCreatedCustomerEventJsonFormat.write(value);
    } else if (isExternalPlatformCustomerCreatedCustomerEvent(value)) {
      return externalPlatformCustomerCreatedCustomerEventJsonFormat.write(
        value,
      );
    } else if (isStorefrontLoginCustomerEvent(value)) {
      return storefrontLoginCustomerEventJsonFormat.write(value);
    } else {
      throw new JsonFormatError(
        `Unknown event type ${value[CUSTOMER_EVENT_DISCRIMINATOR_KEY]}`,
      );
    }
  },
};

export const arrayCustomerEventJsonFormat: JsonFormat<ICustomerEvent[]> =
  arrayJsonFormat(customerEventJsonFormat);
