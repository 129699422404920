import type { ObjectId } from "bson";

export interface SMSConsent {
  consented: boolean;
  phoneNumber: string;
}

export interface EmailConsent {
  consented: boolean;
  email: string;
}

export interface MessagingConsent {
  smsMarketingConsent: SMSConsent;
  emailMarketingConsent: EmailConsent;
  smsTransactionalConsent: SMSConsent;
  emailTransactionalConsent: EmailConsent;
}

export interface SMSTransactionalConsent {
  consented: boolean;
  phoneNumber: string;
  customerId: string;
  customerFirstName: string;
  customerLastName: string;
  team: ObjectId;
}

export const defaultMessagingConsent: MessagingConsent = {
  smsMarketingConsent: {
    consented: false,
    phoneNumber: "",
  },
  emailMarketingConsent: {
    consented: false,
    email: "",
  },
  // TODO: (Josh) this field should be a different type
  // but it's always been wrong and I don't know what to do about it rn
  smsTransactionalConsent: {
    consented: false,
    phoneNumber: "",
  },
  emailTransactionalConsent: {
    consented: false,
    email: "",
  },
};
