import { useRequiredContext } from "@redotech/react-util/context";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { RedoModal } from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { TextInput } from "@redotech/redo-web/text-input";
import { memo, useContext, useState } from "react";
import { RedoMerchantClientContext } from "../client/context";
import { updateConversation } from "../client/conversations";
import { ActiveConversationContext } from "./context/active-conversation-context";

export const ChangeSubjectModal = memo(function ChangeSubjectModal({
  resolve,
  conversation,
}: {
  resolve(): void;
  conversation: ExpandedConversation;
}) {
  const { setActiveConversation } = useContext(ActiveConversationContext);
  const client = useRequiredContext(RedoMerchantClientContext);

  async function submit(): Promise<void> {
    if (newSubject == conversation.subject) {
      resolve();
    } else {
      const result = await updateConversation(client, conversation, {
        subject: newSubject,
      });
      setActiveConversation(result.data);
      resolve();
    }
  }

  const [newSubject, setNewSubject] = useState(conversation.subject);
  const isSubjectValid = newSubject.length > 0;

  return (
    <RedoModal
      onModalCloseRequested={resolve}
      primaryButton={{
        text: "Save",
        onClick: submit,
        disabled: !isSubjectValid,
      }}
      secondaryButton={{ text: "Cancel", onClick: resolve }}
      title="Change email subject"
    >
      <TextInput onChange={setNewSubject} value={newSubject} />
    </RedoModal>
  );
});
