import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { Button, ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import {
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import * as conversationHeaderCss from "./conversation-header.module.css";
import { getCustomerTitle } from "./utils";

import { useRequiredContext } from "@redotech/react-util/context";
import { Permission, permitted } from "@redotech/redo-model/user";
import AlignLeft01Svg from "@redotech/redo-web/arbiter-icon/align-left-01_filled.svg";
import AlignRight01Svg from "@redotech/redo-web/arbiter-icon/align-right-01_filled.svg";
import XCloseSVG from "@redotech/redo-web/arbiter-icon/x-close.svg";
import { InputSize } from "@redotech/redo-web/input";
import { TextInput } from "@redotech/redo-web/text-input";
import { sinkPromise } from "@redotech/util/promise";
import * as classNames from "classnames";
import { UserContext } from "../app/user";
import { RedoMerchantClientContext } from "../client/context";
import { updateConversation } from "../client/conversations";

export const ConversationHeader = memo(function ConversationHeader({
  conversation,
  leftPanelOpen,
  setLeftPanelOpen,
  rightPanelOpen,
  setRightPanelOpen,
  actionButtons,
  setActiveConversation,
}: {
  conversation: ExpandedConversation;
  leftPanelOpen: boolean;
  setLeftPanelOpen: Dispatch<SetStateAction<boolean>>;
  rightPanelOpen: boolean;
  setRightPanelOpen: Dispatch<SetStateAction<boolean>>;
  actionButtons: ReactNode;
  setActiveConversation: (
    conversation: ExpandedConversation | undefined,
  ) => void;
}) {
  const handleBackClick = () => {
    setActiveConversation(undefined);
  };

  useEffect(() => {
    setConversationSubject(conversation.subject);
  }, [conversation]);

  const client = useRequiredContext(RedoMerchantClientContext);
  const user = useRequiredContext(UserContext);
  const canEditSubject =
    !!user && permitted(user.permissions, Permission.CREATE_REPLY);

  const [conversationSubject, setConversationSubject] = useState(
    conversation.subject,
  );

  async function handleSetSubject() {
    if (!conversationSubject) {
      setConversationSubject(conversation.subject);
    } else {
      await updateConversation(client, conversation, {
        subject: conversationSubject,
      });
    }
  }

  return (
    <Flex
      align="center"
      className={conversationHeaderCss.headerBar}
      gap="3xl"
      justify="space-between"
      pb="md"
      pl="2xl"
      pr="2xl"
      pt="md"
    >
      <Flex
        align="center"
        className={conversationHeaderCss.leftHeaderContent}
        gap="sm"
      >
        <Button
          className={conversationHeaderCss.headerButtons}
          onClick={() => setLeftPanelOpen((open) => !open)}
          size={ButtonSize.NANO}
          theme={ButtonTheme.OUTLINED}
        >
          <Flex
            align="center"
            className={conversationHeaderCss.buttonIconWrapper}
            justify="center"
          >
            {leftPanelOpen ? <AlignLeft01Svg /> : <AlignRight01Svg />}
          </Flex>
        </Button>

        <Button
          className={classNames(
            conversationHeaderCss.headerButtons,
            conversationHeaderCss.justIconButtons,
          )}
          onClick={handleBackClick}
          size={ButtonSize.NANO}
          theme={ButtonTheme.SOLID_LIGHT}
        >
          <Flex
            align="center"
            className={classNames(conversationHeaderCss.buttonIconWrapper)}
            justify="center"
          >
            <XCloseSVG />
          </Flex>
        </Button>
        <Text fontSize="sm" fontWeight="bold" textColor="primary">
          {getCustomerTitle(conversation)}
        </Text>

        {conversation.platform === "email" && (
          <>
            <Text
              color="var(--redo-colors-text-text-placeholder_subtle)"
              fontSize="sm"
              fontWeight="regular"
            >
              |
            </Text>
            <Flex className={conversationHeaderCss.subjectContainer}>
              <TextInput
                color="tertiary"
                ellipsis
                fullwidth
                hideFocus
                onChange={setConversationSubject}
                onFocus={(focused) => {
                  if (!focused) {
                    sinkPromise(handleSetSubject());
                  }
                }}
                readonly={!canEditSubject}
                showBorder={false}
                size={InputSize.SMALL}
                value={conversationSubject}
              />
            </Flex>
          </>
        )}
      </Flex>
      <Flex gap="md" justify="flex-end">
        {actionButtons}

        <Button
          className={conversationHeaderCss.headerButtons}
          onClick={() => {
            setRightPanelOpen((open) => !open);
          }}
          size={ButtonSize.NANO}
          theme={ButtonTheme.OUTLINED}
        >
          <Flex
            align="center"
            className={conversationHeaderCss.buttonIconWrapper}
            justify="center"
          >
            {rightPanelOpen ? <AlignRight01Svg /> : <AlignLeft01Svg />}
          </Flex>
        </Button>
      </Flex>
    </Flex>
  );
});
