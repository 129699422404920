import { TextField, TextFieldVariants } from "@mui/material";
import { ChangeEventHandler, memo } from "react";
import * as styledTextField from "./styled-text-field.module.css";

export type StyledTextFieldProps = {
  label?: string;
  variant?: TextFieldVariants | undefined;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  fullWidth?: boolean;
  value: string;
  InputProps?: any;
  padding?: string;
  fontFamily?: string;
  error?: string;
  required?: boolean;
};

export const StyledTextField = memo(function StyledTextField({
  label,
  variant = "outlined",
  onChange,
  fullWidth,
  value,
  InputProps,
  padding,
  fontFamily,
  error,
  required,
}: StyledTextFieldProps) {
  return (
    <div
      className={styledTextField.textField}
      style={fullWidth ? { width: "100%" } : {}}
    >
      <TextField
        error={!!error}
        fullWidth={fullWidth}
        helperText={error}
        InputLabelProps={{
          style: {
            fontFamily:
              fontFamily || 'Inter, "Helvetica Neue", Arial, sans-serif',
            fontSize: "14px",
          },
        }}
        inputProps={{
          style: {
            fontFamily:
              fontFamily || 'Inter, "Helvetica Neue", Arial, sans-serif',
            fontSize: "14px",
            padding,
          },
        }}
        InputProps={InputProps}
        label={label}
        onChange={onChange}
        required={required}
        value={value}
        variant={variant}
      />
    </div>
  );
});
