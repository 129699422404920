import * as classNames from "classnames";
import { useEffect, useState } from "react";
import { getDaysHoursMinutesSecondsFromMs } from "../../../utils/utils";
import * as expirationCountdownCss from "./expiration-countdown.module.css";

export const ExpirationCountdown = ({
  initialMilliseconds,
}: {
  initialMilliseconds: number;
}) => {
  const [milliseconds, setMilliseconds] = useState(initialMilliseconds);

  useEffect(() => {
    if (milliseconds <= 0) {
      return undefined;
    }

    const timerId = setTimeout(() => {
      setMilliseconds(milliseconds - 1000);
    }, 1000);

    return () => clearTimeout(timerId);
  }, [milliseconds]);

  const getExpirationText = (time) => {
    const durationString = getDaysHoursMinutesSecondsFromMs(time).fullFormat;
    return durationString;
  };

  return (
    <div>
      {`Expires in `}
      <span className={classNames(expirationCountdownCss.bold)}>
        {getExpirationText(milliseconds)}
      </span>
    </div>
  );
};
