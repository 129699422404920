export enum PillTheme {
  NORMAL = "NORMAL", // Partially transparent gray
  PRIMARY_LIGHT = "PRIMARY_LIGHT", // Pastel purple
  LIGHT_PINK = "LIGHT_PINK", // Pastel pink
  DANGER = "DANGER", // Pastel red,
  NEUTRAL_YELLOW = "NEUTRAL_YELLOW", // Pastel yellow
  SUCCESS = "SUCCESS", // Pastel green
  NEUTRAL_BLUE = "NEUTRAL_BLUE", // Pastel blue
  PRIMARY = "PRIMARY", // Solid purple
  SOLID_PINK = "SOLID_PINK", // Solid pink
  SOLID_RED = "SOLID_RED", // Solid red
  SOLID_ORANGE = "SOLID_ORANGE", // Solid orange
  SOLID_YELLOW = "SOLID_YELLOW", // Solid yellow
  SOLID_GREEN = "SOLID_GREEN", // Solid green
  SOLID_LIGHT_BLUE = "SOLID_LIGHT_BLUE", // Solid light blue
  SOLID_NAVY = "SOLID_NAVY", // Solid navy
  SOLID_BLACK = "SOLID_BLACK", // Solid black
}
