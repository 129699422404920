// extracted by mini-css-extract-plugin
export var aboveInput = "_57-0";
export var actionButton = "_57-4";
export var actionButtonContainer = "_57-1";
export var actionIcon = "_57-5";
export var aiInfoContainer = "_57-2";
export var automationPillContent = "_57-9";
export var automations = "_57-8";
export var automationsSection = "_57-7";
export var bold = "_57-3";
export var checkIcon = "_57-6";
export var clickingRecipientBar = "_57-16";
export var commentThreadSwitch = "_57-a";
export var draftAttachmentContainer = "_57-b";
export var draftImage = "_57-d";
export var draftImageContainer = "_57-e";
export var draftImages = "_57-g";
export var editButton = "_57-i";
export var editor = "_57-h";
export var editorContainer = "_57-13";
export var fileCarousel = "_57-j";
export var fileInput = "_57-k";
export var headerDivider = "_57-l";
export var icon = "_57-m";
export var info = "_57-n";
export var internal = "_57-r";
export var internalDivider = "_57-o";
export var internalMessage = "_57-p";
export var messageInputCard = "_57-q";
export var messageInputForm = "_57-s";
export var messageInputFormInternal = "_57-t";
export var options = "_57-u";
export var paperclip = "_57-v";
export var publicMessageCustomer = "_57-x";
export var publicMessageMerchant = "_57-w";
export var removeFileButton = "_57-c";
export var removeImageButton = "_57-f";
export var replyButtons = "_57-y";
export var showSignatureButton = "_57-z";
export var spinner = "_57-10";
export var visibilitySelector = "_57-11";
export var warning = "_57-12";
export var wrappingText = "_57-14";
export var writingInternalNoteTopBorder = "_57-15";