import { Order } from "@redotech/redo-model/order";
import {
  DiscountProductType,
  DiscountSettings,
  DiscountType,
  DiscountValueType,
} from "@redotech/redo-model/order-discount";

export const isExpiredDiscount = (days: number, order: Order) => {
  const duration = Temporal.Duration.from({ hours: days * 24 });
  const endDate = Temporal.Instant.from(order.createdAt).add(duration);
  if (
    endDate &&
    Temporal.Instant.compare(endDate, Temporal.Now.instant()) < 0
  ) {
    return true;
  }
  return false;
};

export const validDiscount = (
  discountObject: {
    enabled: boolean;
    settings: DiscountSettings;
  },
  order: Order,
) => {
  if (discountObject) {
    return (
      discountObject.enabled &&
      !isExpiredDiscount(discountObject.settings.expirationDays ?? 10, order)
    );
  }
  return false;
};

export const calculateDiscountPrice = (
  product: any,
  discountSettings: DiscountSettings,
  collectionsLoad,
): { price: number; discounted: boolean } => {
  if (collectionsLoad.value === undefined) {
    return { price: product.price, discounted: false };
  }

  if (discountSettings?.discountType === DiscountType.FREE_SHIPPING) {
    return product.price;
  } else if (discountSettings?.discountType === DiscountType.ORDER) {
    return determineDiscountAmount(discountSettings, product);
  } else if (discountSettings?.discountType === DiscountType.PRODUCT) {
    if (
      discountSettings.discountProductType === DiscountProductType.COLLECTION
    ) {
      const inCollection = collectionsLoad.value?.some((collection) => {
        if (!collection) {
          return false;
        }
        return collection.products.nodes.some(
          (collectionProduct) => collectionProduct.id === product.id,
        );
      });

      return inCollection
        ? determineDiscountAmount(discountSettings, product)
        : { price: product.price, discounted: false };
    } else if (
      discountSettings.discountProductType === DiscountProductType.PRODUCT
    ) {
      return determineDiscountAmount(discountSettings, product);
    }
  }
  return { price: product.price, discounted: false };
};

const determineDiscountAmount = (discountSettings, product) => {
  if (discountSettings?.discountValueType === DiscountValueType.PERCENTAGE) {
    return {
      price:
        product.price -
        product.price * discountSettings?.discountPercentageValue,
      discounted: true,
    };
  } else if (discountSettings?.discountValueType === DiscountValueType.AMOUNT) {
    return {
      price: product.price - discountSettings?.discountAmountValue,
      discounted: true,
    };
  }
  return undefined;
};
