import { getResource } from "@redotech/redo-model/localization/resource";
import { ReturnWarning } from "@redotech/redo-model/return";
import { pickupLocationOptions } from "@redotech/redo-model/return-flow";
import { Card } from "@redotech/redo-web/card";
import { Flex } from "@redotech/redo-web/flex";
import ProgressCompleteCircle from "@redotech/redo-web/icon-old/progress-complete-circle.svg";
import StepCircle from "@redotech/redo-web/icon-old/step-circle.svg";
import { dateToCurrentPlainDate } from "@redotech/util/temporal";
import * as classNames from "classnames";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { Step, StepType } from ".";
import { LabelButtons } from "./label-buttons";
import { PickupButtons } from "./pickup-buttons";
import { ReturnInStoreLocationsButton } from "./return-in-store-locations-button";
import { ReturnLocationsButton } from "./return-locations-button";
import * as returnStatus from "./return-status.module.css";
import { TrackRepairButton } from "./track-repair-button";

export const ReturnStepsCard = memo(function ReturnStepsCard({
  returnData,
  settings,
  cardless = false,
}: {
  returnData: any;
  settings: any;
  cardless?: boolean;
}) {
  const ref = useRef(null);
  const [lastStepHeight, setLastStepHeight] = useState(0);

  const hasPickup =
    returnData.pickup && returnData.pickup.status !== "pending_refund";
  const [pickup, setPickup] = useState(undefined);

  const isRepair = returnData.products.some(
    (product: any) => product.strategy === "repair",
  );

  const copyResource = getResource({
    variant: hasPickup ? "pickup" : returnData.type,
    overrides: settings.resourceOverride,
    // This is an example of how we could get the language region based on the country code
    // language: getLanguageRegion({
    //   countryCode: returnData.shipping_address?.country_code,
    // }),
  });

  let carrier = returnData.shipment?._shipment.tracker?.carrier;
  const country_code = returnData.shipping_address?.country_code;
  if (carrier === "USPSReturns") {
    carrier = "USPS";
  }
  if (carrier === "Sendle") {
    // If in the US, we should show it as USPS
    if (country_code === "US") {
      carrier = "USPS";
    }
  }

  const steps: Step[] = useMemo(() => {
    const steps: Step[] = [];
    if (returnData.markedForManualReview) {
      steps.push({
        type: StepType.MANUAL_REVIEW,
        title: copyResource.STATUS_MANUAL_REVIEW_TITLE,
        subtitle: copyResource.STATUS_MANUAL_REVIEW_SUBTITLE,
        complete: false,
      });
    }

    if (returnData.draftOrderURL) {
      let subtitle = "";
      if (settings.exchanges.createLabelOnDraftOrderComplete) {
        subtitle = "You'll receive your label after you complete your order";
      }
      steps.push({
        type: StepType.EXCHANGE_ORDER,
        title: "Pay for exchange order",
        subtitle,
        complete: false,
      });
    }

    // If there are no label warnings
    if (
      !returnData.warnings?.some(
        (warning: ReturnWarning) => warning.type === "label",
      )
    ) {
      if (returnData.inStoreReturn) {
        steps.push({
          type: StepType.IN_TRANSIT,
          title: "Return in-store",
          subtitle: "You can bring your items to the store",
          complete: false,
          buttons: <ReturnInStoreLocationsButton />,
        });
        steps.push({
          type: StepType.PROCESSED,
          title: "We process the return",
          subtitle: "",
          complete: false,
        });
      } else if (returnData.products.some((product) => !product.green_return)) {
        const commercialInvoice = returnData.shipment?._shipment?.forms?.find(
          (form: any) => form.form_type === "commercial_invoice",
        )?.form_url;
        const singleShipment = !(returnData.shipments.length > 1);
        steps.push({
          type: StepType.IN_TRANSIT,
          title: copyResource.STATUS_IN_TRANSIT_TITLE,
          subtitle: singleShipment
            ? copyResource.STATUS_IN_TRANSIT_SUBTITLE +
              (!returnData.pickup
                ? " " + copyResource.STATUS_IN_TRANSIT_QR_CODE
                : "")
            : copyResource.STATUS_IN_TRANSIT_MULTI_SHIPMENT_SUBTITLE,
          buttons: !!returnData.shipment && singleShipment && !hasPickup && (
            <LabelButtons
              carrier={carrier}
              commercialInvoice={commercialInvoice}
              returnData={returnData}
            />
          ),
          complete: false,
        });
        if (hasPickup) {
          steps.push({
            type: StepType.IN_TRANSIT,
            title: "Print your shipping label",
            subtitle: "Click the button to print your shipping label",
            buttons: !!returnData.shipment && singleShipment && (
              <LabelButtons
                carrier={carrier}
                commercialInvoice={commercialInvoice}
                returnData={returnData}
              />
            ),
            complete: false,
          });
          steps.push({
            type: StepType.IN_TRANSIT,
            title: "Attach shipping label",
            subtitle:
              "Place label on outside of the package and make sure barcode is visible",
            complete: false,
          });
          steps.push({
            type: StepType.IN_TRANSIT,
            title: `Place the package at the ${
              pickupLocationOptions.get(pickup?.pickupLocation?.packageLocation)
                ?.stepLabel || ""
            } on ${
              pickup?.pickupDate
                ? dateToCurrentPlainDate(
                    new Date(pickup.pickupDate),
                  ).toLocaleString()
                : "the date you schedule with support"
            }`,
            subtitle: `Your pickup address is:\n\n ${returnData.shipping_address?.name}\n ${returnData.shipping_address?.address1}\n ${returnData.shipping_address?.address2 !== "" ? returnData.shipping_address?.address2 + "\n" : ""}${returnData.shipping_address?.city}, ${returnData.shipping_address?.province} ${returnData.shipping_address?.zip}\n ${returnData.shipping_address?.country}`,
            buttons: (
              <PickupButtons returnData={returnData} setPickup={setPickup} />
            ),
            complete: false,
          });
        } else {
          steps.push({
            type: StepType.IN_TRANSIT,
            title: `Drop off ${carrier ? "at " + carrier : "package"}`,
            subtitle: "",
            complete: false,
            buttons: <ReturnLocationsButton returnData={returnData} />,
          });
        }
        steps.push({
          type: StepType.PROCESSED,
          title: copyResource.STATUS_PROCESSED_TITLE,
          subtitle: copyResource.STATUS_PROCESSED_SUBTITLE,
          complete: false,
        });
        if (isRepair) {
          steps.push({
            type: StepType.PROCESSED,
            title: "We process the repair",
            subtitle:
              "If the items is not repairable, we will replace or refund the item",
            complete: false,
          });
          steps.push({
            type: StepType.PROCESSED,
            title: "We ship the repaired item",
            subtitle: "",
            complete: false,
            buttons: <TrackRepairButton returnData={returnData} />,
          });
        }
      } else {
        steps.push(
          {
            type: StepType.GREEN_RETURN,
            title:
              settings.theme.green_return_confirmation_text ||
              "Keep your items",
            subtitle:
              settings.theme.green_return_confirmation_description ||
              "You do not need to send your items back.",
            complete: false,
          },
          {
            type: StepType.PROCESSED,
            title: "We process the claim",
            subtitle: "",
            complete: false,
          },
        );
      }
    } else {
      // If there are label warnings, we need to let them know that we are working on it
      steps.push({
        type: StepType.WARNING,
        title: "We're working on it",
        subtitle: "We will resolve any issues and email you with our solution",
        complete: false,
      });
    }

    checkStepsCompletion(returnData, steps);
    return steps;
  }, [pickup]);

  useEffect(() => {
    if (returnData.pickup) {
      setPickup({
        ...returnData.shipment?.pickup,
        pickupLocation: returnData.pickup?.pickupLocation,
      });
    }
  }, []);

  useEffect(() => {
    setLastStepHeight(ref.current.clientHeight);
  });

  return (
    <>
      {!cardless ? (
        <Card
          className={classNames(returnStatus.card, returnStatus.cardWhite)}
          title={copyResource.STATUS_STEPS_TITLE}
        >
          <div className={returnStatus.stepsContainer}>
            <div
              className={returnStatus.stepsLine}
              style={{ height: `calc(100% - ${lastStepHeight}px)` }}
            />
            <div className={returnStatus.steps}>
              {steps.map((step, i) => (
                <div className={returnStatus.step} key={i} ref={ref}>
                  <div className={returnStatus.stepText}>
                    <div className={returnStatus.stepCircle}>
                      {step.complete ? (
                        <ProgressCompleteCircle />
                      ) : (
                        <StepCircle />
                      )}
                    </div>
                    <div>
                      <div className={returnStatus.stepPrimaryText}>
                        {step.title}
                      </div>
                      <div className={returnStatus.stepSubtext}>
                        {step.subtitle}
                      </div>
                    </div>
                  </div>
                  {!!step.buttons && step.buttons}
                </div>
              ))}
            </div>
          </div>
        </Card>
      ) : (
        <div className={returnStatus.steps}>
          {steps.map((step, i) => (
            <div className={returnStatus.step} key={i} ref={ref}>
              <div className={returnStatus.stepText}>
                <div className={returnStatus.stepCircle}>
                  {step.complete ? <ProgressCompleteCircle /> : <StepCircle />}
                </div>
                <Flex dir="column" fontSize="sm" gap="xs">
                  <div>{step.title}</div>
                  <div className={returnStatus.stepSubtext}>
                    {step.subtitle}
                  </div>
                </Flex>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
});

const checkStepsCompletion = (returnData, steps: Step[]) => {
  // Go through steps array backwards. If the step is complete,
  // mark the rest of the steps as complete.
  let isComplete = false;
  for (let i = steps.length - 1; i >= 0; i--) {
    if (isComplete) {
      steps[i].complete = true;
      continue;
    }
    switch (steps[i].type) {
      case StepType.PROCESSED:
        steps[i].complete = returnData.status === "complete";
        break;
      case StepType.DELIVERED:
        steps[i].complete = ["complete", "delivered"].includes(
          returnData.status,
        );
        break;
      case StepType.IN_TRANSIT:
        steps[i].complete = ["complete", "delivered", "in_transit"].includes(
          returnData.status,
        );
        break;
      case StepType.EXCHANGE_ORDER:
        steps[i].complete = returnData.exchangeOrder.length;
        break;
      case StepType.MANUAL_REVIEW:
      case StepType.GREEN_RETURN:
        steps[i].complete = returnData.status !== "needs_review";
        break;
      case StepType.NONE:
        continue;
    }
    isComplete = steps[i].complete;
  }
};
