// extracted by mini-css-extract-plugin
export var alignBaseline = "_2e-8";
export var alignCenter = "_2e-4";
export var alignFlexEnd = "_2e-6";
export var alignFlexStart = "_2e-5";
export var alignSelfBaseline = "_2e-d";
export var alignSelfCenter = "_2e-9";
export var alignSelfFlexEnd = "_2e-b";
export var alignSelfFlexStart = "_2e-a";
export var alignSelfStretch = "_2e-c";
export var alignStretch = "_2e-7";
export var column = "_2e-3";
export var flex = "_2e-0";
export var justifyCenter = "_2e-e";
export var justifyFlexEnd = "_2e-g";
export var justifyFlexStart = "_2e-f";
export var justifySpaceAround = "_2e-i";
export var justifySpaceBetween = "_2e-h";
export var justifySpaceEvenly = "_2e-j";
export var reverse = "_2e-2";
export var row = "_2e-1";
export var wrapNowrap = "_2e-l";
export var wrapWrap = "_2e-k";
export var wrapWrapReverse = "_2e-m";