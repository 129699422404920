import { ConversationPlatform } from "@redotech/redo-model/conversation";
import { PillTheme } from "@redotech/redo-model/pill-theme";
import AttentiveLogoIcon from "@redotech/redo-web/icon-old/attentive-logo.svg";
import ChatBubbleIcon from "@redotech/redo-web/icon-old/chat-bubble.svg";
import FacebookIcon from "@redotech/redo-web/icon-old/facebook-color.svg";
import InstagramIcon from "@redotech/redo-web/icon-old/instagram-color.svg";
import LogoIcon from "@redotech/redo-web/icon-old/logo-icon.svg";
import EnvelopeIcon from "@redotech/redo-web/icon-old/mail.svg";
import { Pill, PillSize } from "@redotech/redo-web/pill";
import { ReactNode, memo } from "react";
import * as conversationPlatformPillCss from "./conversation-platform-pill.module.css";

const platformToTheme: Record<ConversationPlatform, PillTheme> = {
  redoChat: PillTheme.PRIMARY_LIGHT,
  instagram: PillTheme.LIGHT_PINK,
  instagramComments: PillTheme.LIGHT_PINK,
  facebook: PillTheme.NEUTRAL_BLUE,
  facebookComments: PillTheme.NEUTRAL_BLUE,
  email: PillTheme.NORMAL,
  sms: PillTheme.SUCCESS,
  attentive: PillTheme.NEUTRAL_YELLOW,
};

const platformToText: Record<ConversationPlatform, string> = {
  redoChat: "Redo Chat",
  instagram: "DM",
  instagramComments: "Comments",
  facebook: "Messenger",
  facebookComments: "Comments",
  email: "Email",
  sms: "SMS",
  attentive: "Attentive",
};

const platformToIcon: Record<ConversationPlatform, ReactNode> = {
  redoChat: <LogoIcon className={conversationPlatformPillCss.icon} />,
  instagram: <InstagramIcon className={conversationPlatformPillCss.icon} />,
  instagramComments: (
    <InstagramIcon className={conversationPlatformPillCss.icon} />
  ),
  facebook: <FacebookIcon className={conversationPlatformPillCss.icon} />,
  facebookComments: (
    <FacebookIcon className={conversationPlatformPillCss.icon} />
  ),
  email: <EnvelopeIcon className={conversationPlatformPillCss.icon} />,
  sms: <ChatBubbleIcon className={conversationPlatformPillCss.icon} />,
  attentive: <AttentiveLogoIcon className={conversationPlatformPillCss.icon} />,
};

export const ConversationPlatformPill = memo(function ConversationPlatformPill({
  platform,
  size,
}: {
  platform: ConversationPlatform;
  size?: PillSize;
}) {
  return (
    <div className={conversationPlatformPillCss.pillContainer}>
      <Pill size={size} theme={platformToTheme[platform]}>
        <div className={conversationPlatformPillCss.pillContent}>
          {platformToIcon[platform]}
          {platform === ConversationPlatform.ATTENTIVE ? (
            <div className={conversationPlatformPillCss.attentiveText}>
              {platformToText[platform]}
            </div>
          ) : (
            platformToText[platform]
          )}
        </div>
      </Pill>
    </div>
  );
});
