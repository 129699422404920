import { useBreadcrumbs } from "@redotech/redo-web/breadcrumb";
import { memo, useContext, useEffect } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { useDocumentTitle } from "usehooks-ts";
import { AuthContext } from "./app/auth";
import { TeamContext } from "./app/team";
import { UserContext } from "./app/user";
import { FailedAlert } from "./failed-alert";
import { Onboarding } from "./onboarding";
import { OrderSyncProgress } from "./order-sync-progress";

export const RouterRoot = memo(function RouterRoot() {
  const intercom = useIntercom();
  const navigate = useNavigate();
  const { teamId } = useParams();
  const url = useLocation();
  const user = useContext(UserContext);
  const team = useContext(TeamContext);

  const auth = useContext(AuthContext);
  if (auth && auth.teamId && !teamId) {
    const prefix = url.pathname.startsWith("/")
      ? `/stores/${auth.teamId}`
      : `/stores/${auth.teamId}/`;
    navigate(prefix + url.pathname, { replace: true });
  }
  const breadcrumbs = useBreadcrumbs();
  useDocumentTitle(
    breadcrumbs
      ? `${breadcrumbs.map((breadcrumb) => breadcrumb.name).join(" ")} — Redo`
      : "Redo",
  );

  useEffect(() => {
    // TODO(paul): more robust way to exclude Redo users
    if (!user || !team || user.email.endsWith("@getredo.com")) {
      return;
    }
    intercom.update({
      company: {
        companyId: team._id,
        name: team.name,
        createdAt: team.createdAt,
        website: `https://${team.storeUrl}/`,
      },
      createdAt: user.createdAt,
      email: user.email,
      name: user.name,
      userId: user._id,
    });
  }, [user, team]);

  return (
    <>
      <Onboarding />
      <OrderSyncProgress />
      <FailedAlert />
      <Outlet />
    </>
  );
});
