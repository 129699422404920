import { useTriggerLoad } from "@redotech/react-util/load";
import { Flex } from "@redotech/redo-web/flex";
import CloseIcon from "@redotech/redo-web/icon-old/modal-close-button.svg";
import { Text } from "@redotech/redo-web/text";
import { isString } from "@redotech/util/type";
import * as classNames from "classnames";
import { JSXElementConstructor, memo, ReactNode } from "react";
import { Button, ButtonTheme, IconButton } from "../../button";
import { DisabledTooltip } from "../../tooltip/disabled-tooltip";
import { RedoBaseModal } from "./redo-base-modal";
import * as redoModalCss from "./redo-modal.module.css";

export enum RedoModalSize {
  SMALL = "Small",
  MEDIUM = "Medium",
  LARGE = "Large",
}

export enum RedoModalTheme {
  ERROR = "Error",
  WARN = "Warn",
  SUCCESS = "Success",
  INFO = "Info",
  NONE = "None",
}

export enum RedoModalHeaderIconAlignment {
  ABOVE = "Above",
  INLINE = "Inline",
}

export enum ButtonPlacement {
  FULL = "Full",
  RIGHT = "Right",
}

export interface RedoModalButtonProps {
  text: string;
  onClick(): void | Promise<void>;
  disabled?: boolean | string;
}

/**
 * @param onModalCloseRequested - Called when x clicked, the user presses escape, or clicks away
 *
 * @param footerOverride -- Will totally replace the footer with whatever you want.
 * If you are planning on adding a bunch of props to make the footer do what you want,
 * consider using this or just <RedoBaseModal> instead
 */
export interface RedoModalProps {
  title: string;
  subtitle?: string | ReactNode;
  primaryButton: RedoModalButtonProps;
  secondaryButton?: RedoModalButtonProps;
  children?: ReactNode | undefined;
  onModalCloseRequested: () => void;
  TitleIcon?: JSXElementConstructor<unknown>;
  headerIconAlignment?: RedoModalHeaderIconAlignment;
  headerBorder?: boolean;
  showHeader?: boolean;
  theme?: RedoModalTheme;
  hideCloseButton?: boolean;
  modalSize?: RedoModalSize;
  footerOverride?: ReactNode | null;
  footerBorder?: boolean;
  centerContent?: boolean;
  isOpen?: boolean;
  buttonPlacement?: ButtonPlacement;
}

export const RedoModal = memo(function RedoModal({
  title,
  subtitle,
  primaryButton,
  secondaryButton,
  children,
  onModalCloseRequested,
  TitleIcon,
  headerIconAlignment = RedoModalHeaderIconAlignment.ABOVE,
  headerBorder = false,
  showHeader = true,
  theme = RedoModalTheme.NONE,
  hideCloseButton = false,
  modalSize = RedoModalSize.MEDIUM,
  footerOverride,
  footerBorder = false,
  centerContent = false,
  isOpen = true,
  buttonPlacement = ButtonPlacement.FULL,
}: RedoModalProps) {
  return (
    <RedoBaseModal
      isOpen={isOpen}
      onModalCloseRequested={onModalCloseRequested}
      otherClasses={[
        modalSizeClasses[modalSize],
        centerContent && redoModalCss.centered,
      ]}
    >
      <>
        {showHeader && (
          <RedoModalHeader
            cancelClicked={onModalCloseRequested}
            centerContent={centerContent}
            headerBorder={headerBorder}
            headerIconAlignment={headerIconAlignment}
            hideCloseButton={hideCloseButton}
            subtitle={subtitle}
            theme={theme}
            title={title}
            TitleIcon={TitleIcon}
          />
        )}
        {children && (
          <section className={classNames(redoModalCss.main)}>
            {children}
          </section>
        )}
        {footerOverride === undefined ? (
          <RedoModalFooter
            buttonPlacement={buttonPlacement}
            footerBorder={footerBorder}
            primaryButton={primaryButton}
            secondaryButton={secondaryButton}
            theme={theme}
          />
        ) : (
          footerOverride
        )}
      </>
    </RedoBaseModal>
  );
});

export const RedoModalHeader = memo(function RedoModalHeader({
  TitleIcon = undefined,
  theme = RedoModalTheme.NONE,
  title,
  subtitle,
  hideCloseButton = false,
  centerContent = false,
  headerIconAlignment = RedoModalHeaderIconAlignment.ABOVE,
  headerBorder = false,
  cancelClicked,
}: {
  TitleIcon?: JSXElementConstructor<unknown> | undefined;
  theme?: RedoModalTheme;
  title: string;
  subtitle?: string | ReactNode;
  headerIconAlignment?: RedoModalHeaderIconAlignment;
  hideCloseButton?: boolean;
  headerBorder?: boolean;
  centerContent?: boolean;
  cancelClicked(): void;
}) {
  return (
    <header
      className={classNames(
        headerBorder && redoModalCss.border,
        centerContent && redoModalCss.center,
        redoModalCss.header,
        headerIconAlignmentClasses[headerIconAlignment],
      )}
    >
      {TitleIcon && (
        <div
          className={classNames(
            headerIconAlignmentClasses[headerIconAlignment],
            redoModalCss.titleIcon,
          )}
        >
          <span
            className={classNames(redoModalCss.icon, iconThemeClasses[theme])}
          >
            <TitleIcon />
          </span>
        </div>
      )}
      <Flex dir="column" gap="xs">
        <Text fontSize="lg" fontWeight="semibold">
          {title}
        </Text>
        {subtitle && (
          <Text fontSize="sm" textColor="tertiary">
            {subtitle}
          </Text>
        )}
      </Flex>

      {!hideCloseButton && (
        <div className={redoModalCss.closeButton}>
          <IconButton onClick={cancelClicked}>
            <CloseIcon />
          </IconButton>
        </div>
      )}
    </header>
  );
});

const RedoModalFooter = memo(function RedoModalFooter({
  primaryButton,
  secondaryButton,
  theme = RedoModalTheme.NONE,
  footerBorder,
  buttonPlacement,
}: {
  primaryButton: RedoModalButtonProps;
  secondaryButton?: RedoModalButtonProps;
  theme?: RedoModalTheme;
  footerBorder?: boolean;
  buttonPlacement: ButtonPlacement;
}) {
  const [isSubmitting, doSubmit] = useTriggerLoad(async () => {
    await primaryButton.onClick();
  });

  const submitButton = (
    <DisabledTooltip
      disabledMessage={
        isString(primaryButton.disabled) && primaryButton.disabled
      }
    >
      <Button
        className={classNames(
          redoModalCss.button,
          !!primaryButton.disabled && redoModalCss.disabled,
        )}
        disabled={!!primaryButton.disabled}
        // fullWidth
        onClick={() => doSubmit()}
        pending={isSubmitting.pending}
        theme={redoModalThemeToButtonTheme(theme)}
      >
        {primaryButton.text}
      </Button>
    </DisabledTooltip>
  );
  const cancelButton = secondaryButton && (
    <DisabledTooltip
      disabledMessage={
        isString(secondaryButton.disabled) && secondaryButton.disabled
      }
    >
      <Button
        className={classNames(
          redoModalCss.button,
          !!secondaryButton.disabled && redoModalCss.disabled,
        )}
        disabled={isSubmitting.pending || !!secondaryButton.disabled}
        onClick={secondaryButton.onClick}
        theme={ButtonTheme.OUTLINED}
      >
        {secondaryButton.text}
      </Button>
    </DisabledTooltip>
  );

  return (
    <footer
      className={classNames(
        redoModalCss.footer,
        footerBorder && redoModalCss.border,
        buttonPlacementClasses[buttonPlacement],
      )}
    >
      <div
        className={classNames(
          redoModalCss.footerButtons,
          buttonPlacementClasses[buttonPlacement],
        )}
      >
        {cancelButton}
        {submitButton}
      </div>
    </footer>
  );
});

function redoModalThemeToButtonTheme(theme: RedoModalTheme): ButtonTheme {
  switch (theme) {
    case RedoModalTheme.ERROR:
      return ButtonTheme.DANGER;
    default:
      return ButtonTheme.PRIMARY;
  }
}

const modalSizeClasses: Record<RedoModalSize, string> = {
  [RedoModalSize.SMALL]: redoModalCss.small,
  [RedoModalSize.MEDIUM]: "",
  [RedoModalSize.LARGE]: redoModalCss.large,
};

const iconThemeClasses: Record<RedoModalTheme, string> = {
  [RedoModalTheme.ERROR]: redoModalCss.error,
  [RedoModalTheme.WARN]: redoModalCss.warn,
  [RedoModalTheme.SUCCESS]: redoModalCss.success,
  [RedoModalTheme.INFO]: redoModalCss.info,
  [RedoModalTheme.NONE]: redoModalCss.none,
};

const headerIconAlignmentClasses = {
  [RedoModalHeaderIconAlignment.ABOVE]: undefined,
  [RedoModalHeaderIconAlignment.INLINE]: redoModalCss.inline,
};

const buttonPlacementClasses: Record<ButtonPlacement, string> = {
  [ButtonPlacement.FULL]: redoModalCss.fill,
  [ButtonPlacement.RIGHT]: redoModalCss.right,
};
