import {
  Button,
  ButtonBorder,
  ButtonSize,
  ButtonTheme,
} from "@redotech/redo-web/button";
import { Flex } from "@redotech/redo-web/flex";
import Printer from "@redotech/redo-web/icon-old/printer-small.svg";
import QrCode from "@redotech/redo-web/icon-old/qr-code.svg";
import { memo } from "react";
import { openUrl } from ".";
import { ReturnItemsCard } from "./return-items-card";

import * as returnStatus from "./return-status.module.css";
// todo(michael): Make status page work for one product multiple labels
export const ReturnItems = memo(function ReturnItems({
  returnData,
}: {
  returnData: any;
}) {
  const carrier = returnData.shipment?._shipment.tracker?.carrier;
  if (returnData.shipmentGroups?.length) {
    return returnData.shipmentGroups.map((group: any, index: number) => {
      const products = returnData.products.filter(
        (product: any) => product.shipmentGroupID === group.id,
      );

      const downloadButtons = [];
      const shipment = returnData.shipments?.find(
        (shipment: any) => shipment.shipmentGroupID === group.id,
      );
      if (shipment?.postage_label && !shipment?.pickup && carrier != "UPS") {
        downloadButtons.push(
          <Button
            border={ButtonBorder.LIGHT}
            key="postage_label"
            onClick={() => openUrl(shipment.form_label)}
            size={ButtonSize.MICRO}
            theme={ButtonTheme.OUTLINED}
          >
            <QrCode className={returnStatus.buttonIcon} /> Open QR Code
          </Button>,
        );
      }
      if (shipment?.form_label) {
        downloadButtons.push(
          <Button
            border={ButtonBorder.LIGHT}
            key="form_label"
            onClick={() => openUrl(shipment.postage_label)}
            size={ButtonSize.MICRO}
            theme={ButtonTheme.OUTLINED}
          >
            <Printer className={returnStatus.buttonIcon} /> Print label
          </Button>,
        );
      }
      const commercialInvoice = shipment?._shipment?.forms?.find(
        (form: any) => form?.form_type === "commercial_invoice",
      )?.form_url;
      if (commercialInvoice) {
        downloadButtons.push(
          <Button
            border={ButtonBorder.LIGHT}
            key="commercial_invoice"
            onClick={() => openUrl(commercialInvoice)}
            size={ButtonSize.MICRO}
            theme={ButtonTheme.OUTLINED}
          >
            <Printer className={returnStatus.buttonIcon} /> Print commercial
            invoice
          </Button>,
        );
      }

      return (
        <ReturnItemsCard
          headerExtra={
            returnData.shipmentGroups.length > 1 && downloadButtons.length ? (
              <Flex className={returnStatus.downloadButtonContainer}>
                {downloadButtons}
              </Flex>
            ) : null
          }
          key={index}
          products={products}
          returnData={returnData}
          subtitle={
            returnData.shipmentGroups.length > 1
              ? `Shipment ${index + 1} of ${returnData.shipmentGroups.length}`
              : null
          }
        />
      );
    });
  }

  return (
    <ReturnItemsCard products={returnData.products} returnData={returnData} />
  );
});
