import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { closeTicket } from "@redotech/redo-merchant-app/support/utils";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { Team } from "@redotech/redo-model/team";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { memo, useEffect, useState } from "react";
import { TeamContext } from "../app/team";
import { RedoMerchantClient } from "../client";
import { RedoMerchantClientContext } from "../client/context";
import {
  bulkAddTags,
  bulkUpdateConversationsStatus,
  updateConversation,
} from "../client/conversations";
import { updateCurrentTeam } from "../client/team";
import * as markSpamModalCss from "./mark-spam-modal.module.css";

export const handleMarkTicketsSpam = async ({
  client,
  team,
  conversations,
  setActiveConversation,
  inDetailView,
  cardListRef,
  cleanupFn,
}: {
  client: RedoMerchantClient;
  team: Team;
  conversations: ExpandedConversation[];
  setActiveConversation: (val: ExpandedConversation | undefined) => void;
  inDetailView: boolean;
  cardListRef?: any;
  cleanupFn?: (clearSelected?: boolean) => void;
}) => {
  if (conversations.length === 1) {
    // Add spam tag
    await updateConversation(client, conversations[0], {
      tags: [...(conversations[0].tagIds || []), { name: "spam" }],
    });

    // Close ticket
    if (conversations[0].status !== "closed") {
      closeTicket(
        client,
        conversations[0],
        setActiveConversation,
        inDetailView,
        cardListRef?.current.getNext(
          conversations[0],
          (
            conversation1: ExpandedConversation,
            conversation2: ExpandedConversation,
          ) => conversation1._id === conversation2._id,
        ) ||
          cardListRef?.current.getPrevious(
            conversations[0],
            (
              conversation1: ExpandedConversation,
              conversation2: ExpandedConversation,
            ) => conversation1._id === conversation2._id,
          ),
      );
      cardListRef?.current.removeItem(
        conversations[0],
        (
          conversation1: ExpandedConversation,
          conversation2: ExpandedConversation,
        ) => conversation1._id === conversation2._id,
      );
    }

    // Add email to exclusion list
    const addressToExclude = conversations[0].customer.email;
    if (
      team.settings.support &&
      !team.settings.support?.excludedEmails?.includes(addressToExclude)
    ) {
      team.settings.support.excludedEmails = [
        ...(team.settings.support?.excludedEmails || []),
        addressToExclude,
      ];
    }
    await updateCurrentTeam(client, { team });
    amplitude.logEvent("markSpam-conversation", {
      mode: "single",
      conversationIds: [conversations[0]._id],
    });
  } else if (conversations.length > 1) {
    const conversationIdsToClose = conversations
      .filter((conversation) => conversation.status !== "closed")
      .map((conversation) => conversation._id);
    if (conversationIdsToClose.length > 0) {
      await bulkUpdateConversationsStatus(client, {
        conversationIds: conversationIdsToClose,
        status: "closed",
      });
    }

    const conversationIdsToTagSpam = conversations
      .filter((conversation) =>
        (conversation.tagIds || []).every((tag) => tag.name !== "spam"),
      )
      .map((conversation) => conversation._id);
    await bulkAddTags(client, {
      conversationIds: conversationIdsToTagSpam,
      tags: [{ name: "spam" }],
    });

    const emailsToExclude = conversations.map(
      (conversation) => conversation.customer.email,
    );

    if (team.settings.support) {
      team.settings.support.excludedEmails = [
        ...(team.settings.support.excludedEmails ?? []),
        ...emailsToExclude,
      ];
      await updateCurrentTeam(client, { team });
      amplitude.logEvent("markSpam-conversation", {
        mode: "multiple",
        conversationIds: conversations.map((conversation) => conversation._id),
      });
    }
  }
  cleanupFn && cleanupFn(true);
};

export const MarkSpamModal = memo(function MarkSpamModal({
  open,
  setOpen,
  conversations,
  setActiveConversation,
  inDetailView,
  cardListRef,
  cleanupFn,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  conversations: ExpandedConversation[];
  setActiveConversation: (val: ExpandedConversation | undefined) => void;
  inDetailView: boolean;
  cardListRef?: any;
  cleanupFn?: (clearSelected?: boolean) => void;
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const team = useRequiredContext(TeamContext);
  const [markableConversations, setMarkableConversations] = useState<
    ExpandedConversation[]
  >([]);

  useEffect(() => {
    setMarkableConversations(
      conversations.filter(
        (conversation) =>
          conversation.status !== "closed" ||
          !conversation.tagIds?.some((tag) => tag.name === "spam") ||
          !team.settings.support?.excludedEmails?.includes(
            conversation.customer.email,
          ),
      ),
    );
  }, [conversations]);

  const plural = markableConversations.length > 1;

  const footer = (
    <div className={markSpamModalCss.actionButtonsContainer}>
      <Button onClick={() => setOpen(false)} theme={ButtonTheme.OUTLINED}>
        Cancel
      </Button>
      <Button
        onClick={async () => {
          await handleMarkTicketsSpam({
            client,
            team,
            conversations: markableConversations,
            setActiveConversation,
            inDetailView,
            cardListRef,
            cleanupFn,
          });
          setOpen(false);
        }}
        theme={ButtonTheme.PRIMARY}
      >
        Yes, mark ticket{plural ? "s" : ""} as spam
      </Button>
    </div>
  );

  return (
    <Modal
      footer={footer}
      onClose={() => {
        cleanupFn && cleanupFn();
        setOpen(false);
      }}
      open={open}
      showHeaderBorder={false}
      size={ModalSize.SMALL}
      title={
        plural
          ? `Mark ${markableConversations.length} tickets as spam?`
          : `Mark ticket as spam?`
      }
    >
      <div className={markSpamModalCss.actionModalContentContainer}>
        <div>
          {plural ? "These tickets" : "This ticket"} will be closed and tagged
          "spam", and the
          {plural ? " senders' email addresses " : " sender's email address "}
          will be added to your team's exclusion list.
        </div>
      </div>
    </Modal>
  );
});
