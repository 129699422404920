import {
  JsonFormat,
  arrayJsonFormat,
  numberJsonFormat,
  objectJsonFormat,
  stringJsonFormat,
  symbolEnumJsonFormat,
  symbolJsonFormat,
  symbolUnionJsonFormat,
} from "@redotech/json/format";
import { bijectionRef } from "@redotech/util/bijection";

export interface Return {
  /** ID */
  _id: string;
  /** Created datetime */
  createdAt: Temporal.Instant;
  /** Customer */
  customer: {
    /** Name */
    name: { first: string; last: string };
    /** Email address */
    emailAddress: string;
    /** Phone number */
    phoneNumber: string;
  };
  /** Items */
  items: ReturnItem[];
  order: { id: string };
  newItems: {
    quantity: number;
    variantId: string;
  };
  newOrder: {
    externalOrderId: string;
    provision: "instant" | "normal";
    price: number;
  };
  /** Shipment */
  shipment: {
    /** EasyPost shipment */
    easypost: any;
  };
  /** Updated datetime */
  updatedAt: Temporal.Instant;
  transactions: ReturnTransaction[];
}

export interface ReturnItem {
  /** External line item ID */
  externalLineItemId: string;
  /** Original return value */
  price: number;
  /** Return value */
  value: number;
  /** Answers */
  answers: ReturnAnswer[];
  /** Status */
  status: "open" | "closed";
  /** Type */
  type: "exchange" | "refund" | "gift_card" | "discount_code";
  /** Exchange item */
  exchangeItem?: { externalVariantId: string };
}

export const returnItemJson: JsonFormat<ReturnItem> = objectJsonFormat(
  {
    externalLineItemId: stringJsonFormat,
    price: numberJsonFormat,
    answers: arrayJsonFormat(bijectionRef(() => returnAnswerJson)),
    status: <JsonFormat<ReturnItem["status"]>>stringJsonFormat,
    value: numberJsonFormat,
    type: <JsonFormat<ReturnItem["type"]>>stringJsonFormat,
  },
  {
    exchangeItem: objectJsonFormat<{ externalVariantId: string }>(
      { externalVariantId: stringJsonFormat },
      {},
    ),
  },
);

export type ReturnOrderProvision =
  | typeof ReturnOrderProvision.INSTANT
  | typeof ReturnOrderProvision.STANDARD;

export namespace ReturnOrderProvision {
  export const INSTANT = Symbol("instant");
  export const STANDARD = Symbol("standard");
  export const PROCESSED = Symbol("processed"); // todo remove standard, processed is the same and more clear
}

export const returnOrderProvisionJson = symbolEnumJsonFormat([
  ReturnOrderProvision.INSTANT,
  ReturnOrderProvision.STANDARD,
]);

export function returnStatusToReadableName(returnStatus: ReturnStatus): string {
  switch (returnStatus) {
    case ReturnStatus.CANCELLED:
      return "Cancelled";
    case ReturnStatus.CLOSED:
      return "Closed";
    case ReturnStatus.COMPLETE:
      return "Complete";
    case ReturnStatus.DELIVERED:
      return "Delivered";
    case ReturnStatus.IN_TRANSIT:
      return "In transit";
    case ReturnStatus.NEEDS_REVIEW:
      return "Needs review";
    case ReturnStatus.OPEN:
      return "Open";
    case ReturnStatus.REJECTED:
      return "Rejected";
    case ReturnStatus.FLAGGED:
      return "Flagged";
  }
}

export type ReturnStatus =
  | typeof ReturnStatus.CANCELLED
  | typeof ReturnStatus.CLOSED
  | typeof ReturnStatus.COMPLETE
  | typeof ReturnStatus.DELIVERED
  | typeof ReturnStatus.IN_TRANSIT
  | typeof ReturnStatus.NEEDS_REVIEW
  | typeof ReturnStatus.OPEN
  | typeof ReturnStatus.REJECTED
  | typeof ReturnStatus.FLAGGED;

export namespace ReturnStatus {
  /** Merchant cancelled */
  export const CANCELLED = Symbol("cancelled");
  /** Closed */
  export const CLOSED = Symbol("closed");
  /** Complete */
  export const COMPLETE = Symbol("complete");
  /** Delivered */
  export const DELIVERED = Symbol("delivered");
  /** Created */
  export const OPEN = Symbol("open");
  /** In transit */
  export const IN_TRANSIT = Symbol("in_transit");
  /** Needs merchant review */
  export const NEEDS_REVIEW = Symbol("needs_review");
  /** Rejected */
  export const REJECTED = Symbol("rejected");
  /** Flagged */
  export const FLAGGED = Symbol("flagged");
}

export const returnStatusJsonFormat: JsonFormat<ReturnStatus> =
  symbolEnumJsonFormat([
    ReturnStatus.CANCELLED,
    ReturnStatus.CLOSED,
    ReturnStatus.COMPLETE,
    ReturnStatus.DELIVERED,
    ReturnStatus.IN_TRANSIT,
    ReturnStatus.NEEDS_REVIEW,
    ReturnStatus.OPEN,
    ReturnStatus.REJECTED,
    ReturnStatus.FLAGGED,
  ]);

export interface ReturnAnswer {
  prompt: string;
  response: string;
  images: string[];
}

export const returnAnswerJson: JsonFormat<ReturnAnswer> = objectJsonFormat(
  {
    prompt: stringJsonFormat,
    response: stringJsonFormat,
    images: arrayJsonFormat(stringJsonFormat),
  },
  {},
);

export type ReturnTransaction =
  | ReturnTransaction.GiftCard
  | ReturnTransaction.DiscountCode
  | ReturnTransaction.Refund;

export namespace ReturnTransaction {
  export const GIFT_CARD = Symbol("gift_card");
  export const DISCOUNT_CODE = Symbol("discount_code");
  export const REFUND = Symbol("refund");

  export interface GiftCard {
    type: typeof GIFT_CARD;
    amount: number;
    code: string;
    externalId: string;
  }

  export const giftCardJsonFormat: JsonFormat<GiftCard> = objectJsonFormat(
    {
      type: symbolJsonFormat(GIFT_CARD),
      amount: numberJsonFormat,
      code: stringJsonFormat,
      externalId: stringJsonFormat,
    },
    {},
  );

  export interface DiscountCode {
    type: typeof DISCOUNT_CODE;
    amount: number;
    code: string;
    externalId: string;
  }

  export const discountCodeJsonFormat: JsonFormat<DiscountCode> =
    objectJsonFormat(
      {
        type: symbolJsonFormat(DISCOUNT_CODE),
        amount: numberJsonFormat,
        code: stringJsonFormat,
        externalId: stringJsonFormat,
      },
      {},
    );

  export interface Refund {
    type: typeof REFUND;
    amount: number;
  }

  export const refundJsonFormat: JsonFormat<Refund> = objectJsonFormat(
    { type: symbolJsonFormat(REFUND), amount: numberJsonFormat },
    {},
  );
}

export const returnTransactionJsonFormat: JsonFormat<ReturnTransaction> =
  symbolUnionJsonFormat("type", "type", {
    [ReturnTransaction.DISCOUNT_CODE]: ReturnTransaction.discountCodeJsonFormat,
    [ReturnTransaction.GIFT_CARD]: ReturnTransaction.giftCardJsonFormat,
    [ReturnTransaction.REFUND]: ReturnTransaction.refundJsonFormat,
  });
