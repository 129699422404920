import { DiscountSettings } from "@redotech/redo-model/order-discount";
import { OrderTrackingDiscountCode } from "@redotech/server/utils/order-discount-code";
import { AxiosError } from "axios";
import {
  createOrderDiscountByOrderId,
  getOrderDiscountByOrderId,
  runAndReturnDiscountFlow,
} from "../api";
import { TrackingType } from "../order/order-tracking/fulfillment/fulfillment";

export interface DiscountResponse {
  id?: string;
  discount?: OrderTrackingDiscountCode;
  settings?: {
    enabled: boolean;
    settings: DiscountSettings;
  };
  shortDescription?: string;
  created?: boolean;
}

export async function getOrCreateDiscountCode(
  orderId: string,
  trackingType?: TrackingType,
  returnTypes?: string[],
) {
  try {
    const res = await getOrderDiscountByOrderId(
      orderId,
      trackingType,
      returnTypes,
    );
    return res.data as DiscountResponse;
  } catch (error) {
    if ((error as AxiosError)?.response?.status === 404) {
      return await createOrderDiscountCode(orderId, trackingType, returnTypes);
    }
    return undefined;
  }
}

export async function createOrderDiscountCode(
  orderId: string,
  trackingType?: TrackingType,
  returnTypes?: string[],
) {
  try {
    const res = await createOrderDiscountByOrderId(
      orderId,
      trackingType,
      returnTypes,
    );
    return res.data as DiscountResponse;
  } catch (error) {
    return undefined;
  }
}

export async function getDiscountFlowResults(
  orderId: string,
  trackingType?: TrackingType,
  returnTypes?: string[],
) {
  try {
    const res = await getOrderDiscountByOrderId(
      orderId,
      trackingType,
      returnTypes,
    );
    return res.data as DiscountResponse;
  } catch (error) {
    if ((error as AxiosError)?.response?.status === 404) {
      const res = await runAndReturnDiscountFlow(
        orderId,
        trackingType,
        returnTypes,
      );
      return res.data as DiscountResponse;
    }
    return undefined;
  }
}
