// extracted by mini-css-extract-plugin
export var actionButton = "_6j-0";
export var bubble = "_6j-2";
export var bubbleContext = "_6j-6";
export var bubbleItems = "_6j-7";
export var customer = "_6j-3";
export var divider = "_6j-8";
export var fileList = "_6j-9";
export var infoAbove = "_6j-a";
export var internal = "_6j-4";
export var lightActionButton = "_6j-1";
export var link = "_6j-b";
export var merchant = "_6j-5";
export var message = "_6j-c";
export var profilePicture = "_6j-e";
export var quillToolbarContainer = "_6j-f";
export var senderDate = "_6j-g";
export var senderDateCustomer = "_6j-h";
export var senderName = "_6j-d";
export var time = "_6j-i";
export var uploadedImage = "_6j-j";
export var username = "_6j-k";
export var xsmall = "_6j-l";