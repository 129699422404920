import { FilterOptions, View } from "@redotech/redo-model/view";
import { RedoMerchantClient } from ".";

export const createView = async (
  client: RedoMerchantClient,
  {
    filters,
    name,
    isPrivate,
    signal,
  }: {
    filters: FilterOptions;
    name: string;
    isPrivate: boolean;
    signal?: AbortSignal;
  },
) => {
  const response = await client.client.post(
    "team/view",
    { filters, name, isPrivate },
    { headers: client.authorization(), signal },
  );
  return response.data;
};

export const updateView = async (
  client: RedoMerchantClient,
  {
    id,
    filters,
    name,
    isPrivate,
    signal,
  }: {
    id: string;
    filters: FilterOptions;
    name: string;
    isPrivate: boolean;
    signal?: AbortSignal;
  },
) => {
  const response = await client.client.put(
    `team/view/${id}`,
    { filters, name, isPrivate },
    { headers: client.authorization(), signal },
  );
  return response.data;
};

export const deleteView = async (
  client: RedoMerchantClient,
  {
    id,
    signal,
  }: {
    id: string;
    signal?: AbortSignal;
  },
) => {
  await client.client.delete(`team/view/${id}`, {
    headers: client.authorization(),
    signal,
  });
};

export const getTeamViews = async (
  client: RedoMerchantClient,
  {
    signal,
  }: {
    signal: AbortSignal;
  },
): Promise<View[]> => {
  const response = await client.client.get("team/views", {
    headers: client.authorization(),
    signal,
  });
  return response.data.views;
};

export const getUserViews = async (
  client: RedoMerchantClient,
  {
    signal,
  }: {
    signal: AbortSignal;
  },
): Promise<View[]> => {
  const response = await client.client.get("user/support-private-views", {
    headers: client.authorization(),
    signal,
  });
  return response.data.views;
};
