// extracted by mini-css-extract-plugin
export var box = "_4s-1";
export var checked = "_4s-5";
export var descriptionSpacer = "_4s-9";
export var disabled = "_4s-4";
export var icon = "_4s-6";
export var input = "_4s-0";
export var labelSpacer = "_4s-8";
export var medium = "_4s-3";
export var small = "_4s-2";
export var text = "_4s-7";