import { Condition as ModelCondition } from "@redotech/redo-model/return-flow/condition";
import { TrackingType } from "@redotech/redo-return-app/order/order-tracking/fulfillment/fulfillment";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { RadioGroup } from "@redotech/redo-web/radio";
import { ConditionType } from "../condition";

export const TRACKING_TYPE: ConditionType<string, ModelCondition.TrackingType> =
  {
    name: "Tracking type",
    description(state) {
      return `Tracking type: ${state}`;
    },
    Details({ state, setState, disabled, flowType }) {
      return (
        <LabeledInput
          description="Select the type of tracking page you'd like this condition to apply to"
          label="Tracking type"
        >
          <RadioGroup
            disabled={disabled}
            optionLabel={(option: TrackingType) =>
              ({
                order: "Order",
                return: "Return",
              })[option]
            }
            options={[TrackingType.ORDER, TrackingType.RETURN]}
            value={state as TrackingType}
            valueChange={(value: string) => setState(value)}
          />
        </LabeledInput>
      );
    },
    empty: TrackingType.ORDER,
    fromModel(model: ModelCondition.TrackingType) {
      return model.trackingType;
    },
    toModel(state) {
      return { type: ModelCondition.TRACKING_TYPE, trackingType: state };
    },
    valid(state) {
      return state !== undefined;
    },
  };
