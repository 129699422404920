import * as classNames from "classnames";
import { JSXElementConstructor, memo } from "react";
import { ButtonSize, IconButton } from "../../button";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { SpacingValue } from "../../theme/box";
import { TextSizeValue } from "../../theme/typography";
import { UserImage, UserImageSize } from "../../user-image";
import * as badgeColorCss from "./redo-badge-colors.module.css";
import * as badgeCss from "./redo-badge.module.css";

export type BadgeSize = "xs" | "sm" | "md" | "lg";
export type BadgeColor =
  | "gray"
  | "brand"
  | "error"
  | "warning"
  | "success"
  | "grayBlue"
  | "blueLight"
  | "blue"
  | "indigo"
  | "purple"
  | "pink"
  | "orange"
  | "black";

type BadgeIcon = {
  Icon: JSXElementConstructor<any>;
  onClick?: () => void;
};

/**
 * @param type if you are doing something other than pill color, it needs to be fleshed out in the colors module.
 */
export interface BadgeProps {
  iconLeading?: BadgeIcon;
  iconTrailing?: BadgeIcon;
  avatar?: { alt: string; name?: string; imageUrl: string | null } | undefined;
  text?: string;
  size?: BadgeSize;
  type?: "pillColor" | "pillOutline" | "badgeColor" | "badgeModern";
  color?: BadgeColor;
}

export const RedoBadge = memo(function RedoBadge({
  iconTrailing,
  iconLeading,
  avatar,
  text,
  size = "sm",
  type = "pillColor",
  color = "black",
}: BadgeProps) {
  const px = text ? regularPaddingX[size] : iconOnlyPaddingX[size];
  const py = text ? paddingY[size] : iconOnlyPaddingY[size];
  const textSize = fontSize[size];
  const variantClassnames = [
    badgeCss[size],
    badgeColorCss[type],
    badgeColorCss[color],
  ];
  const iconWrapperClassNames = classNames(
    variantClassnames,
    badgeColorCss.iconWrapper,
    badgeCss.iconWrapper,
    badgeCss[size],
  );

  function getIconOrIconButton(iconSetup: BadgeIcon) {
    const icon = <iconSetup.Icon />;
    const wrappedIcon = iconSetup.onClick ? (
      <IconButton
        className={badgeCss.iconButton}
        onClick={iconSetup.onClick}
        size={ButtonSize.SMALL}
      >
        {icon}
      </IconButton>
    ) : (
      icon
    );
    return <Flex className={iconWrapperClassNames}>{wrappedIcon}</Flex>;
  }

  return (
    <Flex
      align="center"
      className={classNames(badgeCss.arbiterBadgeContainer, variantClassnames)}
      gap={itemGap[size]}
      px={px}
      py={py}
    >
      {iconLeading && getIconOrIconButton(iconLeading)}
      {avatar && (
        <UserImage
          alt={avatar.alt}
          imageUrl={avatar.imageUrl}
          name={avatar.name}
          size={avatarSize[size]}
        />
      )}
      {text && (
        <Text
          fontSize={textSize}
          fontWeight="medium"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {text}
        </Text>
      )}
      {iconTrailing && getIconOrIconButton(iconTrailing)}
    </Flex>
  );
});

const fontSize: Record<BadgeSize, TextSizeValue> = {
  xs: "xxs",
  sm: "xs",
  md: "sm",
  lg: "sm",
};

const iconOnlyPaddingY: Record<BadgeSize, SpacingValue> = {
  xs: "xs",
  sm: "xs",
  md: "sm",
  lg: "md",
};

const paddingY: Record<BadgeSize, SpacingValue> = {
  xs: "xxs",
  sm: "xxs",
  md: "xxs",
  lg: "xs",
};

const iconOnlyPaddingX: Record<BadgeSize, SpacingValue> = {
  xs: "xs",
  sm: "xs",
  md: "sm",
  lg: "md",
};

const regularPaddingX: Record<BadgeSize, SpacingValue> = {
  xs: "xs",
  sm: "md",
  md: "md",
  lg: "lg",
};

const itemGap: Record<BadgeSize, SpacingValue> = {
  xs: "xxs",
  sm: "xs",
  md: "sm",
  lg: "sm",
};

const avatarSize: Record<BadgeSize, UserImageSize> = {
  xs: UserImageSize.X_TINY,
  sm: UserImageSize.TINY,
  md: UserImageSize.TINY,
  lg: UserImageSize.TINY,
};
