// extracted by mini-css-extract-plugin
export var actionButton = "_2x-1";
export var actionButtons = "_2x-2";
export var actions = "_2x-0";
export var alignCenter = "_2x-e";
export var backWrapper = "_2x-3";
export var checkboxContainer = "_2x-4";
export var contentContainer = "_2x-5";
export var contentHeader = "_2x-6";
export var dropdownItem = "_2x-9";
export var dropdownItemsContainer = "_2x-8";
export var dropdownTitle = "_2x-a";
export var filter = "_2x-b";
export var gray = "_2x-f";
export var headerCheckboxContainer = "_2x-c";
export var icon = "_2x-g";
export var menuContainer = "_2x-h";
export var oneLine = "_2x-i";
export var optionsButton = "_2x-j";
export var plus = "_2x-k";
export var portalButtonsWrapper = "_2x-d";
export var red = "_2x-l";
export var scrolledTop = "_2x-7";
export var search = "_2x-m";
export var selectedConversationsCount = "_2x-n";
export var spinner = "_2x-o";
export var strong = "_2x-p";
export var summaryCell = "_2x-q";
export var tagContainer = "_2x-r";
export var threeDots = "_2x-s";
export var twoLines = "_2x-t";
export var unread = "_2x-u";