import { useHover } from "@redotech/react-util/hover";
import ThreeDotsHorizontalIcon from "@redotech/redo-web/arbiter-icon/dots-horizontal.svg";
import { ButtonSize, IconButton } from "@redotech/redo-web/button";
import { Flex } from "@redotech/redo-web/flex";
import CheckIcon from "@redotech/redo-web/icon-old/check.svg";
import * as classNames from "classnames";
import { memo, ReactNode, useState } from "react";
import { IconButtonTheme } from "../../button";
import {
  RedoCommandMenu,
  RedoCommandMenuItem,
} from "../command-menu/redo-command-menu";
import * as redoListItemCss from "./redo-list-item.module.css";

export enum RedoListItemSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export type RedoListItemMenu = { onClick(): void } | RedoCommandMenuItem[];

export interface RedoListItemProps {
  children: ReactNode;
  size: RedoListItemSize;
  selected?: boolean;
  menu?: RedoListItemMenu;
  focused?: boolean;
  itemClicked(): void;
}

export const RedoListItem = memo(function RedoListItem({
  size,
  children,
  selected,
  menu,
  focused: externallyFocused,
  itemClicked,
}: RedoListItemProps) {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const isHovered = useHover(anchor);

  if (Array.isArray(menu) && menu.length === 0) {
    menu = undefined;
  }

  const [menuOpen, setMenuOpen] = useState(false);

  const focused = isHovered || menuOpen || externallyFocused;

  const listItemMenu = menu && (
    <RedoListItemMenu
      menu={menu}
      menuOpen={menuOpen}
      setMenuOpen={setMenuOpen}
      size={size}
    />
  );

  const rightArea = (
    <Flex className={redoListItemCss.rightArea}>
      {focused && listItemMenu ? (
        listItemMenu
      ) : selected ? (
        <Flex p="md">
          <CheckIcon />
        </Flex>
      ) : null}
    </Flex>
  );

  return (
    <Flex
      align="center"
      as="button"
      className={classNames(
        redoListItemCss.listItemContainer,
        focused && redoListItemCss.focused,
        redoListItemSizeCss[size],
      )}
      justify="space-between"
      onClick={(event) => {
        itemClicked();
      }}
      px="xl"
      py="md"
      radius="md"
      ref={setAnchor}
    >
      <div className={redoListItemCss.childrenContainer}>{children}</div>
      {rightArea}
    </Flex>
  );
});

const RedoListItemMenu = memo(function RedoListItemMenu({
  size,
  menu,
  menuOpen,
  setMenuOpen,
}: {
  size: RedoListItemSize;
  menu: RedoListItemMenu;
  setMenuOpen(value: boolean): void;
  menuOpen: boolean;
}) {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  if (Array.isArray(menu)) {
    return (
      <>
        <IconButton
          onClick={() => setMenuOpen(!menuOpen)}
          ref={setAnchor}
          size={ButtonSize.MEDIUM}
          theme={IconButtonTheme.GRAY}
        >
          <ThreeDotsHorizontalIcon />
        </IconButton>
        <RedoCommandMenu
          anchor={anchor}
          items={menu}
          open={menuOpen}
          setOpen={setMenuOpen}
        />
      </>
    );
  } else {
    return (
      <IconButton
        onClick={menu.onClick}
        size={ButtonSize.MEDIUM}
        theme={IconButtonTheme.GRAY}
      >
        <ThreeDotsHorizontalIcon />
      </IconButton>
    );
  }
});

const redoListItemSizeCss: Record<RedoListItemSize, string> = {
  [RedoListItemSize.SMALL]: redoListItemCss.small,
  [RedoListItemSize.MEDIUM]: redoListItemCss.medium,
  [RedoListItemSize.LARGE]: redoListItemCss.large,
};
