// extracted by mini-css-extract-plugin
export var actionButton = "_6b-0";
export var assigneeDetails = "_6b-1";
export var assigneeInfo = "_6b-2";
export var assigneeProfile = "_6b-4";
export var buttonContent = "_6b-5";
export var ccEmails = "_6b-a";
export var ccEmailsSection = "_6b-9";
export var chatbotContainer = "_6b-6";
export var collapseContainer = "_6b-c";
export var copyableField = "_6b-d";
export var customerInfoTitle = "_6b-f";
export var detailRow = "_6b-h";
export var details = "_6b-e";
export var detailsText = "_6b-l";
export var disclaimer = "_6b-b";
export var editAssigneeForm = "_6b-j";
export var editAssigneeInfo = "_6b-3";
export var editAssigneeModalContainer = "_6b-i";
export var editButton = "_6b-g";
export var email = "_6b-7";
export var header = "_6b-m";
export var headerDivider = "_6b-n";
export var icon = "_6b-o";
export var instaDesc = "_6b-x";
export var ordersReturnsSection = "_6b-v";
export var postPreview = "_6b-w";
export var profilePicture = "_6b-p";
export var rightContent = "_6b-8";
export var saveButton = "_6b-k";
export var strong = "_6b-q";
export var tagsAndAssigneeContainer = "_6b-t";
export var username = "_6b-r";
export var withBottomBorder = "_6b-u";
export var wrapper = "_6b-s";
export var wrappingText = "_6b-y";