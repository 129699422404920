import { useRequiredContext } from "@redotech/react-util/context";
import { getKey } from "@redotech/react-util/key";
import { useLoad } from "@redotech/react-util/load";
import { ScreenType, useScreenType } from "@redotech/react-util/screen";
import { InteractiveMapLocation } from "@redotech/redo-model/interactive-map-location";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import BuildingIcon from "@redotech/redo-web/icon-old/building-02.svg";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { Modal } from "@redotech/redo-web/modal";
import { memo, useState } from "react";
import { getLocations } from "../../../api";
import { InStoreReturnContext } from "../../../contexts/InStoreReturnContext";
import { InteractiveMap } from "../../interactive-map";
import * as review from "../review.module.css";

export const ReturnInStoreCard = memo(function ReturnInStoreCard() {
  const size = useScreenType();
  const returnInStoreInput = useRequiredContext(InStoreReturnContext);

  const [mapModalOpen, setMapModalOpen] = useState(false);

  return (
    <div
      className={
        size === ScreenType.MOBILE
          ? review.summaryCardPickup
          : review.summaryCardHorizonal
      }
    >
      <div className={review.summaryCardItem}>
        <BuildingIcon />
      </div>
      <div
        className={
          size === ScreenType.MOBILE
            ? review.summaryCardItem
            : review.summaryCardItemHorizonal
        }
      >
        {returnInStoreInput.value ? (
          <>
            <div className={review.summaryTextBold}>
              You've chosen to return in-store!
            </div>
            <div className={review.summaryText}>
              After you submit your return, you can bring your items into the
              store and save the time of shipping.
            </div>
          </>
        ) : (
          <>
            <div className={review.summaryHeader}>Want to return in-store?</div>
            <div className={review.summaryText}>
              You can chose to bring your items right into the store and save
              the time of shipping.
            </div>
          </>
        )}
      </div>
      <div className={review.summaryCardItem}>
        {returnInStoreInput.value ? (
          <Button
            onClick={() => setMapModalOpen(true)}
            theme={ButtonTheme.OUTLINED}
          >
            See Available Locations
          </Button>
        ) : (
          <Button
            onClick={() => returnInStoreInput.setValue(true)}
            theme={ButtonTheme.OUTLINED}
          >
            Return in-store
          </Button>
        )}
      </div>
      <Modal
        onClose={() => setMapModalOpen(false)}
        open={mapModalOpen}
        title="Find a store that accepts drop offs:"
      >
        <ReturnInStoreLocationsModal />
      </Modal>
    </div>
  );
});

export const ReturnInStoreLocationsModal = memo(
  function ReturnInStoreLocationsModal() {
    const locationsLoad = useLoad<InteractiveMapLocation[]>(async () => {
      const { data } = await getLocations();
      const locations = data.locations;

      return locations
        .filter(
          (location) =>
            location.acceptsInStoreReturns &&
            location.address.latitude &&
            location.address.longitude,
        )
        .map((location) => ({
          name: location.name,
          address: {
            address1: location.address.address1,
            address2: location.address.address2,
            city: location.address.city,
            state: location.address.provinceCode,
            zip: location.address.zip,
            country: location.address.countryCode,
          },
          geo: {
            latitude: location.address.latitude,
            longitude: location.address.longitude,
          },
        }));
    }, []);

    return locationsLoad.pending ? (
      <LoadingRedoAnimation />
    ) : locationsLoad.error ? (
      <div>Error loading locations</div>
    ) : locationsLoad.value ? (
      <InteractiveMap
        key={getKey(locationsLoad.value)}
        locations={locationsLoad.value}
      />
    ) : null;
  },
);
