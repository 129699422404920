import { Input } from "@redotech/ui/form";
import * as classNames from "classnames";
import { memo, ReactNode } from "react";
import { LabeledInput, LabelPosition } from "./labeled-input";
import * as switchCss from "./switch.module.css";

export interface SwitchProps {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}

export function Switch({ value, onChange, disabled = false }: SwitchProps) {
  return (
    <label className={switchCss.container}>
      <input
        checked={value}
        className={switchCss.checkbox}
        disabled={disabled}
        onChange={(event) => onChange(event.target.checked)}
        type="checkbox"
      />
      <div
        className={classNames(
          switchCss.switch0,
          disabled ? switchCss.switchDisabled : switchCss.switchEnabled,
        )}
      >
        <div className={switchCss.circle} />
      </div>
    </label>
  );
}

export const TextSwitch = memo(function TextSwitch({
  value,
  onChange,
  disabled = false,
  labels,
}: {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  labels: [string, string];
}) {
  return (
    <div className={switchCss.textSwitchContainer}>
      <div
        className={classNames(
          switchCss.textSwitchOption,
          value ? switchCss.textSwitchOptionSelected : null,
        )}
        onClick={() => onChange(true)}
      >
        {labels[0]}
      </div>
      <div
        className={classNames(
          switchCss.textSwitchOption,
          !value ? switchCss.textSwitchOptionSelected : null,
        )}
        onClick={() => onChange(false)}
      >
        {labels[1]}
      </div>
    </div>
  );
});

export function FormSwitch({
  input,
  label,
  children,
  position = LabelPosition.ABOVE,
  disabled = false,
}: {
  input: Input<boolean>;
  label: string;
  children?: ReactNode | ReactNode[];
  position?: LabelPosition;
  disabled?: boolean;
} & Omit<SwitchProps, "value" | "onChange">) {
  return (
    <LabeledInput
      description={children}
      errors={input.errors}
      label={label}
      position={position}
    >
      <Switch
        disabled={disabled}
        onChange={input.setValue}
        value={input.value}
      />
    </LabeledInput>
  );
}
