import { RegisterInit } from "@shopify/web-pixels-extension";
import {
  MoneyV2,
  PixelEventsProductAddedToCartData,
  PixelEventsProductViewedData,
} from "@shopify/web-pixels-extension/build/ts/types/PixelEvents";

export interface ProductInfo {
  productId: string;
  productTitle: string;
  variantId?: string;
  variantTitle?: string;
  url?: string;
  price: MoneyV2;
  priceRange?: {
    minVariantPrice: MoneyV2;
    maxVariantPrice: MoneyV2;
  };
  imageUrl?: string;
  handle?: string;
}

export enum ShoppingEventType {
  PRODUCT_VIEWED = "product_viewed",
  PRODUCT_ADDED_TO_CART = "product_added_to_cart",
}

export const SHOPPING_EVENT_DISCRIMINATOR_KEY = "eventType";

export interface IShoppingEvent {
  _id: string;
  team: string;
  email: string;
  timestamp: Date;
  eventId: string;
  eventType: ShoppingEventType;
}

export interface IProductViewedShoppingEvent extends IShoppingEvent {
  eventType: ShoppingEventType.PRODUCT_VIEWED;
  productVariant: PixelEventsProductViewedData["productVariant"];
  customer: RegisterInit["data"]["customer"];
  cart: RegisterInit["data"]["cart"];
}

export interface IProductAddedToCartShoppingEvent extends IShoppingEvent {
  eventType: ShoppingEventType.PRODUCT_ADDED_TO_CART;
  cartLine: PixelEventsProductAddedToCartData["cartLine"];
  customer: RegisterInit["data"]["customer"];
}
