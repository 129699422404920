/**
 * A monadic result type that enables functional-programming-style error handling.
 * You can chain operations together using `map` and `flatMap`, and
 * anything thrown inside will be caught and squashed into an Error type.
 * @typeParam T The type of the value that the result represents.
 *
 * @example
 * ```
 * const result = ok(5).map((value) => value * 2).map((value) => value + 1);
 * if (result.ok) {
 *  console.log(result.value); // 11
 * }
 * ```
 *
 * @example
 * ```
 * const result = ok('{"name": "John"}').map(JSON.parse).map((value) => value.name);
 * if (result.ok) {
 *  console.log(result.value); // John
 * } else {
 *  console.error(result.error);
 * }
 * ```
 *
 * @remarks
 *
 * The intent of this type is to provide a more ergonomic way to handle errors
 * as compared to the built-in `try`/`catch` mechanism in JavaScript, which
 * complicates control flow.
 *
 * If you're unfamiliar with monads, here are some resources to get you started:
 * {@link https://www.adit.io/posts/2013-04-17-functors,_applicatives,_and_monads_in_pictures.html
 * {@link https://stackoverflow.com/questions/17054978/composition-operator-and-pipe-forward-operator-in-rust/17111630#17111630}
 */
export type CoercedResult<T> = ResultBase<T> &
  (
    | {
        ok: true;
        value: T;
      }
    | {
        ok: false;
        error: Error;
      }
  );

export interface ResultBase<T> {
  /**
   * Maps the value of the result to a new value using the given function.
   * It can be chained with other operations that return results.
   * If an error is thrown anywhere in the chain, no runtime error will occur.
   * Instead, the error will be caught, wrapped in a Result, and propagated down the chain.
   */
  map<U>(fn: (value: T) => U): CoercedResult<U>;

  /**
   * Same as map, except the function is expected to return a result itself.
   * This is useful for chaining - instead of nesting - operations that return results.
   */
  flatMap<U>(fn: (value: T) => CoercedResult<U>): CoercedResult<U>;

  /**
   * Maps the value of the result to a new value using the given async function.
   */
  mapAsync<U>(fn: (value: T) => Promise<U>): Promise<CoercedResult<U>>;

  /**
   * Async version of flatMap.
   */
  flatMapAsync<U>(
    fn: (value: T) => Promise<CoercedResult<U>>,
  ): Promise<CoercedResult<U>>;

  /**
   * Unwraps the result, throwing an error if it is an error result.
   * This is useful when you're certain that the result is successful.
   * If the result is an error, the error will be thrown.
   *
   * @param error An optional error to throw if the result is an error.
   * If undefined the packaged error will be thrown.
   * If a function is provided, it will be called with the error and should throw an error itself.
   */
  unwrap(error?: Error | ((err: Error) => Error)): T;
}

/**
 * Creates a successful result with the given value.
 * @param value The value to wrap in a successful result.
 * @returns A successful result containing the given value.
 * @typeParam T The type of the value to wrap.
 */
export function ok<T>(value: T): CoercedResult<T> {
  return {
    ok: true,
    value,
    map<U>(fn: (value: T) => U): CoercedResult<U> {
      try {
        return ok(fn(value));
      } catch (err: unknown) {
        return error(coerceToError(err));
      }
    },
    flatMap<U>(fn: (value: T) => CoercedResult<U>): CoercedResult<U> {
      try {
        return fn(value);
      } catch (err: unknown) {
        return error(coerceToError(err));
      }
    },
    async mapAsync<U>(fn: (value: T) => Promise<U>): Promise<CoercedResult<U>> {
      try {
        return ok(await fn(value));
      } catch (err: unknown) {
        return error(coerceToError(err));
      }
    },
    async flatMapAsync<U>(
      fn: (value: T) => Promise<CoercedResult<U>>,
    ): Promise<CoercedResult<U>> {
      try {
        return await fn(value);
      } catch (err: unknown) {
        return error(coerceToError(err));
      }
    },
    unwrap(_?: Error | ((err: Error) => never)): T {
      return value;
    },
  };
}

/**
 * Creates a failed result with the given error.
 * @param err The error to wrap in a failed result.
 * @returns A failed result containing the given error.
 */
export function error(err: unknown): CoercedResult<never> {
  return {
    ok: false,
    error: coerceToError(err),
    map<U>(): CoercedResult<U> {
      return this;
    },
    flatMap<U>(): CoercedResult<U> {
      return this;
    },
    async mapAsync<U>(): Promise<CoercedResult<U>> {
      return this;
    },
    async flatMapAsync<U>(): Promise<CoercedResult<U>> {
      return this;
    },
    unwrap(errorMap?: Error | ((err: Error) => Error)): never {
      if (error === undefined) {
        throw this.error;
      } else if (typeof errorMap === "function") {
        throw errorMap(this.error);
      } else {
        throw errorMap;
      }
    },
  };
}

export function isOk<T>(
  result: CoercedResult<T>,
): result is { ok: true; value: T } & ResultBase<T> {
  return result.ok;
}

export function isError<T>(
  result: CoercedResult<T>,
): result is { ok: false; error: Error } & ResultBase<T> {
  return !result.ok;
}

function coerceToError(err: unknown): Error {
  if (err instanceof Error) {
    return err;
  }
  if (typeof err === "string") {
    return new Error(err);
  }
  if (
    typeof err === "object" &&
    err &&
    "message" in err &&
    JSON.stringify(err) === "{}"
  ) {
    // weird Mongoose error objects that don't serialize properly
    return new Error(String(err));
  }
  if (typeof err === "object") {
    try {
      return new Error(`An error occurred: ${JSON.stringify(err)}`);
    } catch (jsonError) {
      return new Error("An error occurred, but it could not be serialized.");
    }
  }
  return new Error(
    `An unknown error of type ${typeof err} occurred: ${String(err)}`,
  );
}
