import { getResource } from "@redotech/redo-model/localization/resource";
import { Card } from "@redotech/redo-web/card";
import { memo } from "react";
import { Product } from "./product";

import * as returnStatus from "./return-status.module.css";

export const ReturnItemsCard = memo(function ReturnItemsCard({
  returnData,
  products,
  subtitle,
  headerExtra,
}: {
  returnData: any;
  products: any;
  subtitle?: string;
  headerExtra?: React.ReactNode | React.ReactNode[];
}) {
  const copyResource = getResource({
    variant: returnData.type,
  });

  return (
    <Card
      headerExtra={headerExtra}
      subtitle={subtitle}
      title={
        <>
          {copyResource.ITEMS_OLD_HEADER}{" "}
          {!returnData.totals.fee && returnData.shipment && (
            <span className={returnStatus.productReturnType}>
              Covered by Redo
            </span>
          )}
        </>
      }
    >
      {products.map((item: any, i: number) => (
        <Product
          imageSrc={item.images?.[0]}
          key={i}
          last={i === products.length - 1}
          price={item.price}
          returnType={getReturnTypeForProduct(returnData, item)}
          title={item.product_title || item.title}
          variantTitle={item.variant_title}
        />
      ))}
    </Card>
  );
});

const getReturnTypeForProduct = (return_: any, product: any) => {
  if (
    return_.advancedExchangeItems.length ||
    product.exchange_for ||
    product.exchangeGroupItem
  ) {
    return "Exchange";
  } else if (product.strategy === "store_credit") {
    return "Store credit";
  } else if (product.strategy === "refund") {
    return "Refund";
  } else {
    return ""; // Rejected returns
  }
};
