// extracted by mini-css-extract-plugin
export var bubble = "_2j-0";
export var container = "_2j-3";
export var contrastBorder = "_2j-4";
export var greyOut = "_2j-2";
export var initialsWrapper = "_2j-1";
export var large = "_2j-9";
export var medium = "_2j-8";
export var mediumSmall = "_2j-7";
export var micro = "_2j-5";
export var small = "_2j-6";
export var tiny = "_2j-b";
export var xTiny = "_2j-a";