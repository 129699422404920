import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import * as classNames from "classnames";
import { memo } from "react";
import * as orderDetailsCss from "./action-panel/order-details/order-details.module.css";

export const ProductDetailCard = memo(function ProductDetailCard({
  imageName,
  imageSrc,
  price,
  item,
  quantity,
  variant,
}: {
  imageName: string;
  imageSrc: string;
  price: string;
  item: string;
  quantity: number | string;
  variant: string;
}) {
  return (
    <div
      className={classNames(
        orderDetailsCss.product,
        orderDetailsCss.wrappingText,
      )}
    >
      <Flex align="center" className={orderDetailsCss.wrappingText}>
        <Flex>
          {imageSrc && (
            <div className={orderDetailsCss.imgContainer}>
              <img alt={imageName} src={imageSrc} />
            </div>
          )}
        </Flex>
        <Flex className={orderDetailsCss.wrappingText} grow="1">
          <Flex
            className={classNames(
              orderDetailsCss.wrappingText,
              orderDetailsCss.tightText,
            )}
            dir="column"
          >
            <Text fontSize="xs" fontWeight="medium" textColor="primary">
              {price}
            </Text>
            <Flex className={orderDetailsCss.wrappingText}>
              <Tooltip placement="left" title={item}>
                <p className={orderDetailsCss.wrappingText}>
                  <Text
                    fontSize="xs"
                    fontWeight="medium"
                    overflow="hidden"
                    textColor="primary"
                    textOverflow="ellipsis"
                  >
                    {item}
                  </Text>
                </p>
              </Tooltip>
            </Flex>
            <Text fontSize="xxs" textColor="quaternary">
              {quantity}
              {variant !== "Default Title" && !!variant && (
                <span>{"  |  " + variant}</span>
              )}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
});
