// extracted by mini-css-extract-plugin
export var arbiterButtonIcon = "_4m-f";
export var buttonIcon = "_4m-e";
export var container = "_4m-0";
export var copyButton = "_4m-c";
export var darkGrey = "_4m-l";
export var divider = "_4m-d";
export var fulfillmentFooter = "_4m-5";
export var grey = "_4m-j";
export var header = "_4m-3";
export var headerWrapper = "_4m-1";
export var mobileColumn = "_4m-m";
export var mobileColumnReverse = "_4m-n";
export var orderDetailsContainer = "_4m-o";
export var orderMetadata = "_4m-b";
export var orderMetadataWrapper = "_4m-a";
export var product = "_4m-6";
export var productDivider = "_4m-8";
export var productImage = "_4m-9";
export var productInfo = "_4m-q";
export var productTitle = "_4m-7";
export var strikethrough = "_4m-k";
export var thin = "_4m-i";
export var tinyText = "_4m-h";
export var title = "_4m-2";
export var trackingContainer = "_4m-4";
export var upsellHeader = "_4m-p";
export var xTinyText = "_4m-g";