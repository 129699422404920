import * as React from "react";
const SvgCalendarPlus = props => <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16px" height="16px" viewBox="0 0 18 16" {...props}><g id="surface1"><path style={{
      fill: "none",
      strokeWidth: 2,
      strokeLinecap: "round",
      strokeLinejoin: "round",
      stroke: "rgb(7.843137%,7.843137%,7.843137%)",
      strokeOpacity: 1,
      strokeMiterlimit: 4
    }} d="M 21 11.501953 L 21 8.800781 C 21 7.119141 21 6.28125 20.671875 5.636719 C 20.384766 5.074219 19.927734 4.617188 19.359375 4.324219 C 18.720703 4.001953 17.882812 4.001953 16.201172 4.001953 L 7.798828 4.001953 C 6.117188 4.001953 5.279297 4.001953 4.640625 4.324219 C 4.072266 4.617188 3.615234 5.074219 3.328125 5.636719 C 3 6.28125 3 7.119141 3 8.800781 L 3 17.197266 C 3 18.878906 3 19.722656 3.328125 20.361328 C 3.615234 20.923828 4.072266 21.386719 4.640625 21.673828 C 5.279297 22.001953 6.117188 22.001953 7.798828 22.001953 L 12.498047 22.001953 M 21 10.001953 L 3 10.001953 M 16.001953 1.998047 L 16.001953 6 M 7.998047 1.998047 L 7.998047 6 M 18 21 L 18 15 M 15 18 L 21 18 " transform="matrix(0.666667,0,0,0.666667,0,0)" /></g></svg>;
export default SvgCalendarPlus;