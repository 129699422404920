import * as amplitude from "@amplitude/analytics-browser";
import Gravatar from "@gravatar/js";
import { styled, TooltipProps } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  ConversationPlatform,
  ExpandedConversation,
  ExpandedConversationMessage,
  MessageVisibility,
} from "@redotech/redo-model/conversation";
import { Avatar } from "@redotech/redo-web/arbiter-components/avatars/avatar";
import { AvatarLabelGroup } from "@redotech/redo-web/arbiter-components/avatars/avatar-label-group";
import { RedoBadge } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import DotsIconSvg from "@redotech/redo-web/arbiter-icon/dots-horizontal.svg";
import InfoSvg from "@redotech/redo-web/arbiter-icon/info-circle.svg";
import PaperclipSvg from "@redotech/redo-web/arbiter-icon/paperclip_filled.svg";
import { AttachmentThumbnail } from "@redotech/redo-web/attachment-thumbnail";
import { Button } from "@redotech/redo-web/button";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import * as classNames from "classnames";
import {
  Dispatch,
  memo,
  ReactElement,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MessageInput } from "../message-input";
import { EmailAddressesTooltip } from "./email-address-tooltip";
import { EmailDraftMode, EmailDraftState } from "./email-draft-state";
import {
  convertPlainTextToHtml,
  cutOutRepliesFromMessageContent,
} from "./format-email-message-content";

import {
  EmailAddressInfo,
  EmailAddressInfoWithValidEmail,
  filterAndPreprocessEmailInfo,
  hasExactlyOneRecipientEmail,
  stringifyEmailRecipientsInfo,
} from "@redotech/redo-model/support/conversations/email-info";
import CornerUpLeftDoubleSvg from "@redotech/redo-web/arbiter-icon/corner-up-left-double.svg";
import CornerUpLeftSvg from "@redotech/redo-web/arbiter-icon/corner-up-left_filled.svg";
import CornerUpRightSvg from "@redotech/redo-web/arbiter-icon/corner-up-right_filled.svg";
import * as emailMessageCss from "./email-message.module.css";
const CustomTooltip = styled(function CustomTooltip({
  className,
  ...props
}: TooltipProps) {
  return <Tooltip {...props} classes={{ popper: className }} />;
})(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#0F0F0F",
    color: "#FFFFFF",
    fontSize: "var(--redo-body-xsmall-text-size) !important",
    font: "var(--redo-body-font-family)",
    padding: "8px 12px",
    borderRadius: "var(--redo-corner-small-radius)",
    lineHeight: "18px",
    maxWidth: 400,
  },
}));

const SubtleButton = memo(function SubtleButton({
  iconSvg,
  onClick,
}: {
  iconSvg: (props: any) => ReactElement;
  onClick: (e: any) => any;
}) {
  return (
    <Button className={emailMessageCss.subtleButton} onClick={onClick}>
      <Flex
        align="center"
        className={emailMessageCss.subtleButtonIconContainer}
        justify="center"
        p="md"
      >
        {iconSvg({})}
      </Flex>
    </Button>
  );
});

export type MessageContent =
  | { type: "messageText"; messageText: string }
  | { type: "messageHtml"; messageHtml: string };

export type MessageInputProps = {
  setActiveConversation: (
    conversation: ExpandedConversation | undefined,
  ) => void;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  setShowErrorMessage: Dispatch<SetStateAction<boolean>>;
  showFullCommentThread: boolean;
  typing: Record<string, Date>;
  setTyping: Dispatch<SetStateAction<Record<string, Date>>>;
  setLeftPanelOpen: Dispatch<SetStateAction<boolean>>;
  setShowFullCommentThread: Dispatch<SetStateAction<boolean>>;
  cardListRef?: any;
};

const adoptSheet = (atRoot: ShadowRoot, styles: string) => {
  const sheet = new CSSStyleSheet();
  sheet.replaceSync(styles);
  atRoot.adoptedStyleSheets.push(sheet);
};

export const EmailMessage = memo(function EmailMessage({
  startCollapsed,
  username,
  userAvatarImageUrl,
  messageContent,
  from,
  to,
  cc,
  bcc,
  date,
  subject,
  files,
  visibility,
  isUser,
  message,
  conversation,
  profilePictures,
  startInDraftingMode,
  setActiveConversation,
  setErrorMessage,
  setShowErrorMessage,
  showFullCommentThread,
  typing,
  setTyping,
  setLeftPanelOpen,
  setShowFullCommentThread,
  cardListRef,
}: {
  startCollapsed: boolean;
  username: string;
  userAvatarImageUrl: string;
  messageContent: MessageContent;
  from: EmailAddressInfoWithValidEmail | undefined;
  to: EmailAddressInfoWithValidEmail[];
  cc: EmailAddressInfoWithValidEmail[];
  bcc: EmailAddressInfoWithValidEmail[];
  date: string;
  subject: string;
  files: any[];
  visibility: MessageVisibility;
  isUser?: boolean;
  message: ExpandedConversationMessage;
  conversation: ExpandedConversation;
  profilePictures: Record<string, string> | undefined;
  startInDraftingMode: "Reply" | "Reply All" | undefined;
} & MessageInputProps) {
  const shouldShowReplyButton = !!to.length;

  const shouldShowReplyAllButton =
    !hasExactlyOneRecipientEmail({
      to,
      cc,
      bcc,
    }) || !to.length;

  /** Don't actually "reply" to your own messages lol. Reply to the original recipients when applicable */
  const isSendeeSameAsOldFrom = from?.email === conversation.email?.email;

  /**
   * compare emails regardless of aliases (sender addr to the right of the "+" sign infront of "@")
   */
  const compareEmailsRegardlessOfAliases = (
    email1: EmailAddressInfo | undefined,
    email2: EmailAddressInfo | undefined,
  ): boolean => {
    if (!email1?.email || !email2?.email) {
      return false;
    }
    const getEmailWithoutAlias = (email: string): string => {
      return email.split("@")[0].split("+")[0] + "@" + email.split("@")[1];
    };

    try {
      return (
        getEmailWithoutAlias(email1.email) ===
        getEmailWithoutAlias(email2.email)
      );
    } catch (error) {
      console.error("Error comparing emails:", error);
      return false;
    }
  };

  /**
   * Helper function to filter a list of EmailAddressInfo that
   * if the current "from" email is in the CC or BCC list, remove it.
   */
  const filterOutSelfFromCcOrBcc = (
    emailAddresses: EmailAddressInfo[],
  ): EmailAddressInfo[] => {
    return emailAddresses.filter(
      (emailAddress) => !compareEmailsRegardlessOfAliases(emailAddress, from),
    );
  };

  /**
   * Use localstorage to save the draft email,
   * and use an effect to reload the draft specific to some key that
   * is unique to both the conversation and the message id.
   */

  const initialDraftToAssignment = (mode: EmailDraftMode) =>
    /** Reply uses only the "to" email addresses */
    mode === "Reply"
      ? isSendeeSameAsOldFrom
        ? to ?? []
        : from
          ? [from]
          : []
      : /** (when replying all, include new people who were looped in from your reply)*/
        mode === "Reply All"
        ? isSendeeSameAsOldFrom
          ? to ?? []
          : [
              ...(from ? [from] : []),
              ...to.filter((ei) => ei.email !== conversation.email?.email),
            ] ?? []
        : /** if the mode is forward, don't have any "to" addresses to start */
          [];

  const getInitialDraft = (mode: EmailDraftMode): EmailDraftState => ({
    status: "drafting",
    mode: mode,
    draft: {
      recipientsInfo: Object.assign(
        {},
        ...Object.entries(
          mode === "Forward"
            ? {
                to: [],
                cc: [],
                bcc: [],
              }
            : {
                to: initialDraftToAssignment(mode),

                cc: filterOutSelfFromCcOrBcc(mode === "Reply All" ? cc : []),
                bcc: filterOutSelfFromCcOrBcc(mode === "Reply All" ? bcc : []),
              },
        ).map(([emailType, unvalidatedEmails]) => ({
          [emailType]: filterAndPreprocessEmailInfo(unvalidatedEmails),
        })),
        {
          inReplyTo: message?.email?.messageId,
        },
      ),
      subject: `Re: ${subject}`,
      content: "",
    },
  });
  const initializeDraftToDefault = (): EmailDraftState => {
    if (startInDraftingMode) {
      return getInitialDraft(startInDraftingMode);
    }
    return { status: "noDraft" };
  };

  const [replyDraft, setReplyDraft] = useState<EmailDraftState>(
    initializeDraftToDefault,
  );

  const getEmailMessageInfoKey = (
    conversationId: string | undefined,
    messageId: string | undefined,
  ) => `redo.support.emaildraft.${conversationId}.${messageId}`;
  const emailMessageInfoKey = getEmailMessageInfoKey(
    conversation._id,
    message._id,
  );

  /**
   * Load the draft from storage when we switch to it
   */
  const loadDraftFromStorage = () => {
    const draft = localStorage.getItem(emailMessageInfoKey);
    if (!draft) {
      setReplyDraft(initializeDraftToDefault);
      return;
    }
    try {
      const parsedDraft = JSON.parse(draft);
      if (parsedDraft) {
        setReplyDraft(parsedDraft);
        return;
      }
    } catch (error) {
      console.error("Error parsing email draft:", error);
      setReplyDraft(initializeDraftToDefault);
    }
  };
  useEffect(() => {
    loadDraftFromStorage();
  }, [emailMessageInfoKey]);

  /**
   * If the draft mode has changed, we need to reset the draft.
   * Otherwise, if it's been edited, update the stored draft.
   */
  const handleSetReplyDraft: Dispatch<SetStateAction<EmailDraftState>> = (
    newDraft: SetStateAction<EmailDraftState>,
  ) => {
    setReplyDraft((prevDraft: EmailDraftState) => {
      const updatedDraft =
        typeof newDraft === "function" ? newDraft(prevDraft) : newDraft;

      let updatedDraftOrSwitchedTypeDraft = updatedDraft;

      const shouldResetRecipientInfo =
        prevDraft.status === "drafting" &&
        updatedDraft.status === "drafting" &&
        updatedDraft.mode !== prevDraft.mode;
      if (shouldResetRecipientInfo) {
        updatedDraftOrSwitchedTypeDraft = getInitialDraft(updatedDraft.mode);
      }
      /**
       * Save in-edit drafts, but don't save drafts that are not in edit.
       */
      if (updatedDraftOrSwitchedTypeDraft.status === "drafting") {
        localStorage.setItem(
          emailMessageInfoKey,
          JSON.stringify(updatedDraftOrSwitchedTypeDraft),
        );
      } else {
        localStorage.removeItem(emailMessageInfoKey);
      }
      return updatedDraftOrSwitchedTypeDraft;
    });
  };

  const [collapsed, setCollapsed] = useState(startCollapsed);
  const [mouseDownLoc, setMouseDownLoc] = useState<
    { x: number; y: number } | undefined
  >({ x: 0, y: 0 });
  const [hideReplies, setHideReplies] = useState(true);

  const messageHTML =
    messageContent.type === "messageHtml"
      ? messageContent.messageHtml
      : convertPlainTextToHtml(messageContent.messageText);

  let showToggleViewReplyButton = false;
  let shortenedMessage = messageHTML;
  if (hideReplies) {
    const { processedContent, showToggleViewReplyButton: showToggleViewReply } =
      cutOutRepliesFromMessageContent(shortenedMessage);
    showToggleViewReplyButton = showToggleViewReply;
    shortenedMessage = processedContent;
  } else {
    showToggleViewReplyButton = true;
  }

  // Our emails need to be in a shadow dom to prevent style pollution.
  // This shadow dom needs to be imperitave because <template shadowrootmode="open"> doesn't seem to work in react.
  // We also take extra care to clear / refill the shadow dom when the contents change.
  const emailShadowDomRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let shadowDom;
    if (emailShadowDomRef.current) {
      if (emailShadowDomRef.current.shadowRoot) {
        shadowDom = emailShadowDomRef.current.shadowRoot;
      } else {
        shadowDom = emailShadowDomRef.current.attachShadow({
          mode: "open",
        });
        adoptSheet(
          shadowDom,
          `
          p {
            margin-block-start: 0;
            margin-block-end: 0;
          }

          img {
            max-width: 100%;
          }
        `,
        );
      }

      const style = {
        all: "initial",
        fontFamily: "Inter",
        fontSize: "var(--redo-body-small-text-size)",
        lineHeight: "var(--redo-line-height)",
        fontWeight: "var(--redo-font-weight-regular)",
        color: "var(--redo-colors-text-text-primary-900)",
      };
      for (const key of Object.keys(style)) {
        emailShadowDomRef.current.style[key as keyof typeof style] =
          style[key as keyof typeof style];
      }

      emailShadowDomRef.current.style.width = "fit-content";
      emailShadowDomRef.current.style.height = "fit-content";

      const rootElement = document.createElement("div");
      rootElement.innerHTML = shortenedMessage;
      shadowDom.appendChild(rootElement);
    }
    return () => {
      if (emailShadowDomRef?.current?.shadowRoot) {
        emailShadowDomRef.current.shadowRoot.innerHTML = "";
      }
    };
  }, [shortenedMessage, collapsed]);

  type VaryingByMessageTypeProps = {
    avatarGroup: {
      headerColor: string;
      subtlerColor: string;
    };
  };

  /* Most things are covered by css selectors of these classNames, but some things need to be passed as props to arbiter components. */
  const varyingByMessageTypePropsMap = {
    [emailMessageCss.internal]: {
      avatarGroup: {
        headerColor: "var(--redo-colors-text-text-primary-900) !important",
        subtlerColor: "var(--redo-colors-text-text-tertiary-600) !important",
      },
    },
    [emailMessageCss.merchant]: {
      avatarGroup: {
        headerColor: "var(--redo-colors-text-text-primary-900) !important",
        subtlerColor: "var(--redo-colors-text-text-tertiary-600) !important",
      },
    },
    [emailMessageCss.normal]: {
      avatarGroup: {
        headerColor: "var(--redo-colors-text-text-primary-900) !important",
        subtlerColor: "var(--redo-colors-text-text-primary-900) !important",
      },
    },
  };
  const messageVariantClassname =
    visibility === MessageVisibility.INTERNAL
      ? emailMessageCss.internal
      : isUser
        ? emailMessageCss.merchant
        : emailMessageCss.normal;

  const messageVariantProps = varyingByMessageTypePropsMap[
    messageVariantClassname as keyof typeof varyingByMessageTypePropsMap
  ] as VaryingByMessageTypeProps;

  // TODO: Do more than just use gravatar. We may want a way for support reps to upload profile photos,
  // and we may want something to get better photos for customers as well.
  let senderImage = useMemo(() => {
    const senderEmail =
      message.type === "merchant" || message.type === "system"
        ? message.user?.email
        : message.customer.email;
    if (senderEmail) {
      return Gravatar({
        email: senderEmail,
        defaultImage: "404",
        protocol: location.protocol === "https:" ? "https" : undefined,
        size: Math.ceil(devicePixelRatio * 32),
      });
    } else return null;
  }, [devicePixelRatio, message]);
  if (message.type === "customer") {
    if (message.customer === conversation.customer.customer) {
      if (conversation.customer?.image?.url) {
        senderImage = conversation.customer.image.url;
      }

      if (
        [
          ConversationPlatform.INSTAGRAM,
          ConversationPlatform.INSTAGRAM_COMMENTS,
        ].includes(conversation.platform) &&
        conversation.customer?.instagram?.profilePic
      ) {
        senderImage = conversation.customer.instagram.profilePic;
      } else if (
        conversation.platform === ConversationPlatform.FACEBOOK &&
        conversation.customer?.facebook?.profilePic
      ) {
        senderImage = conversation.customer.facebook.profilePic;
      }
    } else if (profilePictures && profilePictures[message.customer]) {
      senderImage = profilePictures[message.customer];
    }
  }

  const shouldShowReplyButtons =
    visibility !== MessageVisibility.INTERNAL &&
    replyDraft.status === "noDraft";

  const replyButtons = (
    <Flex gap="xl" pl="6xl">
      {shouldShowReplyButton && (
        <RedoButton
          hierarchy="Secondary"
          icon={<CornerUpLeftSvg />}
          onClick={() => {
            amplitude.logEvent("select-supportTicketEmailReply", {
              conversationId: conversation._id,
              channel: conversation.platform,
            });
            setReplyDraft(getInitialDraft("Reply"));
          }}
          size="regular"
          text="Reply"
        />
      )}

      {shouldShowReplyAllButton && (
        <RedoButton
          hierarchy="Secondary"
          icon={(props: any) => <CornerUpLeftDoubleSvg />}
          onClick={() => {
            amplitude.logEvent("select-supportTicketEmailReplyAll", {
              conversationId: conversation._id,
              channel: conversation.platform,
            });
            setReplyDraft(getInitialDraft("Reply All"));
          }}
          size="regular"
          text="Reply All"
        />
      )}

      <RedoButton
        hierarchy="Secondary"
        icon={<CornerUpRightSvg />}
        onClick={() => {
          amplitude.logEvent("select-supportTicketEmailForward", {
            conversationId: conversation._id,
            channel: conversation.platform,
          });
          setReplyDraft(getInitialDraft("Forward"));
        }}
        size="regular"
        text="Forward"
      />
    </Flex>
  );

  return (
    <Flex
      dir="column"
      {...(!collapsed ? { pb: "xl" } : {})}
      className={classNames(
        emailMessageCss.emailContainer,
        messageVariantClassname,
        {
          [emailMessageCss.collapsed]: collapsed,
        },
      )}
    >
      <Flex
        align="center"
        className={classNames(
          emailMessageCss.emailHeader,
          messageVariantClassname,
        )}
        gap="4xl"
        justify="space-between"
        onMouseDown={(e) => {
          setMouseDownLoc({ x: e.clientX, y: e.clientY });
        }}
        onMouseUp={(e) => {
          if (
            mouseDownLoc &&
            Math.abs(e.clientX - mouseDownLoc?.x) < 5 &&
            Math.abs(e.clientY - mouseDownLoc?.y) < 5
          ) {
            setCollapsed(!collapsed);
          }
        }}
        p="xl"
      >
        <AvatarLabelGroup
          avatar={(p) => (
            <Avatar
              alt="avatar image"
              imageUrl={senderImage || "404"}
              name={username}
            />
          )}
          infoRow={
            <>
              {visibility == MessageVisibility.INTERNAL ? (
                <RedoBadge
                  color="warning"
                  size="xs"
                  text="Internal note"
                  type="badgeColor"
                />
              ) : collapsed ? (
                <div className={emailMessageCss.infoRowBoundedContent}>
                  <Text
                    color={messageVariantProps.avatarGroup.subtlerColor}
                    fontSize="xs"
                    pb="xxs"
                    pt="xxs"
                  >
                    <div className={emailMessageCss.emailShortenedSummary}>
                      {message.content}
                    </div>
                  </Text>
                </div>
              ) : (
                <>
                  <CustomTooltip
                    placement="bottom-end"
                    title={
                      <EmailAddressesTooltip
                        bcc={bcc}
                        cc={cc}
                        date={date}
                        from={from ?? { email: "", name: "" }}
                        subject={subject}
                        to={to}
                      />
                    }
                  >
                    <Flex
                      align="center"
                      className={classNames(
                        emailMessageCss.emailInfoIcon,
                        messageVariantClassname,
                      )}
                      justify="center"
                      onMouseUp={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <InfoSvg />
                    </Flex>
                  </CustomTooltip>
                  <Text
                    color={messageVariantProps.avatarGroup.subtlerColor}
                    fontSize="xs"
                  >
                    {stringifyEmailRecipientsInfo({
                      to: to,
                      cc: cc,
                      bcc: bcc,
                    })}
                  </Text>
                </>
              )}
            </>
          }
          usernameRow={
            <Flex align="center">
              <Text
                color={messageVariantProps.avatarGroup.headerColor}
                fontSize="sm"
                fontWeight="semibold"
              >
                {username}
              </Text>
              {message.type === "merchant" &&
                message.visibility !== MessageVisibility.INTERNAL && (
                  <RedoBadge size="xs" text="Merchant reply" />
                )}
            </Flex>
          }
        />
        <Flex align="center" justify="flex-end" shrink="0">
          {!!files.length && (
            <RedoBadge
              iconLeading={{ Icon: PaperclipSvg }}
              size="sm"
              text={`${files.length} attachment${files.length > 1 ? "s" : ""}`}
            />
          )}
          <Text
            color={messageVariantProps.avatarGroup.subtlerColor}
            fontSize="xs"
          >
            {date}
          </Text>
        </Flex>
      </Flex>
      {!collapsed && (
        <>
          <Flex className={emailMessageCss.emailContentPaddedContainer}>
            <div ref={emailShadowDomRef} />
          </Flex>
          {!!files.length && (
            <Flex
              className={emailMessageCss.emailContentPaddedContainer}
              dir="row"
              gap="xl"
              wrap="wrap"
            >
              {files.map((file) => (
                <Flex key={`keyforemailattachments${file._id}`} radius="xs">
                  <AttachmentThumbnail
                    description={file.description}
                    mimeType={file.mimeType}
                    url={file.url}
                  />
                </Flex>
              ))}
            </Flex>
          )}
          {showToggleViewReplyButton && (
            <Flex
              align="center"
              className={emailMessageCss.toggleViewRepliesButton}
              justify="flex-start"
            >
              <SubtleButton
                iconSvg={(props) => <DotsIconSvg />}
                onClick={() => setHideReplies(!hideReplies)}
              />
            </Flex>
          )}
          {shouldShowReplyButtons && replyButtons}
          {replyDraft.status === "drafting" && (
            <Flex
              align="flex-start"
              grow={1}
              justify="flex-start"
              ml="6xl"
              mr="6xl"
            >
              <Flex
                className={classNames(
                  emailMessageCss.draftEditor,
                  emailMessageCss.wrappingText,
                )}
                grow={1}
                shrink={0}
              >
                <MessageInput
                  cardListRef={cardListRef}
                  conversation={conversation}
                  emailDraftProps={{
                    draftInfo: replyDraft,
                    handleSetReplyDraft: handleSetReplyDraft,
                  }}
                  messageIdForKey={message._id}
                  setActiveConversation={setActiveConversation}
                  setErrorMessage={setErrorMessage}
                  setShowErrorMessage={setShowErrorMessage}
                  setShowFullCommentThread={setShowFullCommentThread}
                  setTyping={setTyping}
                  showFullCommentThread={showFullCommentThread}
                />
              </Flex>
            </Flex>
          )}
        </>
      )}
    </Flex>
  );
});
