import { LoadState } from "@redotech/react-util/load";
import { Order } from "@redotech/redo-model/order";
import {
  DiscountSettings,
  DiscountType,
} from "@redotech/redo-model/order-discount";
import { Return } from "@redotech/redo-model/return";
import {
  Button,
  ButtonBorder,
  ButtonSize,
  ButtonTheme,
} from "@redotech/redo-web/button";
import { CurrencyContext } from "@redotech/redo-web/currency";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import * as classNames from "classnames";
import { memo, MutableRefObject, useContext, useState } from "react";
import { logPageClick } from "../../../api";
import { ReturnAppSettings } from "../../../app/settings";
import { DiscountResponse } from "../../../utils/discount-code";
import { redirectToWebsiteWithDiscountCode } from "../../../utils/utils";
import * as trackingCss from "../order-tracking.module.css";
import { ProductDeliveryRange } from "../product-delivery-range";
import { calculateDiscountPrice, validDiscount } from "./discount-util";
import { TrackingType } from "./fulfillment";

export const RecommendedProductComponent = memo(
  function RecommendedProductComponent({
    settings,
    order,
    returnData,
    trackingType,
    storeURL,
    selectWindow,
    getDiscountCode,
    discountFlowLoad,
    product,
    index,
    collectionsLoad,
  }: {
    settings: ReturnAppSettings;
    order: Order;
    returnData: Return;
    trackingType: TrackingType;
    storeURL: string;
    selectWindow: MutableRefObject<Window>;
    getDiscountCode(
      discountResponse: DiscountResponse,
    ): Promise<string | undefined>;
    discountFlowLoad: LoadState<DiscountResponse>;
    product: any;
    index: number;
    collectionsLoad: LoadState<any>;
  }) {
    const { formatCurrency } = useContext(CurrencyContext);
    const [isHovered, setIsHovered] = useState(false);

    const getImageUrl = (product: any) => {
      const imageUrl =
        isHovered &&
        product?.altImage?.url &&
        settings?.orderTracking?.upsellProducts?.hoverSecondImage &&
        product?.altImage?.url
          ? new URL(product?.altImage?.url)
          : product?.featuredImage?.url
            ? new URL(product?.featuredImage?.url)
            : null;
      if (!imageUrl) {
        return null;
      }

      imageUrl.searchParams.append("width", "144");
      return imageUrl.toString();
    };
    const getAltText = (product: any) => {
      return isHovered &&
        product?.altImage?.altText &&
        settings?.orderTracking?.upsellProducts?.hoverSecondImage
        ? product?.altImage?.altText
        : product?.featuredImage?.altText;
    };

    return (
      <>
        <hr className={trackingCss.productDivider} />

        <Flex align="center" className={trackingCss.product} key={index}>
          <img
            alt={getAltText(product)}
            className={trackingCss.productImage}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            src={getImageUrl(product)}
          />
          <Flex
            className={trackingCss.productInfo}
            dir="row"
            grow={1}
            justify="space-between"
          >
            <Flex dir="column" flex={1} gap="xs">
              <Text
                className={classNames(
                  trackingCss.thin,
                  trackingCss.darkGrey,
                  trackingCss.productTitle,
                )}
                fontSize="xs"
              >
                {product.title}
              </Text>
              <span>
                {getPriceDetails(
                  product,
                  discountFlowLoad?.value?.settings,
                  collectionsLoad,
                  order,
                  formatCurrency,
                )}
              </span>
              {settings.orderTracking?.upsellProducts
                ?.productEstimateEnabled && (
                <ProductDeliveryRange
                  shippingAddress={order.shopify.shipping_address}
                />
              )}
            </Flex>
            <a
              onClick={async () => {
                void logPageClick({
                  trackableId:
                    trackingType === TrackingType.ORDER
                      ? order._id
                      : returnData?._id,
                  trackableType: trackingType,
                  eventType: "upsell",
                  url:
                    product.onlineStoreUrl ||
                    `${storeURL}/products/${product.handle}`,
                  image: product.featuredImage.url,
                });
                const discountCode = await getDiscountCode(
                  discountFlowLoad?.value,
                );
                redirectToWebsiteWithDiscountCode({
                  utmSource: "redo",
                  utmMedium: "order_tracking",
                  utmTerm: order._id,
                  utmContent: "upsell",
                  settings,
                  window: selectWindow,
                  discountCode: discountCode,
                  selectedProduct: {
                    url:
                      product.onlineStoreUrl ||
                      `${storeURL}/products/${product.handle}`,
                  },
                });
              }}
            >
              <Button
                border={ButtonBorder.LIGHT}
                size={ButtonSize.MICRO}
                theme={ButtonTheme.OUTLINED}
              >
                Add to cart
              </Button>
            </a>
          </Flex>
        </Flex>
      </>
    );
  },
);

const getPriceDetails = (
  product: any,
  discount: any,
  collectionsLoad,
  order: Order,
  formatCurrency: (amount: number) => string,
) => {
  const discountSettings: DiscountSettings = discount?.settings;

  const defaultPriceDisplay = (
    <Text fontWeight="semibold">{formatCurrency(product.price)}</Text>
  );

  if (!validDiscount(discount, order)) {
    return defaultPriceDisplay;
  }

  if (discountSettings?.discountType === DiscountType.FREE_SHIPPING) {
    return defaultPriceDisplay;
  } else if (
    discountSettings?.discountType === DiscountType.ORDER ||
    discountSettings?.discountType === DiscountType.PRODUCT
  ) {
    const { price, discounted } = calculateDiscountPrice(
      product,
      discountSettings,
      collectionsLoad,
    );
    return (
      <span>
        {discounted && (
          <span
            className={classNames(trackingCss.strikethrough, trackingCss.grey)}
          >
            {formatCurrency(product.price)}
          </span>
        )}
        <Text fontWeight="semibold">{formatCurrency(price)}</Text>
      </span>
    );
  }

  return defaultPriceDisplay;
};
