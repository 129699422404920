import { Condition } from "@redotech/redo-model/return-flow/condition";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { TextInput } from "@redotech/redo-web/text-input";
import { ConditionType } from "../../return-flow/condition";

const TEXT_SUBJECT_DISPLAY_LENGTH = 15;

const subjectLineMsg = (fuzzy_match: string) =>
  `Subject line matches: ${
    !fuzzy_match
      ? ""
      : `"${fuzzy_match.substring(0, TEXT_SUBJECT_DISPLAY_LENGTH)}${fuzzy_match.length > TEXT_SUBJECT_DISPLAY_LENGTH ? "..." : ""}"`
  }`;

export const SUBJECT_LINE: ConditionType<string, Condition.SubjectLine> = {
  name: "Subject line",
  description(state) {
    return subjectLineMsg(state);
  },
  Details({ state, setState, disabled }) {
    return (
      <LabeledInput label={subjectLineMsg(state)}>
        <TextInput onChange={setState} value={state} />
      </LabeledInput>
    );
  },
  empty: "",
  fromModel(model) {
    return model.fuzzy_match;
  },
  toModel(state) {
    return { type: Condition.SUBJECT_LINE, fuzzy_match: state };
  },
  valid() {
    return true;
  },
};
