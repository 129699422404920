// extracted by mini-css-extract-plugin
export var alignCenter = "_4j-b";
export var buttonContent = "_4j-0";
export var cardList = "_4j-1";
export var centerContent = "_4j-2";
export var chevron = "_4j-3";
export var closePanelButton = "_4j-h";
export var conversationDetailContainer = "_4j-4";
export var detailWrapper = "_4j-5";
export var dropdownButton = "_4j-c";
export var dropdownTitle = "_4j-7";
export var hidden = "_4j-8";
export var icon = "_4j-9";
export var panel = "_4j-g";
export var portalButtonsWrapper = "_4j-a";
export var tableSummaryContainer = "_4j-6";
export var tableSummaryHeader = "_4j-e";
export var threeDotsButton = "_4j-d";
export var wide = "_4j-f";