import { RedoMerchantClient } from ".";

export type GetSupportAIArgs = {
  type: "conversationReplySuggestion" | "conversationSummary";
  conversationId?: string;
  signal?: AbortSignal;
};

export type GetSupportAiResult = {
  text: string;
  cost: number;
};

export async function getSupportAiResponse(
  client: RedoMerchantClient,
  {
    type,
    conversationId,
    signal,
  }: { type: string; conversationId?: string; signal?: AbortSignal },
): Promise<{ text: string; cost: number }> {
  const response = await client.client.post(
    "support/ai/response",
    {
      type,
      conversationId,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * POST /support/ai/create-subscription
 */
export async function chargeForAiMessages(
  client: RedoMerchantClient,
  {
    billingDetails,
    signal,
  }: {
    billingDetails: {
      aiMessageSuggestionPlanName: string;
      aiMessageSuggestionPricePerMonth: number;
      aiMessageSuggestionOveragePrice: number;
      aiMessageSuggestionTicketsCovered: number;
    };
    signal?: AbortSignal;
  },
) {
  const response = await client.client.post(
    "support/ai/create-subscription",
    {
      billingDetails,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

export async function getSupportAISummary(
  client: RedoMerchantClient,
  { type, conversationId, signal }: GetSupportAIArgs,
): Promise<GetSupportAiResult | "error"> {
  try {
    const response = await client.client.post(
      "support/ai/summary",
      {
        type,
        conversationId,
      },
      {
        headers: client.authorization(),
        signal,
        timeout: 15000,
      },
    );
    return response.data;
  } catch {
    return "error";
  }
}
