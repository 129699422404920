import { memo, useEffect } from "react";
import { Flex } from "../../flex";
import { RedoCheckbox, RedoCheckboxProps } from "./redo-checkbox";

export const RedoCheckboxGroup = memo(function RedoCheckboxGroup({
  parent,
  children,
}: {
  parent: RedoCheckboxProps;
  children: Omit<RedoCheckboxProps, "size">[];
}) {
  function handleParentClicked() {
    if (parent.value === "indeterminate") {
      const newValue = true;
      parent.setValue(newValue);
      children.forEach((child) => !child.disabled && child.setValue(newValue));
    } else {
      const newValue = !parent.value;
      parent.setValue(newValue);
      children.forEach((child) => !child.disabled && child.setValue(newValue));
    }
  }

  const anyChildHasDescription = children.some((child) => child.description);
  const anyChildHasLabel = children.some((child) => child.label);

  const allChecked = children.every((child) => child.value === true);
  const allUnchecked = children.every((child) => child.value === false);

  useEffect(() => {
    if (allChecked) {
      parent.setValue(true);
    } else if (allUnchecked) {
      parent.setValue(false);
    } else {
      parent.setValue("indeterminate");
    }
  }, [allChecked, allUnchecked, parent]);

  return (
    <Flex dir="column">
      <RedoCheckbox {...parent} setValue={handleParentClicked} />
      <Flex dir="column" pl="3xl">
        {children.map((child, idx) => {
          return (
            <RedoCheckbox
              key={idx}
              {...child}
              disabled={parent.disabled || child.disabled}
              showDescriptionSpacer={anyChildHasDescription}
              showLabelSpacer={anyChildHasLabel}
              size={parent.size}
            />
          );
        })}
      </Flex>
    </Flex>
  );
});
