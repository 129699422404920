// extracted by mini-css-extract-plugin
export var brand = "_3d-4";
export var compact = "_3d-7";
export var extraSmall = "_3d-8";
export var icon = "_3d-0";
export var large = "_3d-1";
export var medium = "_3d-2";
export var outline = "_3d-9";
export var primaryDark = "_3d-5";
export var primaryLight = "_3d-3";
export var primaryModern = "_3d-6";