import {
  Button,
  ButtonBorder,
  ButtonSize,
  ButtonTheme,
} from "@redotech/redo-web/button";
import { memo, useMemo } from "react";

import * as returnStatus from "./return-status.module.css";

export const TrackRepairButton = memo(function TrackRepairButton({
  returnData,
}: {
  returnData: any;
}) {
  const repairTracking = useMemo(
    () => returnData?.reShipments?.[0]?._shipment?.tracker?.public_url,
    [returnData],
  );

  if (!repairTracking) {
    return null;
  }

  return (
    <div className={returnStatus.stepButtons}>
      <Button
        border={ButtonBorder.LIGHT}
        onClick={() => window.open(repairTracking, "_blank")}
        size={ButtonSize.MICRO}
        theme={ButtonTheme.OUTLINED}
      >
        Track Repair
      </Button>
    </div>
  );
});
