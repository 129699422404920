// extracted by mini-css-extract-plugin
export var active = "_6p-b";
export var arrow = "_6p-8";
export var arrowContainer = "_6p-4";
export var container = "_6p-0";
export var disabled = "_6p-5";
export var dot = "_6p-a";
export var dotContainer = "_6p-9";
export var leftArrowContainer = "_6p-6 _6p-4";
export var rightArrowContainer = "_6p-7 _6p-4";
export var slide = "_6p-3";
export var sliderContainer = "_6p-1";
export var sliderOverflow = "_6p-2";