import { getResource } from "@redotech/redo-model/localization/resource";
import { ReturnTotals } from "@redotech/redo-model/return-totals-calculator";
import { Card } from "@redotech/redo-web/card";
import { CurrencyContext } from "@redotech/redo-web/currency";
import Logo from "@redotech/redo-web/icon-old/logo-icon.svg";
import { memo, useContext } from "react";
import { SettingsContext } from "../../app/settings";

import * as review from "../return-flow/review.module.css";
import * as returnStatus from "./return-status.module.css";

export const SummaryCard = memo(function SummaryCard({
  returnData,
  returnTotals,
  newItems,
}: {
  returnData: any;
  returnTotals: ReturnTotals[];
  newItems: any[];
}) {
  const { formatCurrency, formatCurrencyFromUSD } = useContext(CurrencyContext);
  const copyResource = getResource({
    variant: returnData.type,
  });
  const settings = useContext(SettingsContext);

  return (
    <Card>
      <div className={returnStatus.cardHeader}>Summary</div>
      <div className={review.summaryCardItem}>
        <div className={review.summaryText}>{copyResource.FEES_HEADER}</div>
        <div className={review.summaryText}>
          {formatCurrency(
            returnTotals.reduce(
              (acc, totals) => (acc += totals.totalReturnValue),
              0,
            ),
          )}
        </div>
      </div>
      {returnTotals.reduce(
        (acc, totals) => (acc += totals.shippingFeeDeducted),
        0,
      ) > 0 && (
        <div className={review.summaryCardItem}>
          <div className={review.summaryText}>Shipping fee</div>
          <div className={review.summaryText}>
            -
            {formatCurrency(
              returnTotals.reduce(
                (acc, totals) => (acc += totals.shippingFeeDeducted),
                0,
              ),
            )}
          </div>
        </div>
      )}
      {returnTotals.reduce((acc, totals) => (acc += totals.newOrderValue), 0) >
        0 && (
        <div className={review.summaryCardItem}>
          <div className={review.summaryText}>
            {copyResource.FEES_ORDER_HEADER}
          </div>
          <div className={review.summaryText}>
            {formatCurrency(
              returnTotals.reduce(
                (acc, totals) => (acc += totals.newOrderValue),
                0,
              ),
            )}
          </div>
        </div>
      )}
      {/* FIXME Does not include line for Settlement discount, so the refund amount won't add up with the return value */}
      <hr className={review.line} />
      {/* The 2 sections below should never be shown if payment owed > 0 since they are deducted from the amount first. */}
      {returnTotals.reduce(
        (acc, totals) => (acc += totals.totalStoreCredit),
        0,
      ) > 0 && (
        <div className={review.summaryCardItem}>
          <div className={review.summaryTextBold}>Store Credit</div>
          <div className={review.summaryText}>
            {formatCurrency(
              returnTotals.reduce(
                (acc, totals) => (acc += totals.totalStoreCredit),
                0,
              ),
            )}
          </div>
        </div>
      )}
      {returnTotals.reduce((acc, totals) => (acc += totals.totalRefund), 0) >
        0 && (
        <div className={review.summaryCardItem}>
          <div className={review.summaryTextBold}>Refund</div>
          <div className={review.summaryText}>
            {formatCurrency(
              returnTotals.reduce(
                (acc, totals) => (acc += totals.totalRefund),
                0,
              ),
            )}
          </div>
        </div>
      )}
      {returnTotals.reduce((acc, totals) => (acc += totals.displayCharge), 0) >
        0 && (
        <div className={review.summaryCardItem} style={{ marginTop: "12px" }}>
          <div className={review.summaryTextBold}>Payment</div>
          <div className={review.summaryText}>
            {formatCurrency(
              returnTotals.reduce(
                (acc, totals) => (acc += totals.displayCharge),
                0,
              ),
            )}
          </div>
        </div>
      )}
      {!(
        returnTotals.reduce(
          (acc, totals) => (acc += totals.shippingFeeDeducted),
          0,
        ) > 0 &&
        returnTotals.reduce((acc, totals) => (acc += totals.shippingFee), 0) ===
          0
      ) && (
        <div>
          <div className={review.summaryCardItem}>
            <div className={review.summaryTextBold}>
              {settings.claimShippingLineItemText || `Shipping fee`}
            </div>
            {returnTotals.reduce(
              (acc, totals) => (acc += totals.shippingFee),
              0,
            ) === 0 ? (
              <div className={review.flex}>
                {!settings?.hidePortalBranding && (
                  <div className={review.brand}>
                    <Logo className={review.logo} />
                  </div>
                )}
                <div className={review.summaryText}>
                  Free
                  {returnData.inStoreReturn && " (returning In-Store)"}
                </div>
              </div>
            ) : (
              // this one
              <div>
                {formatCurrencyFromUSD(
                  returnTotals.reduce(
                    (acc, totals) => (acc += totals.shippingFee),
                    0,
                  ),
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {!!returnData.totals.repair && (
        <div>
          <div className={review.summaryCardItem}>
            <div className={review.summaryText}>Repair fee</div>
            <div>{formatCurrencyFromUSD(returnData.totals.repair)}</div>
          </div>
        </div>
      )}
      {returnData.giftCards.length > 0 && (
        <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <div className={review.summaryTextBold}>Gift card code(s)</div>
          {returnData.giftCards.map((giftCard, i) => (
            <div className={returnStatus.giftCard} key={i}>
              {giftCard.code}
            </div>
          ))}
        </div>
      )}
      {returnData.storedCredits.length > 0 && (
        <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <div className={review.summaryTextBold}>Discount code(s)</div>
          {returnData.storedCredits.map((storedCredits, i) => (
            <div className={returnStatus.giftCard} key={i}>
              {storedCredits.code}
            </div>
          ))}
        </div>
      )}
    </Card>
  );
});
