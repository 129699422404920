import Fuse from "fuse.js";
import { useEffect, useState } from "react";

export function useSearch<DOC>(
  searcher: Fuse<DOC>,
  allDocs: DOC[] | undefined,
  searchText: string,
): DOC[] {
  const [searchResults, setSearchResults] = useState<DOC[]>([]);
  useEffect(() => {
    searcher.setCollection(allDocs || []);
  }, [allDocs, searcher]);
  useEffect(() => {
    if (searchText) {
      setSearchResults(
        searcher.search(searchText).map((result) => result.item),
      );
    } else {
      setSearchResults(allDocs || []);
    }
  }, [searchText, allDocs, searcher]);

  return searchResults;
}
