import { useRequiredContext } from "@redotech/react-util/context";
import { Fulfillment, Order } from "@redotech/redo-model/order";
import { RedoClientContext } from "@redotech/redo-web/client";
import { ExternalLink } from "@redotech/redo-web/link";
import { Text } from "@redotech/redo-web/text";
import { newWindow } from "@redotech/web-util/window";
import { memo } from "react";
import { TeamContext } from "../../../app/team";
import { getCustomerPortalLink } from "../../../order/utils/get-customer-portal-link";
import * as orderDetailsCss from "./order-details.module.css";

export const OrderDetailsTrackingInformation = memo(
  function TrackingInformation({
    order,
    fulfillment,
    index,
  }: {
    order: Order;
    fulfillment: Fulfillment;
    index: number;
  }) {
    const team = useRequiredContext(TeamContext);
    const apiClient = useRequiredContext(RedoClientContext);

    function handleOrderDetailsLinkClicked(
      event: React.MouseEvent,
      order: Order,
      index: number,
    ) {
      event.stopPropagation();
      newWindow(async () => {
        return getCustomerPortalLink(apiClient, team, order, index);
      });
    }

    const orderTrackingEnabled = !!team.settings.orderTracking?.enabled;

    if (orderTrackingEnabled) {
      return (
        <div className={orderDetailsCss.detailColumn}>
          <Text fontSize="xxs" fontWeight="medium" textColor="primary">
            <ExternalLink
              onClick={(clickEvent) => {
                handleOrderDetailsLinkClicked(clickEvent, order, index);
              }}
            >
              Tracking information
            </ExternalLink>
          </Text>
        </div>
      );
    } else {
      return (
        <>
          {!!fulfillment.tracking_numbers?.length && (
            <div className={orderDetailsCss.detailColumn}>
              {fulfillment.tracking_numbers.map((trackingNumber, index) => {
                return fulfillment.tracking_urls[index] ? (
                  <div className={orderDetailsCss.copyableField}>
                    <Text
                      fontSize="xxs"
                      fontWeight="medium"
                      textColor="primary"
                    >
                      <ExternalLink url={fulfillment.tracking_urls[index]}>
                        {trackingNumber}
                      </ExternalLink>
                    </Text>
                  </div>
                ) : (
                  <h2 className={orderDetailsCss.strong}>{trackingNumber}</h2>
                );
              })}
            </div>
          )}
        </>
      );
    }
  },
);
