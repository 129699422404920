// extracted by mini-css-extract-plugin
export var acitonButtons = "_5c-0";
export var block = "_5c-1";
export var blockContent = "_5c-7";
export var blockDescription = "_5c-8";
export var blockIcon = "_5c-9";
export var blockTitle = "_5c-6";
export var border = "_5c-a";
export var center = "_5c-k";
export var edge = "_5c-b";
export var edgeLabel = "_5c-c";
export var edgePath = "_5c-d";
export var error = "_5c-e";
export var flowchart = "_5c-f";
export var flowchartContent = "_5c-g";
export var highlighted = "_5c-3";
export var hover = "_5c-4";
export var icon = "_5c-h";
export var left = "_5c-l";
export var navButton = "_5c-i";
export var navButtons = "_5c-j";
export var right = "_5c-m";
export var selected = "_5c-2";
export var small = "_5c-5";