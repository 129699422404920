import * as amplitude from "@amplitude/analytics-browser";
import { Currency } from "@redotech/money/currencies";
import { useRequiredContext } from "@redotech/react-util/context";
import { useTriggerLoad } from "@redotech/react-util/load";
import {
  getCalculatedRefundValue,
  refundOrder,
} from "@redotech/redo-merchant-app/client/shopify";
import { Order } from "@redotech/redo-model/order";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { Checkbox } from "@redotech/redo-web/checkbox";
import { Divider } from "@redotech/redo-web/divider";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { Modal, ModalSize, PaddingAmount } from "@redotech/redo-web/modal";
import { OrderLineItem } from "@redotech/redo-web/order-line-item";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import { ShippingContactInfo } from "@redotech/redo-web/shipping-contact-info";
import { TextInput } from "@redotech/redo-web/text-input";
import { memo, useEffect, useState } from "react";
import { RedoMerchantClientContext } from "../../client/context";
import * as refundOrderModalCss from "./refund-order-modal.module.css";

export const RefundOrderModal = memo(function RefundOrderModal({
  open,
  setOpen,
  order,
  onOrdersChange,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  order: Order;
  onOrdersChange: () => void;
}) {
  const [pending, setPending] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [notifyCustomer, setNotifyCustomer] = useState(true);
  const refundCalculationOptions = ["Amount", "Percentage"];
  const [refundCalculationType, setRefundCalculationType] = useState(
    refundCalculationOptions[0],
  );
  const [refundAmount, setRefundAmount] = useState("0");
  const [refundPercentage, setRefundPercentage] = useState("0");
  const client = useRequiredContext(RedoMerchantClientContext);
  const lineItems = order.shopify?.line_items;
  const [refundAmountLoad, doRefundAmountLoad] = useTriggerLoad(
    async (signal) => {
      const response = await getCalculatedRefundValue(client, {
        shopifyOrderId: order.shopify.id,
        currency:
          order.shopify.total_price_set?.presentment_money.currency_code,
        signal,
      });
      return response.refundAmount;
    },
  );

  useEffect(() => {
    if (order) {
      doRefundAmountLoad();
    }
  }, [order]);

  const handleClose = () => {
    setConfirmModalOpen(false);
    setOpen(false);
    setPending(false);
  };
  const handleRefund = async () => {
    setPending(true);
    const amount =
      refundCalculationType === "Amount"
        ? Number(refundAmount)
        : (Number(refundPercentage) / 100) * refundAmountLoad.value;
    await refundOrder(client, {
      orderId: order._id,
      notify: notifyCustomer,
      amount,
    });
    amplitude.logEvent("refund-order", { shopifyOrderId: order.shopify_id });
    handleClose();
    onOrdersChange();
  };
  const handleFirstConfirmClick = () => {
    setConfirmModalOpen(true);
  };
  const Footer = memo(function Footer() {
    return (
      <div className={refundOrderModalCss.actionButtonsContainer}>
        <Button onClick={handleClose} theme={ButtonTheme.OUTLINED}>
          No, don't refund order
        </Button>
        {refundAmountLoad.value > 0 && (
          <Button
            disabled={
              (refundCalculationType == "Amount" &&
                (Number(refundAmount) < 0 ||
                  Number(refundAmount) > refundAmountLoad.value)) ||
              (refundCalculationType === "Percentage" &&
                (Number(refundPercentage) < 0 ||
                  Number(refundPercentage) > 100))
            }
            onClick={() => handleFirstConfirmClick()}
            theme={ButtonTheme.DANGER}
          >
            Yes, refund order
          </Button>
        )}
      </div>
    );
  });
  return (
    <>
      <Modal
        footer={refundAmountLoad.pending ? undefined : <Footer />}
        onClose={handleClose}
        open={open}
        paddingAmount={PaddingAmount.NONE}
        showFooterBorder
        size={ModalSize.MEDIUM}
        title="Refund order"
      >
        {refundAmountLoad.pending ? (
          <div className={refundOrderModalCss.animationContainer}>
            <LoadingRedoAnimation />
          </div>
        ) : (
          <div className={refundOrderModalCss.modalContent}>
            <div className={refundOrderModalCss.orderName}>
              Order {order.shopify.name}
            </div>
            {lineItems.map((lineItem, index) => (
              <OrderLineItem index={index} key={index} lineItem={lineItem} />
            ))}
            <ShippingContactInfo order={order} />
            <Divider />
            {refundAmountLoad.value > 0 && (
              <Checkbox onChange={setNotifyCustomer} value={notifyCustomer}>
                Notify customer
              </Checkbox>
            )}
            <div className={refundOrderModalCss.refundOptionsContainer}>
              <div className={refundOrderModalCss.refundOption}>
                <LabeledInput label="Refund type">
                  <SelectDropdown
                    options={refundCalculationOptions}
                    value={refundCalculationType}
                    valueChange={setRefundCalculationType}
                  >
                    {(option) => (
                      <div>
                        <p>{option.toString()}</p>
                      </div>
                    )}
                  </SelectDropdown>
                </LabeledInput>
              </div>
              {refundCalculationType === "Amount" && (
                <div className={refundOrderModalCss.refundOption}>
                  <LabeledInput
                    description={
                      refundAmountLoad.value > 0
                        ? `Total available to refund: ${
                            order.shopify.total_price_set?.presentment_money
                              .currency_code === Currency.USD
                              ? "$"
                              : ""
                          }${refundAmountLoad.value.toFixed(2)} ${
                            order.shopify.total_price_set?.presentment_money
                              .currency_code
                          }`
                        : ""
                    }
                    label="Refund amount"
                  >
                    <TextInput
                      error={
                        Number(refundAmount) > refundAmountLoad.value ||
                        Number(refundAmount) < 0
                      }
                      max={refundAmountLoad.value}
                      min={0}
                      onChange={setRefundAmount}
                      prefix="$"
                      type="number"
                      value={refundAmount}
                    />
                  </LabeledInput>
                </div>
              )}
              {refundCalculationType === "Percentage" && (
                <div className={refundOrderModalCss.refundOption}>
                  <LabeledInput
                    description={
                      refundAmountLoad.value > 0
                        ? `Amount to refund: ${
                            order.shopify.total_price_set?.presentment_money
                              .currency_code === Currency.USD
                              ? "$"
                              : ""
                          }${(
                            refundAmountLoad.value *
                            (Number(refundPercentage) / 100)
                          ).toFixed(2)} ${
                            order.shopify.total_price_set?.presentment_money
                              .currency_code
                          }`
                        : ""
                    }
                    label="Refund percent"
                  >
                    <TextInput
                      error={
                        Number(refundPercentage) > 100 ||
                        Number(refundPercentage) < 0
                      }
                      max={100}
                      min={0}
                      onChange={setRefundPercentage}
                      step={1}
                      suffix="%"
                      type="number"
                      value={refundPercentage}
                    />
                  </LabeledInput>
                </div>
              )}
            </div>
          </div>
        )}
      </Modal>
      <Modal
        onClose={() => setConfirmModalOpen(false)}
        open={confirmModalOpen}
        size={ModalSize.SMALL}
        title="Are you sure you want to refund this order?"
      >
        <>
          <Button
            onClick={handleRefund}
            pending={pending}
            theme={ButtonTheme.DANGER}
          >
            Yes, refund order
          </Button>
          <Button onClick={handleClose} theme={ButtonTheme.OUTLINED}>
            No, don't refund order
          </Button>
        </>
      </Modal>
    </>
  );
});
