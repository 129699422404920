import { Flex } from "@redotech/redo-web/flex";
import CopyIcon from "@redotech/redo-web/icon-old/copy.svg";
import DownArrowIcon from "@redotech/redo-web/icon-old/down-arrow.svg";
import TrashIcon from "@redotech/redo-web/icon-old/trash2.svg";
import UpArrowIcon from "@redotech/redo-web/icon-old/up-arrow.svg";
import * as classNames from "classnames";
import { Dispatch, MouseEvent, SetStateAction, memo } from "react";
import * as sectionToolbarCss from "./section-toolbar.module.css";

enum Direction {
  UP = "up",
  DOWN = "down",
}

export const SectionToolbar = <T extends { duplicate: () => T }>({
  open,
  index,
  totalSections,
  setSections,
  setSelected,
  setModified,
  canDuplicate = true,
}: {
  open: boolean;
  index: number;
  totalSections: number;
  setSections: Dispatch<SetStateAction<Map<string, T>>>;
  setSelected: (value: string | undefined) => void;
  setModified: Dispatch<SetStateAction<boolean>>;
  canDuplicate?: boolean;
}) => {
  if (!open) {
    return null;
  }
  const removeSection = (index: number) => {
    setSections((sections) => {
      const newSections = new Map<string, T>();

      // Iterate over existing sections and adjust their indices
      sections.forEach((section, key) => {
        const currentIndex = parseInt(key);
        if (currentIndex > index) {
          newSections.set((currentIndex - 1).toString(), section);
        } else if (currentIndex !== index) {
          newSections.set(key, section);
        }
      });

      setSelected(undefined);
      setModified(true);
      return newSections;
    });
  };

  const duplicateSection = (index: number) => {
    setSections((sections) => {
      const newSections = new Map<string, T>();

      // Iterate over existing sections and adjust their indices
      sections.forEach((section, key) => {
        const currentIndex = parseInt(key);
        if (currentIndex > index) {
          newSections.set((currentIndex + 1).toString(), section);
        } else if (currentIndex === index) {
          newSections.set(key, section);
          newSections.set((currentIndex + 1).toString(), section.duplicate());
        } else {
          newSections.set(key, section);
        }
      });
      setSelected((index + 1).toString());
      setModified(true);
      return newSections;
    });
  };

  const moveSection = (index: number, direction: Direction) => {
    setSections((sections) => {
      const newSections = new Map<string, T>();

      // Iterate over existing sections and adjust their indices
      let sectionToSwap = null;
      sections.forEach((section, key) => {
        if (parseInt(key) === index - 1 && direction === Direction.UP) {
          sectionToSwap = section;
        } else if (parseInt(key) === index) {
          if (direction === Direction.UP) {
            newSections.set((index - 1).toString(), section);
            newSections.set(index.toString(), sectionToSwap!);
          } else {
            sectionToSwap = section;
          }
        } else if (
          parseInt(key) === index + 1 &&
          direction === Direction.DOWN
        ) {
          newSections.set(index.toString(), section);
          newSections.set((index + 1).toString(), sectionToSwap!);
        } else {
          newSections.set(key, section);
        }
      });

      switch (direction) {
        case Direction.UP:
          setSelected((index - 1).toString());
          break;
        case Direction.DOWN:
          setSelected((index + 1).toString());
          break;
      }
      setModified(true);
      return newSections;
    });
  };

  const canDelete = totalSections > 1;
  const canMoveDown = index < totalSections - 1;
  const canMoveUp = index > 0;
  const editOptions = [];
  if (canDuplicate) {
    editOptions.push({
      helperText: "Duplicate",
      icon: <CopyIcon style={{ width: "20px", height: "20px" }} />,
      onClick: (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        duplicateSection(index);
      },
    });
  }
  if (canDelete) {
    editOptions.push({
      helperText: "Delete",
      icon: <TrashIcon />,
      onClick: (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        removeSection(index);
      },
    });
  }
  if (canMoveUp) {
    editOptions.push({
      helperText: "Move up",
      icon: <UpArrowIcon />,
      onClick: (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        moveSection(index, Direction.UP);
      },
    });
  }
  if (canMoveDown) {
    editOptions.push({
      helperText: "Move down",
      icon: <DownArrowIcon />,
      onClick: (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        moveSection(index, Direction.DOWN);
      },
    });
  }
  if (!editOptions.length) {
    return null;
  }

  return (
    <Flex
      className={classNames(sectionToolbarCss.sectionToolbar)}
      dir="column"
      gap="none"
    >
      {editOptions.map((option, index) => (
        <Flex
          align="center"
          className={sectionToolbarCss.toolbarOption}
          justify="center"
          key={index}
          onMouseDown={option.onClick}
        >
          {option.icon}
        </Flex>
      ))}
    </Flex>
  );
};

export default memo(SectionToolbar) as typeof SectionToolbar;
