export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export type NullishPartial<T> = {
  [P in keyof T]?: T[P] | null;
};

export type Optional<T> = T | undefined | null;

export function isString(v: unknown): v is string {
  return typeof v === "string";
}

export function assertNever<T>(value: never): T {
  throw new Error(`Unexpected value: ${value}`);
}

export function isObject(v: unknown): v is Record<string, unknown> {
  return typeof v === "object" && v !== null;
}
