import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { ConversationMessages } from "@redotech/redo-merchant-app/support/conversation-messages";
import { MessageInput } from "@redotech/redo-merchant-app/support/message-input";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { Dispatch, memo, SetStateAction, useState } from "react";
import { ConversationEmailMessagesView } from "./conversation-email-view/conversation-email-messages-view";
import { ConversationHeader } from "./conversation-header";

export const ConversationContent = memo(function ConversationContent({
  conversation,
  setActiveConversation,
  actionButtons,
  leftPanelOpen,
  rightPanelOpen,
  setLeftPanelOpen,
  setRightPanelOpen,
  cardListRef,
}: {
  conversation: ExpandedConversation;
  setActiveConversation: (
    conversation: ExpandedConversation | undefined,
  ) => void;
  actionButtons: React.ReactNode;
  leftPanelOpen: boolean;
  rightPanelOpen: boolean;
  setLeftPanelOpen: Dispatch<SetStateAction<boolean>>;
  setRightPanelOpen: Dispatch<SetStateAction<boolean>>;
  cardListRef?: any;
}) {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [typing, setTyping] = useState<Record<string, Date>>({});
  const [showFullCommentThread, setShowFullCommentThread] = useState(true);

  return (
    <>
      <ConversationHeader
        actionButtons={actionButtons}
        conversation={conversation}
        leftPanelOpen={leftPanelOpen}
        rightPanelOpen={rightPanelOpen}
        setActiveConversation={setActiveConversation}
        setLeftPanelOpen={setLeftPanelOpen}
        setRightPanelOpen={setRightPanelOpen}
      />
      {conversation?.platform === "email" ? (
        <ConversationEmailMessagesView
          cardListRef={cardListRef}
          conversation={conversation}
          setActiveConversation={setActiveConversation}
          setErrorMessage={setErrorMessage}
          setLeftPanelOpen={setLeftPanelOpen}
          setShowErrorMessage={setShowErrorMessage}
          setShowFullCommentThread={setShowFullCommentThread}
          setTyping={setTyping}
          showFullCommentThread={showFullCommentThread}
          typing={typing}
        />
      ) : (
        <ConversationMessages
          conversation={conversation}
          leftPanelOpen={leftPanelOpen}
          setActiveConversation={setActiveConversation}
          setErrorMessage={setErrorMessage}
          setLeftPanelOpen={setLeftPanelOpen}
          setShowErrorMessage={setShowErrorMessage}
          showFullCommentThread={showFullCommentThread}
          typing={typing}
        />
      )}
      <MessageInput
        cardListRef={cardListRef}
        conversation={conversation}
        rightPanelOpen={rightPanelOpen}
        setActiveConversation={setActiveConversation}
        setErrorMessage={setErrorMessage}
        setShowErrorMessage={setShowErrorMessage}
        setShowFullCommentThread={setShowFullCommentThread}
        setTyping={setTyping}
        showFullCommentThread={showFullCommentThread}
      />
      {showErrorMessage && (
        <Snackbar
          autoHideDuration={10000}
          onClose={() => setShowErrorMessage(false)}
          open={showErrorMessage}
        >
          <Alert onClose={() => setShowErrorMessage(false)} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
});
