import { ZodError } from "zod";
import {
  InferRpcDefinition,
  RpcClientDefinition,
  RpcDefinition,
} from "./definition";
import { ClientError } from "./errors";
import { dehydrate, hydrate } from "./wire-protocol";

export type RpcClient<D extends RpcClientDefinition> = {
  [key in keyof D]: (
    input: D[key]["input"],
    signal?: AbortSignal,
  ) => Promise<D[key]["output"]>;
};

export type ClientOptions<D extends RpcDefinition> = {
  baseURL: URL;
  headers?: Record<string, string>;
  onError?: (rpcName: keyof D, error: unknown) => Promise<void> | void;
};

export function createRpcClient<D extends RpcDefinition>(
  def: D,
  { baseURL, headers, onError }: ClientOptions<D>,
) {
  const client = {} as RpcClient<InferRpcDefinition<D>>;
  for (const key in def) {
    const rpc = def[key];
    if (!rpc) {
      continue;
    }
    const url = new URL(
      `${baseURL.pathname.replace(/\/$/, "")}/${key}`,
      baseURL,
    );

    client[key] = async (params: unknown, signal?: AbortSignal) => {
      try {
        let input;
        try {
          const zod = rpc.input.safeParse(params);
          if (!zod.success) {
            throw zod.error;
          }
          input = dehydrate({ input: zod.data });
        } catch (error) {
          throw new Error(
            `RPC Input Validation Error ${key}: ${
              error instanceof ZodError
                ? error.format()
                : error instanceof Error
                  ? error.message
                  : error
            }`,
          );
        }

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...headers,
          },
          body: JSON.stringify(input),
          signal,
        });
        if (!response.ok) {
          throw response;
        }
        const json: any = await response.json();

        let output;
        try {
          const hydratedOutput = hydrate(json.output);
          const zod = rpc.output.safeParse(hydratedOutput);
          if (!zod.success) {
            throw zod.error;
          }
          output = zod.data;
        } catch (error) {
          throw new Error(
            `RPC Output Validation Error ${key}: ${
              error instanceof ZodError
                ? error.format()
                : error instanceof Error
                  ? error.message
                  : error
            }`,
          );
        }

        return output;
      } catch (error) {
        await onError?.(key, error);

        if (error instanceof Response) {
          let message = "Unknown error";
          if (error.headers.get("Content-Type")?.includes("application/json")) {
            const json: any = await error.json();
            message = json.error || json.message || message;
          } else {
            message = await error.text();
          }
          throw new ClientError(error.status, message || "Unknown error");
        } else if (error instanceof ZodError) {
          throw new Error(`RPC Validation Error ${key}: ${error.format()}`);
        }

        throw error;
      }
    };
  }
  return client;
}
