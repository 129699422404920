// extracted by mini-css-extract-plugin
export var colorSquare = "_6y-0";
export var danger = "_6y-5";
export var lightPink = "_6y-8";
export var neutralBlue = "_6y-7";
export var neutralYellow = "_6y-6";
export var normal = "_6y-1";
export var primary = "_6y-2";
export var primaryLight = "_6y-3";
export var solidBlack = "_6y-g";
export var solidGreen = "_6y-b";
export var solidLightBlue = "_6y-d";
export var solidNavy = "_6y-c";
export var solidOrange = "_6y-f";
export var solidPink = "_6y-e";
export var solidRed = "_6y-9";
export var solidYellow = "_6y-a";
export var success = "_6y-4";