import { useRequiredContext } from "@redotech/react-util/context";
import { ConversationTagId } from "@redotech/redo-model/conversation";
import {
  RedoModal,
  RedoModalTheme,
} from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import TrashIcon from "@redotech/redo-web/icon-old/trash.svg";
import { memo, useContext } from "react";
import { ReloadConversationTagsContext } from "../../app/conversation-tags-context";
import { RedoMerchantClientContext } from "../../client/context";
import { deleteConversationTag } from "../../client/conversation-tags";
import { getConversation } from "../../client/conversations";
import { ActiveConversationContext } from "../context/active-conversation-context";

export const DeleteConversationTagModal = memo(
  function DeleteConversationTagModal({
    tag,
    cancelled,
    deleted,
  }: {
    tag: ConversationTagId;
    cancelled(): void;
    deleted(): void;
  }) {
    const client = useRequiredContext(RedoMerchantClientContext);
    const { setActiveConversation, activeConversation } = useContext(
      ActiveConversationContext,
    );

    const refreshConversationTags = useContext(ReloadConversationTagsContext);

    async function handleDelete() {
      await deleteConversationTag(client, tag.tagId, async () => {
        refreshConversationTags();
        if (activeConversation) {
          const updatedConversation = await getConversation(client, {
            conversationId: activeConversation._id,
          });
          setActiveConversation(updatedConversation);
        }
      });
      deleted();
    }

    return (
      <RedoModal
        isOpen
        onModalCloseRequested={cancelled}
        primaryButton={{ text: "Delete", onClick: handleDelete }}
        secondaryButton={{ text: "Cancel", onClick: cancelled }}
        subtitle="Are you sure you want to delete this tag? This will remove this tag from all conversations, filters, and views."
        theme={RedoModalTheme.ERROR}
        title="Delete tag"
        TitleIcon={TrashIcon}
      />
    );
  },
);
