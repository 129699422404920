import { Status } from "@redotech/http/semantics";

export class RpcError extends Error {
  constructor(
    readonly status: Status,
    override readonly message: string,
  ) {
    super(message);
  }
}

export class ClientError extends Error {
  constructor(
    readonly statusCode: number,
    override readonly message: string,
  ) {
    super(message);
  }
}
