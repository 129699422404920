import { Return } from "@redotech/redo-model/return";
import ClockRefresh from "@redotech/redo-web/arbiter-icon/clock-refresh_filled.svg";
import { Button, ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo } from "react";
import { openUrl } from "./return-status";
import * as returnStatusCss from "./return-status/return-status.module.css";

export const ExchangeHeader = memo(function ExchangeHeader({
  returnData,
}: {
  returnData: Return;
}) {
  return (
    <Flex align="center" dir="column" gap="lg" pb="xl">
      <Flex
        align="center"
        className={returnStatusCss.iconBackground}
        justify="center"
      >
        <ClockRefresh
          className={returnStatusCss.refreshIcon}
          height={28}
          width={28}
        />
      </Flex>

      <div className={returnStatusCss.exchangeTitle}>
        {" "}
        Your return submission is almost complete
      </div>
      <Text fontSize="md">
        Pay for your exchange to receive your return label and complete your
        submission.
      </Text>
      <Button
        onClick={() => openUrl(returnData.draftOrderURL)}
        radius="md"
        size={ButtonSize.MICRO}
        theme={ButtonTheme.PRIMARY}
      >
        Pay for exchange order
      </Button>
    </Flex>
  );
});
