import ChevronDownSvg from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import ChevronUpSvg from "@redotech/redo-web/arbiter-icon/chevron-up_filled.svg";
import * as classNames from "classnames";
import {
  ButtonHTMLAttributes,
  ForwardedRef,
  forwardRef,
  memo,
  ReactElement,
} from "react";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { SpacingValue } from "../../theme/box";
import { TextSizeValue } from "../../theme/typography";
import * as redoButtonCss from "./redo-button.module.css";

export type RedoButtonSize = "xs" | "small" | "regular";

export type RedoButtonTheme = "Normal" | "Destructive" | "Success";

export type RedoButtonHierarchy = "Primary" | "Secondary" | "Tertiary" | "Link";

export type IconSvgType = ReactElement | ((props?: any) => ReactElement);

/** We infer the type of the button from which icons are passed. */
type RedoButtonIconType = "default" | "only";

/** For a icon type + size, get arbiter props. */
const buttonPropsMap: Record<
  RedoButtonIconType,
  | Record<
      RedoButtonSize,
      | {
          textSize?: TextSizeValue;
          pt?: SpacingValue;
          pr?: SpacingValue;
          pl?: SpacingValue;
          pb?: SpacingValue;
        }
      | undefined
    >
  | undefined
> = {
  only: {
    xs: {
      textSize: "xs",
      pt: "none",
      pr: "none",
      pb: "none",
      pl: "none",
    },
    small: undefined,
    regular: undefined,
  },
  default: {
    xs: undefined,
    small: {
      textSize: "xs",
      pt: "md",
      pr: "lg",
      pb: "md",
      pl: "lg",
    },
    regular: {
      textSize: "sm",
      pt: "md",
      pr: "lg",
      pb: "md",
      pl: "lg",
    },
  },
};

const buttonGapMap: Record<RedoButtonIconType, SpacingValue | undefined> = {
  default: "xs",
  only: undefined,
};

/** Whitelisted button props that the RedoButton supports (add your own if it's missing) */
type RegularButtonPropsSupportedByRedoButton = Partial<
  Pick<
    ButtonHTMLAttributes<HTMLButtonElement>,
    "onClick" | "onMouseUp" | "onMouseDown" | "disabled"
  > & { ref: ForwardedRef<HTMLButtonElement> }
>;

/**
 *  Arbiter-compliant button
 * https://www.figma.com/design/iZHj2I36zd9i8nRbWKw4ZK/%E2%9D%96-Arbiter?node-id=3287-427074&t=p984S770InFMNsms-0
 *
 * Does not manage its own state - pass your own onClick, etc.
 * Adding a pending state is also not managed - if you want pending,
 * use the disabled prop, and pass the spinner & pending text as an icon/text when your state is pending.
 */
export const RedoButton = memo(
  forwardRef(function RedoButton(
    {
      size = "small",
      theme = "Normal",
      hierarchy = "Tertiary",
      icon = undefined,
      iconTrailing = undefined,
      text = undefined,
      multiAction = false,
      ...otherButtonProps
    }: {
      size?: RedoButtonSize;
      theme?: RedoButtonTheme;
      hierarchy?: RedoButtonHierarchy;
      icon?: IconSvgType | undefined;
      iconTrailing?: IconSvgType | undefined;
      text?: string | undefined;

      /**
       * If you don't want a dropdown, just put false or leave unset, then you get no dropdown.
       * If you want a dropdown (vertical bar and chevron icon on the right, overriding iconTrailing),
       * pass in the parent-controlled open state, as well as a setter via a button onClick, etc...
       */
      multiAction?:
        | false
        | ({
            /** If you want a multi */
            isDropdownOpen: boolean;
          } & RegularButtonPropsSupportedByRedoButton);
    } & RegularButtonPropsSupportedByRedoButton,
    ref: ForwardedRef<HTMLButtonElement>,
  ) {
    const iconType = text ? "default" : "only";

    const { textSize, pt, pr, pb, pl } = buttonPropsMap?.[iconType]?.[size] ?? {
      textSize: "xs",
      pt: "none",
      pr: "none",
      pb: "none",
      pl: "none",
    };
    const rowGap = buttonGapMap?.[iconType] ?? "xs";
    const iconContainerClassname = classNames(
      redoButtonCss.iconContainer,
      redoButtonCss[size],
    );

    const {
      isDropdownOpen,
      ref: multiSelectRef,
      disabled: multiActionSpecificallyDisabled,
      ...otherMultiActionProps
    } = multiAction || {
      isDropdownOpen: false,
    };

    const commonButtonClassnames = classNames(
      otherButtonProps.disabled ? redoButtonCss.disabled : undefined,
      redoButtonCss.button,
      redoButtonCss[size],
      redoButtonCss[`theme${theme}`],
      redoButtonCss[`hierarchy${hierarchy}`],
    );

    const wrappedWithMultiSelect = (children: ReactElement) =>
      multiAction ? (
        <Flex align="center" gap="none" justify="center" ref={multiSelectRef}>
          {children}
          <button
            className={classNames(
              commonButtonClassnames,
              redoButtonCss.multiActionRight,
              redoButtonCss.only,
            )}
            disabled={
              multiActionSpecificallyDisabled !== undefined
                ? multiActionSpecificallyDisabled
                : otherButtonProps.disabled
            }
            type="button"
            {...otherMultiActionProps}
          >
            <Flex
              align="center"
              className={iconContainerClassname}
              justify="center"
            >
              {isDropdownOpen ? <ChevronUpSvg /> : <ChevronDownSvg />}
            </Flex>
          </button>
        </Flex>
      ) : (
        <>{children}</>
      );

    const [iconSvg, iconTrailingSvg] = Array.from(
      [icon, iconTrailing].map((icon) =>
        icon ? (typeof icon === "function" ? icon({}) : icon) : undefined,
      ),
    );

    return wrappedWithMultiSelect(
      <button
        className={classNames(
          commonButtonClassnames,
          multiAction ? redoButtonCss.multiActionLeft : undefined,
        )}
        ref={ref}
        type="button"
        {...otherButtonProps}
      >
        <Flex
          align="center"
          className={classNames(
            redoButtonCss.buttonFlexContainer,
            redoButtonCss[size],
          )}
          gap={rowGap}
          justify="center"
          pb={pb}
          pl={pl}
          pr={pr}
          pt={pt}
        >
          {iconSvg && (
            <Flex
              align="center"
              className={iconContainerClassname}
              justify="center"
            >
              {iconSvg}
            </Flex>
          )}
          {iconType !== "only" && (
            <Text fontSize={textSize} fontWeight="medium">
              {text}
            </Text>
          )}
          {!multiAction && iconType === "default" && iconTrailingSvg && (
            <Flex
              align="center"
              className={iconContainerClassname}
              justify="center"
            >
              {iconTrailingSvg}
            </Flex>
          )}
        </Flex>
      </button>,
    );
  }),
);
