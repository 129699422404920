import { PillTheme } from "@redotech/redo-model/pill-theme";
import { CompleteOrderFulfillmentStatus } from "@redotech/redo-model/shopify-order";
import { Pill, PillSize } from "@redotech/redo-web/pill";
import { memo } from "react";

export const OrderStatusPill = memo(function OrderStatusPill({
  orderStatus,
}: {
  orderStatus: CompleteOrderFulfillmentStatus;
}) {
  const getPillTheme = (): PillTheme => {
    if (orderStatus === CompleteOrderFulfillmentStatus.FULFILLED) {
      return PillTheme.SUCCESS;
    } else if (orderStatus === CompleteOrderFulfillmentStatus.UNFULFILLED) {
      return PillTheme.NEUTRAL_YELLOW;
    } else if (orderStatus === CompleteOrderFulfillmentStatus.PARTIAL) {
      return PillTheme.NEUTRAL_YELLOW;
    } else {
      return PillTheme.NORMAL;
    }
  };

  const getPillText = (): string => {
    return orderStatus.displayValue;
  };

  return (
    <Pill size={PillSize.NEAR_SQUARE} theme={getPillTheme()}>
      {getPillText()}
    </Pill>
  );
});
