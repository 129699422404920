import { ClickAwayListener } from "@mui/base";
import { Button, ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { Dropdown } from "@redotech/redo-web/dropdown";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import * as classNames from "classnames";
import { JSXElementConstructor, memo } from "react";
import * as redoCommandMenuCss from "./redo-command-menu.module.css";

export interface RedoCommandMenuItem {
  Icon?: JSXElementConstructor<any>;
  text: string;
  onClick(): void;
  theme?: ButtonTheme;
}

export const RedoCommandMenu = memo(function RedoCommandMenu({
  items,
  open,
  setOpen,
  anchor,
}: {
  items: RedoCommandMenuItem[];
  open: boolean;
  setOpen(value: boolean): void;
  anchor: HTMLElement | null;
}) {
  const atLeastOneIcon = items.some((item) => item.Icon !== undefined);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Dropdown anchor={anchor} fitToAnchor={false} open={open}>
        <Flex dir="column">
          {items.map((item, idx) => {
            return (
              <RedoCommandMenuItem
                indentForIcon={atLeastOneIcon}
                item={item}
                itemClicked={() => setOpen(false)}
                key={idx}
              />
            );
          })}
        </Flex>
      </Dropdown>
    </ClickAwayListener>
  );
});

/**
 * @param indentForIcon if at least one of the menu items has an icon,
 * we want to space all the buttons evenly
 */
const RedoCommandMenuItem = memo(function RedoCommandMenuItem({
  item,
  indentForIcon,
  itemClicked,
}: {
  item: RedoCommandMenuItem;
  indentForIcon: boolean;
  itemClicked(): void;
}) {
  const icon = ({ className }: { className?: string }) => {
    if (item.Icon) {
      return <item.Icon className={className} />;
    } else {
      return (
        <span
          className={classNames(className, redoCommandMenuCss.iconPlaceholder)}
        />
      );
    }
  };

  return (
    <Button
      className={redoCommandMenuCss.dropdownButton}
      icon={indentForIcon ? icon : undefined}
      onClick={() => {
        itemClicked();
        item.onClick();
      }}
      size={ButtonSize.MICRO}
      theme={item.theme}
    >
      <Text as="span" textColor="secondary">
        {item.text}
      </Text>
    </Button>
  );
});
