import { Order } from "@redotech/redo-model/order";
import { Return } from "@redotech/redo-model/return";
import { CURRENCY_FORMAT } from "@redotech/redo-web/currency";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo } from "react";

export const CustomerTotals = memo(function CustomerTotals({
  orders,
  returns,
}: {
  orders: Order[] | undefined;
  returns: Return[] | undefined;
}) {
  const totalOrders = orders?.length || 0;
  const totalReturns = returns?.length || 0;
  const totalOrderAmount = orders?.length
    ? orders.reduce(
        (accumulator: number, order: Order) =>
          accumulator +
          Number(order.shopify.total_price_usd || order.shopify.total_price),
        0,
      )
    : 0;
  return (
    <Flex dir="column">
      <Flex justify="space-between">
        <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
          Total spent
        </Text>
        <Text fontSize="xs" fontWeight="medium" textColor="primary">
          {CURRENCY_FORMAT().format(totalOrderAmount)}
        </Text>
      </Flex>
      <Flex justify="space-between">
        <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
          Total orders
        </Text>
        <Text fontSize="xs" fontWeight="medium" textColor="primary">
          {totalOrders}
        </Text>
      </Flex>
      <Flex justify="space-between">
        <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
          Total returns
        </Text>
        <Text fontSize="xs" fontWeight="medium" textColor="primary">
          {totalReturns}
        </Text>
      </Flex>
    </Flex>
  );
});
