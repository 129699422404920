import { ChipInput } from "@redotech/redo-web/chip-input";
import { Text } from "@redotech/redo-web/text";
import { isValidEmail } from "@redotech/util/email";
import { memo } from "react";

export const EmailListChipInput = memo(function EmailListChipInput({
  title,
  infoText,
  emailsToShow,
  setEmails,
}: {
  title: string;
  infoText: string;
  emailsToShow: readonly string[];
  setEmails(emails: readonly string[]): void;
}) {
  const validateNewInputs = (
    emailCandidates: readonly string[],
  ): readonly string[] => {
    return emailCandidates.filter(isValidEmail);
  };

  return (
    <div>
      <Text fontSize="md">{title}</Text>
      <ChipInput
        infoTooltip={infoText}
        placeholderText="Type an email and press enter"
        value={emailsToShow}
        valueChange={(newValues) => {
          setEmails(validateNewInputs(newValues));
        }}
      />
    </div>
  );
});

export const recipientDescriptions = {
  toTitle: "To",
  toInfo: "The primary recipients of the email.",
  ccTitle: "CC",
  bccTitle: "BCC",
  ccInfo:
    "A CC (Carbon Copy) address in an email allows you to send a copy of the message to additional recipients, making their email addresses visible to all recipients.",
  bccInfo:
    "A BCC (Blind Carbon Copy) address enables you to send an email to multiple recipients without revealing their email addresses to each other, ensuring privacy and confidentiality.",
};
