import * as amplitude from "@amplitude/analytics-browser";
import { ThemeProvider } from "@mui/material";
import { composeWrappers } from "@redotech/react-util/component";
import { RedoClient } from "@redotech/redo-api-client";
import { PickupContextProvider } from "@redotech/redo-return-app/contexts/PickupContext";
import { SettlementContextProvider } from "@redotech/redo-return-app/contexts/SettlementContext";
import { RedoClientContext } from "@redotech/redo-web/client";
import { MUI_THEME } from "@redotech/redo-web/mui-theme";
import { CustomSnackbarProvider } from "@redotech/redo-web/snackbar";
import { ThemeProvider as RedoThemeProvider } from "@redotech/redo-web/theme-provider";
import { StripeProvider } from "@redotech/stripe-react";
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import { AuthContext, AuthProvider } from "./app/auth";
import { RedoMerchantRpcClientProvider } from "./app/redo-merchant-rpc-client-provider";
import { TeamProvider } from "./app/team";
import { ViewsProvider } from "./app/views";
import { RedoMerchantClientProvider } from "./client/context";
import { REDO_API_URL, REDO_MERCHANT_SERVER_URL, SENTRY_DSN } from "./config";
import { MerchantAppEventServerProvider } from "./events/merchant-app-event-server-provider";
import { routes } from "./route";
import "./styles.css";

// Initialize exchange rates for currency conversion - necessary to use Money objects
// TODO @JstnMcBrd Change the passed-in method to only call getExchangeRates() if it's been over a certain amount of time,
// and otherwise pull from localStorage. Also need to find a way to save the exchange rates to localStorage.
// TODO @JstnMcBrd Re-enable once Money objects are ready
// import { ExchangeRatesManager } from "@redotech/money/exchange-rates";
// import { getExchangeRates } from "./api";
// void ExchangeRatesManager.initialize(getExchangeRates);

if (SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
  });
}

if (process.env.NODE_ENV !== "development") {
  amplitude.init(process.env.AMPLITUDE_API_KEY!, {
    defaultTracking: true,
  });
}

const router = createBrowserRouter(routes);

const MerchantApp = () => {
  return composeWrappers(
    (inner) => <ThemeProvider theme={MUI_THEME}>{inner}</ThemeProvider>,
    (inner) => (
      <StripeProvider publicKey={process.env.STRIPE_PUBLIC_KEY!}>
        {inner}
      </StripeProvider>
    ),
    (inner) => (
      <IntercomProvider
        appId={process.env.INTERCOM_APP_ID || ""}
        autoBoot
        shouldInitialize={!!process.env.INTERCOM_APP_ID}
      >
        {inner}
      </IntercomProvider>
    ),
    (inner) => <CustomSnackbarProvider>{inner}</CustomSnackbarProvider>,
    (inner) => <AuthProvider>{inner}</AuthProvider>,
    (inner) => (
      <AuthContext.Consumer>
        {(auth) => (
          <RedoClientContext.Provider
            value={new RedoClient(REDO_API_URL, auth?.token)}
          >
            {inner}
          </RedoClientContext.Provider>
        )}
      </AuthContext.Consumer>
    ),
    (inner) => (
      <AuthContext.Consumer>
        {(auth) => (
          <RedoMerchantClientProvider
            token={auth?.token}
            url={REDO_MERCHANT_SERVER_URL}
          >
            {inner}
          </RedoMerchantClientProvider>
        )}
      </AuthContext.Consumer>
    ),
    (inner) => (
      <AuthContext.Consumer>
        {(auth) => (
          <RedoMerchantRpcClientProvider
            authToken={auth?.token}
            baseUrl={REDO_MERCHANT_SERVER_URL}
          >
            {inner}
          </RedoMerchantRpcClientProvider>
        )}
      </AuthContext.Consumer>
    ),
    (inner) => <TeamProvider>{inner}</TeamProvider>,
    (inner) => <ViewsProvider>{inner}</ViewsProvider>,
    (inner) => (
      <MerchantAppEventServerProvider>{inner}</MerchantAppEventServerProvider>
    ),
    (inner) => <PickupContextProvider>{inner}</PickupContextProvider>,
    (inner) => <SettlementContextProvider>{inner}</SettlementContextProvider>,
    (inner) => <RedoThemeProvider>{inner}</RedoThemeProvider>,
  )(<RouterProvider router={router} />);
};

const root = createRoot(document.getElementById("root")!);
root.render(<MerchantApp />);
