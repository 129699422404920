export enum SortDirection {
  ASC = "asc",
  DESC = "desc",
}

export interface TableSort<T extends string = string> {
  direction: SortDirection;
  key: T;
}

/**  Conversations table—specific stuff  */

/** Columns in the conversations table that the user can sort the table by */
export const sortableConversationsTableColumns = [
  "customer",
  "summary",
  "assignee",
  "platform",
  "lastResponse",
] as const;

export const defaultConversationsTableSort: TableSort<SortableConversationTableColumn> =
  {
    key: "lastResponse",
    direction: SortDirection.DESC,
  };

export type SortableConversationTableColumn =
  (typeof sortableConversationsTableColumns)[number];

export const sortableConversationsTableColumnTitlesByKey: Record<
  SortableConversationTableColumn,
  string
> = {
  customer: "Customer",
  summary: "Summary",
  assignee: "Assignee",
  platform: "Platform",
  lastResponse: "Last response",
};
