import { Currency, isCurrency } from "@redotech/money/currencies";
import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import { Order } from "@redotech/redo-model/order";
import type { Return } from "@redotech/redo-model/return";
import { ReturnWarning } from "@redotech/redo-model/return";
import {
  ReturnTotals,
  ReturnTotalsCalculator,
} from "@redotech/redo-model/return-totals-calculator";
import {
  CURRENCY_FORMAT,
  CurrencyContext,
  CurrencyEnv,
  DEFAULT_CURRENCY,
} from "@redotech/redo-web/currency";
import CheckCircle from "@redotech/redo-web/icon-old/check-circle-large.svg";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { Popover } from "@typeform/embed-react";
import htmlReactParser from "html-react-parser";
import { memo, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  getOrderById,
  getReturn,
  getShopifyOrder,
  old_getCurrencyExchangeRates,
} from "../../api";
import { AuthContext } from "../../app/auth";
import { SettingsContext, StorefrontClientContext } from "../../app/settings";
import {
  FulfillmentDisplay,
  TrackingType,
} from "../order-tracking/fulfillment/fulfillment";
import * as returnStyles from "../return-flow/return.module.css";
import { ExchangeHeader } from "../utils";
import { DeadlineCard } from "./deadline-card";
import { NewItemsCard } from "./new-items-card";
import { ReturnItems } from "./return-items";
import * as returnStatus from "./return-status.module.css";
import { ReturnStepsCard } from "./return-steps-card";
import { ShippingCard } from "./shipping-card";
import { SummaryCard } from "./summary-card";
import { WarningsCard } from "./warnings-card";

export type NewOrder = {
  shopify: {
    fulfillment_status: string;
  };
};

// TODO @JstnMcBrd Should we add a step for flagged? Do we want the customer to know if their return has been flagged by the merchant?
export enum StepType {
  NONE = "none",
  EXCHANGE_ORDER = "exchange_order",
  MANUAL_REVIEW = "manual_review",
  GREEN_RETURN = "green_return",
  IN_TRANSIT = "in_transit",
  DELIVERED = "delivered",
  PROCESSED = "processed",
  WARNING = "warning",
}

export type Step = {
  type: StepType;
  title: string;
  subtitle: string;
  buttons?: JSX.Element;
  complete: boolean;
};

export const openUrl = (url: string) => {
  window.open(url, "_blank");
};

export const ReturnStatus = memo(function ReturnStatus({
  return: return_,
  order = null,
  exchangeOrder = null,
  orderId = null,
}: {
  return?: Return;
  order?: any;
  exchangeOrder?: any;
  orderId?: string;
}) {
  const { id } = useParams();
  const settings = useContext(SettingsContext);
  const auth = useContext(AuthContext);
  const teamId = auth?.teamId;
  const storefrontClient = useRequiredContext(StorefrontClientContext);

  const returnLoad = useLoad(
    async (signal) => {
      let returnData = return_;
      if (!returnData) {
        const res = await getReturn(id);
        returnData = res.data;
      }
      const newItems = [...returnData.advancedExchangeItems];
      returnData.products.forEach((product) => {
        if (product.exchange_for) {
          newItems.push(product.exchange_for);
        }
        if (product.exchangeGroupItem) {
          newItems.push(product.exchangeGroupItem);
        }
      });
      // Filter our irrelavant warnings
      returnData.warnings = returnData.warnings.filter(
        (warning: ReturnWarning) => {
          if (warning.type === "label" && returnData.shipment) {
            return false;
          } else if (warning.type === "pickup" && returnData.shipment?.pickup) {
            return false;
          } else if (
            warning.type === "instant-refund" &&
            returnData.instantRefund
          ) {
            return false;
          }
          return true;
        },
      );
      return { returnData, newItems };
    },
    [id],
  );

  const { returnData, newItems = [] } = returnLoad.value || {};

  type OrderInfo = {
    order: Order;
    orderDate: string;
    returnTotals: ReturnTotals;
    orderCurrencyEnv: CurrencyEnv;
  };

  const ordersLoad = useLoad(
    async (signal) => {
      if (!returnData) {
        return null;
      }
      const orders = [];
      if (order) {
        orders.push(order);
      } else {
        for (const order of returnData.orders) {
          const res = await getOrderById(order.order);
          orders.push(res.data.order);
        }
      }

      const orderInfo: OrderInfo[] = [];
      for (const order of orders) {
        const calculator = new ReturnTotalsCalculator({
          return_: returnData,
          order,
          team: {
            settings,
          },
        });

        const rawTotals = calculator.getTotalsForAllProducts();
        // TODO: probably need a better way to compute whether it was an instant refund
        const isInstantRefund =
          returnData.totals.refund === rawTotals.totalRefund * 0.97;
        const returnTotals =
          calculator.getTotalsForAllProducts(isInstantRefund);
        const orderDate = new Date(order.createdAt).toLocaleDateString(
          "en-us",
          {
            month: "short",
            day: "numeric",
            year: "numeric",
          },
        );

        // TODO: remove duplicated code. This same currency logic is done in to build the original CurrencyContext.
        // TODO: note we also fetch exchange rates here
        const shopCurrencyStr: string | undefined = order?.shopify?.currency;
        const orderCurrencyStr: string | undefined =
          order?.shopify?.presentment_currency;

        const shopCurrency =
          shopCurrencyStr && isCurrency(shopCurrencyStr)
            ? shopCurrencyStr
            : DEFAULT_CURRENCY;
        const orderCurrency =
          orderCurrencyStr && isCurrency(orderCurrencyStr)
            ? orderCurrencyStr
            : DEFAULT_CURRENCY;

        let usdRatesPromise;
        if (orderCurrency !== Currency.USD) {
          usdRatesPromise = old_getCurrencyExchangeRates(Currency.USD);
        }
        let formatCurrency: (amount: number) => string;
        if (orderCurrency !== shopCurrency) {
          try {
            const exchangeRates = (
              await old_getCurrencyExchangeRates(shopCurrency)
            ).data;
            formatCurrency = exchangeRates
              ? (amount) => {
                  return CURRENCY_FORMAT(orderCurrency).format(
                    amount * exchangeRates[orderCurrency],
                  );
                }
              : undefined;
          } catch (e) {
            console.error(e);
          }
        }
        if (formatCurrency === undefined) {
          formatCurrency = (amount) =>
            CURRENCY_FORMAT(shopCurrency).format(amount);
        }

        const usdRates = usdRatesPromise
          ? (await usdRatesPromise).data
          : undefined;
        const formatCurrencyFromUSD = usdRates
          ? (amount) => {
              return CURRENCY_FORMAT(orderCurrency).format(
                amount * usdRates[orderCurrency],
              );
            }
          : (amount) => CURRENCY_FORMAT(Currency.USD).format(amount);

        orderInfo.push({
          order,
          orderDate,
          returnTotals,
          orderCurrencyEnv: {
            currency: orderCurrency,
            formatCurrency,
            formatCurrencyFromUSD,
            setCurrency: (_currency) => {},
          } as CurrencyEnv,
        });
      }
      return orderInfo;
    },
    [returnData],
  );

  const newOrderLoad = useLoad(async () => {
    let newOrder: NewOrder;
    let newOrderNumber = "";
    if (returnData?.exchangeOrder.length) {
      const res = await getShopifyOrder(returnData.exchangeOrder[0].id);
      newOrder = { shopify: res.data.order };
      newOrderNumber = res.data.order.name;
    } else {
      newOrder = {
        shopify: {
          fulfillment_status: "unfulfilled",
        },
      };
    }

    return {
      newOrder,
      newOrderNumber,
    };
  }, [returnData]);

  const { newOrder } = newOrderLoad.value || {};

  if (returnLoad.pending || ordersLoad.pending) {
    return <LoadingRedoAnimation />;
  }
  if (returnLoad.error || ordersLoad.error || newOrderLoad.error) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className={returnStatus.title}>Error loading return status</div>
      </div>
    );
  }
  if (!returnData || !ordersLoad.value) {
    return null;
  }

  if (
    settings.returnTracking?.enabled &&
    ordersLoad.value &&
    returnData.status !== "needs_review" &&
    returnData.status !== "open"
  ) {
    return (
      <FulfillmentDisplay
        editMode={false}
        fullOrder={ordersLoad.value[0].order} // todo support multi order returns in return tracking
        returnData={returnData}
        settings={settings}
        storefrontClient={storefrontClient}
        trackingType={TrackingType.RETURN}
      />
    );
  }

  const isExchange =
    returnData.advancedExchangeItems?.length ||
    returnData.products.some(
      (product) => product.exchange_for || product.exchangeGroupItem,
    );
  const showShippingInformation =
    !!returnData.shipping_address &&
    isExchange &&
    !returnData.exchangeOrder.length &&
    !returnData.draftOrderId;

  const rejected = returnData.status === "rejected";

  const deadlineDays = Math.max(
    0,
    Math.round(
      Temporal.Now.instant()
        // FIXME Our types are all wonky - expirationDate is really a string, not a Date object
        .until(
          new Date(
            returnData.expirationDate as unknown as string,
          ).toTemporalInstant(),
        )
        .total("days"),
    ),
  );

  const needToPayDraftOrder =
    returnData.type === "return" &&
    returnData.draftOrderURL &&
    !returnData.exchangeOrder.length;

  return (
    <CurrencyContext.Provider value={ordersLoad.value[0].orderCurrencyEnv}>
      <div className={returnStatus.page}>
        {needToPayDraftOrder ? (
          <ExchangeHeader returnData={returnData} />
        ) : (
          <div className={returnStatus.title}>
            {!rejected && <CheckCircle className={returnStatus.checkCircle} />}
            {`Your ${
              returnData.type === "return" ? "return" : "claim"
            } has been ${rejected ? "rejected" : "submitted"}`}
          </div>
        )}

        {ordersLoad.value.map((info: OrderInfo) => (
          <div
            key={info.order.shopify.name}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className={returnStatus.header}>
              <div className={returnStyles.flex}>
                <div className={returnStyles.grayText}>Order:&nbsp;</div>
                <div className={returnStyles.orderNumber}>
                  {/*We used to handle returns across multiple orders but now we are just doing one so this should be ok to get first in array*/}
                  {info.order.shopify.name}
                </div>
              </div>
              <div className={returnStyles.grayText}>
                Date: {info.orderDate}
              </div>
            </div>
          </div>
        ))}

        <div className={returnStatus.mainContent}>
          {rejected ? (
            <div className={returnStatus.text}>
              {htmlReactParser(returnData.products[0].rejectMessage)}
            </div>
          ) : (
            settings.theme.custom_confirmation_text && (
              <div className={returnStatus.text}>
                {htmlReactParser(settings.theme.custom_confirmation_text)}
              </div>
            )
          )}
          {!rejected && (
            <>
              {returnData.warnings?.length > 0 && (
                <WarningsCard warnings={returnData.warnings} />
              )}
              <ReturnStepsCard returnData={returnData} settings={settings} />
              {deadlineDays <= 10 && (
                <DeadlineCard deadlineDays={deadlineDays} />
              )}
            </>
          )}
          <ReturnItems returnData={returnData} />
          {newItems.length > 0 && !rejected && (
            <NewItemsCard
              newItems={newItems}
              newOrder={newOrder}
              returnType={returnData.type}
            />
          )}
          {!rejected && (
            <>
              <SummaryCard
                newItems={newItems}
                returnData={returnData}
                returnTotals={ordersLoad.value.map(
                  (info: OrderInfo) => info.returnTotals,
                )}
              />
              {showShippingInformation && (
                <ShippingCard returnData={returnData} />
              )}
            </>
          )}
        </div>
      </div>
      <Popover
        buttonColor="var(--redo-primary-color)"
        hidden={{
          team_id: teamId,
          order_id: orderId,
          return_id: returnData._id,
        }}
        id="ervbmPsn"
        keepSession
        tooltip="Please tell us about your return experience"
      />
    </CurrencyContext.Provider>
  );
});
