import { getResource } from "@redotech/redo-model/localization/resource";
import { ReturnType } from "@redotech/redo-model/return";
import { Card } from "@redotech/redo-web/card";
import * as classNames from "classnames";
import { memo } from "react";
import { NewOrder } from ".";
import { Product } from "./product";

import * as returnStatus from "./return-status.module.css";

export const NewItemsCard = memo(function NewItemsCard({
  newItems,
  newOrder,
  returnType,
}: {
  newItems: any;
  newOrder: NewOrder;
  returnType: ReturnType;
}) {
  const copyResource = getResource({
    variant: returnType,
  });

  return (
    <Card
      className={classNames(returnStatus.card, returnStatus.cardWhite)}
      title={
        <>
          {copyResource.ITEMS_NEW_HEADER}{" "}
          {newOrder && (
            <span className={returnStatus.productReturnType}>
              {capitalizeFirstLetterShopifyFulfillment(
                newOrder.shopify.fulfillment_status || "unfulfilled",
              )}
            </span>
          )}
        </>
      }
    >
      {newItems.map((item: any, i: number) => (
        <Product
          imageSrc={item.images?.[0] || item.imageSrc}
          key={i}
          last={i === newItems.length - 1}
          price={item.price}
          returnType=""
          title={item.title || item.product_title}
          variantTitle={item.variantTitle || item.variant_title}
        />
      ))}
    </Card>
  );
});

function capitalizeFirstLetterShopifyFulfillment(str: string): string {
  if (str === "partial") {
    str = "partially fulfilled";
  }
  return (str.charAt(0).toUpperCase() + str.slice(1)).replace(/_/, " ");
}
