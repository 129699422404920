import { VariantResource, Variants } from "./resource";

export const COMMON: VariantResource<Variants> = {
  LOGIN_FIELD_ONE_LABEL: "Email or Zip/Postal",
  LOGIN_FIELD_TWO_LABEL: "Order # / Tracking #",
  PORTAL_BUTTON_TEXT: "Start a Return",
  PORTAL_GIFT_BUTTON_TEXT: "Returning a gift?",
  PORTAL_POLICY_LINK_TEXT: "Return Policy",
  ITEMS_OLD_HEADER: "What you're returning",
  ITEMS_NEW_HEADER: "Your new items",
  FEES_HEADER: "Return value",
  FEES_ORDER_HEADER: "New order",
  REVIEW_SUBMIT_BUTTON_TEXT: "Submit return",
  REVIEW_SHIPPING_FROM_TEXT: "Returning items from",
  REVIEW_SHIPPING_TO_SAME_TEXT: "Same as returning items from",
  REVIEW_LABEL_COVERAGE: "Return covered for free by",
  REVIEW_FLOW_HEADER: "Review your return",
  STATUS_STEPS_TITLE: "What happens next?",
  STATUS_MANUAL_REVIEW_TITLE: "We'll review your return request",
  STATUS_MANUAL_REVIEW_SUBTITLE:
    "We'll email you the return label if your return is approved",
  STATUS_IN_TRANSIT_TITLE: "Pack up your returns",
  STATUS_IN_TRANSIT_SUBTITLE: "Print your shipping label",
  STATUS_IN_TRANSIT_QR_CODE: "(or open the QR code)",
  STATUS_IN_TRANSIT_MULTI_SHIPMENT_SUBTITLE:
    "Look below for all of your shipping labels",
  STATUS_PROCESSED_TITLE: "Carrier delivers the return package to us",
  STATUS_PROCESSED_SUBTITLE: "We'll review and process your return",
  STATUS_SHIPPING_TEXT:
    "Please confirm the address you'd like your new order shipped to.",
  EMAIL_SUBJECT: "We received your return request",
  EMAIL_HEADING: "Thanks for your return request.",
  EMAIL_BODY:
    "Please follow the steps below so that we can process your return as quickly as possible.",
  EMAIL_STEP_1: "Package all items you're requesting to be returned.",
  IN_STORE_RETURN_EMAIL_STEP_1:
    "Gather all items you're requesting to be returned.",
  SHOPIFY_NOTE: "This is an exchange order generated from Redo, for order",
  variants: {
    claim: {
      PORTAL_BUTTON_TEXT: "Start a Claim",
      PORTAL_GIFT_BUTTON_TEXT: "Start a claim for a gift",
      PORTAL_POLICY_LINK_TEXT: "Claim Policy",
      ITEMS_OLD_HEADER: "What you're filing a claim for",
      ITEMS_NEW_HEADER: "Your replacement items",
      FEES_HEADER: "Claim value",
      FEES_ORDER_HEADER: "Replacement order",
      REVIEW_SUBMIT_BUTTON_TEXT: "Submit claim",
      REVIEW_SHIPPING_FROM_TEXT: "Shipping from",
      REVIEW_SHIPPING_TO_SAME_TEXT: "Same as shipping from",
      REVIEW_LABEL_COVERAGE: "Claim covered for free by",
      REVIEW_FLOW_HEADER: "Review your shipping claim",
      STATUS_MANUAL_REVIEW_TITLE: "We'll review your claim request",
      STATUS_MANUAL_REVIEW_SUBTITLE:
        "We'll email you a label for your items if your claim is approved",
      STATUS_PROCESSED_TITLE: "Carrier delivers the package to us",
      STATUS_PROCESSED_SUBTITLE: "We'll review and process your claim",
      STATUS_SHIPPING_TEXT:
        "Please confirm the address you'd like your replacement items shipped to.",
      EMAIL_SUBJECT: "We received your claim request",
      EMAIL_HEADING: "Thanks for your claim request.",
      EMAIL_BODY:
        "Please follow the steps below so that we can process your claim as quickly as possible.",
      EMAIL_STEP_1: "Package all items you're filing a claim for.",
      IN_STORE_RETURN_EMAIL_STEP_1:
        "Gather all items you're filing a claim for.",
      SHOPIFY_NOTE:
        "This is a replacement order generated from Redo, for order",
    },
    exchange: {
      PORTAL_BUTTON_TEXT: "Start Exchange or Return",
      EMAIL_SUBJECT: "We received your return or exchange request",
      EMAIL_BODY:
        "Package all items you're requesting to be returned or exchanged.",
      EMAIL_STEP_1:
        "Package all items you're requesting to be returned or exchanged.",
      IN_STORE_RETURN_EMAIL_STEP_1:
        "Gather all items you're requesting to be returned or exchanged.",
    },
    return_claim: {
      PORTAL_BUTTON_TEXT: "Start Return or Claim",
      PORTAL_GIFT_BUTTON_TEXT: "Start a return or claim for a gift",
      PORTAL_POLICY_LINK_TEXT: "Return/Claim Policy",
    },
    pickup: {
      REVIEW_SHIPPING_FROM_TEXT: "Pickup Address",
      STATUS_IN_TRANSIT_SUBTITLE: "Use original packaging or poly bag",
      STATUS_PROCESSED_TITLE: "Package Pickup",
      STATUS_PROCESSED_SUBTITLE:
        "Carrier will pickup the package from the pickup address and deliver the return to us. We'll review and process your return",
      EMAIL_HEADING: "Thank you for your pickup request on your return order",
    },
    // TODO: Separate warranty and repair language
    warranty: {
      PORTAL_BUTTON_TEXT: "Start a Warranty Claim",
      ITEMS_OLD_HEADER: "What you're submitting for your warranty claim",
      REVIEW_SUBMIT_BUTTON_TEXT: "Submit warranty claim request",
      REVIEW_SHIPPING_FROM_TEXT: "Sending items from",
      REVIEW_FLOW_HEADER: "Review your warranty claim request",
      STATUS_MANUAL_REVIEW_TITLE: "We'll review your warranty claim request",
      STATUS_MANUAL_REVIEW_SUBTITLE:
        "We'll email you the label if your warranty claim is approved",
      STATUS_IN_TRANSIT_TITLE: "Pack up your items",
      STATUS_PROCESSED_TITLE: "Carrier delivers the package to us",
      STATUS_PROCESSED_SUBTITLE:
        "We'll review and process your warranty claim request",
      EMAIL_SUBJECT: "We received your warranty claim request",
      EMAIL_HEADING: "Thanks for your warranty claim request.",
      EMAIL_BODY:
        "Please follow the steps below so that we can process your warranty claim as quickly as possible.",
      EMAIL_STEP_1: "Package all items you're requesting to be processed.",
      IN_STORE_RETURN_EMAIL_STEP_1:
        "Gather all items you're requesting to be processed.",
    },
  },
};

// An example of a region override
// export const EN_US: VariantResource<Variants> = {
//   ...COMMON,
//   STATUS_PROCESSED_TITLE: "TEST ONE",
//   variants: {
//     ...COMMON.variants,
//     claim: {
//       ...COMMON.variants?.claim,
//       STATUS_PROCESSED_TITLE: "TEST TWO",
//     },
//   }
// };

export const EN_US: VariantResource<Variants> = {
  ...COMMON,
};

export const EN_AU: VariantResource<Variants> = {
  ...COMMON,
};
