// extracted by mini-css-extract-plugin
export var container = "_52-0";
export var content = "_52-3";
export var customerTitle = "_52-4";
export var duration = "_52-5";
export var empty = "_52-6";
export var gray = "_52-7";
export var header = "_52-8";
export var info = "_52-9";
export var loading = "_52-2";
export var loadingCard = "_52-a";
export var oneLine = "_52-b";
export var personName = "_52-f";
export var pillContainer = "_52-c";
export var selected = "_52-1";
export var text = "_52-d";
export var twoLines = "_52-e";
export var unread = "_52-g";
export var unreadIndicator = "_52-h";