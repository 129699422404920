import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { View } from "@redotech/redo-model/view";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import TrashIcon from "@redotech/redo-web/icon-old/trash.svg";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { memo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ReloadViewsContext } from "../app/views";
import { RedoMerchantClientContext } from "../client/context";
import { deleteView } from "../client/view";
import * as deleteViewModalCss from "./delete-view-modal.module.css";

export const DeleteViewModal = memo(function DeleteViewModal({
  open,
  setOpen,
  view,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  view: View;
}) {
  const reloadViews = useContext(ReloadViewsContext);
  const client = useRequiredContext(RedoMerchantClientContext);
  const navigate = useNavigate();

  const handleDeleteView = async () => {
    await deleteView(client, { id: view._id });
    amplitude.logEvent("delete-view", { view });
    if (reloadViews) {
      reloadViews();
    }
    setOpen(false);
    navigate(`/stores/${view.team}/support/table/all`);
  };

  const footer = (
    <div className={deleteViewModalCss.actionButtonsContainer}>
      <Button onClick={() => setOpen(false)} theme={ButtonTheme.OUTLINED}>
        Cancel
      </Button>
      <Button onClick={handleDeleteView} theme={ButtonTheme.PRIMARY}>
        Delete
      </Button>
    </div>
  );

  return (
    <>
      <button
        onClick={() => {
          amplitude.logEvent("view-deleteViewModal", { view });
          setOpen(true);
        }}
      >
        <TrashIcon />
      </button>
      <Modal
        footer={footer}
        onClose={() => setOpen(false)}
        open={open}
        showHeaderBorder={false}
        size={ModalSize.SMALL}
        title={`Delete view - ${view.name}`}
      >
        <div className={deleteViewModalCss.actionModalContentContainer}>
          Are you sure you want to delete this view?
        </div>
      </Modal>
    </>
  );
});
