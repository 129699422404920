// extracted by mini-css-extract-plugin
export var actionButton = "_6r-1";
export var actions = "_6r-0";
export var copyableField = "_6r-2";
export var detailColumn = "_6r-3";
export var detailRow = "_6r-4";
export var dropdownTitle = "_6r-5";
export var fulfillmentDetails = "_6r-7";
export var fulfillments = "_6r-6";
export var icon = "_6r-8";
export var imgContainer = "_6r-9";
export var lineItems = "_6r-a";
export var orderCard = "_6r-k";
export var orderCollapseButton = "_6r-l";
export var orderCollapseButtonIcon = "_6r-m";
export var orderDetails = "_6r-b";
export var orderPillsContainer = "_6r-c";
export var pillWrapper = "_6r-d";
export var product = "_6r-e";
export var productDetails = "_6r-g";
export var productTopDetails = "_6r-h";
export var products = "_6r-f";
export var strong = "_6r-i";
export var tightText = "_6r-o";
export var variantQuantityRow = "_6r-j";
export var wrappingText = "_6r-n";