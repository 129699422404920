import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { FilterOptions, View } from "@redotech/redo-model/view";
import { memo, useContext } from "react";
import { ReloadViewsContext } from "../app/views";
import { RedoMerchantClientContext } from "../client/context";
import { updateView } from "../client/view";
import { FilterModal } from "./filter-modal";

export const EditViewModal = memo(function EditViewModal({
  open,
  setOpen,
  view,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  view: View;
}) {
  const reloadViews = useContext(ReloadViewsContext);
  const client = useRequiredContext(RedoMerchantClientContext);

  const handleUpdateView = async (
    id: string,
    filters: FilterOptions,
    name: string,
    isPrivate: boolean,
  ) => {
    await updateView(client, { id, filters, name, isPrivate });
    amplitude.logEvent("update-view", { view });
    const pathPieces = location.pathname.split("/");
    const viewNameFromUrl = decodeURIComponent(
      pathPieces[pathPieces.length - 1],
    );
    if (viewNameFromUrl === name) {
      location.reload();
    }

    reloadViews && reloadViews();
  };

  return (
    <FilterModal
      allowFiltering={false}
      applyFilters={() => {}}
      createView={async () => {}}
      current={view.filters}
      defaults={view.filters}
      editingView={view}
      open={open}
      setOpen={setOpen}
      updateView={handleUpdateView}
    />
  );
});
