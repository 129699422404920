import { useInput } from "@redotech/react-util/form";
import { MacroVariable } from "@redotech/redo-merchant-app/support/rendered-message-content";
import { ConversationTagId } from "@redotech/redo-model/conversation";
import {
  Macro,
  MACRO_VARIABLES,
  MacroStatusToSet,
  MacroVariable as MacroVariableType,
  SnoozeDuration,
} from "@redotech/redo-model/macro";
import { PutMacroBody } from "@redotech/redo-model/put-macro-body";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import ClearFormatIcon from "@redotech/redo-web/icon-old/clear-format.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { Modal, ModalSize, PaddingAmount } from "@redotech/redo-web/modal";
import { QuillEditor } from "@redotech/redo-web/quill-editor";
import * as quillEditorCss from "@redotech/redo-web/quill-editor.module.css";
import { QuillToolbarOptions } from "@redotech/redo-web/quill-toolbar-options";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import { Switch } from "@redotech/redo-web/switch";
import { Text } from "@redotech/redo-web/text";
import { FormTextInput, TextInput } from "@redotech/redo-web/text-input";
import {
  groupInput,
  input,
  nonEmptyValidator,
  optionalInput,
} from "@redotech/ui/form";
import { arrayEqual } from "@redotech/util/equal";
import Quill from "quill";
import { memo, useContext, useEffect, useState } from "react";
import { ConversationTagsContext } from "../../app/conversation-tags-context";
import { ConversationTagInput } from "../conversation-tags/conversation-tag-input";
import { SnoozeOptionText } from "../snooze-modal";
import * as macroModalCss from "./macro-modal.module.css";
const Delta = Quill.import("delta");

const macroBody = groupInput({
  macroName: input<string>({ validator: nonEmptyValidator }),
  statusToSet: optionalInput(
    input<MacroStatusToSet | undefined>(),
    () => undefined,
  ),
  snoozeDuration: optionalInput(
    input<SnoozeDuration | undefined>(),
    () => undefined,
  ),
  tagsToAdd: input<ConversationTagId[]>({
    equal: arrayEqual((a, b) => a.name === b.name),
  }),
  emailSubject: optionalInput(
    input<string | undefined>({ validator: nonEmptyValidator }),
    () => undefined,
  ),
});

/**
 * Used to edit existing macros or create new ones
 */
export const MacroEditorModal = memo(function MacroEditorModal({
  cancel,
  submitMacro,
  initialState,
}: {
  cancel(): void;
  submitMacro(macro: PutMacroBody): Promise<void>;
  initialState: Macro | undefined;
}) {
  const conversationTags = useContext(ConversationTagsContext);

  const initialTagNames = new Set<string>(initialState?.tagsToAdd || []);
  const initialTags = conversationTags.filter((tag) =>
    initialTagNames.has(tag.name),
  );

  const macro = useInput(macroBody, {
    macroName: initialState?.name || "",
    statusToSet: initialState?.statusToSet,
    snoozeDuration: initialState?.snoozeDuration,
    tagsToAdd: initialTags,
    emailSubject: initialState?.emailSubjectToSet,
  });

  const [submitDisabledBecauseOfQuill, setSubmitDisabledBecauseOfQuill] =
    useState<boolean>(true);
  const [quill, setQuill] = useState<Quill | null>(null);

  const [snoozeModalOpen, setSnoozeModalOpen] = useState(false);

  const handleSaveMacro = async () => {
    if (!quill) {
      return;
    }
    const textContent = quill.getText();
    const content = textContent?.substring(0, textContent.length - 1) || ""; // Remove trailing newline added by Quill
    const htmlContent = quill.getSemanticHTML() || "";

    const inputValue = macro.value;

    const body: PutMacroBody = {
      name: inputValue.macroName,
      statusToSet: inputValue.statusToSet,
      snoozeDuration: inputValue.snoozeDuration,
      tagsToAdd: inputValue.tagsToAdd?.map((tag) => tag.name),
      emailSubjectToSet: inputValue.emailSubject,
      content,
      htmlContent,
    };

    await submitMacro(body);
  };

  const onVariableClick = (variable: MacroVariableType) => {
    if (!quill) {
      return;
    }
    const variableText = variable.variable;
    const insertPosition = quill.getSelection(true)?.index || 0;
    quill.insertText(insertPosition, variableText, "silent");
    quill.formatText(
      insertPosition,
      variableText.length,
      { color: "#5f45e2", background: "#ebe9fd" },
      "silent",
    );
    // Sets current cursor to not have formatting
    quill.format("color", "black");
    quill.format("background", "white");
  };

  useEffect(() => {
    if (quill && initialState?.htmlContent) {
      quill.clipboard.dangerouslyPasteHTML(initialState.htmlContent);
    }
  }, [quill]);

  const title = initialState
    ? `Edit template: ${initialState.name}`
    : "Create new template";

  return (
    <>
      <Modal
        onClose={cancel}
        open
        paddingAmount={PaddingAmount.NONE}
        size={ModalSize.LARGE}
        title={title}
      >
        <div className={macroModalCss.wrapper}>
          <div className={macroModalCss.sidebar}>
            <div className={macroModalCss.sidebarHeader}>Variables</div>
            <div className={macroModalCss.sidebarList}>
              <MacroVariableList onClick={onVariableClick} />
            </div>
          </div>
          <section className={macroModalCss.mainContent}>
            <div className={macroModalCss.details}>
              <div className={macroModalCss.preview2}>
                <FormTextInput
                  input={macro.inputs.macroName}
                  label="Template name"
                  placeholder="Template name"
                />
                <div className={macroModalCss.input}>
                  <div className={macroModalCss.quillContainer}>
                    <QuillEditor
                      defaultValue={new Delta().insert("")}
                      disabled={submitDisabledBecauseOfQuill}
                      placeholder="Start typing..."
                      readOnly={false}
                      ref={setQuill}
                      setDisabled={setSubmitDisabledBecauseOfQuill}
                      toolbarElementId="macro-toolbar"
                    />
                  </div>
                  <div
                    className={quillEditorCss.quillToolbar}
                    id="macro-toolbar"
                  >
                    <QuillToolbarOptions emailMode>
                      <Button
                        onClick={() => {
                          if (!quill) {
                            return;
                          }
                          const selection = quill.getSelection();
                          quill.removeFormat(
                            selection?.index || 0,
                            selection?.length || 0,
                          );
                        }}
                        theme={ButtonTheme.GHOST}
                      >
                        <div className={macroModalCss.actionButton}>
                          <ClearFormatIcon />
                        </div>
                      </Button>
                    </QuillToolbarOptions>
                  </div>
                </div>
              </div>
              <Divider />
              <div className={macroModalCss.preview2}>
                <div>
                  <h3>
                    <strong>Automations</strong>
                  </h3>
                  <Text fontSize="sm" textColor="tertiary">
                    These settings will take place if you use this template in a
                    support ticket
                  </Text>
                </div>

                <LabeledInput
                  description={
                    macro.value.snoozeDuration
                      ? `Until ${snoozeDurationToLabel(macro.value.snoozeDuration).charAt(0).toLowerCase()}${snoozeDurationToLabel(macro.value.snoozeDuration).slice(1)}`
                      : undefined
                  }
                  label="Set status"
                >
                  <SelectDropdown
                    allowNull
                    options={[undefined, ...Object.values(MacroStatusToSet)]}
                    placeholder="No status change"
                    value={macro.value.statusToSet}
                    valueChange={(value) => {
                      if (value === MacroStatusToSet.SNOOZED) {
                        setSnoozeModalOpen(true);
                      } else {
                        macro.inputs.snoozeDuration.setValue(undefined);
                      }
                      macro.inputs.statusToSet.setValue(value);
                    }}
                  >
                    {(option) => {
                      if (option) {
                        return option.charAt(0).toUpperCase() + option.slice(1);
                      } else {
                        return "No status change";
                      }
                    }}
                  </SelectDropdown>
                </LabeledInput>
                <LabeledInput label="Add tags">
                  <ConversationTagInput
                    currentTags={macro.inputs.tagsToAdd.value}
                    setCurrentTags={macro.inputs.tagsToAdd.setValue}
                  />
                </LabeledInput>
                <Flex dir="column">
                  <Flex>
                    <Switch
                      onChange={(value) => {
                        macro.inputs.emailSubject.setValue(
                          value ? "" : undefined,
                        );
                      }}
                      value={macro.inputs.emailSubject.value !== undefined}
                    />
                    <Flex dir="column" pl="xl">
                      <Text fontWeight="medium">Update email subject</Text>
                      <Text textColor="tertiary">
                        This only applies when used on email tickets
                      </Text>
                    </Flex>
                  </Flex>
                  {macro.inputs.emailSubject.value !== undefined && (
                    <TextInput
                      onChange={macro.inputs.emailSubject.setValue}
                      placeholder="Enter email subject"
                      value={macro.inputs.emailSubject.value || ""}
                    />
                  )}
                </Flex>
              </div>
            </div>
            <div className={macroModalCss.buttonSection}>
              <Divider />
              <div className={macroModalCss.buttonContainer2}>
                <Button onClick={cancel}>Cancel</Button>
                <Button
                  disabled={
                    macro.allErrors.length > 0 || submitDisabledBecauseOfQuill
                  }
                  onClick={handleSaveMacro}
                  theme={ButtonTheme.PRIMARY}
                >
                  Save template
                </Button>
              </div>
            </div>
          </section>
        </div>
      </Modal>
      <SelectSnoozeDurationModal
        onClose={() => {
          if (!macro.inputs.snoozeDuration.value) {
            macro.inputs.statusToSet.setValue(undefined);
          }
          setSnoozeModalOpen(false);
        }}
        onDurationSelect={(duration) => {
          macro.inputs.snoozeDuration.setValue(duration);
          setSnoozeModalOpen(false);
        }}
        open={snoozeModalOpen}
      />
    </>
  );
});

const MacroVariableList = memo(function MacroVariableList({
  onClick,
}: {
  onClick(variable: MacroVariableType): void;
}) {
  return (
    <>
      {MACRO_VARIABLES?.sort((a, b) =>
        a.displayName.localeCompare(b.displayName),
      ).map((variable: MacroVariableType) => {
        return (
          <div
            className={macroModalCss.sidebarListItem}
            key={variable.id}
            onClick={() => onClick(variable)}
          >
            <MacroVariable variable={variable} />
          </div>
        );
      })}
    </>
  );
});

const snoozeDurationToLabel = (duration: SnoozeDuration) => {
  switch (duration) {
    case SnoozeDuration.SAME_DAY:
      return "Later in the day";
    case SnoozeDuration.ONE_DAY:
      return "The next day";
    case SnoozeDuration.TWO_DAYS:
      return "Two days later";
    case SnoozeDuration.SATURDAY:
      return "The next Saturday";
    case SnoozeDuration.MONDAY:
      return "The next Monday";
  }
};

const SelectSnoozeDurationModal = memo(function SelectSnoozeDurationModal({
  open,
  onClose,
  onDurationSelect,
}: {
  open: boolean;
  onClose(): void;
  onDurationSelect(duration: SnoozeDuration): void;
}) {
  const today = new Date();
  today.setHours(18, 0, 0);

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(8, 0, 0);

  const twoDays = new Date();
  twoDays.setDate(twoDays.getDate() + 2);
  twoDays.setHours(8, 0, 0);

  const nextSaturday = new Date();
  nextSaturday.setDate(
    nextSaturday.getDate() + ((6 + 7 - nextSaturday.getDay()) % 7 || 7),
  );
  nextSaturday.setHours(8, 0, 0);

  const nextMonday = new Date();
  nextMonday.setDate(
    nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7 || 7),
  );
  nextMonday.setHours(8, 0, 0);

  return (
    <Modal
      onClose={onClose}
      open={open}
      showHeaderBorder={false}
      size={ModalSize.SMALL}
      subtitle="Choose how long tickets will be snoozed for when this template is used"
      title="Snooze ticket until"
    >
      <div className={macroModalCss.snoozeDurationWrapper}>
        <Button onClick={() => onDurationSelect(SnoozeDuration.SAME_DAY)}>
          <SnoozeOptionText
            text={snoozeDurationToLabel(SnoozeDuration.SAME_DAY)}
            time={today}
          />
        </Button>
        <Button onClick={() => onDurationSelect(SnoozeDuration.ONE_DAY)}>
          <SnoozeOptionText
            text={snoozeDurationToLabel(SnoozeDuration.ONE_DAY)}
            time={tomorrow}
          />
        </Button>
        <Button onClick={() => onDurationSelect(SnoozeDuration.TWO_DAYS)}>
          <SnoozeOptionText
            text={snoozeDurationToLabel(SnoozeDuration.TWO_DAYS)}
            time={twoDays}
          />
        </Button>
        <Button onClick={() => onDurationSelect(SnoozeDuration.SATURDAY)}>
          <SnoozeOptionText
            text={snoozeDurationToLabel(SnoozeDuration.SATURDAY)}
            time={nextSaturday}
          />
        </Button>
        <Button onClick={() => onDurationSelect(SnoozeDuration.MONDAY)}>
          <SnoozeOptionText
            text={snoozeDurationToLabel(SnoozeDuration.MONDAY)}
            time={nextMonday}
          />
        </Button>
      </div>
    </Modal>
  );
});
