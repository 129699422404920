import { FlowType } from "@redotech/redo-model/return-flow";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { Flex } from "@redotech/redo-web/flex";
import { TextInput } from "@redotech/redo-web/text-input";
import * as classnames from "classnames";
import { memo } from "react";
import { DisabledTooltip } from "../../../../web/src/tooltip/disabled-tooltip";
import * as flowEditorHeaderCss from "./flow-editor-header-buttons.module.css";

export interface FlowEditorHeaderProps {
  flowType: FlowType;

  showSaveAndCancel: boolean;
  cancelDisabled: boolean;
  saveDisabled: string | undefined;
  savePending: boolean;

  showPublish: boolean;
  publishPending: boolean;

  rightPanelOpen: boolean;

  detailsClicked: () => void;
  cancelClicked: () => void;
  saveClicked: () => void;
  publishClicked: () => void;

  ruleName: string;
  setRuleName: (name: string) => void;

  defaultButtonClicked?: () => void; // button that is visible when all others are not
  defaultButtonPending?: boolean;
  defaultButtonComponent?: React.ReactNode;
}

export const FlowEditorHeaderButtons = memo(function FlowEditorHeaderButtons({
  flowType,
  showSaveAndCancel,
  cancelDisabled,
  saveDisabled,
  savePending,
  publishPending,
  showPublish,
  rightPanelOpen,
  ruleName,
  setRuleName,
  detailsClicked,
  cancelClicked,
  saveClicked,
  publishClicked,
  defaultButtonClicked,
  defaultButtonPending,
  defaultButtonComponent,
}: FlowEditorHeaderProps) {
  const ruleSpecificItems = flowType === FlowType.RULE && (
    <>
      <TextInput
        onChange={setRuleName}
        placeholder="Give this rule a name"
        value={ruleName}
      />
      <Button onClick={detailsClicked} theme={ButtonTheme.GHOST}>
        Details
      </Button>
    </>
  );

  return (
    <Flex
      className={classnames({
        [flowEditorHeaderCss.rightPanelOpen]: rightPanelOpen,
      })}
    >
      {ruleSpecificItems}
      {showSaveAndCancel && (
        <>
          <Button
            disabled={cancelDisabled}
            onClick={cancelClicked}
            theme={ButtonTheme.OUTLINED}
          >
            Cancel
          </Button>
          <DisabledTooltip disabledMessage={saveDisabled}>
            <Button
              disabled={!!saveDisabled}
              onClick={saveClicked}
              pending={savePending}
              theme={ButtonTheme.PRIMARY}
            >
              Save
            </Button>
          </DisabledTooltip>
        </>
      )}
      {showPublish && (
        <Button
          onClick={publishClicked}
          pending={publishPending}
          theme={ButtonTheme.PRIMARY}
        >
          Publish
        </Button>
      )}
      {defaultButtonClicked &&
        defaultButtonComponent &&
        !showSaveAndCancel &&
        !showPublish && (
          <Button
            onClick={defaultButtonClicked}
            pending={defaultButtonPending ?? false}
            theme={ButtonTheme.OUTLINED}
          >
            {defaultButtonComponent}
          </Button>
        )}
    </Flex>
  );
});
