import { useRequiredContext } from "@redotech/react-util/context";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { memo, useContext, useState } from "react";
import { RedoMerchantClientContext } from "../../client/context";
import { ActiveConversationContext } from "../context/active-conversation-context";
import { MergeModal } from "../merge-modal";
import { MergeSuggestionDialog } from "./merge-suggestion-dialog";
import { MergeSuggestionSummaryModal } from "./merge-suggestion-summary-modal";
import { dismissMergeSuggestionForConversation } from "./utils";

export const MergeSuggestionFlow = memo(function MergeSuggestionFlow({
  activeConversation,
  suggestedConversations,
  initialStep,
  onClose,
  alreadyDismissed,
}: {
  activeConversation: ExpandedConversation;
  suggestedConversations: ExpandedConversation[];
  initialStep: "dialog" | "summaries" | "merge";
  onClose: () => void;
  alreadyDismissed: boolean;
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const { setActiveConversation } = useContext(ActiveConversationContext);
  const [step, setStep] = useState<"dialog" | "summaries" | "merge">(
    initialStep,
  );
  const [onBack, setOnBack] = useState<() => boolean>(() => () => false);
  const [selectedConversationIds, setSelectedConversationIds] = useState<
    Set<string>
  >(new Set([activeConversation._id]));
  const onCancel = () => {
    onClose();
    // Mark merge suggestion viewed in db
    if (!alreadyDismissed) {
      void dismissMergeSuggestionForConversation({
        client,
        conversationId: activeConversation._id,
        signal: new AbortController().signal,
      });
    }
  };

  return (
    <>
      {step === "dialog" && (
        <MergeSuggestionDialog
          customerName={activeConversation.customer.name}
          onCancel={onCancel}
          openMergeModal={() => {
            setSelectedConversationIds(
              new Set([
                activeConversation._id,
                ...suggestedConversations.map(
                  (conversation) => conversation._id,
                ),
              ]),
            );
            setOnBack(() => () => {
              onClose();
              return false;
            });
            setStep("merge");
          }}
          openMergeSuggestionSummaryModal={() => {
            setStep("summaries");
          }}
        />
      )}
      {step === "summaries" && (
        <MergeSuggestionSummaryModal
          activeConversation={activeConversation}
          onClose={onCancel}
          onSubmit={() => {
            setStep("merge");
            setOnBack(() => () => {
              setStep("summaries");
              return false;
            });
          }}
          selectedConversationIds={selectedConversationIds}
          setSelectedConversationIds={setSelectedConversationIds}
          suggestedConversations={suggestedConversations}
        />
      )}
      {step === "merge" && (
        <MergeModal
          conversations={[activeConversation, ...suggestedConversations].filter(
            (conversation) => selectedConversationIds.has(conversation._id),
          )}
          onBack={onBack}
          open
          setActiveConversation={setActiveConversation}
          setOpen={(e) => !e && onCancel()}
        />
      )}
    </>
  );
});
