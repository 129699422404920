// extracted by mini-css-extract-plugin
export var actionButton = "_6o-1";
export var actions = "_6o-0";
export var copyableField = "_6o-2";
export var icon = "_6o-3";
export var overflowVisible = "_6o-4";
export var pillWrapper = "_6o-5";
export var productDetails = "_6o-7";
export var productPillsContainer = "_6o-8";
export var productTopDetails = "_6o-9";
export var products = "_6o-6";
export var returnDetails = "_6o-a";
export var strong = "_6o-b";
export var variantQuantityRow = "_6o-c";