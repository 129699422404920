import { UserNotificationSettings } from "@redotech/redo-model/user/user-notification-settings";

export interface GetUser {
  roles: string[];
  _id: string;
  email: string;
  createdAt: string;
  firstName: string;
  lastName: string;
  name: string;
  updatedAt: string;
  image: any[];
  team: string;
  permissions: PermissionEntry[];
  notifications: UserNotificationSettings;
  customViewOrdering?: {
    viewName?: string; // View name for default views
    view?: string; // View _id for custom views
  }[];
}

export interface PermissionEntry {
  name: string;
  action: PermissionAction;
}

export enum PermissionAction {
  ALLOW = "allow",
  DENY = "deny",
}

export enum Permission {
  EDIT_SETTINGS = "Edit Settings",
  MANAGE_RETURN = "Manage Returns",
  MANAGE_CLAIM = "Manage Claims",
  MANAGE_ORDER = "Manage Orders",
  CLOSE_CONVERSATION = "Support: Close tickets",
  ASSIGN_TAG = "Support: Add tags to tickets",
  MANAGE_TAG = "Support: Create tags",
  CREATE_REPLY = "Support: Reply to customers",
  USE_MACRO = "Support: Use templates",
  CREATE_MACRO = "Support: Create templates",
  ARCHIVE_CONVERSATION = "Support: Archive tickets",
  // This is CREATE_TEAM_VIEW because we plan to add user-specific views at some point, with their own permission
  CREATE_TEAM_VIEW = "Support: Create views",
  EDIT_ASSIGNEE = "Support: Set assignee",
  EDIT_CUSTOMER = "Support: Set customer",
  CREATE_RULE = "Support: Create rule",
  DELETE_RULE = "Support: Delete rule",
}

export const permitted = (
  grantedPermissions: PermissionEntry[] | undefined,
  permissionName: Permission,
) => {
  if (!grantedPermissions || grantedPermissions.length === 0) {
    return false;
  }
  const permissionEntry = grantedPermissions.find(
    (permission) => permission.name === permissionName,
  );
  return !!permissionEntry && permissionEntry.action === PermissionAction.ALLOW;
};
