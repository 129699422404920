import { useRequiredContext } from "@redotech/react-util/context";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { GetUser } from "@redotech/redo-model/user";
import { AvatarStack } from "@redotech/redo-web/avatar-stack";
import { memo, useContext, useEffect, useState } from "react";
import { UserCacheContext } from "../app/user-cache";
import { RedoMerchantClientContext } from "../client/context";
import { getUserById } from "../client/user";
import * as activeUsersCss from "./active-users.module.css";
import { TeamConversationActivityContext } from "./support";

export const ActiveUsers = memo(function ActiveUsers({
  conversation,
  maxAvatars,
}: {
  conversation: ExpandedConversation;
  maxAvatars: number;
}) {
  const [activeUsers, setActiveUsers] = useState<GetUser[]>([]);
  const [typing, setTyping] = useState<boolean>(false);
  const userCache = useContext(UserCacheContext);
  const client = useRequiredContext(RedoMerchantClientContext);
  const teamConversationActivity = useContext(TeamConversationActivityContext);

  useEffect(() => {
    (async () => {
      if (conversation && teamConversationActivity?.[conversation._id]) {
        setActiveUsers(
          await Promise.all(
            teamConversationActivity[conversation._id].viewing?.map(
              async (userId) => {
                if (!userCache.has(userId)) {
                  userCache.set(
                    userId,
                    await getUserById(client, { id: userId }),
                  );
                }
                return userCache.get(userId)!;
              },
            ),
          ),
        );
        if (teamConversationActivity[conversation._id].typing.length > 0) {
          setTyping(true);
        } else {
          setTyping(false);
        }
      } else {
        setActiveUsers([]);
        setTyping(false);
      }
    })();
    // TODO: this dependency means that the effect will run for every card every time
    // teamConversationActivity changes. Better to run only when the corresponding entry changes. -Zach
  }, [teamConversationActivity]);

  return (
    <div className={activeUsersCss.activityContainer}>
      {activeUsers.length > 0 && (
        <AvatarStack
          maxAvatars={maxAvatars}
          users={activeUsers.map((activeUser) => {
            return { user: activeUser };
          })}
        />
      )}
      {typing && (
        <div className={activeUsersCss.typingIndicatorContainer}>
          <div className={activeUsersCss.typingIndicatorDot} />
          <div className={activeUsersCss.typingIndicatorDot} />
          <div className={activeUsersCss.typingIndicatorDot} />
        </div>
      )}
    </div>
  );
});
