import { RouteObject } from "react-router-dom";
import { ACTIVE_CONVERSATION_QUERY_PARAMETER } from "./query-parameters";
import { Support } from "./support";

const hideHeader = (url: string) => {
  return (
    url.includes(ACTIVE_CONVERSATION_QUERY_PARAMETER) ||
    /support\/about/.test(url)
  );
};

export const supportRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Support", breadcrumbNavigate: false },
    async lazy() {
      const { Page } = await import("../app/page");
      return { element: <Page hideHeader={hideHeader} /> };
    },
    children: [
      {
        path: "about",
        async lazy() {
          const { AboutSupport } = await import("./about-support");
          return { element: <AboutSupport /> };
        },
      },
      {
        handle: { breadcrumb: "All" },
        path: "table/all",
        element: <Support view="all" />,
      },
      {
        handle: { breadcrumb: "Assigned to me" },
        path: "table/assigned",
        element: <Support view="assigned" />,
      },
      {
        handle: { breadcrumb: "Spam" },
        path: "table/spam",
        element: <Support view="spam" />,
      },
      {
        handle: { breadcrumb: "Custom View" },
        path: "*",
        element: <Support />,
      },
      // Add back in if we do create order inside Redo
      // {
      //   handle: { breadcrumb: "Create order" },
      //   path: "create-order",
      //   element: <CreateOrder />,
      // },
      {
        path: "facebook-connect",
        async lazy() {
          const { Connect } = await import("./facebook/connect");
          return { element: <Connect /> };
        },
      },
      {
        path: "instagram-connect",
        async lazy() {
          const { Connect } = await import("./instagram/connect");
          return { element: <Connect /> };
        },
      },
      {
        path: "gmail-connect",
        async lazy() {
          const { Connect } = await import("./gmail/connect");
          return { element: <Connect /> };
        },
      },
      {
        path: "outlook-connect",
        async lazy() {
          const { Connect } = await import("./outlook/connect");
          return { element: <Connect /> };
        },
      },
      {
        path: "meta-callback",
        async lazy() {
          const { Callback } = await import("./meta-callback");
          return { element: <Callback /> };
        },
      },
      {
        path: "attentive-connect",
        async lazy() {
          const { Connect } = await import("./attentive/connect");
          return { element: <Connect /> };
        },
      },
      {
        path: "postscript-connect",
        async lazy() {
          const { Connect } = await import("./postscript/connect");
          return { element: <Connect /> };
        },
      },
      {
        path: "attentive-callback",
        async lazy() {
          const { Callback } = await import("./attentive-callback");
          return { element: <Callback /> };
        },
      },
      {
        path: "gmail-callback",
        async lazy() {
          const { Callback } = await import("./gmail-callback");
          return { element: <Callback /> };
        },
      },
      {
        path: "outlook-callback",
        async lazy() {
          const { Callback } = await import("./outlook-callback");
          return { element: <Callback /> };
        },
      },
    ],
  },
];
