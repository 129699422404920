import {
  isProductAddedToCartEvent,
  isProductViewedEvent,
} from "@redotech/redo-model/customer-activity/customer-activity-definition";
import { IShoppingEvent } from "@redotech/redo-model/shopping-event/shopping-event-definition";
import ShoppingBagIcon from "@redotech/redo-web/arbiter-icon/shopping-bag-03.svg";
import { Flex } from "@redotech/redo-web/flex";
import { memo } from "react";
import { BaseCustomerActivityCard } from "./base-customer-activity-card";
import * as customerActivityCardCss from "./customer-activity-card.module.css";

export const ShoppingCustomerActivityCard = memo(
  function ShoppingCustomerActivityCard({
    shoppingEvent,
  }: {
    shoppingEvent: IShoppingEvent;
  }) {
    const getShoppingEventImageSrc = (): string | undefined => {
      if (isProductViewedEvent(shoppingEvent)) {
        return shoppingEvent.productVariant.image?.src;
      } else if (isProductAddedToCartEvent(shoppingEvent)) {
        return shoppingEvent.cartLine?.merchandise.image?.src;
      } else {
        return undefined;
      }
    };

    const imgElement = (
      <Flex
        align="center"
        className={
          customerActivityCardCss.customerActivityShoppingIconContainer
        }
        justify="center"
        radius="xs"
      >
        {getShoppingEventImageSrc() ? (
          <img alt="Product item" src={getShoppingEventImageSrc()} />
        ) : (
          <ShoppingBagIcon />
        )}
      </Flex>
    );

    const getTitle = (): string => {
      if (isProductViewedEvent(shoppingEvent)) {
        return `Viewed item: ${shoppingEvent.productVariant.product.title}`;
      } else if (isProductAddedToCartEvent(shoppingEvent)) {
        return `Item added to cart: ${shoppingEvent.cartLine?.merchandise.product.title}`;
      } else {
        return "";
      }
    };

    return (
      <BaseCustomerActivityCard
        onClick={undefined}
        timestamp={shoppingEvent.timestamp}
        title={getTitle()}
        topLeftElement={imgElement}
      />
    );
  },
);
