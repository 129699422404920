import * as amplitude from "@amplitude/analytics-browser";
import { StringParam, useParam } from "@redotech/react-router-util/param";
import { useInput } from "@redotech/react-util/form";
import { Pickup } from "@redotech/redo-model/return-flow";
import {
  Button,
  ButtonBorder,
  ButtonSize,
  ButtonTheme,
} from "@redotech/redo-web/button";
import CalendarPlus from "@redotech/redo-web/icon-old/calendar-plus.svg";
import CircleClose from "@redotech/redo-web/icon-old/x-offset.svg";
import { memo, useEffect, useState } from "react";
import { cancelPickup, reschedulePickup } from "../../api";
import { PickupFormCard, pickupForm } from "../return-flow/review/pickup";

import { Modal } from "@redotech/redo-web/modal";
import { dateToCurrentPlainDate } from "@redotech/util/temporal";
import * as returnStatus from "./return-status.module.css";

export const PickupButtons = memo(function PickupButtons({
  returnData,
  setPickup,
}: {
  returnData: any;
  setPickup: any;
}) {
  const [rescheduleModalOpen, setRescheduleModalOpen] = useParam(
    new StringParam("rescheduleModalOpen", undefined),
  );
  const [cancelModalOpen, setCancelModalOpen] = useParam(
    new StringParam("cancelModalOpen", undefined),
  );

  const [pending, setPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const input = useInput(pickupForm, {
    finalized: false,
    pickup: {
      location: undefined,
      date: undefined,
      specialInstructions: "",
      textReminder: false,
      pickupPayer: undefined,
    },
  });

  const carrier: string | undefined =
    returnData?.shipment?._shipment?.selected_rate?.carrier;

  // If the pickup date is in the future, we should show the cancel button
  const shouldShowCancel = returnData.shipment?.pickup?.pickupDate
    ? Temporal.PlainDate.compare(
        dateToCurrentPlainDate(new Date(returnData.shipment.pickup.pickupDate)),
        Temporal.Now.plainDateISO(),
      ) === 1
    : false;

  useEffect(() => {
    // If the cancel modal is open, but the cancel button is hidden (ie. past pickup date),
    // then they reached the return status page from the troubleshooting email
    if (!shouldShowCancel && !!cancelModalOpen) {
      amplitude.logEvent("render-return-status", {
        location: "email",
      });
    }
  }, []);

  useEffect(() => {
    if (input.value.finalized) {
      const pickup: Pickup = {
        pickupLocation: {
          packageLocation: input.value.pickup.location,
        },
        pickupDate: input.value.pickup.date.toString(),
        textReminder: input.value.pickup.textReminder,
      };
      amplitude.logEvent("rescheduled-pickup");
      reschedulePickup(returnData.id, pickup)
        .then((res) => {
          // Reload page
          setRescheduleModalOpen(undefined);
          setPending(false);
          input.inputs.finalized.setValue(false);
          setPickup({
            ...res.data,
            pickupLocation: {
              packageLocation: input.value.pickup.location,
            },
          });
          // Reload page
          window.location.reload();
        })
        .catch((err) => {
          setPending(false);
          setErrorMessage("There was a problem rescheduling the pickup");
          input.inputs.finalized.setValue(false);
          console.log("Problem rescheduling pickup", err);
        });
    }
  }, [input.value.finalized]);

  return (
    <>
      <div className={returnStatus.stepButtons}>
        <Button
          border={ButtonBorder.LIGHT}
          onClick={() => setRescheduleModalOpen("true")}
          size={ButtonSize.MICRO}
          theme={ButtonTheme.OUTLINED}
        >
          <CalendarPlus className={returnStatus.buttonIcon} />
          Reschedule pickup
        </Button>
        {shouldShowCancel && (
          <Button
            border={ButtonBorder.LIGHT}
            onClick={() => {
              setCancelModalOpen("true");
            }}
            size={ButtonSize.MICRO}
            theme={ButtonTheme.OUTLINED}
          >
            <CircleClose className={returnStatus.closeIcon} />
            Cancel pickup
          </Button>
        )}
      </div>
      <Modal
        className={returnStatus.pickupModal}
        onClose={() => setRescheduleModalOpen(undefined)}
        open={!!rescheduleModalOpen}
        title="Reschedule pickup"
      >
        <PickupFormCard
          errorMessage={errorMessage}
          input={input}
          isStatusPage
          pending={pending}
          setPending={setPending}
        />
      </Modal>
      <Modal
        className={returnStatus.pickupModal}
        onClose={() => setCancelModalOpen(undefined)}
        open={!!cancelModalOpen}
        title="Cancel pickup"
      >
        <div style={{ fontWeight: "bold" }}>
          Are you sure you want to cancel the pickup?
        </div>
        <div>
          Keep the label you have and use it for drop off at a {carrier}{" "}
          location.
          <br />
          You will be issued a refund when drop-off is completed.
        </div>
        <div className={returnStatus.stepButtons}>
          <Button
            border={ButtonBorder.LIGHT}
            onClick={() => {
              amplitude.logEvent("canceled-pickup");
              cancelPickup(returnData.id)
                .then(() => {
                  setCancelModalOpen(undefined);
                  setErrorMessage(undefined);
                  // Reload page
                  window.location.reload();
                })
                .catch((err) => {
                  setErrorMessage(err.response.data.message);
                  console.log("Problem canceling pickup", err);
                });
            }}
            size={ButtonSize.MICRO}
            theme={ButtonTheme.OUTLINED}
          >
            Yes, cancel pickup
          </Button>
        </div>
        {errorMessage && (
          <div className={returnStatus.error}>{errorMessage}</div>
        )}
      </Modal>
    </>
  );
});
