import type { InteractiveMapLocation } from "@redotech/redo-model/interactive-map-location";
import { Flex } from "@redotech/redo-web/flex";
import {
  APIProvider,
  AdvancedMarker,
  Map,
  Pin,
  useMap,
} from "@vis.gl/react-google-maps";
import * as classNames from "classnames";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import * as interactiveMap from "./interactive-map.module.css";

export const InteractiveMap = memo(function InteractiveMap({
  locations,
}: {
  locations: InteractiveMapLocation[];
}) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  if (!locations.length) {
    return <div>No locations found</div>;
  }

  return (
    <Flex className={interactiveMap.locationsContainer}>
      <LocationsList
        locations={locations}
        selectedIndex={selectedIndex}
        selectLocation={setSelectedIndex}
      />
      <APIProvider apiKey="AIzaSyBLnX3xDv_dzLIUsaKBZDW4vNJnpimI31M">
        <LocationMap
          locations={locations}
          selectedIndex={selectedIndex}
          selectLocation={setSelectedIndex}
        />
      </APIProvider>
    </Flex>
  );
});

const LocationsList = memo(function LocationsList({
  locations,
  selectedIndex,
  selectLocation,
}: {
  locations: InteractiveMapLocation[];
  selectedIndex: number;
  selectLocation: (index: number) => void;
}) {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.children[selectedIndex]?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [ref.current, selectedIndex]);

  if (!locations.length) {
    return <div>No locations found</div>;
  }

  return (
    <Flex className={interactiveMap.locations} dir="column" gap="xl">
      <div className={interactiveMap.locationsWrapper} ref={ref}>
        {locations.map((location, i) => (
          <div
            className={classNames(interactiveMap.location, {
              [interactiveMap.active]: selectedIndex === i,
            })}
            key={i}
            onClick={() => selectLocation(i)}
          >
            <div className={interactiveMap.locationContent}>
              <div>
                <span className={interactiveMap.name}>
                  {i + 1}. {location.name}
                </span>
                {location.distance && <span>&nbsp;({location.distance})</span>}
              </div>
              <div>{location.address.address1}</div>
              {location.address.address2 && (
                <div>{location.address.address2}</div>
              )}
              <div>
                {location.address.city}, {location.address.state}{" "}
                {location.address.zip}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Flex>
  );
});

const LocationMap = memo(function LocationMap({
  locations,
  selectedIndex,
  selectLocation,
}: {
  locations: InteractiveMapLocation[];
  selectedIndex: number;
  selectLocation: (index: number) => void;
}) {
  const map = useMap();
  const positions = useMemo(() => {
    return locations
      .filter((location) => location.geo)
      .map((location) => ({
        lat: location.geo.latitude,
        lng: location.geo.longitude,
      }));
  }, [locations]);

  useEffect(() => {
    if (map) {
      map.setCenter({
        lat: locations[selectedIndex].geo.latitude,
        lng: locations[selectedIndex].geo.longitude,
      });
      selectedIndex;
    }
  }, [map, selectedIndex]);

  return (
    <div className={interactiveMap.mapContainer}>
      <Map
        defaultCenter={positions[0]}
        defaultZoom={12}
        fullscreenControl={false}
        mapId="37a5dbe51230f056"
        mapTypeControl={false}
        streetViewControl={false}
      >
        {positions.map((position, i) => (
          <AdvancedMarker
            key={i}
            onClick={() => selectLocation(i)}
            position={position}
            zIndex={i === selectedIndex ? 10000 : i}
          >
            <Pin borderColor="black" scale={i === selectedIndex ? 1.25 : 1}>
              <span style={{ fontSize: i === selectedIndex ? "18px" : "14px" }}>
                {i + 1}
              </span>
            </Pin>
          </AdvancedMarker>
        ))}
      </Map>
    </div>
  );
});
