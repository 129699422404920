import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { GetUser } from "@redotech/redo-model/user";

export const formatTypingNames = (
  typing: Record<string, Date>,
  user: GetUser | undefined,
  conversation: ExpandedConversation,
) => {
  console.log(typing);
  const peopleTyping = Object.keys(typing).filter(
    (name) => name !== user?.name,
  );
  if (peopleTyping.length > 1) {
    return "Multiple people are typing...";
  } else if (peopleTyping.length === 1) {
    const name = peopleTyping[0];
    if (name === "Customer") {
      const customerName = conversation?.customer?.name || "Customer";
      return `${customerName} is typing...`;
    } else {
      return `${name} is typing...`;
    }
  } else {
    return "";
  }
};
