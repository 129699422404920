import { ConversationTag } from "@redotech/redo-model/conversation";
import { ActionType, AddTag } from "@redotech/redo-model/return-flow/action";
import { useContext } from "react";
import { ConversationTagsContext } from "../../app/conversation-tags-context";
import { ConversationTagInput } from "../../support/conversation-tags/conversation-tag-input";
import { ActionTypeSetup } from "./multiple-actions";

const getTagNames = (tags: ConversationTag[]) =>
  tags.map((tag: ConversationTag) => tag.name);

export const ADD_TAG: ActionTypeSetup<string[], AddTag> = {
  name: "Apply tags",
  description() {
    return "Adds tags to the ticket";
  },
  Details({ state, setState, disabled }) {
    const conversationTags = useContext(ConversationTagsContext);
    const tagNames = new Set(state);
    const tags = conversationTags.filter((tag) => tagNames.has(tag.name));

    return (
      <ConversationTagInput
        currentTags={tags}
        disabled={disabled}
        setCurrentTags={(tags) => setState(tags.map((tag) => tag.name))}
      />
    );
  },
  empty: [],
  fromModel(model) {
    return typeof model.tag === "string" ? [model.tag] : model.tag;
  },
  toModel(state) {
    return {
      type: ActionType.AddTag,
      tag: state,
    };
  },
  valid(state) {
    // FIXME @slarson-redo React hooks can only be used in React components, and this function is not a component
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const conversationTags = useContext(ConversationTagsContext);
    const tagNames = new Set(getTagNames(conversationTags));
    if (state.some((tag) => !tagNames.has(tag))) {
      return false;
    }
    return true;
  },
};
