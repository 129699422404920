// extracted by mini-css-extract-plugin
export var active = "_2u-3";
export var activeWrapper = "_2u-0";
export var button = "_2u-1";
export var collapsed = "_2u-i";
export var divider = "_2u-7";
export var dividerSection = "_2u-6";
export var dropdown = "_2u-8";
export var dropdownChevron = "_2u-a";
export var expand = "_2u-9";
export var fullscreen = "_2u-r";
export var icon = "_2u-n";
export var label = "_2u-5 _9-0";
export var labelWithBubble = "_2u-p _9-0";
export var logo = "_2u-j";
export var logoSection = "_2u-m";
export var main = "_2u-q";
export var menu = "_2u-b";
export var menuHeader = "_2u-c _9-0";
export var menuItem = "_2u-d _9-0";
export var nav = "_2u-e";
export var noBackground = "_2u-2";
export var option = "_2u-4";
export var options = "_2u-g";
export var optionsContainer = "_2u-f";
export var page = "_2u-h";
export var rotate = "_2u-o";
export var section = "_2u-k";
export var settings = "_2u-t";
export var subitem = "_2u-s";
export var subitemActive = "_2u-x";
export var subitemLabel = "_2u-w";
export var subitemLine = "_2u-v";
export var subitemLineContainer = "_2u-u";
export var subsection = "_2u-l";