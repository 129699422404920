import { genericMemo } from "@redotech/react-util/component";
import { ArrowDown, ArrowUp, EnterKey } from "@redotech/web-util/key";
import * as React from "react";
import { ReactNode, useEffect } from "react";
import { Flex } from "../../flex";
import {
  RedoListItem,
  RedoListItemMenu,
  RedoListItemSize,
} from "./redo-list-item";
import * as redoListCss from "./redo-list.module.css";

export interface RedoListItem<T> {
  key?: string;
  children: ReactNode;
  value: T;
  menu?: RedoListItemMenu;
  selected?: boolean;
}

export enum RedoListItemSelectedSource {
  Keyboard = "keyboard",
  Mouse = "mouse",
}

export interface RedoListProps<T> {
  size: RedoListItemSize;
  items: RedoListItem<T>[];
  itemSelected(item: RedoListItem<T>, source: RedoListItemSelectedSource): void;
  refToListenTo: HTMLElement | null;
  focusedIndex: number | undefined;
  setFocusedIndex(index: number | undefined): void;
}

export const RedoList = genericMemo(function RedoList<T>({
  size,
  items,
  itemSelected,
  focusedIndex,
  setFocusedIndex,
  refToListenTo,
}: RedoListProps<T>) {
  function handleKeyPress(event: KeyboardEvent | React.KeyboardEvent) {
    if (event.key === EnterKey) {
      event.preventDefault();
      if (focusedIndex === undefined) {
        return;
      }
      itemSelected(items[focusedIndex], RedoListItemSelectedSource.Keyboard);
    } else if (event.key === ArrowDown) {
      event.preventDefault();
      const oldIndex = focusedIndex ?? -1;
      const newIndex = Math.min(oldIndex + 1, items.length - 1);
      setFocusedIndex(newIndex);
    } else if (event.key === ArrowUp) {
      event.preventDefault();
      const oldIndex = focusedIndex ?? -1;
      const newIndex = Math.max(oldIndex - 1, -1);
      if (newIndex === -1) {
        setFocusedIndex(undefined);
      } else {
        setFocusedIndex(newIndex);
      }
    }
  }

  function onBlur() {
    setFocusedIndex(undefined);
  }

  useEffect(() => {
    refToListenTo?.addEventListener("blur", onBlur);
    refToListenTo?.addEventListener("keydown", handleKeyPress);
    return () => {
      refToListenTo?.removeEventListener("keydown", handleKeyPress);
      refToListenTo?.removeEventListener("blur", onBlur);
    };
  }, [refToListenTo, handleKeyPress, onBlur]);

  return (
    <Flex
      className={redoListCss.childrenContainer}
      dir="column"
      onKeyDown={handleKeyPress}
      tabIndex={0}
    >
      {items.map((item, idx) => {
        return (
          <RedoListItem
            focused={idx === focusedIndex}
            itemClicked={() =>
              itemSelected(item, RedoListItemSelectedSource.Mouse)
            }
            key={item.key ?? idx}
            menu={item.menu}
            selected={item.selected}
            size={size}
          >
            {item.children}
          </RedoListItem>
        );
      })}
    </Flex>
  );
});
