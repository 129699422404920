import { Order } from "@redotech/redo-model/order";
import { Team, UpsellProductSource } from "@redotech/redo-model/team";
import { ProductSortKeys } from "@redotech/shopify-client/storefront.graphql";
import {
  PRODUCT_INFO_FRAGMENT,
  ProductInfo,
  getCollection,
  getProduct,
  getProductWithMetafield,
  getProducts,
} from "./products";
import {
  GetProductRecommendationsQuery,
  GetProductRecommendationsQueryVariables,
} from "./shopify-storefront.graphql";
import { ShopifyStorefrontClient } from "./storefront-client";

const getProductRecommendationsQuery = /* GraphQL */ `
  ${PRODUCT_INFO_FRAGMENT}
  query getProductRecommendations($productId: ID!) {
    productRecommendations(productId: $productId) {
      ...ProductInfo
    }
  }
`;

export const getProductRecommendations = async (
  client: ShopifyStorefrontClient,
  productId: string,
): Promise<ProductInfo[]> => {
  const { data, errors } = await client.request<
    GetProductRecommendationsQuery,
    GetProductRecommendationsQueryVariables
  >(getProductRecommendationsQuery, { productId });

  if (errors) {
    if ((errors as any).graphQLErrors) {
      (errors as any).graphQLErrors.filter((error: Error) => {
        error.message.includes("totalInventory"); // this is a recoverable error
      });
    } else {
      throw errors;
    }
  }

  return data?.productRecommendations || [];
};

const validProduct = (product: ProductInfo) =>
  product.vendor !== "re:do" && product.variants.nodes?.[0]?.requiresShipping;

export async function getRecommendationsForMultipleProducts(
  storefrontClient: ShopifyStorefrontClient,
  productIDs: string[],
  numRecommendations: number,
  productIdsToExclude: string[] = [],
): Promise<ProductInfo[]> {
  const originalProducts = new Set(productIDs);
  const recommendations = (
    await Promise.all(
      productIDs.map(async (id: any) => {
        try {
          return await getProductRecommendations(storefrontClient, id);
        } catch (e) {
          console.error("Error getting product recommendations", e);
          return [];
        }
      }),
    )
  )
    .flat()
    .filter((p) => p.availableForSale);

  const counts = recommendations
    .filter(validProduct)
    .reduce(
      (
        acc: Record<string, { count: number; product: ProductInfo }>,
        product,
      ) => {
        if (!acc[product.id]) {
          acc[product.id] = { count: 0, product };
        }
        acc[product.id].count++;
        return acc;
      },
      {},
    );

  const topRecommendations = Object.entries(counts)
    .filter(([id]) => !productIdsToExclude.includes(id))
    .sort((a, b) => b[1].count - a[1].count)
    .slice(0, numRecommendations)
    .map(([_, { product }]) => product);

  try {
    // If we didn't find enough recommendations,
    // fill the rest of the list with the best sellers.
    if (topRecommendations.length < numRecommendations) {
      const iterator = getProducts(storefrontClient, {
        first: numRecommendations,
        query: `-vendor:re\\:do`,
        sortKey: ProductSortKeys.BestSelling,
      });
      for await (const product of iterator) {
        if (topRecommendations.length >= numRecommendations) {
          break;
        }
        // Don't push products we're already recommending,
        // or products we are trying to make recommendations for.
        if (
          validProduct(product) &&
          !counts[product.id] &&
          !originalProducts.has(product.id) &&
          product.availableForSale
        ) {
          counts[product.id] = { count: 1, product };
          topRecommendations.push(product);
        }
      }
    }
  } catch (e) {
    console.error("Error getting top products", e);
  }
  return topRecommendations;
}

export const getProductsByTags = async (
  client: ShopifyStorefrontClient,
  tags: string[],
  amount: number = 1000,
) => {
  const products: ProductInfo[] = [];
  try {
    const iterator = getProducts(client, {
      first: amount,
      query: `tags:${tags.map((tag: string) => `"${tag}"`).join(" OR ")}`,
      sortKey: ProductSortKeys.BestSelling,
    });
    for await (const product of iterator) {
      products.push(product);
    }
  } catch (e) {
    console.error("Error getting top products", e);
  }
  const productsWithImageAndPrice = products
    .map((recommendation) => {
      return {
        ...recommendation,
        altImage: {
          ...recommendation!.images.nodes[1],
        },
        price: recommendation!.priceRange.minVariantPrice.amount,
      };
    })
    .filter((p) => !!p);

  return productsWithImageAndPrice;
};

export const getProductsByCollection = async (
  client: ShopifyStorefrontClient,
  collectionId: string,
  numRecommendations: number,
) => {
  const collection = await getCollection(client, collectionId.toString());
  if (!collection) {
    throw new Error("Collection not found");
  }

  const items = collection.products.nodes
    .map((product: any) => {
      return {
        ...product,
        altImage: {
          ...product.images.nodes[1],
        },
        price: product.priceRange.minVariantPrice.amount,
      };
    })
    .slice(0, numRecommendations);
  return items;
};

export const getProductsById = async (
  client: ShopifyStorefrontClient,
  productIds: string[],
) => {
  const products = [];
  try {
    for (const id of productIds) {
      const iterator = getProducts(client, {
        query: `id:${id}`,
      });
      for await (const product of iterator) {
        products.push({
          ...product,
          price: product.priceRange.minVariantPrice.amount,
        });
      }
    }
  } catch (e) {
    console.error("Error getting products by id", e);
  }

  return products;
};

export const getProductsByShopifyRecommendations = async (
  storefrontClient: any,
  order: Order,
  numRecommendations: number,
) => {
  const lineItems = order.shopify?.line_items || [];
  const productIDs: string[] = lineItems
    .filter((item: any) => item.vendor !== "re:do")
    .map((item: any) => `gid://shopify/Product/${item.product_id}`);

  // get extra recommended products so that if any have low inventory, we can filter them out
  const test = (
    await getRecommendationsForMultipleProducts(
      storefrontClient,
      productIDs,
      15,
    )
  ).map((p) => {
    return {
      ...p,
      altImage: {
        url: p?.images?.nodes?.[1]?.url,
        altText: p?.images?.nodes?.[1]?.altText,
      },
      price: p.priceRange.minVariantPrice.amount,
    };
  });
  return test;
};

export const getProductsByProductMetafieldInOrder = async (
  storefrontClient: ShopifyStorefrontClient,
  order: Order,
  metafield: {
    key: string;
    namespace: string;
    metaobjectKey?: string;
  },
  numRecommendations: number,
) => {
  const productsSortedByPrice = order.shopify.line_items.sort(
    (a, b) => b.price - a.price,
  );
  const productIds: string[] = productsSortedByPrice.map((item: any) =>
    item.product_id.toString(),
  );
  if (!metafield?.key || !metafield?.namespace) {
    console.error("Metafield key or namespace not found in settings");
    return getProductsByShopifyRecommendations(
      storefrontClient,
      order,
      numRecommendations,
    );
  }

  const productsWithMetafields = await successfulResults(productIds, (id) =>
    getProductWithMetafield(
      storefrontClient,
      id,
      metafield.key,
      metafield.namespace,
      metafield.metaobjectKey,
    ),
  );

  const metafieldValues = await successfulResults(
    productsWithMetafields,
    async (product) => {
      if (!product.chosenMetafield) {
        return [];
      }
      const value =
        "reference" in product.chosenMetafield
          ? product.chosenMetafield.reference?.field?.value
          : product.chosenMetafield.value;
      return JSON.parse(value ?? "[]");
    },
  );

  const recommendationIds = [
    ...new Set(
      metafieldValues
        .filter((v) => !!v)
        .reduce<
          string[]
        >((all: string[], subset: string[]) => all.concat(subset.map((id) => id.trim())), []),
    ),
  ];

  const recommendations = await successfulResults(recommendationIds, (id) =>
    getProduct(storefrontClient, id),
  );

  const items = recommendations
    .map((recommendation) => {
      return {
        ...recommendation,
        altImage: {
          ...recommendation!.images.nodes[1],
        },
        price: recommendation!.priceRange.minVariantPrice.amount,
      };
    })
    .filter((p) => !!p);

  if (items.length < numRecommendations) {
    // Couldn't pull enough products from metafields, filling in remaining with recommendations
    const recommendedProducts = await getProductsByShopifyRecommendations(
      storefrontClient,
      order,
      numRecommendations - items.length,
    );
    const uniqueRecommendedProducts = recommendedProducts.filter(
      (p) => !items.some((item) => item.id === p.id),
    );
    const itemsWithExtra = items.concat(uniqueRecommendedProducts);
    return itemsWithExtra;
  }
  return items;
};

function isSuccessful<T>(
  result: PromiseSettledResult<T>,
): result is PromiseFulfilledResult<T> {
  return result.status === "fulfilled" && !!result.value;
}

async function successfulResults<A, B>(
  inputValues: A[],
  fn: (value: A) => Promise<B>,
): Promise<NonNullable<B>[]> {
  return (await Promise.allSettled(inputValues.map(fn)))
    .filter(isSuccessful)
    .map((result) => result.value as NonNullable<B>);
}

export async function getRecommendedProductsByTeamStrategy(
  team: Pick<Team, "storeUrl" | "storefrontAccessToken" | "settings">,
  numRecommendations: number,
  order?: Order,
): Promise<
  Omit<
    Awaited<ReturnType<typeof getProductsByProductMetafieldInOrder>>,
    "altImage" | "price"
  >
> {
  const client = new ShopifyStorefrontClient(
    team.storeUrl,
    team.storefrontAccessToken!,
  );

  // TODO: this function shouldn't depend on the existence of order tracking
  const teamUpsellSettings = team?.settings?.orderTracking?.upsellProducts;
  if (!teamUpsellSettings) {
    console.error(
      "Upsell product settings not found in team settings, falling back to default",
      {
        teamId: team?.storeUrl,
      },
    );
  }

  const upsellSettings = teamUpsellSettings || {
    source: UpsellProductSource.RECOMMENDATIONS,
  };

  const recommendations: Omit<
    Awaited<ReturnType<typeof getProductsByProductMetafieldInOrder>>,
    "altImage" | "price"
  > = await (async () => {
    switch (upsellSettings.source) {
      case UpsellProductSource.COLLECTION:
        if (!upsellSettings.collectionId) {
          throw new Error("Collection ID not found in team settings");
        }
        return await getProductsByCollection(
          client,
          upsellSettings.collectionId.toString(),
          numRecommendations,
        );
      case UpsellProductSource.METAFIELD: {
        if (!order) {
          throw new Error("Order not found");
        }
        const metafield =
          team.settings.orderTracking?.upsellProducts?.metafield;
        if (!metafield) {
          return await getProductsByShopifyRecommendations(
            client,
            order,
            numRecommendations,
          );
        }
        return await getProductsByProductMetafieldInOrder(
          client,
          order,
          metafield,
          numRecommendations,
        );
      }
      case UpsellProductSource.TAG: {
        if (!order) {
          throw new Error("Order not found");
        }
        const tags = team.settings.orderTracking?.upsellProducts?.productTags;
        if (!tags) {
          return await getProductsByShopifyRecommendations(
            client,
            order,
            numRecommendations,
          );
        }
        return await getProductsByTags(client, tags, numRecommendations);
      }
      case UpsellProductSource.RECOMMENDATIONS:
        if (!order) {
          throw new Error("Order not found");
        }
        return await getProductsByShopifyRecommendations(
          client,
          order,
          numRecommendations,
        );
    }
  })();

  return recommendations.filter((product) => product.vendor !== "re:do");
}
