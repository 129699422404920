// extracted by mini-css-extract-plugin
export var box = "_27-0";
export var check = "_27-1";
export var checkboxButton = "_27-6";
export var checkboxGroup = "_27-5";
export var disabled = "_27-7";
export var ghost = "_27-9";
export var input = "_27-2";
export var label = "_27-4";
export var normal = "_27-8";
export var uncheck = "_27-3";