// extracted by mini-css-extract-plugin
export var brand = "_28-9";
export var button = "_28-0";
export var chevron = "_28-u";
export var chevronButton = "_28-v";
export var content = "_28-n";
export var danger = "_28-g";
export var dangerOutlined = "_28-h";
export var dark = "_28-l";
export var darkTheme = "_28-c";
export var extraSmall = "_28-4";
export var fullWidth = "_28-8";
export var ghost = "_28-d";
export var gray = "_28-s";
export var icon = "_28-o";
export var iconButton = "_28-r";
export var iconButtonBorder = "_28-w";
export var iconButtonBorderDark = "_28-x";
export var iconButtonBorderLight = "_28-y";
export var iconLeft = "_28-p";
export var iconRight = "_28-q";
export var large = "_28-1";
export var light = "_28-m";
export var medium = "_28-2";
export var micro = "_28-5";
export var nano = "_28-7";
export var outlined = "_28-k";
export var pending = "_28-a";
export var primary = "_28-i";
export var small = "_28-3";
export var solidLight = "_28-e";
export var solidLightBrand = "_28-f";
export var spinner = "_28-t";
export var transparent = "_28-j";
export var warning = "_28-b";
export var wideNano = "_28-6";