import { OverlapTransitionStateContext } from "@redotech/react-animation/outlet-transition";
import { OverlapTransitionState } from "@redotech/react-animation/transition";
import { RouterOutletFade } from "@redotech/react-router-util/outlet-fade";
import {
  useHtmlEventListener,
  useWindowEventListener,
} from "@redotech/react-util/event";
import { useScrolled } from "@redotech/react-util/scroll";
import * as classnames from "classnames";
import {
  ReactNode,
  createContext,
  memo,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { Breadcrumbs } from "./breadcrumb";
import * as pageCss from "./page.module.css";
import { SideNavLogo, SideNavPage } from "./side-nav";

export const Action0PortalContext = createContext<HTMLElement | undefined>(
  undefined,
);

export const ActionPortalContext = createContext<HTMLElement | undefined>(
  undefined,
);

export const Actions = memo(function Actions({
  show,
  children,
  noBorder = false,
}: {
  children: ReactNode | ReactNode[];
  show: boolean;
  noBorder?: boolean;
}) {
  const transitionState = useContext(OverlapTransitionStateContext);

  const [ref, setRef] = useState<HTMLElement | null>(null);
  const [goal, setGoal] = useState(show);
  const [finished, setFinished] = useState(true);

  useHtmlEventListener(ref, "transitionend", (e) => {
    if (e.propertyName !== "opacity") {
      return;
    }
    setFinished(true);
  });

  useLayoutEffect(() => {
    if (!ref || show === goal) {
      return;
    }
    void ref.offsetHeight;
    setGoal(show);
    setFinished(false);
  }, [show, ref, goal]);

  if (
    (!show && !goal && finished) ||
    transitionState === OverlapTransitionState.EXIT
  ) {
    return null;
  }

  return (
    <div
      className={classnames(pageCss.actions, !noBorder && pageCss.border, {
        [pageCss.collapse]: !goal,
      })}
      ref={setRef}
    >
      {children}
    </div>
  );
});

let scrollXCount = 0;

export function useScrollableWidth() {
  useLayoutEffect(() => {
    if (!scrollXCount++) {
      document.documentElement.classList.add(pageCss.noScrollX);
    }
    return () => {
      if (!--scrollXCount) {
        document.documentElement.classList.remove(pageCss.noScrollX);
      }
    };
  }, []);
}

let scrollYCount = 0;

export function useScrollableHeight() {
  useLayoutEffect(() => {
    if (!scrollYCount++) {
      document.documentElement.classList.add(pageCss.noScrollY);
    }
    return () => {
      if (!--scrollYCount) {
        document.documentElement.classList.remove(pageCss.noScrollY);
      }
    };
  }, []);
}

export const Page = memo(function Page({
  logo,
  nav,
  profile,
  banner,
  hideHeader = (url: string) => false,
  hideNavbar = (url: string) => false,
  // Used to hide the box shadow on the header in cases where we want a larger header - the page itself should handle it if used
  extendedHeader = (url: string) => false,
  hidePadding = (url: string) => false,
}: {
  logo(expanded: boolean): ReactNode;
  nav: ReactNode;
  profile?: ReactNode;
  banner?: ReactNode;
  hideHeader?: (url: string) => boolean;
  hideNavbar?: (url: string) => boolean;
  extendedHeader?: (url: string) => boolean;
  hidePadding?: (url: string) => boolean;
}) {
  const [actions0Element, setActions0Element] = useState<HTMLElement | null>(
    null,
  );
  const [actionsElement, setActionsElement] = useState<HTMLElement | null>(
    null,
  );

  const [scrollPosition, setScrollPosition] = useState(window.scrollY);
  useWindowEventListener(window, "scroll", () => {
    setScrollPosition(window.scrollY);
  });
  const location = useLocation();

  const [hideHeader_, setHideHeader_] = useState(false);
  const [hideNavbar_, setHideNavbar_] = useState(false);
  const [hidePadding_, setHidePadding_] = useState(false);
  const [extendedHeader_, setExtendedHeader_] = useState(false);
  useEffect(() => {
    setHideHeader_(hideHeader(window.location.href));
    setHideNavbar_(hideNavbar(window.location.href));
    setHidePadding_(hidePadding(window.location.href));
    setExtendedHeader_(extendedHeader(window.location.href));
  }, [location]);

  const compact = 24 < scrollPosition;

  const scrolled = useScrolled(document.documentElement);
  return (
    <SideNavPage
      hidden={hideNavbar_}
      nav={nav}
      top={<PageLogo>{logo}</PageLogo>}
    >
      {!hideNavbar_ && banner}
      {!hideHeader_ && (
        <div className={pageCss.headerContainer}>
          <div
            className={classnames(pageCss.header, {
              [pageCss.compact]: compact,
              [pageCss.scrolledTop]: extendedHeader_ ? true : scrolled.top,
            })}
          >
            <header className={pageCss.title}>
              <Breadcrumbs />
            </header>
            <div
              className={pageCss.actionsContainer}
              ref={setActions0Element}
            />
            <div className={pageCss.actionsContainer} ref={setActionsElement} />
            {profile}
          </div>
        </div>
      )}
      <Action0PortalContext.Provider value={actions0Element || undefined}>
        <ActionPortalContext.Provider value={actionsElement || undefined}>
          <RouterOutletFade
            childClassName={pageCss.contentChild}
            containerClassName={classnames(pageCss.content, {
              [pageCss.fullscreen]: hideNavbar_,
              [pageCss.hidePadding]: hidePadding_,
              // @andrew-radford will re-add this when fixing breadcrumb spacing things
              // [pageCss.optOutTopPadding]: hideHeader_,
            })}
            element="main"
          />
        </ActionPortalContext.Provider>
      </Action0PortalContext.Provider>
    </SideNavPage>
  );
});

const PageLogo = memo(function PageLogo({
  children,
}: {
  children(expanded: boolean): ReactNode;
}) {
  return (
    <SideNavLogo collapsed={children(false)}>{children(true)}</SideNavLogo>
  );
});
