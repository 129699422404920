// extracted by mini-css-extract-plugin
export var actionButtonsContainer = "_72-0";
export var footer = "_72-2";
export var infoSection = "_72-3";
export var infoSectionContainer = "_72-4";
export var infoSectionTitleRow = "_72-5";
export var itemDetails = "_72-6";
export var line = "_72-7";
export var lineItemImage = "_72-1";
export var lineItems = "_72-8";
export var modalContent = "_72-9";
export var multiLineInfo = "_72-a";
export var orderName = "_72-b";
export var strong = "_72-c";