import { IterableMap } from "@redotech/react-util/component";
import { useLoad } from "@redotech/react-util/load";
import { Order } from "@redotech/redo-model/order";
import {
  DiscountCollectionsSelectionType,
  DiscountType,
} from "@redotech/redo-model/order-discount";
import { Return } from "@redotech/redo-model/return";
import {
  upsellCardDefaultText,
  UpsellProductSection,
  UpsellProductSource,
} from "@redotech/redo-model/team";
import { Card } from "@redotech/redo-web/card";
import { Flex } from "@redotech/redo-web/flex";
import { ExternalLink } from "@redotech/redo-web/link";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { Text } from "@redotech/redo-web/text";
import {
  getAllCollections,
  getCollection,
  getProductsByCollection,
  getProductsById,
  getProductsByProductMetafieldInOrder,
  getProductsByShopifyRecommendations,
  getProductsByTags,
  ShopifyStorefrontClient,
} from "@redotech/shopify-storefront";
import * as classNames from "classnames";
import { memo, useContext } from "react";
import { getItemsViewedAndInCart, logPageClick } from "../../../api";
import { ReturnAppSettings } from "../../../app/settings";
import {
  DiscountResponse,
  getDiscountFlowResults,
  getOrCreateDiscountCode,
} from "../../../utils/discount-code";
import { redirectToWebsiteWithDiscountCode } from "../../../utils/utils";
import { WindowContext } from "../../window";
import * as trackingCss from "../order-tracking.module.css";
import { validDiscount } from "./discount-util";
import { ExpirationCountdown } from "./expiration-countdown";
import { TrackingType } from "./fulfillment";
import * as fulfillmentCss from "./fulfillment.module.css";
import { RecommendedProductComponent } from "./recommended-product";

export const UpsellProductsCard = memo(function UpsellProductsCard({
  order,
  settings,
  upsellText,
  onUpsellSectionSelect,
  editMode,
  upsellSectionHighlighted,
  storefrontClient,
  upsellSectionSettings,
  trackingType = TrackingType.ORDER,
  returnData,
}: {
  order: Order;
  settings: ReturnAppSettings;
  upsellText: string;
  onUpsellSectionSelect?: () => void;
  editMode: boolean;
  upsellSectionHighlighted?: boolean;
  storefrontClient: ShopifyStorefrontClient;
  upsellSectionSettings?: UpsellProductSection;
  trackingType?: TrackingType;
  returnData?: Return;
}) {
  const recommendedProductsLoad = useLoad(
    async () => {
      if (!storefrontClient) {
        return [];
      }
      let items = [];

      if (!editMode) {
        const viewedAndAddedToCartProducts = await getItemsViewedAndInCart({
          team: order.team,
          email: order.shopify.contact_email,
          date: order.createdAt,
        });
        if (viewedAndAddedToCartProducts.data.length > 0) {
          items = await getProductsById(
            storefrontClient,
            viewedAndAddedToCartProducts.data,
          );
          items = items.filter(
            (product) =>
              product.availableForSale &&
              !order.shopify.line_items.some((lineItem) =>
                product.id.toString().includes(lineItem.product_id.toString()),
              ),
          );
          if (items.length >= 3) {
            return items.slice(0, 3);
          }
        }
      }

      switch (upsellSectionSettings?.source) {
        case UpsellProductSource.COLLECTION: {
          const collectionId = upsellSectionSettings?.collectionId;
          if (!collectionId) {
            console.error("Upsell collection ID not found in settings");
          }
          items = [
            ...items,
            ...(await getProductsByCollection(
              storefrontClient,
              collectionId.toString(),
              3,
            )),
          ];
          break;
        }
        case UpsellProductSource.METAFIELD: {
          const metafield = upsellSectionSettings?.metafield;
          if (!metafield?.key || !metafield?.namespace) {
            console.error("Metafield key or namespace not found in settings");
            items = [
              ...items,
              ...(await getProductsByShopifyRecommendations(
                storefrontClient,
                order,
                3,
              )),
            ];
            break;
          }
          items = [
            ...items,
            ...(await getProductsByProductMetafieldInOrder(
              storefrontClient,
              order,
              metafield,
              3,
            )),
          ];
          break;
        }
        case UpsellProductSource.TAG: {
          const productTags = upsellSectionSettings?.productTags;
          if (!productTags || productTags.length === 0) {
            console.error("Upsell collection ID not found in settings");
          }

          // getProductsByCollection returns an object with altImage, price, etc. we mimic that here.
          items = [
            ...items,
            ...(
              await getProductsByTags(storefrontClient, productTags, 250)
            ).map((product) => {
              return {
                ...product,
                altImage: {
                  ...product.images.nodes[1],
                },
                price: product.priceRange.minVariantPrice.amount,
              };
            }),
          ];
          break;
        }
        default:
          items = [
            ...items,
            ...(await getProductsByShopifyRecommendations(
              storefrontClient,
              order,
              3,
            )),
          ];
          break;
      }
      return items.filter((product) => product.availableForSale).slice(0, 3);
    },
    editMode
      ? [
          upsellSectionSettings.source,
          upsellSectionSettings.metafield,
          upsellSectionSettings.metafieldKey,
          upsellSectionSettings.collectionName,
        ]
      : [storefrontClient, order],
  );

  const discountFlowLoad = useLoad<DiscountResponse | undefined>(async () => {
    const discountObject = await getDiscountFlowResults(
      order._id,
      trackingType,
      returnData?.returnType,
    );
    return discountObject.settings.enabled
      ? discountObject
      : Promise.resolve(undefined);
  }, [order._id]);

  const collectionsLoad = useLoad(async () => {
    const discountSettings = discountFlowLoad.value?.settings?.settings;
    if (discountSettings.discountType !== DiscountType.PRODUCT) {
      return undefined;
    }

    const collections = await getAllCollections(storefrontClient);

    const discountCollections = await Promise.all(
      discountSettings.discountCollections.map((collection) =>
        getCollection(storefrontClient, collection.id),
      ),
    );

    if (
      discountSettings.discountCollectionsSelectionType ===
      DiscountCollectionsSelectionType.INCLUDE
    ) {
      return discountCollections;
    } else {
      return collections.filter((collection) =>
        discountCollections.some(
          (discountCollection) => discountCollection.id !== collection.id,
        ),
      );
    }
  }, [discountFlowLoad.value]);

  const getUpsellText = (): string => {
    let upsellText = upsellCardDefaultText;
    if (
      upsellSectionSettings?.text &&
      upsellSectionSettings?.text !== upsellCardDefaultText
    ) {
      upsellText = upsellSectionSettings.text;
    }
    if (
      discountFlowLoad?.value &&
      validDiscount(discountFlowLoad?.value?.settings, order) &&
      discountFlowLoad?.value?.shortDescription
    ) {
      upsellText = `Shop again, get ${discountFlowLoad.value.shortDescription}`;
    }
    return upsellText;
  };

  const getInitialExpirationDuration = (): number | undefined => {
    if (discountFlowLoad.value) {
      const expirationDays =
        discountFlowLoad.value.settings.settings.expirationDays ?? 10;
      const duration = Temporal.Duration.from({
        hours: expirationDays * 24,
      });
      const endDate = Temporal.Instant.from(order.createdAt).add(duration);
      const futureInstant = Temporal.Instant.from(endDate);
      const durationUntilFutureInMs = Temporal.Now.instant()
        .until(futureInstant)
        .total({ unit: "milliseconds" });
      return durationUntilFutureInMs > 0 ? durationUntilFutureInMs : undefined;
    }
    return undefined;
  };

  const getDiscountCode = async (discountResponse: DiscountResponse) => {
    if (validDiscount(discountResponse.settings, order)) {
      if (discountResponse.created) {
        return discountResponse.discount.codes?.nodes[0]?.code;
      } else {
        const newDiscount = await getOrCreateDiscountCode(
          order._id,
          trackingType,
        );

        return newDiscount
          ? newDiscount.discount.codes?.nodes[0]?.code
          : undefined;
      }
    }
    return undefined;
  };

  const initialMilliseconds = getInitialExpirationDuration();
  const selectWindow = useContext(WindowContext);

  const storeURL = `https://${
    settings.exchanges.shopSiteDomain || settings.storeUrl
  }`;

  const borderClasses = classNames(
    fulfillmentCss.upsellProductsCardBorder,
    ...(upsellSectionHighlighted ? [fulfillmentCss.highlighted] : []),
  );

  if (recommendedProductsLoad.pending || discountFlowLoad.pending) {
    return (
      <Card className={fulfillmentCss.upsellProductsCard}>
        <Text fontSize="lg" fontWeight="semibold">
          Loading deals...
        </Text>

        <LoadingRedoAnimation />
      </Card>
    );
  } else {
    return (
      <div className={borderClasses}>
        <Card
          className={classNames(fulfillmentCss.upsellProductsCard, {
            [fulfillmentCss.editMode]: editMode,
          })}
          onClick={onUpsellSectionSelect}
        >
          <Flex dir="column" inert={editMode}>
            <Flex
              align="center"
              className={trackingCss.upsellHeader}
              justify="space-between"
            >
              <Text fontSize="lg" fontWeight="semibold">
                {upsellText ?? getUpsellText()}
              </Text>
              <ExternalLink
                onClick={async () => {
                  void logPageClick({
                    trackableId: order._id,
                    trackableType: trackingType,
                    eventType: "upsell",
                    url: storeURL,
                  });
                  const discountCode = await getDiscountCode(
                    discountFlowLoad?.value,
                  );
                  redirectToWebsiteWithDiscountCode({
                    utmSource: "redo",
                    utmMedium: "order_tracking",
                    utmContent: "upsell",
                    utmTerm: order._id,
                    settings,
                    window: selectWindow,
                    discountCode: discountCode,
                  });
                }}
              >
                Shop full store
              </ExternalLink>
            </Flex>
            {initialMilliseconds && (
              <ExpirationCountdown initialMilliseconds={initialMilliseconds} />
            )}
          </Flex>

          <Flex dir="column" inert={editMode}>
            {recommendedProductsLoad.value ? (
              <IterableMap
                items={recommendedProductsLoad.value}
                keyFn={(item: any) => item.id}
              >
                {(product, index) => (
                  <RecommendedProductComponent
                    collectionsLoad={collectionsLoad}
                    discountFlowLoad={discountFlowLoad}
                    getDiscountCode={getDiscountCode}
                    index={index}
                    order={order}
                    product={product}
                    returnData={returnData}
                    selectWindow={selectWindow}
                    settings={settings}
                    storeURL={storeURL}
                    trackingType={trackingType}
                  />
                )}
              </IterableMap>
            ) : null}
          </Flex>
        </Card>
      </div>
    );
  }
});
