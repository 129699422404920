import CheckIcon from "@redotech/redo-web/arbiter-icon/check.svg";
import MinusIcon from "@redotech/redo-web/arbiter-icon/minus.svg";
import { Text } from "@redotech/redo-web/text";
import * as classNames from "classnames";
import { memo, useEffect, useRef } from "react";
import { Flex } from "../../flex";
import { TextSizeValue } from "../../theme/typography";
import * as redoCheckboxCss from "./redo-checkbox.module.css";

export type CheckboxValue = boolean | "indeterminate";

export enum RedoCheckboxSize {
  SMALL = "small",
  MEDIUM = "medium",
}

export interface RedoCheckboxProps {
  value: CheckboxValue;
  setValue(value: CheckboxValue): void;
  size?: RedoCheckboxSize;
  disabled?: boolean;
  label?: string;
  description?: string;
  showLabelSpacer?: boolean;
  showDescriptionSpacer?: boolean;
}

export const RedoCheckbox = memo(function RedoCheckbox({
  value,
  setValue,
  disabled,
  label,
  description,
  size = RedoCheckboxSize.MEDIUM,
  showLabelSpacer,
  showDescriptionSpacer,
}: RedoCheckboxProps) {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.stopPropagation();
    if (event.target.indeterminate) {
      setValue("indeterminate");
    } else if (event.target.checked) {
      setValue(true);
    } else {
      setValue(false);
    }
  }

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.indeterminate = value === "indeterminate";
  }, [value, inputRef]);

  const checked = !!value;

  const Icon = value === "indeterminate" ? MinusIcon : CheckIcon;

  const labelColor = disabled || !checked ? "secondary" : "primary";
  const labelWeight = checked ? "medium" : "regular";

  return (
    <Flex as="label">
      <Flex pt="xxs">
        <input
          checked={checked}
          className={redoCheckboxCss.input}
          disabled={disabled}
          onChange={handleChange}
          ref={inputRef}
          type="checkbox"
        />
        <div
          className={classNames(
            redoCheckboxCss.box,
            checkboxSizeToCssSize[size],
            disabled && redoCheckboxCss.disabled,
            checked && redoCheckboxCss.checked,
          )}
        >
          <Icon
            className={classNames(
              redoCheckboxCss.icon,
              checkboxSizeToCssSize[size],
              disabled && redoCheckboxCss.disabled,
              checked && redoCheckboxCss.checked,
            )}
          />
        </div>
      </Flex>
      <Flex dir="column" gap="none">
        {label ? (
          <Text
            className={redoCheckboxCss.text}
            fontSize={checkboxSizeToLabelSize[size]}
            fontWeight={labelWeight}
            textColor={labelColor}
          >
            {label}
          </Text>
        ) : (
          showLabelSpacer && (
            <span
              className={classNames(
                redoCheckboxCss.labelSpacer,
                checkboxSizeToCssSize[size],
              )}
            />
          )
        )}
        {description ? (
          <Text
            className={redoCheckboxCss.text}
            fontSize="sm"
            textColor="tertiary"
          >
            {description}
          </Text>
        ) : (
          showDescriptionSpacer && (
            <span className={redoCheckboxCss.descriptionSpacer} />
          )
        )}
      </Flex>
    </Flex>
  );
});

const checkboxSizeToCssSize: Record<RedoCheckboxSize, string> = {
  [RedoCheckboxSize.SMALL]: redoCheckboxCss.small,
  [RedoCheckboxSize.MEDIUM]: redoCheckboxCss.medium,
};

const checkboxSizeToLabelSize: Record<RedoCheckboxSize, TextSizeValue> = {
  [RedoCheckboxSize.SMALL]: "sm",
  [RedoCheckboxSize.MEDIUM]: "md",
};
