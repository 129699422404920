import { ReturnWarning } from "@redotech/redo-model/return";
import { Card } from "@redotech/redo-web/card";
import { memo } from "react";

import * as returnStatus from "./return-status.module.css";

export const WarningsCard = memo(function WarningsCard({
  warnings,
}: {
  warnings: any;
}) {
  return (
    <Card
      className={returnStatus.card}
      title="There were some problems with your return."
    >
      <div className={returnStatus.warnings}>
        Here are the issues we are working to resolve:
        {warnings.map((warning: ReturnWarning, i) => {
          return (
            <div className={returnStatus.warning} key={i}>
              {warning.customerMessage}
            </div>
          );
        })}
      </div>
    </Card>
  );
});
