export const formatTimeAgo = (time: string | undefined): string => {
  if (!time) {
    return "";
  }

  const now = Temporal.Now.zonedDateTimeISO();
  const inputTime = Temporal.Instant.from(time).toZonedDateTimeISO(
    now.timeZoneId,
  );
  const duration = now.since(inputTime);

  const minutes = Math.floor(duration.total({ unit: "minute" }));

  const today = now.toPlainDate();
  const inputDate = inputTime.toPlainDate();
  const daysDifference = today.since(inputDate).total({ unit: "day" });

  // "Now" for times within the last minute
  if (minutes < 1) {
    return "Now";
  }

  // "<Number of mintues>m" for times within the last 60 minutes
  if (minutes < 60) {
    return `${minutes}m`;
  }

  // "hh:mm AM/PM" for times within the current day
  if (daysDifference < 1) {
    return inputTime.toPlainTime().toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }

  // "Yesterday" for times from the previous day
  if (daysDifference === 1) {
    return "Yesterday";
  }

  // Day of the week for times within the last week
  if (daysDifference < 7) {
    return inputDate.toLocaleString("en-US", { weekday: "long" });
  }

  // "<Month name>, dd, YYYY" for times older than a week
  return inputDate.toLocaleString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};
