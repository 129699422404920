import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useTriggerLoad } from "@redotech/react-util/load";
import { RedoClient } from "@redotech/redo-api-client";
import {
  sendTyping,
  uploadFile,
} from "@redotech/redo-api-client/conversations";
import { MacroModal } from "@redotech/redo-merchant-app/support/macros/macro-modal";
import {
  ConversationPlatform,
  ExpandedConversation,
  MessageVisibility,
} from "@redotech/redo-model/conversation";
import { Attachment } from "@redotech/redo-model/createconversationbody";
import { Macro, MacroStatusToSet } from "@redotech/redo-model/macro";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import ThreeDotsIcon from "@redotech/redo-web/arbiter-icon/dots-horizontal_filled.svg";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  IconButton,
} from "@redotech/redo-web/button";
import { ButtonDropdown } from "@redotech/redo-web/button-dropdown";
import CircleSpinner from "@redotech/redo-web/circle-spinner.svg";
import { RedoClientContext } from "@redotech/redo-web/client";
import { Dropdown, DropdownOption } from "@redotech/redo-web/dropdown";
import AiIcon from "@redotech/redo-web/icon-old/ai.svg";
import ChatBubbleIcon from "@redotech/redo-web/icon-old/chat-bubble.svg";
import ChevronDownIcon from "@redotech/redo-web/icon-old/chevron-down.svg";
import LightningIcon from "@redotech/redo-web/icon-old/lightning.svg";
import NoteIcon from "@redotech/redo-web/icon-old/note.svg";
import PaperclipIcon from "@redotech/redo-web/icon-old/paperclip.svg";
import PercentIcon from "@redotech/redo-web/icon-old/percent.svg";
import ShoppingBagIcon from "@redotech/redo-web/icon-old/shopping-bag.svg";
import StarsIcon from "@redotech/redo-web/icon-old/stars-01.svg";
import { QuillEditor } from "@redotech/redo-web/quill-editor";
import * as quillEditorCss from "@redotech/redo-web/quill-editor.module.css";
import Quill from "quill";
import {
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { UserContext } from "../app/user";
import { sendMessageMerchant } from "../client/conversations";
import { getMacros, replaceMacroVariables } from "../client/macro";
import { REDO_API_URL } from "../config";
import * as messageInputCss from "./message-input.module.css";
import {
  canSendMetaMessage,
  closeTicket,
  doFileDrop,
  getLastCustomerDirectMessage,
  listen,
} from "./utils";
const Delta = Quill.import("delta");
// Styles for quill editor
import * as amplitude from "@amplitude/analytics-browser";
import { JsonObject } from "@redotech/json/json";
import { PillTheme } from "@redotech/redo-model/pill-theme";
import { conversationFileUploadErrorMessages } from "@redotech/redo-model/support/conversations/conversation-file-upload-error";
import { teamCanUseAi } from "@redotech/redo-model/team";
import { GetUser, Permission, permitted } from "@redotech/redo-model/user";
import { toast } from "@redotech/redo-web/alert";
import { AttachmentThumbnail } from "@redotech/redo-web/attachment-thumbnail";
import { Autocomplete } from "@redotech/redo-web/autocomplete";
import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import CheckIcon from "@redotech/redo-web/icon-old/check.svg";
import XIcon from "@redotech/redo-web/icon-old/x.svg";
import { Pill, PillSize } from "@redotech/redo-web/pill";
import { QuillToolbarOptions } from "@redotech/redo-web/quill-toolbar-options";
import { Switch } from "@redotech/redo-web/switch";
import { Text } from "@redotech/redo-web/text";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { filterTruthy } from "@redotech/util/array";
import { assertNever } from "@redotech/util/type";
import * as classNames from "classnames";
import * as capitalize from "lodash/capitalize";
import { Delta as DeltaType, EmitterSource } from "quill/core";
import "quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { stringSimilarity } from "string-similarity-js";
import { useDebounce } from "usehooks-ts";
import { TeamContext } from "../app/team";
import { RedoMerchantClientContext } from "../client/context";
import { searchDiscountCodes, searchProducts } from "../client/shopify";
import { getSupportAiResponse } from "../client/support-ai";
import { ActiveConversationContext } from "./context/active-conversation-context";
import { ShopifyProduct } from "./create-order";
import {
  EmailSubjectMacroAutomationPill,
  StatusMacroAutomationPill,
  TagMacroAutomationPill,
} from "./macros/macro-automation-pill";
import {
  MacroAutomations,
  performMacroAutomations,
} from "./macros/perform-macro-automations";

import {
  EmailDraftMode,
  EmailDraftState,
  InDraftingEmailState,
} from "./conversation-email-view/email-draft-state";

import { ClickAwayListener } from "@mui/base";
import {
  hasAtLeastOneRecipientEmail,
  stringifyEmailRecipientsInfo,
} from "@redotech/redo-model/support/conversations/email-info";
import ChevronDownSvg from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import CornerUpLeftSvg from "@redotech/redo-web/arbiter-icon/corner-up-left_filled.svg";
import Send01Svg from "@redotech/redo-web/arbiter-icon/send-01.svg";
import StickerSquareSvg from "@redotech/redo-web/arbiter-icon/sticker-square.svg";
import { EditRecipientsModal } from "./action-panel/edit-recipients-modal";

const getTypingStream = (
  client: RedoClient,
  { conversationId, signal }: { conversationId: string; signal: AbortSignal },
) => {
  const url = `${REDO_API_URL}/conversations/${conversationId}/typing`;
  return fetch(url, { signal, headers: client.authorization() });
};
/* Edit history of Quill editor by find-replace on attributes. Used to clear the formatting of the macro variables. */
const findReplaceQuillInsertAttribute = (
  quill: Quill,
  attrName: string,
  attrValueToMatch: string | any,
  attrValueToSub: string | any,
) => {
  const content = quill.getContents();
  const ops = content.ops;

  for (let i = 0; i < ops.length; i++) {
    const op = ops[i];
    if (typeof op.insert === "string") {
      const attributes = op.attributes;

      if (attributes && attributes[attrName] === attrValueToMatch) {
        attributes[attrName] = attrValueToSub;
      }
    }
  }

  quill.setContents(quill.getContents());
};

enum AutocompleteType {
  MENTION = "mention",
  DISCOUNT_CODE = "discountCode",
  PRODUCT = "product",
  MACRO = "macro",
}

type OtherHotkeyType = "sendReply" | "sendReplyAndClose" | "addInternalNote";

/**
 * @ is not included because it is a shift key instead of a control key -- it actually gets typed
 */
const autoCompleteTypeToKey: Record<AutocompleteType, string> = {
  [AutocompleteType.MENTION]: "",
  [AutocompleteType.DISCOUNT_CODE]: "%",
  [AutocompleteType.PRODUCT]: "&",
  [AutocompleteType.MACRO]: "#",
};

const characterToAutoCompleteType: Record<string, AutocompleteType> = {
  "@": AutocompleteType.MENTION,
};

interface DraftContents {
  quillContents: DeltaType;
  attachments: Attachment[];
}

const DRAFT_KEY_BASE = "redo.message_draft";

export const MessageInput = memo(function MessageInput({
  conversation,
  setActiveConversation,
  cardListRef,
  setErrorMessage,
  setShowErrorMessage,
  setTyping,
  rightPanelOpen = true,
  showFullCommentThread,
  setShowFullCommentThread,
  emailDraftProps = undefined,
  messageIdForKey = undefined,
}: {
  conversation: ExpandedConversation;
  setActiveConversation: (
    conversation: ExpandedConversation | undefined,
  ) => void;
  cardListRef?: any;
  setErrorMessage: (message: string) => void;
  setShowErrorMessage: (show: boolean) => void;
  setTyping: Dispatch<SetStateAction<Record<string, Date>>>;
  rightPanelOpen?: boolean;
  showFullCommentThread: boolean;
  setShowFullCommentThread: Dispatch<SetStateAction<boolean>>;

  emailDraftProps?:
    | {
        draftInfo: InDraftingEmailState;
        handleSetReplyDraft: Dispatch<SetStateAction<EmailDraftState>>;
      }
    | undefined;
  messageIdForKey?: string | undefined;
}) {
  const navigate = useNavigate();
  const team = useRequiredContext(TeamContext);
  const {
    div: conversationDetailRef,
    ticketActions,
    conversationClosing,
    setConversationClosing,
  } = useContext(ActiveConversationContext);

  const [visibility, setVisibility] = useState<MessageVisibility>(
    MessageVisibility.PUBLIC,
  );
  const [replyPending, setReplyPending] = useState(false);
  const [replyAndClosePending, setReplyAndClosePending] = useState(false);
  const [sendTypingCooldown, setSendTypingCooldown] = useState(false);
  const apiClient = useRequiredContext(RedoClientContext);
  const client = useRequiredContext(RedoMerchantClientContext);
  const user = useContext(UserContext);
  const [typingRetryCount, setTypingRetryCount] = useState(0);
  const [macroModalOpen, setMacroModalOpen] = useState(false);
  const [draftAttachments, setDraftAttachments] = useState<Attachment[]>([]);
  const [discountCodesInMessage, setDiscountCodesInMessage] = useState<
    string[]
  >([]);
  const [productsInMessage, setProductsInMessage] = useState<ShopifyProduct[]>(
    [],
  );
  const [macrosLoad, doMacrosLoad] = useTriggerLoad(async (signal) => {
    const macros = await getMacros(client, { signal });
    return macros.data;
  });
  const [discountCodeSearch, doDiscountCodeSearch] = useTriggerLoad(
    async (signal) => {
      const responseData = await searchDiscountCodes(client, {
        search: textToInsert!,
        signal,
      });
      return responseData.discountCodes;
    },
  );
  const [productsSearch, doProductsSearch] = useTriggerLoad(async (signal) => {
    const responseData = await searchProducts(client, {
      search: textToInsert!,
      signal,
    });
    return responseData.products;
  });
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const setEmojiPickerOpenRef = useRef<Dispatch<SetStateAction<boolean>>>();
  const [quill, setQuill] = useState<Quill | null>(null);

  const [autocompleteInfo, setAutocompleteInfo] = useState<{
    visible: boolean;
    top: number;
    left: number;
    indexOfSymbol: number;
    type: AutocompleteType;
  }>({
    visible: false,
    top: 0,
    left: 0,
    indexOfSymbol: 0,
    type: AutocompleteType.MENTION,
  });
  const [textToInsert, setTextToInsert] = useState<string | undefined>();
  const [shouldInsertText, setShouldInsertText] = useState(false);
  const cursorIndexRef = useRef<number | undefined>(undefined);
  const [aiSuggestionPending, setAiSuggestionPending] = useState(false);
  const [showSignature, setShowSignature] = useState(false);
  const canReply =
    !!user && permitted(user.permissions, Permission.CREATE_REPLY);
  const canUseMacro =
    !!user && permitted(user.permissions, Permission.USE_MACRO);

  const conversationDraftKey = (
    conversationId: string,
    messageId: string | undefined,
  ) => DRAFT_KEY_BASE + `.${conversationId}` + `.${messageId}`;

  const signatureMarker = "\u200B";
  const [htmlToPasteSignature, setHtmlToPasteSignature] = useState<
    string | undefined
  >(undefined);
  const [macroAutomations, setMacroAutomations] = useState<MacroAutomations>({
    statusToSet: undefined,
    snoozeDuration: undefined,
    tagsToAdd: undefined,
    emailSubjectToChange: undefined,
  });
  const [sendMessageDisabled, setSendMessageDisabled] =
    useState<boolean>(false);

  const [pickingReplyType, setPickingReplyType] = useState(false);

  const canSendAsMetaMessage = canSendMetaMessage(conversation);
  let metaSendType: "message" | "forbidden" | "none" = "none";
  if (
    [ConversationPlatform.FACEBOOK, ConversationPlatform.INSTAGRAM].includes(
      conversation.platform,
    )
  ) {
    if (canSendAsMetaMessage) {
      metaSendType = "message";
    } else {
      metaSendType = "forbidden";
    }
  }
  const lastMerchantMessage = conversation.messages
    .filter((message) => message.type === "merchant")
    .reverse()[0];
  const privateReplyLimitation =
    metaSendType === "forbidden" &&
    !!lastMerchantMessage?.instagram?.privateRepliedComment;
  const lastCustomerDirectMessage = getLastCustomerDirectMessage(conversation);

  useEffect(() => {
    const replyingPermitted =
      !!user && permitted(user.permissions, Permission.CREATE_REPLY);
    if (!replyingPermitted) {
      setVisibility(MessageVisibility.INTERNAL);
    }
  }, [user]);

  useEffect(() => {
    if (
      conversation.platform === ConversationPlatform.EMAIL &&
      team?.settings.support?.emailSignature &&
      visibility === MessageVisibility.PUBLIC
    ) {
      // We use unicode character \u200B (zero-width space) to mark the start of the signature.
      setHtmlToPasteSignature(
        `<br><br><p>&#x200B;--</p>${team.settings.support.emailSignature}`,
      );
    } else {
      setHtmlToPasteSignature(undefined);
    }
  }, [team, conversation.platform, visibility]);

  useEffect(() => {
    // If it was just set to true, send that we're typing
    if (user && sendTypingCooldown) {
      setTimeout(() => {
        setSendTypingCooldown(false);
      }, 2000);
      void sendTyping(apiClient, {
        conversationId: conversation._id,
        id: user._id,
        visibility,
      });
    }
  }, [sendTypingCooldown]);

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      try {
        for await (const typingEvent of listen({
          query: async () => {
            return await getTypingStream(apiClient, {
              conversationId: conversation._id,
              signal: abortController.signal,
            });
          },
          loopCondition: !!conversation?._id,
          setErrorMessage,
          setShowErrorMessage,
        })) {
          const typingEventJson = typingEvent as unknown as JsonObject;
          if (typingEventJson?.name) {
            const name: string = typingEventJson.name as string;
            const expire = new Date();
            expire.setSeconds(expire.getSeconds() + 4);
            setTyping((oldTyping) => {
              setTimeout(() => {
                clearTyping();
              }, 4000);
              return {
                ...oldTyping,
                [name]: expire,
              };
            });
          }
        }
      } catch (e) {
        // Wait 5 seconds before trying again
        setTimeout(() => {
          // Continue polling for changes
          setTypingRetryCount(typingRetryCount + 1);
        }, 5000);
        if (abortController.signal.aborted) {
          return;
        }
        throw e;
      }
    })();
    return () => abortController.abort();
  }, [conversation._id, typingRetryCount]);

  useEffect(() => {
    if (!quill) {
      return;
    }
    if (conversation.currentAiResponse) {
      quill.setText(conversation.currentAiResponse);
    } else {
      quill.setText("");
    }

    setShowSignature(false);
    loadDraftFromLocalStorage();
    const textChangeCallback = (_: any, __: any, source: string) => {
      if (source === "user" || source === "api") {
        storeDraft();
      }
    };
    quill.on(Quill.events.TEXT_CHANGE, textChangeCallback);
    return () => {
      quill.off(Quill.events.TEXT_CHANGE, textChangeCallback);
    };
  }, [conversation._id, quill]);

  useEffect(() => {
    // If visibility was specified in the url, set it accordingly.
    const searchParams = new URLSearchParams(location.search);
    const visibilityParam = searchParams.get("visibility");
    if (["public", "internal"].includes(visibilityParam || "")) {
      setVisibility(visibilityParam as MessageVisibility);
      searchParams.delete("visibility");
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }

    doMacrosLoad();
  }, []);

  useEffect(() => {
    if (autocompleteInfo.visible) {
      const input = document.getElementById("autocomplete-input");
      if (input) {
        input.focus();
      }
    }
  }, [autocompleteInfo.visible]);

  useEffect(() => {
    if (!quill) {
      return;
    }
    formatWithInserts(quill);
  }, [visibility]);

  useEffect(() => {
    if (
      metaSendType === "forbidden" &&
      visibility === MessageVisibility.PUBLIC
    ) {
      conversationDetailRef?.current?.focus();
    } else {
      quill?.focus();
    }
  }, [quill, metaSendType]);

  useEffect(() => {
    if (!quill) {
      return;
    }

    const quillPosition = quill.getSelection()?.index;
    if (visibility === MessageVisibility.INTERNAL) {
      const signatureIndex = quill.getText().indexOf(signatureMarker);
      if (signatureIndex !== -1 && htmlToPasteSignature) {
        let text = quill.getText();
        text = quill.getText().substring(0, signatureIndex) + "\n";
        // Remove up to two newlines from the end of the text, leaving at least one.
        let i = 2;
        while (text.endsWith("\n\n") && i > 0) {
          text = text.substring(0, text.length - 1);
          i--;
        }
        quill.setText(text);
      }
    }
    if (quillPosition !== undefined) {
      quill.setSelection(quillPosition, 0);
    }
  }, [team, visibility, htmlToPasteSignature]);

  useEffect(() => {
    if (!quill) {
      return;
    }
    const signatureIndex = quill.getText().indexOf(signatureMarker);
    if (showSignature && signatureIndex === -1 && htmlToPasteSignature) {
      quill.clipboard.dangerouslyPasteHTML(
        quill.getLength() - 1,
        htmlToPasteSignature,
      );
    } else if (!showSignature && signatureIndex !== -1) {
      let text = quill.getText();
      text = quill.getText().substring(0, signatureIndex) + "\n";
      // Remove up to two newlines from the end of the text, leaving at least one.
      let i = 2;
      while (text.endsWith("\n\n") && i > 0) {
        text = text.substring(0, text.length - 1);
        i--;
      }
      quill.setText(text);
    }
  }, [showSignature]);

  useEffect(() => {
    if (!quill) {
      return;
    }

    const characterHotkeyCallback = (
      delta: DeltaType,
      oldDelta: DeltaType,
      source: EmitterSource,
    ) => {
      if (source === "user") {
        maybePerformCharacterHotkeyAction(delta);
      }
    };

    quill.on(Quill.events.TEXT_CHANGE, characterHotkeyCallback);

    const formatInsertsCallback = (
      delta: DeltaType,
      oldDelta: DeltaType,
      source: EmitterSource,
    ) => {
      if (!delta.ops.some((op) => !!op.insert || !!op.delete)) {
        return;
      }
      formatWithInserts(quill);
    };

    quill.on(Quill.events.TEXT_CHANGE, formatInsertsCallback);

    return () => {
      quill.off(Quill.events.TEXT_CHANGE, characterHotkeyCallback);
      quill.off(Quill.events.TEXT_CHANGE, formatInsertsCallback);
    };
  }, [quill, visibility, productsInMessage, discountCodesInMessage]);

  const debouncedSearch = useDebounce(textToInsert, 500);
  useEffect(() => {
    if (
      textToInsert &&
      !shouldInsertText &&
      autocompleteInfo.type === AutocompleteType.DISCOUNT_CODE
    ) {
      // Search for discount codes
      doDiscountCodeSearch();
    }
    if (
      textToInsert &&
      !shouldInsertText &&
      autocompleteInfo.type === AutocompleteType.PRODUCT
    ) {
      // Search for products
      doProductsSearch();
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (!textToInsert || !shouldInsertText) {
      return;
    }

    if (!quill) {
      return;
    }

    quill.insertText(autocompleteInfo.indexOfSymbol, textToInsert);

    setTextToInsert(undefined);
    setShouldInsertText(false);
    closeAutoComplete();

    quill.setSelection(autocompleteInfo.indexOfSymbol + textToInsert.length);
  }, [textToInsert, shouldInsertText]);

  useEffect(() => {
    const shouldBlockSendBecauseNoRecipients =
      emailDraftProps?.draftInfo.draft &&
      !hasAtLeastOneRecipientEmail(
        emailDraftProps?.draftInfo.draft.recipientsInfo,
      );

    setSendMessageDisabled(
      !!(
        submitDisabled ||
        replyPending ||
        replyAndClosePending ||
        (visibility === MessageVisibility.PUBLIC &&
          (metaSendType === "forbidden" || conversationClosing)) ||
        shouldBlockSendBecauseNoRecipients
      ),
    );
  }, [
    submitDisabled,
    replyPending,
    replyAndClosePending,
    visibility,
    conversationClosing,
  ]);

  function maybePerformCharacterHotkeyAction(delta: DeltaType) {
    const autoCompleteToOpen: AutocompleteType | undefined = filterTruthy(
      delta.ops.map(
        (op) =>
          typeof op.insert === "string" &&
          characterToAutoCompleteType[op.insert],
      ),
    )[0];

    if (autoCompleteToOpen) {
      openAutocompleteMenu(autoCompleteToOpen, { offsetLineStarts: true });
    }
  }

  /**
   * Different from @see {maybePerformCharacterHotkeyAction} because
   * the maybePerformCharacterHotkeyAction actually pastes a character into the editor in additon to performing
   * the side effect. This function only performs the side effect.
   */
  function maybePerformControlHotkeyAction(event: KeyboardEvent) {
    if (!event.ctrlKey && !event.metaKey) {
      return;
    }
    if (event.key === "2") {
      openAutocompleteMenu(AutocompleteType.MENTION);
      event.preventDefault();
    }
    if (event.key === "5") {
      openAutocompleteMenu(AutocompleteType.DISCOUNT_CODE);
      event.preventDefault();
    }
    if (event.key === "6") {
      setMacroModalOpen(true);
      event.preventDefault();
    }
    if (event.key === "7") {
      openAutocompleteMenu(AutocompleteType.PRODUCT);
      event.preventDefault();
    }
  }

  const clearTyping = () => {
    const now = new Date();
    setTyping((oldTyping) => {
      if (oldTyping) {
        return Object.keys(oldTyping).reduce((accumulator, key) => {
          if (oldTyping[key] > now) {
            return {
              ...accumulator,
              [key]: oldTyping[key],
            };
          } else {
            return accumulator;
          }
        }, {});
      } else {
        return {};
      }
    });
  };

  const closeEmailDraft = () => {
    if (emailDraftProps) {
      emailDraftProps.handleSetReplyDraft({ status: "noDraft" });
    }
  };

  const handleMessageSend: (
    event: React.ChangeEvent<{ value?: unknown }>,
    preventUpdateActiveConversation?: boolean,
  ) => Promise<boolean> = useHandler(
    async (e, preventUpdateActiveConversation = false) => {
      if (
        !quill ||
        (visibility === MessageVisibility.PUBLIC &&
          metaSendType === "forbidden") ||
        replyPending ||
        replyAndClosePending ||
        conversationClosing
      ) {
        return false;
      }

      setPickingEmailSendType(false);

      const originalHtml = quill.getSemanticHTML();

      const signatureIndex = quill.getText().indexOf(signatureMarker);
      if (htmlToPasteSignature && signatureIndex === -1 && !showSignature) {
        quill.clipboard.dangerouslyPasteHTML(
          quill.getLength() - 1,
          htmlToPasteSignature,
        );
      }

      let text = quill.getText();
      let html = quill.getSemanticHTML();
      quill.setText("");

      if (discountCodesInMessage.length > 0) {
        discountCodesInMessage.forEach((discountCode) => {
          // Remove % sign before each discount code
          text = text.replaceAll(`%${discountCode}`, discountCode);
          html = html.replaceAll(`%${discountCode}`, discountCode);
        });
      }

      if (productsInMessage.length > 0) {
        productsInMessage.forEach((product) => {
          // Remove & sign before each product
          const pdpUrl = `https://${team?.storeUrl}/products/${product.handle}`;
          text = text.replaceAll(
            `&${product.title}`,
            `${product.title} - ${pdpUrl}`,
          );
          html = html.replaceAll(
            `&amp;${product.title}`,
            `<a href="${pdpUrl}" style="color: #FAA7E0; background-color: #851651; padding: 4px !important; border-radius: 4px;">${product.title}</a>`,
          );
        });
      }

      e.preventDefault();
      try {
        setReplyPending(true);
        quill.disable();

        const conversationAfterMacros = await performMacroAutomations(
          client,
          macroAutomations,
          conversation,
        );
        if (conversationAfterMacros) {
          setMacroAutomations({
            statusToSet: undefined,
            snoozeDuration: undefined,
            tagsToAdd: undefined,
            emailSubjectToChange: undefined,
          });
          setActiveConversation(conversationAfterMacros);
        }

        const resultConversation = await sendMessageMerchant(client, {
          conversationId: conversation._id,
          message: text,
          htmlBody: html,
          visibility,
          attachments: draftAttachments,
          emailEnvelopeInfo: emailDraftProps?.draftInfo.draft.recipientsInfo,
        });
        if (evaluateResponseSimilarityToAiResponse(text)) {
          amplitude.logEvent("sent-ai-response", {
            conversationId: conversation._id,
            channel: conversation.platform,
            visibility,
          });
        }

        /**
         * If message was sent, stop holding a draft to it
         */
        setReplyPending(false);
        localStorage.removeItem(
          conversationDraftKey(conversation._id, messageIdForKey),
        );
        emailDraftProps?.handleSetReplyDraft({ status: "noDraft" });

        quill.enable();
        quill.setText("");
        setShowSignature(false);

        amplitude.logEvent("create-conversationMessage", {
          conversationId: conversation._id,
          channel: conversation.platform,
          visibility,
        });
        if (!preventUpdateActiveConversation) {
          setActiveConversation(resultConversation);
        }
        setDraftAttachments([]);
        closeEmailDraft();
      } catch (e) {
        setErrorMessage("Failed to send message");
        setShowErrorMessage(true);
        setReplyPending(false);

        quill.clipboard.dangerouslyPasteHTML(originalHtml);
        quill.enable();
        return false;
      }
      return true;
    },
  );

  const handleSendAndClose: (
    event: React.ChangeEvent<{ value?: unknown }>,
  ) => void = useHandler(async (e) => {
    setConversationClosing(true);
    setReplyAndClosePending(true);
    if (await handleMessageSend(e, true)) {
      // Close the conversation directly
      closeTicket(
        client,
        conversation,
        setActiveConversation,
        true,
        cardListRef?.current.getNext(
          conversation,
          (
            conversation1: ExpandedConversation,
            conversation2: ExpandedConversation,
          ) => conversation1._id === conversation2._id,
        ) ||
          cardListRef?.current.getPrevious(
            conversation,
            (
              conversation1: ExpandedConversation,
              conversation2: ExpandedConversation,
            ) => conversation1._id === conversation2._id,
          ),
      );
      cardListRef?.current.removeItem(
        conversation,
        (
          conversation1: ExpandedConversation,
          conversation2: ExpandedConversation,
        ) => conversation1._id === conversation2._id,
      );
      amplitude.logEvent("reply-and-close-conversation", {
        mode: "single",
        conversationIds: [conversation._id],
        channels: [conversation.platform],
      });

      closeEmailDraft();
    }
    setReplyAndClosePending(false);
    setConversationClosing(false);
  });

  const handleUpload = async ({
    event,
    file,
  }: {
    event?: any;
    file?: File;
  }) => {
    const fileToUpload = file || event.target?.files?.[0];
    if (!fileToUpload) {
      return;
    }

    const form = new FormData();
    form.append("file", fileToUpload);
    form.append("fileName", fileToUpload.name);
    const response = await uploadFile(apiClient, form);
    if (response.success) {
      const body = response.body;
      amplitude.logEvent("create-attachment", {
        channel: conversation.platform,
        file: fileToUpload.name,
      });
      const newAttachment = {
        url: body.url,
        description: body.description,
        mimeType: body.mimeType,
      };
      setDraftAttachments((oldDraftAttachments) => {
        return [newAttachment, ...oldDraftAttachments];
      });
      addDraftAttachmentToLocalStorage(newAttachment);
    } else {
      toast(conversationFileUploadErrorMessages[response.error], {
        variant: "error",
      });
    }
  };

  const clearInput = (e: any) => {
    // This makes the onChange always get triggered.
    e.target.value = "";
  };

  const [macroToInsert, setMacroToInsert] = useState<Macro | undefined>();

  useEffect(() => {
    if (macroToInsert) {
      const doSetMacro = async () => {
        await setMacro(macroToInsert);
        setMacroToInsert(undefined);
      };
      doSetMacro().catch((e) => {
        setErrorMessage("Failed to insert template.");
        setShowErrorMessage(true);
      });
    }
  }, [macroToInsert]);

  const setMacro = async (macro: Macro) => {
    let htmlContentWithVariablesReplaced;
    const contentWithVariablesReplaced = await replaceMacroVariables(client, {
      message: macro.content,
      email: conversation.customer.email,
      firstName: conversation.customer.firstName,
      lastName: conversation.customer.lastName,
      fullName: conversation.customer.name,
      agentFirstName: user?.firstName || "",
      agentLastName: user?.lastName || "",
      agentFullName: user?.name,
    });
    if (macro.htmlContent) {
      htmlContentWithVariablesReplaced = await replaceMacroVariables(client, {
        message: macro.htmlContent,
        email: conversation.customer.email,
        firstName: conversation.customer.firstName,
        lastName: conversation.customer.lastName,
        fullName: conversation.customer.name,
        agentFirstName: user?.firstName || "",
        agentLastName: user?.lastName || "",
        agentFullName: user?.name,
      });
    }
    amplitude.logEvent("use-macro", { name: macro.name });

    if (!contentWithVariablesReplaced.valuesExistForAllVariables) {
      setErrorMessage(
        "Some variables in the template didn't have a value. Defaults were used.",
      );
      setShowErrorMessage(true);
    }
    if (quill) {
      const indexToInsert = cursorIndexRef.current ?? 0;
      if (
        conversation.platform === ConversationPlatform.EMAIL &&
        htmlContentWithVariablesReplaced
      ) {
        quill.clipboard.dangerouslyPasteHTML(
          indexToInsert,
          htmlContentWithVariablesReplaced.message,
        );
      } else {
        quill.insertText(indexToInsert, contentWithVariablesReplaced.message);
      }

      /* Macro variables have a unique purple-on-purple color scheme. This resets the color and background color of the macro variables to the default. */
      findReplaceQuillInsertAttribute(quill, "color", "#5f45e2", "black");
      findReplaceQuillInsertAttribute(
        quill,
        "background",
        "#ebe9fd",
        "inherit",
      );
    }

    setMacroAutomations((oldMacroAutomations) => {
      let statusToSet = oldMacroAutomations.statusToSet;
      let snoozeDuration = oldMacroAutomations.snoozeDuration;
      let tagsToAdd = oldMacroAutomations.tagsToAdd;
      let emailSubjectToChange = oldMacroAutomations.emailSubjectToChange;

      if (macro.statusToSet === "snoozed" && !!macro.snoozeDuration) {
        statusToSet = MacroStatusToSet.SNOOZED;
        snoozeDuration = macro.snoozeDuration;
      } else {
        statusToSet = macro.statusToSet;
        snoozeDuration = undefined;
      }

      if (tagsToAdd?.length) {
        macro.tagsToAdd?.forEach((tag) => {
          if (!tagsToAdd!.includes(tag)) {
            tagsToAdd!.push(tag);
          }
        });
      } else if (macro.tagsToAdd?.length) {
        tagsToAdd = macro.tagsToAdd;
      }

      if (macro.emailSubjectToSet != emailSubjectToChange) {
        emailSubjectToChange = macro.emailSubjectToSet;
      }

      return {
        statusToSet,
        snoozeDuration,
        tagsToAdd,
        emailSubjectToChange,
      };
    });
  };

  const formatWithInserts = (quill: Quill) => {
    const userNames =
      team?.users.map((user) => (user.user as GetUser).name.toLowerCase()) ||
      [];
    const text = quill.getText().toLowerCase();

    const defaultFormat = {
      color: "black",
      background: "transparent",
    };

    // Formats the message for discount codes and products
    const handleFormat = (
      color: string,
      background: string,
      searchCharacter: string,
      wordsToFormat: string[],
    ) => {
      for (const word of wordsToFormat) {
        const searchString = `${searchCharacter}${word.toLowerCase()}`;
        let atIndex = text.indexOf(`${searchCharacter}${word.toLowerCase()}`);
        while (atIndex !== -1) {
          // Clear all formatting for the search string
          quill.removeFormat(atIndex, searchString.length);

          // Format the color and background
          quill.formatText(atIndex, searchString.length, {
            color,
            background,
          });

          // Get the format of the last character before the search string
          // If the search string is at the beginning of the message, use the default format
          const lastFormat =
            atIndex > 0 ? quill.getFormat(atIndex - 1) : defaultFormat;

          // Create the styles going forward. We want all of the last styles, and if the last styles did not include a color or background, we want to use the default format so that we override the values from the formatting of our search string
          const stylesAfterSearchString = {
            ...lastFormat,
            color: lastFormat.color || defaultFormat.color,
            background: lastFormat.background || defaultFormat.background,
          };

          const endIndex = atIndex + searchString.length;
          quill.formatText(endIndex, 1, stylesAfterSearchString);

          atIndex = text.indexOf(searchString, atIndex + 1);
        }
      }
    };
    // dark green, light green
    handleFormat("#067647", "#DCFAE6", "%", discountCodesInMessage);
    // dark pink, light pink
    handleFormat(
      "#C11574",
      "#FCE7F6",
      "&",
      productsInMessage.map((product) => product.title),
    );
    // Format for mentions
    if (visibility === MessageVisibility.INTERNAL) {
      handleFormat("#194185", "#84CAFF", "@", userNames);
    }
  };

  /**
   * For hotkeys where we actually type a character, we need some 'off by one' logic
   * to paste the autocomplete content correctly
   */
  const openAutocompleteMenu = (
    type: AutocompleteType,
    options?: { offsetLineStarts: boolean },
  ) => {
    if (!quill) {
      return;
    }

    if (
      visibility !== MessageVisibility.INTERNAL &&
      type === AutocompleteType.MENTION
    ) {
      return;
    }

    const range = quill.getSelection();
    if (!range) {
      return;
    }

    let cursorPosition = range.index;

    const needsOffset = ["", "\n"].includes(
      quill.getText(cursorPosition - 1, 1),
    );
    if (options?.offsetLineStarts && needsOffset) {
      cursorPosition += 1;
    }

    const bounds = quill.getBounds(cursorPosition);
    if (!bounds) {
      return;
    }

    setAutocompleteInfo({
      visible: true,
      top: bounds.top + 22,
      left: bounds.left + 4,
      indexOfSymbol: cursorPosition,
      type,
    });
  };

  const closeAutoComplete = () => {
    setAutocompleteInfo({
      visible: false,
      top: 0,
      left: 0,
      indexOfSymbol: 0,
      type: AutocompleteType.MENTION,
    });
    if (quill) {
      quill.focus();
    }
  };

  const handleAutocompleteChange = (
    value: string | null,
    type: AutocompleteType,
  ) => {
    if (!value) {
      closeAutoComplete();
      return;
    }
    if (autocompleteInfo.type === AutocompleteType.DISCOUNT_CODE) {
      setDiscountCodesInMessage([...discountCodesInMessage, value!]);
    } else if (autocompleteInfo.type === AutocompleteType.PRODUCT) {
      const currentProduct = productsSearch.value?.find(
        (product: ShopifyProduct) => product.title === value!,
      );
      if (currentProduct) {
        setProductsInMessage([...productsInMessage, currentProduct]);
      }
    }

    const specialKey = autoCompleteTypeToKey[type];

    const valueToPaste = value ? `${specialKey}${value}` : undefined;

    setTextToInsert(valueToPaste);
    setShouldInsertText(true);
  };

  const removeFileFromDrafts = (url: string) => {
    setDraftAttachments((oldDraftAttachments) => {
      return oldDraftAttachments.filter((attachment) => attachment.url !== url);
    });
    removeDraftAttachmentFromLocalStorage(url);
  };

  const messageIsOnlySignature = (quill: Quill) => {
    const text = quill.getText();
    return (
      text.includes(signatureMarker) &&
      text.substring(0, text.indexOf(signatureMarker)).trim().length === 0
    );
  };

  const storeDraft = () => {
    if (quill && !replyPending && !replyAndClosePending) {
      const empty =
        messageIsOnlySignature(quill) || quill.getText().length === 1;
      let cachedAttachments: Attachment[] = [];
      if (
        localStorage.getItem(
          conversationDraftKey(conversation._id, messageIdForKey),
        )
      ) {
        cachedAttachments = JSON.parse(
          localStorage.getItem(
            conversationDraftKey(conversation._id, messageIdForKey),
          )!,
        ).attachments;
      }
      if (!empty || cachedAttachments.length > 0) {
        localStorage.setItem(
          conversationDraftKey(conversation._id, messageIdForKey),
          JSON.stringify({
            quillContents: quill.getContents(),
            attachments: cachedAttachments,
          }),
        );
      } else {
        localStorage.removeItem(
          conversationDraftKey(conversation._id, messageIdForKey),
        );
      }

      /**
       * Ensure email recipient draft is saved alongside the actual content, if needed
       */
      emailDraftProps?.handleSetReplyDraft((v) => v);
    }
  };

  const loadDraftFromLocalStorage = () => {
    if (
      localStorage.getItem(
        conversationDraftKey(conversation._id, messageIdForKey),
      )
    ) {
      const cachedContents: DraftContents = JSON.parse(
        localStorage.getItem(
          conversationDraftKey(conversation._id, messageIdForKey),
        )!,
      );
      quill && quill.setContents(cachedContents.quillContents);
      setDraftAttachments(cachedContents.attachments);
    }
  };

  // For some reason, when we try to store attachments within storeDraft, draftAttachments is always
  // what it was when the component mounted, so we have to store attachments separately in code.
  // They're still in the same DraftContents object in localStorage.

  const removeDraftAttachmentFromLocalStorage = (url: string) => {
    if (
      localStorage.getItem(
        conversationDraftKey(conversation._id, messageIdForKey),
      )
    ) {
      const cachedDraft: DraftContents = JSON.parse(
        localStorage.getItem(
          conversationDraftKey(conversation._id, messageIdForKey),
        )!,
      );
      cachedDraft.attachments = cachedDraft.attachments.filter(
        (attachment) => attachment.url !== url,
      );
      localStorage.setItem(
        conversationDraftKey(conversation._id, messageIdForKey),
        JSON.stringify(cachedDraft),
      );
    }
  };

  const addDraftAttachmentToLocalStorage = (attachment: Attachment) => {
    if (
      localStorage.getItem(
        conversationDraftKey(conversation._id, messageIdForKey),
      )
    ) {
      const cachedDraft: DraftContents = JSON.parse(
        localStorage.getItem(
          conversationDraftKey(conversation._id, messageIdForKey),
        )!,
      );
      cachedDraft.attachments.push(attachment);
      localStorage.setItem(
        conversationDraftKey(conversation._id, messageIdForKey),
        JSON.stringify(cachedDraft),
      );
    } else {
      localStorage.setItem(
        conversationDraftKey(conversation._id, messageIdForKey),
        JSON.stringify({
          quillContents: [],
          attachments: [attachment],
        }),
      );
    }
  };

  const getAutocompleteOptions = (): string[] => {
    if (!team) {
      return [];
    }
    switch (autocompleteInfo.type) {
      case AutocompleteType.MENTION:
        return team.users.map((user) => (user.user as GetUser).name);
      case AutocompleteType.DISCOUNT_CODE:
        return (
          discountCodeSearch.value?.map((discountCode) => discountCode) || []
        );
      case AutocompleteType.PRODUCT:
        return productsSearch.value?.map((product: any) => product.title) || [];
      default:
        return [];
    }
  };

  const getAutocompleteNoOptionsText = () => {
    if (autocompleteInfo.type === AutocompleteType.DISCOUNT_CODE) {
      return discountCodeSearch.pending
        ? "Searching..."
        : "No discount codes found";
    } else if (autocompleteInfo.type === AutocompleteType.PRODUCT) {
      return productsSearch.pending ? "Searching..." : "No products found";
    }
    return "No options";
  };

  const [customerRequestedAiResponse, setCustomerRequestedAiResponse] =
    useState<string>("");

  const handleAiSuggestion = async () => {
    if (quill) {
      setAiSuggestionPending(true);
      const aiSuggestion = await getSupportAiResponse(client, {
        type: "conversationReplySuggestion",
        conversationId: conversation._id,
      });
      if (aiSuggestion?.text) {
        const indexToInsert = cursorIndexRef.current ?? 0;
        quill.insertText(indexToInsert, aiSuggestion.text);
        setCustomerRequestedAiResponse(aiSuggestion.text);
        amplitude.logEvent("generate-copilotSuggestion", {
          conversationId: conversation._id,
          cost: aiSuggestion.cost,
        });
      }
      setAiSuggestionPending(false);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (conversationClosing) return;
    if ((e.metaKey || e.ctrlKey) && e.key === "Enter" && !sendMessageDisabled) {
      if (e.altKey) {
        // Ctrl+Alt+Enter to reply and snooze
        ticketActions?.doSnoozeTicketAction();
        void handleMessageSend(e);
        e.preventDefault();
      } else if (e.shiftKey) {
        // Ctrl+Shift+Enter to reply and close
        void handleSendAndClose(e);
        e.preventDefault();
      } else {
        // Ctrl+Enter to reply
        e.preventDefault();
        void handleMessageSend(e);
      }
    } else if (!sendTypingCooldown) {
      setSendTypingCooldown(true);
    }
  };

  const isMac = /mac/i.test(navigator.userAgent);

  const getPlaceHolderText = () => {
    switch (autocompleteInfo.type) {
      case AutocompleteType.MENTION:
        return "Search users...";
      case AutocompleteType.DISCOUNT_CODE:
        return "Search discount codes...";
      case AutocompleteType.PRODUCT:
        return "Search products...";
    }
    return "Search...";
  };

  const evaluateResponseSimilarityToAiResponse = (currentText: string) => {
    // Checks if the current response is similar to our ai generated response, if it is return true indicating they are using an ai generated response, otherwise false
    if (!quill) {
      return false;
    }
    if (customerRequestedAiResponse || conversation.currentAiResponse) {
      let similarityScoreAutoGeneratedAiResponse = 0;
      let similarityScoreCustomerRequestedAiResponse = 0;
      if (conversation.currentAiResponse) {
        similarityScoreAutoGeneratedAiResponse = stringSimilarity(
          currentText,
          conversation.currentAiResponse,
        );
      }
      if (customerRequestedAiResponse) {
        similarityScoreCustomerRequestedAiResponse = stringSimilarity(
          currentText,
          customerRequestedAiResponse,
        );
      }
      if (
        similarityScoreAutoGeneratedAiResponse > 0.8 ||
        similarityScoreCustomerRequestedAiResponse > 0.8
      ) {
        return true;
      }
    }
    return false;
  };

  const [pickReplyButtonRef, setPickReplyButtonRef] =
    useState<HTMLButtonElement | null>(null);

  const handlePickReplyType = (mode: EmailDraftMode) => {
    emailDraftProps?.handleSetReplyDraft(
      (di) => ({ ...di, mode }) as EmailDraftState,
    );
    if (mode === "Forward") {
      setEditingCCBCC(true);
    }
    setPickingReplyType(false);
  };

  /** If we open a fresh editor for email replies and it's in forward mode, start with picker for forwarding */
  const [editingCCBCC, setEditingCCBCC] = useState(
    emailDraftProps?.draftInfo?.mode === "Forward",
  );

  const [emailSendButtonRef, setEmailSendButtonRef] =
    useState<HTMLButtonElement | null>(null);
  const [pickingEmailSendType, setPickingEmailSendType] = useState(false);
  const [emailSendType, setEmailSendType] = useState<"Send" | "Send and Close">(
    "Send",
  );

  const handleSetEmailSendType = (
    state: SetStateAction<"Send" | "Send and Close">,
  ) => {
    setPickingEmailSendType((t) => false);
    setEmailSendType(state);
  };

  const toolbarSelector =
    messageIdForKey !== undefined
      ? `redo-quill-toolbar-${messageIdForKey}`
      : "toolbar";

  const sholdShowTemplateMacroButton =
    canUseMacro && metaSendType !== "forbidden";

  const shouldAllowEditingMessage =
    visibility === MessageVisibility.INTERNAL || metaSendType !== "forbidden";

  const shouldUseEmailConversationLayout = !!emailDraftProps;

  const toolbarElement = (
    <Flex
      align="flex-end"
      className={messageInputCss.wrappingText}
      flex="1 1 0%"
      justify="space-between"
      p="sm"
      pb="xs"
    >
      <Flex
        align="center"
        className={messageInputCss.wrappingtext}
        flex="1 1 0%"
        gap="xs"
        pl="sm"
      >
        {sholdShowTemplateMacroButton && (
          <Tooltip
            arrow
            title={getHotkeyTooltip(AutocompleteType.MACRO, isMac)}
          >
            <RedoButton
              disabled={macrosLoad.pending}
              hierarchy="Tertiary"
              icon={(p: any) => <LightningIcon />}
              onClick={() => {
                amplitude.logEvent("view-macroModal", {
                  conversationId: conversation._id,
                  channel: conversation.platform,
                });
                setMacroModalOpen(true);
              }}
              size="xs"
              text={undefined}
            />
          </Tooltip>
        )}
        {(visibility === MessageVisibility.INTERNAL ||
          metaSendType !== "forbidden") && (
          <div className={quillEditorCss.quillToolbar} id={toolbarSelector}>
            <QuillToolbarOptions
              emailMode={conversation.platform === ConversationPlatform.EMAIL}
            >
              <>
                <label htmlFor="file-upload" id="file-upload-label">
                  <Button
                    onClick={() => {
                      document.getElementById(`file-upload`)?.click();
                    }}
                    theme={ButtonTheme.GHOST}
                  >
                    <div className={messageInputCss.actionButton}>
                      <PaperclipIcon className={messageInputCss.paperclip} />
                    </div>
                  </Button>
                </label>
                <input
                  className={messageInputCss.fileInput}
                  id="file-upload"
                  onChange={(e) => handleUpload({ event: e })}
                  onClick={(e) => clearInput(e)}
                  type="file"
                />
                <Tooltip
                  arrow
                  title={getHotkeyTooltip(
                    AutocompleteType.DISCOUNT_CODE,
                    isMac,
                  )}
                >
                  <IconButton
                    onClick={() => {
                      openAutocompleteMenu(AutocompleteType.DISCOUNT_CODE);
                    }}
                  >
                    <div className={messageInputCss.actionButton}>
                      <PercentIcon className={messageInputCss.actionIcon} />
                    </div>
                  </IconButton>
                </Tooltip>
                <Tooltip
                  arrow
                  title={getHotkeyTooltip(AutocompleteType.PRODUCT, isMac)}
                >
                  <IconButton
                    onClick={() => {
                      openAutocompleteMenu(AutocompleteType.PRODUCT);
                    }}
                  >
                    <div className={messageInputCss.actionButton}>
                      <ShoppingBagIcon className={messageInputCss.actionIcon} />
                    </div>
                  </IconButton>
                </Tooltip>
              </>
            </QuillToolbarOptions>

            {teamCanUseAi(team) && (
              <div className={quillEditorCss.quillFormatButtons}>
                {aiSuggestionPending ? (
                  <CircleSpinner className={messageInputCss.spinner} />
                ) : (
                  <IconButton onClick={handleAiSuggestion}>
                    <div className={messageInputCss.actionButton}>
                      <AiIcon classname={messageInputCss.actionIcon} />
                    </div>
                  </IconButton>
                )}
              </div>
            )}
          </div>
        )}
      </Flex>
      <Flex align="flex-end">
        <>
          {shouldUseEmailConversationLayout && (
            <ClickAwayListener
              onClickAway={() => setPickingEmailSendType(false)}
            >
              <div>
                <Tooltip
                  arrow
                  title={getHotkeyTooltip(
                    emailSendType === "Send"
                      ? "sendReply"
                      : "sendReplyAndClose",
                    isMac,
                  )}
                >
                  <span>
                    <RedoButton
                      disabled={
                        submitDisabled ||
                        metaSendType === "forbidden" ||
                        replyPending ||
                        replyAndClosePending ||
                        conversationClosing ||
                        !hasAtLeastOneRecipientEmail(
                          emailDraftProps.draftInfo?.draft?.recipientsInfo,
                        )
                      }
                      hierarchy="Primary"
                      icon={
                        replyPending ||
                        replyAndClosePending ||
                        conversationClosing ? (
                          (p: any) => <CircleSpinner />
                        ) : (
                          <Send01Svg />
                        )
                      }
                      multiAction={{
                        ref: setEmailSendButtonRef,
                        isDropdownOpen: pickingEmailSendType,
                        onClick: () =>
                          setPickingEmailSendType(
                            (pickingEmailSendType) => !pickingEmailSendType,
                          ),
                      }}
                      onClick={
                        emailSendType === "Send"
                          ? handleMessageSend
                          : handleSendAndClose
                      }
                      size="regular"
                      text={
                        replyPending || replyAndClosePending
                          ? "Sending..."
                          : conversationClosing
                            ? "Closing..."
                            : emailSendType
                      }
                    />
                  </span>
                </Tooltip>
              </div>
            </ClickAwayListener>
          )}
          <Dropdown
            anchor={emailSendButtonRef}
            fitToAnchor={false}
            open={pickingEmailSendType}
          >
            <DropdownOption action={() => handleSetEmailSendType("Send")}>
              Send
            </DropdownOption>
            <DropdownOption
              action={() => handleSetEmailSendType("Send and Close")}
            >
              Send and Close
            </DropdownOption>
          </Dropdown>
          {!shouldUseEmailConversationLayout && (
            <Flex p="md">
              <div
                className={messageInputCss.replyButtons}
                style={{ marginRight: rightPanelOpen ? "0px" : "64px" }}
              >
                {visibility === MessageVisibility.PUBLIC ? (
                  <Tooltip arrow title={getHotkeyTooltip("sendReply", isMac)}>
                    <span>
                      <Button
                        disabled={
                          submitDisabled ||
                          metaSendType === "forbidden" ||
                          replyPending ||
                          replyAndClosePending ||
                          conversationClosing
                        }
                        onClick={handleMessageSend}
                        pending={replyPending && !replyAndClosePending}
                        size={ButtonSize.MICRO}
                        theme={ButtonTheme.PRIMARY}
                      >
                        <Text fontSize="sm" fontWeight="semibold">
                          Reply
                        </Text>
                      </Button>
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip
                    arrow
                    title={getHotkeyTooltip("addInternalNote", isMac)}
                  >
                    <Button
                      disabled={
                        submitDisabled || replyPending || replyAndClosePending
                      }
                      onClick={handleMessageSend}
                      pending={replyPending && !replyAndClosePending}
                      size={ButtonSize.MICRO}
                      theme={ButtonTheme.WARNING}
                    >
                      <Text fontSize="sm" fontWeight="semibold">
                        Add note
                      </Text>
                    </Button>
                  </Tooltip>
                )}
                {visibility === MessageVisibility.PUBLIC && (
                  <Tooltip
                    arrow
                    title={getHotkeyTooltip("sendReplyAndClose", isMac)}
                  >
                    <span>
                      <Button
                        disabled={
                          submitDisabled ||
                          metaSendType === "forbidden" ||
                          replyPending ||
                          replyAndClosePending ||
                          conversationClosing
                        }
                        onClick={handleSendAndClose}
                        pending={replyAndClosePending}
                        size={ButtonSize.MICRO}
                        theme={ButtonTheme.OUTLINED}
                      >
                        <Text fontSize="sm" fontWeight="semibold">
                          Reply & close
                        </Text>
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </div>
            </Flex>
          )}
        </>
      </Flex>
    </Flex>
  );

  if (
    conversation.platform === ConversationPlatform.EMAIL &&
    !shouldUseEmailConversationLayout &&
    visibility !== MessageVisibility.INTERNAL
  ) {
    return (
      <Flex justify="flex-end" p="lg">
        <RedoButton
          hierarchy="Secondary"
          icon={(p: any) => <StickerSquareSvg />}
          onClick={() => setVisibility(MessageVisibility.INTERNAL)}
          size="regular"
          text="Add a note"
        />
      </Flex>
    );
  }

  const writingInternalNoteOnEmailPlatform =
    conversation.platform === ConversationPlatform.EMAIL &&
    visibility === MessageVisibility.INTERNAL &&
    !emailDraftProps;

  const selectReplyNoteOrDropdown = (
    <>
      {writingInternalNoteOnEmailPlatform ? (
        <>
          {/* For spacing the button correctly like non-email platforms which use "space-between" */}
          <Flex> </Flex>

          {!shouldUseEmailConversationLayout && (
            <RedoButton
              hierarchy="Tertiary"
              onClick={() => setVisibility(MessageVisibility.PUBLIC)}
              size="regular"
              text="Close note"
            />
          )}
        </>
      ) : (
        !shouldUseEmailConversationLayout && (
          <ButtonDropdown
            dropdown={
              <>
                {canReply && (
                  <DropdownOption
                    action={() => setVisibility(MessageVisibility.PUBLIC)}
                  >
                    <div className={messageInputCss.actionButtonContainer}>
                      <div
                        className={classNames(
                          messageInputCss.actionButton,
                          visibility === MessageVisibility.PUBLIC &&
                            messageInputCss.bold,
                        )}
                      >
                        <ChatBubbleIcon className={messageInputCss.icon} />
                        Reply
                      </div>
                      {visibility === MessageVisibility.PUBLIC && (
                        <CheckIcon className={messageInputCss.checkIcon} />
                      )}
                    </div>
                  </DropdownOption>
                )}
                <DropdownOption
                  action={() => setVisibility(MessageVisibility.INTERNAL)}
                >
                  <div className={messageInputCss.actionButtonContainer}>
                    <div
                      className={classNames(
                        messageInputCss.actionButton,
                        visibility === MessageVisibility.INTERNAL &&
                          messageInputCss.bold,
                      )}
                    >
                      <NoteIcon className={messageInputCss.icon} />
                      Internal note
                    </div>
                    {visibility === MessageVisibility.INTERNAL && (
                      <CheckIcon className={messageInputCss.checkIcon} />
                    )}
                  </div>
                </DropdownOption>
              </>
            }
            restrictDropdownSizeToParent={false}
            size={ButtonSize.MICRO}
            theme={ButtonTheme.GHOST}
          >
            <div className={messageInputCss.actionButton}>
              {visibility === MessageVisibility.PUBLIC ? (
                <>
                  <ChatBubbleIcon className={messageInputCss.icon} />
                  Reply
                  <ChevronDownIcon className={messageInputCss.icon} />
                </>
              ) : (
                <>
                  <NoteIcon className={messageInputCss.icon} />
                  Internal note
                  <ChevronDownIcon className={messageInputCss.icon} />
                </>
              )}
            </div>
          </ButtonDropdown>
        )
      )}
    </>
  );

  return (
    <>
      <div
        className={classNames(messageInputCss.messageInputCard, {
          [messageInputCss.writingInternalNoteTopBorder]:
            writingInternalNoteOnEmailPlatform,
        })}
      >
        <div className={messageInputCss.aboveInput}>
          {selectReplyNoteOrDropdown}

          {((conversation.platform ===
            ConversationPlatform.INSTAGRAM_COMMENTS &&
            !!conversation.instagramCommentThread) ||
            (conversation.platform === ConversationPlatform.FACEBOOK_COMMENTS &&
              !!conversation.facebookCommentThread)) && (
            <div className={messageInputCss.commentThreadSwitch}>
              <Switch
                onChange={(value) => setShowFullCommentThread(value)}
                value={showFullCommentThread}
              />
              <p>Show full comment thread</p>
            </div>
          )}
          {evaluateResponseSimilarityToAiResponse(quill?.getText() || "") && (
            <div>
              <Pill size={PillSize.NEAR_SQUARE} theme={PillTheme.PRIMARY_LIGHT}>
                <div className={messageInputCss.aiInfoContainer}>
                  <StarsIcon className={messageInputCss.aiInfoContainer} />
                  <div className={messageInputCss.aiInfoContainer}>
                    AI generated response
                  </div>
                </div>
              </Pill>
            </div>
          )}
        </div>
        <div
          className={messageInputCss.editorContainer}
          onKeyDown={(e: React.KeyboardEvent) => {
            if (
              (e.code === "Escape" ||
                (e.code === "Backspace" && !textToInsert)) &&
              autocompleteInfo.visible
            ) {
              if (!quill) {
                return;
              }
              e.preventDefault();
              setTextToInsert(undefined);
              closeAutoComplete();
            }
          }}
        >
          {
            <div style={{ position: "relative" }}>
              <form
                className={
                  visibility === MessageVisibility.INTERNAL
                    ? messageInputCss.messageInputFormInternal
                    : messageInputCss.messageInputForm
                }
                onKeyDown={onKeyDown}
              >
                {shouldUseEmailConversationLayout && (
                  <Flex
                    align="center"
                    className={classNames(messageInputCss.wrappingText)}
                    p="md"
                  >
                    <ClickAwayListener
                      onClickAway={() => setPickingReplyType(false)}
                    >
                      <div>
                        <RedoButton
                          hierarchy="Tertiary"
                          icon={(props: any) => <CornerUpLeftSvg />}
                          iconTrailing={<ChevronDownSvg />}
                          onClick={(e) => setPickingReplyType((v) => !v)}
                          ref={setPickReplyButtonRef}
                          size="regular"
                          text={emailDraftProps.draftInfo.mode}
                        />
                        <Dropdown
                          anchor={pickReplyButtonRef}
                          fitToAnchor={false}
                          open={pickingReplyType}
                        >
                          <DropdownOption
                            action={() => handlePickReplyType("Reply")}
                          >
                            Reply
                          </DropdownOption>
                          <DropdownOption
                            action={() => handlePickReplyType("Reply All")}
                          >
                            Reply All
                          </DropdownOption>
                          <DropdownOption
                            action={() => handlePickReplyType("Forward")}
                          >
                            Forward
                          </DropdownOption>
                        </Dropdown>
                      </div>
                    </ClickAwayListener>
                    <Flex
                      align="center"
                      className={messageInputCss.wrappingText}
                      gap="2xl"
                      justify="space-between"
                    >
                      <Flex
                        align="center"
                        className={classNames(
                          messageInputCss.wrappingText,
                          messageInputCss.clickingRecipientBar,
                        )}
                        onClick={() => setEditingCCBCC(true)}
                      >
                        <Text
                          fontSize="md"
                          overflow="hidden"
                          textColor="primary"
                          textOverflow="ellipsis"
                        >
                          {stringifyEmailRecipientsInfo(
                            emailDraftProps?.draftInfo.draft.recipientsInfo,
                          ) ?? ""}
                        </Text>
                      </Flex>
                      <Flex align="center">
                        <RedoButton
                          hierarchy="Tertiary"
                          onClick={() => setEditingCCBCC(true)}
                          size="regular"
                          text="Edit recipients"
                        />
                        <EditRecipientsModal
                          conversation={conversation}
                          emailDraft={emailDraftProps.draftInfo}
                          isOpen={editingCCBCC}
                          onClose={() => setEditingCCBCC(false)}
                          setEmailDraft={emailDraftProps.handleSetReplyDraft}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                )}
                <Flex
                  className={messageInputCss.editor}
                  {...(!shouldUseEmailConversationLayout && { pt: "xl" })}
                >
                  <div
                    className={classNames(quillEditorCss.quillContainer)}
                    onDrop={(e) => doFileDrop(e, handleUpload)}
                    onKeyDown={maybePerformControlHotkeyAction}
                  >
                    <QuillEditor
                      cursorIndexRef={cursorIndexRef}
                      defaultValue={new Delta().insert("")}
                      disabled={submitDisabled}
                      placeholder={
                        [
                          ConversationPlatform.INSTAGRAM_COMMENTS,
                          ConversationPlatform.FACEBOOK_COMMENTS,
                        ].includes(conversation.platform)
                          ? "Post a comment reply..."
                          : "Start typing..."
                      }
                      readOnly={!shouldAllowEditingMessage}
                      ref={setQuill}
                      setDisabled={setSubmitDisabled}
                      setEmojiPickerOpenRef={setEmojiPickerOpenRef}
                      toolbarElementId={toolbarSelector}
                    />
                  </div>
                  {htmlToPasteSignature && (
                    <Button
                      className={messageInputCss.showSignatureButton}
                      onClick={() => setShowSignature(!showSignature)}
                      size={ButtonSize.MICRO}
                      theme={ButtonTheme.OUTLINED}
                    >
                      <ThreeDotsIcon />
                    </Button>
                  )}
                </Flex>
                {shouldAllowEditingMessage && toolbarElement}

                {!!draftAttachments.length && (
                  <div className={messageInputCss.fileCarousel}>
                    {draftAttachments.map((attachment, index) => (
                      <div
                        className={messageInputCss.draftAttachmentContainer}
                        key={index}
                      >
                        <AttachmentThumbnail
                          description={attachment.description}
                          mimeType={attachment.mimeType}
                          url={attachment.url}
                        />
                        <div className={messageInputCss.removeFileButton}>
                          <IconButton
                            onClick={() => removeFileFromDrafts(attachment.url)}
                          >
                            <XIcon />
                          </IconButton>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </form>
              {/* Hide autocomplete for mentions when it's not an internal note */}
              {autocompleteInfo.visible && (
                <Autocomplete
                  getLabel={(option) => {
                    return option;
                  }}
                  id="autocomplete-input"
                  noOptionsText={getAutocompleteNoOptionsText()}
                  onInputChange={(event, newInputValue) => {
                    if (event.type === "change") {
                      if (!quill) {
                        return;
                      }
                      setTextToInsert(newInputValue);
                    } else if (event.type === "blur") {
                      setShouldInsertText(true);
                    }
                  }}
                  options={getAutocompleteOptions()}
                  placeholder={getPlaceHolderText()}
                  style={{
                    position: "absolute",
                    top: autocompleteInfo.top,
                    left: autocompleteInfo.left,
                    width: "250px",
                    zIndex: 1000,
                  }}
                  valueChange={(submittedValue) =>
                    handleAutocompleteChange(
                      submittedValue,
                      autocompleteInfo.type,
                    )
                  }
                />
              )}
            </div>
          }
        </div>
        {metaSendType === "forbidden" &&
          visibility === MessageVisibility.PUBLIC && (
            <div className={messageInputCss.warning}>
              {privateReplyLimitation
                ? lastCustomerDirectMessage
                  ? `Cannot send message. Can't send messages through ${capitalize(conversation.platform)}'s API 7 days after the most recent message, and Instagram does not allow sending more than one private reply to a comment until the customer responds.`
                  : "Instagram does not allow sending more than one private reply to a comment until the customer responds."
                : `Can't send messages through ${capitalize(conversation.platform)}'s API 7 days after the most recent customer message.`}
            </div>
          )}
        {(!!macroAutomations.statusToSet ||
          !!macroAutomations.tagsToAdd?.length ||
          (!!macroAutomations.emailSubjectToChange &&
            conversation.platform === ConversationPlatform.EMAIL)) && (
          <>
            <div className={messageInputCss.automationsSection}>
              <p>Automations</p>
              <div className={messageInputCss.automations}>
                {macroAutomations.statusToSet && (
                  <StatusMacroAutomationPill
                    snoozeDuration={macroAutomations.snoozeDuration}
                    statusToSet={macroAutomations.statusToSet}
                    xClicked={() => {
                      setMacroAutomations((old) => {
                        return {
                          ...old,
                          statusToSet: undefined,
                          snoozeDuration: undefined,
                        };
                      });
                    }}
                  />
                )}
                {macroAutomations.tagsToAdd?.map((tag) => {
                  return (
                    <TagMacroAutomationPill
                      key={tag}
                      tag={tag}
                      xClicked={() => {
                        setMacroAutomations((old) => {
                          return {
                            ...old,
                            tagsToAdd: old.tagsToAdd?.filter(
                              (oldTag) => oldTag !== tag,
                            ),
                          };
                        });
                      }}
                    />
                  );
                })}
                {macroAutomations.emailSubjectToChange && (
                  <EmailSubjectMacroAutomationPill
                    emailSubject={macroAutomations.emailSubjectToChange}
                    xClicked={() => {
                      setMacroAutomations((old) => {
                        return {
                          ...old,
                          emailSubjectToChange: undefined,
                        };
                      });
                    }}
                  />
                )}
              </div>
            </div>
            <Divider />
          </>
        )}
      </div>
      <MacroModal
        key={macrosLoad?.value?.length}
        macros={macrosLoad.value}
        open={macroModalOpen}
        refreshMacros={doMacrosLoad}
        setMacro={setMacro}
        setOpen={setMacroModalOpen}
      />
    </>
  );
});

function controlHotkeyTooltip({
  action = "Insert",
  code,
  name,
  isMac,
}: {
  action?: string | undefined;
  code: string;
  name: string;
  isMac: boolean;
}): string {
  const controlKey = isMac ? "⌘" : "Ctrl";

  return `${action ?? ""}${action ? " " : ""}${name} (${controlKey}+${code})`;
}

function getHotkeyTooltip(
  type: AutocompleteType | OtherHotkeyType,
  isMac: boolean,
): string {
  switch (type) {
    case AutocompleteType.DISCOUNT_CODE:
      return controlHotkeyTooltip({ code: "5", name: "discount code", isMac });
    case AutocompleteType.MACRO:
      return controlHotkeyTooltip({ code: "6", name: "template", isMac });
    case AutocompleteType.PRODUCT:
      return controlHotkeyTooltip({ code: "7", name: "product", isMac });
    case AutocompleteType.MENTION:
      return "Insert mention (@)";
    case "sendReply":
      return controlHotkeyTooltip({
        action: undefined,
        code: "Enter",
        name: "Send reply",
        isMac,
      });
    case "sendReplyAndClose":
      return controlHotkeyTooltip({
        action: undefined,
        code: "Shift+Enter",
        name: "Send reply and close",
        isMac,
      });
    case "addInternalNote":
      return controlHotkeyTooltip({
        action: undefined,
        code: "Enter",
        name: "Add internal note",
        isMac,
      });
    default:
      return assertNever(type);
  }
}
