import { IterableMap } from "@redotech/react-util/component";
import { useFocus } from "@redotech/react-util/focus";
import { Input } from "@redotech/ui/form";
import { BackspaceKey, EnterKey } from "@redotech/web-util/key";
import * as classNames from "classnames";
import {
  KeyboardEventHandler,
  ReactNode,
  memo,
  useEffect,
  useId,
  useState,
} from "react";
import * as chipInputCss from "./chip-input.module.css";
import XIcon from "./icon-old/x.svg";
import { InputSize } from "./input";
import { LabeledInput } from "./labeled-input";

import InfoIcon from "@redotech/redo-web/icon-old/info.svg";
import { Tooltip } from "./tooltip/tooltip";

export enum ChipDelimiter {
  NEWLINE = 0,
  WHITESPACE = 1,
}

const inputSizeClass = {
  [InputSize.EXTRA_SMALL]: chipInputCss.xSmall,
  [InputSize.SMALL]: chipInputCss.small,
  [InputSize.MEDIUM]: chipInputCss.medium,
  [InputSize.LARGE]: undefined,
};

export interface ChipInputProps {
  delimiter?: ChipDelimiter;
  id?: string;
  error?: boolean;
  size?: InputSize;
  value: readonly string[];
  valueChange(value: readonly string[]): void;
  disabled?: boolean;
  focused?: boolean;
  trimWhitespace?: boolean;
  placeholderText?: string;
  showPlaceholderWithoutFocus?: boolean;
  infoTooltip?: string;
}

export const ChipInput = memo(function ChipInput({
  delimiter = ChipDelimiter.WHITESPACE,
  error = false,
  id,
  size = InputSize.MEDIUM,
  value,
  valueChange,
  disabled = false,
  focused = false,
  trimWhitespace = false,
  placeholderText = "Type and press Enter",
  showPlaceholderWithoutFocus = false,
  infoTooltip,
}: ChipInputProps) {
  const [newInput, setNewInput] = useState<HTMLInputElement | null>(null);
  const [focus, focusProps] = useFocus();

  useEffect(() => {
    if (!focus && newInput) {
      processCurrentInput(newInput);
    }
  }, [focus]);

  useEffect(() => {
    if (focused && newInput) {
      newInput.focus();
    }
  }, [focused, newInput]);

  const processCurrentInput = (inputElement: HTMLInputElement) => {
    const newValues = textToValues(
      inputElement.value,
      delimiter,
      trimWhitespace,
    );
    if (!newValues.length) {
      return;
    }
    valueChange([...value.filter((v) => !newValues.includes(v)), ...newValues]);
    inputElement.value = "";
  };

  return (
    <div
      className={classNames(chipInputCss.outerContainer, inputSizeClass[size], {
        [chipInputCss.error]: error,
      })}
    >
      <div className={chipInputCss.innerContainer}>
        <IterableMap items={value} keyFn={(value) => value}>
          {(chipValue) => {
            function edit() {
              if (disabled) {
                return;
              }
              valueChange(value.filter((v) => v !== chipValue));
              newInput!.value = chipValue;
              newInput!.focus();
            }
            function remove() {
              if (disabled) {
                return;
              }
              valueChange(value.filter((v) => v !== chipValue));
            }

            return (
              <Chip edit={edit} remove={remove}>
                {chipValue}
              </Chip>
            );
          }}
        </IterableMap>
        <input
          className={chipInputCss.input}
          disabled={disabled}
          id={id}
          onKeyDown={(event) => {
            if (event.key === EnterKey) {
              event.preventDefault();
            }
            const inputElement = event.target as HTMLInputElement;
            if (event.key === BackspaceKey && !inputElement.value) {
              valueChange(value.slice(0, -1));
            }
          }}
          onKeyUp={(event) => {
            if (
              event.key === EnterKey ||
              (delimiter === ChipDelimiter.WHITESPACE && /\s/.test(event.key))
            ) {
              const inputElement = event.target as HTMLInputElement;
              processCurrentInput(inputElement);
              inputElement.focus();
            }
          }}
          placeholder={
            focus || showPlaceholderWithoutFocus ? placeholderText : undefined
          }
          ref={setNewInput}
          {...focusProps}
        />
      </div>
      {infoTooltip && <InfoTooltip infoText={infoTooltip} />}
    </div>
  );
});

const InfoTooltip = memo(function InfoTooltip({
  infoText,
}: {
  infoText: string;
}) {
  return (
    <Tooltip title={infoText}>
      <span className={chipInputCss.infoTooltip}>
        <InfoIcon />
      </span>
    </Tooltip>
  );
});

export const Chip = memo(function Chip({
  children,
  remove,
  edit,
}: {
  children: ReactNode;
  edit?(): void;
  remove(e: React.MouseEvent): void;
}) {
  const onKeyUp: KeyboardEventHandler<unknown> = (event) => {
    if (event.key === EnterKey) {
      edit?.();
    }
  };
  return (
    <div className={chipInputCss.chip} onDoubleClick={edit} onKeyUp={onKeyUp}>
      <div className={chipInputCss.close} onClick={remove}>
        <XIcon className={chipInputCss.closeIcon} />
      </div>
      <span className={chipInputCss.chipLabel}>{children}</span>
    </div>
  );
});

export const FormChipInput = memo(function FormChipInput({
  description,
  label,
  input,
  disabled = false,
  ...props
}: {
  description?: string;
  label: string;
  input: Input<readonly string[]>;
  disabled?: boolean;
} & Omit<ChipInputProps, "error" | "id" | "value" | "valueChange">) {
  const id = useId();
  return (
    <LabeledInput
      description={description}
      errors={input.changed ? input.errors : []}
      id={id}
      label={label}
    >
      <ChipInput
        disabled={disabled}
        error={input.changed && !!input.errors.length}
        id={id}
        value={input.value}
        valueChange={input.setValue}
        {...props}
      />
    </LabeledInput>
  );
});

function textToValues(
  text: string,
  delimiter: ChipDelimiter,
  trimWhitespace: boolean,
) {
  return text
    .split(delimiter === ChipDelimiter.NEWLINE ? "\n" : /\s/)
    .map((v) => (trimWhitespace ? v.trim() : v))
    .filter(Boolean);
}
