import * as amplitude from "@amplitude/analytics-browser";
import Gravatar from "@gravatar/js";
import { useRequiredContext } from "@redotech/react-util/context";
import { useTriggerLoad } from "@redotech/react-util/load";
import {
  ConversationPlatform,
  ExpandedConversation,
} from "@redotech/redo-model/conversation";
import { Customer } from "@redotech/redo-model/customer";
import { Order } from "@redotech/redo-model/order";
import { Return } from "@redotech/redo-model/return";
import { Team } from "@redotech/redo-model/team";
import { Permission, permitted } from "@redotech/redo-model/user";
import { RedoBadge } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import ArrowUpRightSvg from "@redotech/redo-web/arbiter-icon/arrow-up-right_filled.svg";
import { Autocomplete } from "@redotech/redo-web/autocomplete";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  IconButton,
} from "@redotech/redo-web/button";
import { Flex } from "@redotech/redo-web/flex";
import EditPencil from "@redotech/redo-web/icon-old/edit-pencil.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { ExternalLink } from "@redotech/redo-web/link";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import ShopifyBagLogo from "@redotech/redo-web/shopify/bag-logo.svg";
import { Text } from "@redotech/redo-web/text";
import { TextInput } from "@redotech/redo-web/text-input";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { filterTruthy, unique } from "@redotech/util/array";
import * as classNames from "classnames";
import {
  FormEventHandler,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "usehooks-ts";
import { TeamContext } from "../../app/team";
import { UserContext } from "../../app/user";
import { RedoMerchantClientContext } from "../../client/context";
import { updateConversation } from "../../client/conversations";
import { getCustomerSearch } from "../../client/shopify";
import { copyToClipboard } from "../../clipboard";
import { ShopifyCustomer } from "../create-conversation-modal";
import * as customerDetailsCss from "./customer-details.module.css";
import { CustomerTotals } from "./customer-totals";

const NAME_EMAIL_DELIMITER = " - ";

export const CustomerDetails = memo(function CustomerDetails({
  conversation,
  orders,
  returns,
}: {
  conversation: ExpandedConversation;
  orders: Order[] | undefined;
  returns: Return[] | undefined;
}) {
  const customer = conversation.customer;
  const client = useRequiredContext(RedoMerchantClientContext);
  const team = useRequiredContext(TeamContext);
  const user = useContext(UserContext);
  const [editCustomerModalOpen, setEditCustomerModalOpen] = useState(false);

  const navigate = useNavigate();

  const [newEmail, setNewEmail] = useState<string | null>(
    customer?.email || "none",
  );
  const [firstName, setFirstName] = useState<string | null>();
  const [lastName, setLastName] = useState<string | null>();
  const canEditCustomer =
    !!user && permitted(user.permissions, Permission.EDIT_CUSTOMER);

  const [searchString, setSearchString] = useState<string | undefined>();
  const debouncedSearch = useDebounce(searchString, 500);
  const [customersLoading, setCustomersLoading] = useState(false);
  const [customers, setCustomers] = useState<Record<string, ShopifyCustomer>>(
    {},
  );
  const [pending, setPending] = useState(false);

  const gravatarUrl = useMemo(() => {
    if (!conversation?.customer?.email) {
      return null;
    }
    return Gravatar({
      email: conversation.customer?.email,
      defaultImage: "404",
      protocol: location.protocol === "https:" ? "https" : undefined,
      size: Math.ceil(devicePixelRatio * 32),
    });
  }, [devicePixelRatio, conversation?.customer?.email]);

  const [customersLoad, doCustomersLoad] = useTriggerLoad(async (signal) => {
    const searchTermPieces = (debouncedSearch || "").split(
      NAME_EMAIL_DELIMITER,
    );
    const searchTerm = searchTermPieces[searchTermPieces.length - 1];
    const customers = await getCustomerSearch(client, {
      search: searchTerm,
      signal,
    });
    setCustomersLoading(false);
    return customers;
  });
  useEffect(() => {
    if (searchString !== undefined) {
      setCustomersLoading(true);
      doCustomersLoad();
    }
  }, [debouncedSearch]);

  useEffect(() => {
    setLocalCustomer({
      ...conversation.customer,
      _id: conversation.customer?.customer ?? undefined,
    });
  }, [conversation]);

  useEffect(() => {
    if (customersLoad.value?.customers) {
      setCustomers(
        customersLoad.value.customers.reduce(
          (
            accumulator: Record<string, ShopifyCustomer>,
            customer: Record<string, any>,
          ) => {
            accumulator[customer.email] = {
              email: customer.email,
              firstName: customer.first_name,
              lastName: customer.last_name,
            };
            return accumulator;
          },
          {},
        ),
      );
    }
  }, [customersLoad.value]);

  const onCustomerInputChange = (event: any, value: string) => {
    setSearchString(value);
  };
  const [localCustomer, setLocalCustomer] = useState<Customer | null>(customer);

  const handleCustomerChange: FormEventHandler<HTMLFormElement> = async (
    event,
  ) => {
    event.preventDefault();
    setPending(true);
    const newCustomer = {
      email: newEmail || undefined,
      firstName:
        firstName ||
        (newEmail ? customers[newEmail]?.firstName || undefined : undefined),
      lastName:
        lastName ||
        (newEmail ? customers[newEmail]?.lastName || undefined : undefined),
    };
    if (newEmail) {
      const result = await updateConversation(client, conversation, {
        customer: newCustomer,
      });
      amplitude.logEvent("update-conversationCustomer", {
        conversationId: conversation._id,
        channel: conversation.platform,
        customer: newCustomer?.email,
      });
      setLocalCustomer(result.data.customer);
      setEditCustomerModalOpen(false);
      setNewEmail(null);
      setFirstName(null);
      setLastName(null);
    }
    setPending(false);
  };

  const getCustomerDisplayName = (email: string) => {
    if (email === "none") {
      return "No customer";
    }
    if (email.includes(NAME_EMAIL_DELIMITER)) {
      return email;
    }
    if (email === customer?.email) {
      return `${customer.name}${NAME_EMAIL_DELIMITER}${customer.email}`;
    }
    if (!customers[email]) {
      return `New customer${NAME_EMAIL_DELIMITER}${email}`;
    }
    if (
      !customers[email]?.firstName &&
      !customers[email]?.lastName &&
      !customers[email]?.email
    ) {
      return "";
    }
    if (!customers[email]?.firstName && !customers[email]?.lastName) {
      return `Missing name${NAME_EMAIL_DELIMITER}${customers[email]?.email || ""}`;
    }
    return `${customers[email]?.firstName || ""} ${
      customers[email]?.lastName || ""
    }${NAME_EMAIL_DELIMITER}${customers[email]?.email || ""}`;
  };

  const prependCustomCustomerResult = (customerNames: string[]) => {
    if (searchString?.includes("@")) {
      return [searchString, ...customerNames];
    } else {
      return customerNames;
    }
  };

  function handleViewCustomerOnShopify(url: string): void {
    window.open(url, "_blank");
  }

  const shopifyUrl = shopifyCustomerLink(team, conversation, orders || []);

  return (
    <div className={customerDetailsCss.details}>
      <Flex dir="column" gap="xs">
        <Flex align="center" justify="space-between">
          <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
            Customer
          </Text>
          <div className={customerDetailsCss.customerInfoTitle}>
            {canEditCustomer && (
              <Button
                className={customerDetailsCss.editButton}
                onClick={() => {
                  amplitude.logEvent("view-updateCustomerModal", {
                    conversationId: conversation._id,
                    channel: conversation.platform,
                  });
                  setEditCustomerModalOpen(true);
                }}
              >
                <EditPencil />
              </Button>
            )}
          </div>
        </Flex>
        <Flex align="center" dir="row" gap="md">
          <RedoBadge
            avatar={{
              alt: "Customer profile picture",
              name: localCustomer?.name,
              imageUrl: gravatarUrl,
            }}
            color="gray"
            size="sm"
            text={localCustomer?.name || "none"}
          />
          {team.settings.customerAccounts?.enabled &&
            localCustomer?._id &&
            localCustomer.email && (
              <IconButton
                onClick={() => {
                  navigate(
                    `/stores/${team._id}/customers/${localCustomer._id}`,
                  );
                }}
                size={ButtonSize.SMALL}
              >
                <ArrowUpRightSvg />
              </IconButton>
            )}
        </Flex>
        <Flex dir="column">
          {localCustomer?.email && (
            <div>
              <div className={customerDetailsCss.copyableField}>
                <div className={customerDetailsCss.email}>
                  <Tooltip title={localCustomer?.email ?? ""}>
                    <Text
                      fontSize="xs"
                      fontWeight="medium"
                      onClick={() => copyToClipboard(localCustomer.email)}
                      textColor="tertiary"
                      textOverflow="ellipsis"
                    >
                      {localCustomer?.email}
                    </Text>
                  </Tooltip>
                </div>
              </div>
            </div>
          )}

          {localCustomer?.phoneNumber && (
            <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
              {localCustomer.phoneNumber}
            </Text>
          )}
          {[
            ConversationPlatform.INSTAGRAM,
            ConversationPlatform.INSTAGRAM_COMMENTS,
          ].includes(conversation.platform) &&
            localCustomer?.instagram?.username && (
              <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
                <ExternalLink
                  className={customerDetailsCss.username}
                  showIcon={false}
                  url={`https://instagram.com/${localCustomer.instagram.username}`}
                >
                  @{localCustomer.instagram.username}
                </ExternalLink>
              </Text>
            )}
        </Flex>
      </Flex>

      {(orders || returns) && (
        <CustomerTotals orders={orders} returns={returns} />
      )}
      {shopifyUrl && (
        <Button
          icon={(iconProps) => (
            <ShopifyBagLogo
              className={classNames(
                iconProps.className,
                customerDetailsCss.shopifyIcon,
              )}
            />
          )}
          onClick={() => handleViewCustomerOnShopify(shopifyUrl)}
          size={ButtonSize.NANO}
          theme={ButtonTheme.OUTLINED}
        >
          View on Shopify
        </Button>
      )}

      <Modal
        onClose={() => setEditCustomerModalOpen(false)}
        open={editCustomerModalOpen}
        size={ModalSize.SMALL}
        title="Select customer"
      >
        <form
          className={customerDetailsCss.editAssigneeForm}
          onSubmit={handleCustomerChange}
        >
          <LabeledInput description="Type to search customers" label="Customer">
            <Autocomplete
              filterOptions={(x) => x}
              getLabel={(customer): string => {
                return getCustomerDisplayName(customer);
              }}
              keyFn={(customer, index): string => {
                return `${getCustomerDisplayName(customer)}-${index}`;
              }}
              noOptionsText={
                customersLoading
                  ? "Loading..."
                  : "Search for customer by email, name, or order number"
              }
              onInputChange={onCustomerInputChange}
              options={
                customers
                  ? prependCustomCustomerResult(Object.keys(customers))
                  : []
              }
              value={newEmail}
              valueChange={setNewEmail}
            >
              {(customer) => getCustomerDisplayName(customer)}
            </Autocomplete>
          </LabeledInput>
          {newEmail &&
            getCustomerDisplayName(newEmail || "").includes(
              `New customer${NAME_EMAIL_DELIMITER}`,
            ) && (
              <>
                <LabeledInput label="First name">
                  <TextInput onChange={setFirstName} value={firstName || ""} />
                </LabeledInput>
                <LabeledInput label="Last name">
                  <TextInput onChange={setLastName} value={lastName || ""} />
                </LabeledInput>
              </>
            )}
          <Button
            className={customerDetailsCss.saveButton}
            pending={pending}
            size={ButtonSize.MEDIUM}
            theme={ButtonTheme.PRIMARY}
            type="submit"
          >
            Save
          </Button>
        </form>
      </Modal>
    </div>
  );
});

function shopifyCustomerId(
  conversation: ExpandedConversation,
  orders: Order[],
): string | undefined {
  const customerIdFromConversation: string | undefined =
    conversation.customer?.shopifyCustomerId;
  if (customerIdFromConversation) {
    return customerIdFromConversation;
  }

  const customerIdFromOrders = unique(
    filterTruthy(orders?.map((order) => order.shopify.customer?.id)),
  );
  return customerIdFromOrders[0];
}

function shopifyCustomerLink(
  team: Team,
  conversation: ExpandedConversation,
  orders: Order[],
): string | undefined {
  const id = shopifyCustomerId(conversation, orders);
  if (!id) {
    return undefined;
  }
  const regexMatch = team.storeUrl?.match(/(.*)\.myshopify.com/);
  const storeName = regexMatch ? regexMatch[1] : undefined;
  if (!storeName) {
    return;
  }
  return `https://admin.shopify.com/store/${storeName}/customers/${id}`;
}
