import { breadcrumbSlot } from "@redotech/redo-web/breadcrumb";
import { RouteObject } from "react-router-dom";

const returnBreadcrumb = breadcrumbSlot();

export const returnRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Returns" },
    async lazy() {
      const { Page } = await import("../app/page");
      return { Component: Page };
    },
    children: [
      {
        index: true,
        async lazy() {
          const { Returns } = await import("./returns");
          return { element: <Returns /> };
        },
      },
      {
        handle: { breadcrumb: returnBreadcrumb },
        path: "create/:orderId",
        children: [
          {
            index: true,
            async lazy() {
              const { CreateReturnPage: CreateReturn } = await import(
                "./create-return"
              );
              return {
                element: <CreateReturn breadcrumb={returnBreadcrumb} />,
              };
            },
          },
        ],
      },
      {
        path: "instant-exchange",
        children: [
          {
            index: true,
            handle: { breadcrumb: "Instant exchange recovery" },
            async lazy() {
              const { InstantExchangeRecovery } = await import(
                "./instant-exchange-recovery"
              );
              return {
                element: <InstantExchangeRecovery />,
              };
            },
          },
        ],
      },
      {
        handle: { breadcrumb: returnBreadcrumb },
        path: ":returnId",
        children: [
          {
            index: true,
            async lazy() {
              const { ReturnPage: Return } = await import("./return");
              return {
                element: (
                  <Return breadcrumb={returnBreadcrumb} returnType="return" />
                ),
              };
            },
          },
          {
            handle: { breadcrumb: "Edit" },
            path: "edit",
            async lazy() {
              const { EditReturnPage: EditReturn } = await import(
                "./edit-return"
              );
              return {
                element: (
                  <EditReturn
                    breadcrumb={returnBreadcrumb}
                    returnType="return"
                  />
                ),
              };
            },
          },
        ],
      },
    ],
  },
];

export const claimRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Claims" },
    async lazy() {
      const { Page } = await import("../app/page");
      return { Component: Page };
    },
    children: [
      {
        index: true,
        async lazy() {
          const { Claims } = await import("./claims");
          return { element: <Claims /> };
        },
      },
      {
        path: "about",
        async lazy() {
          const { AboutClaims } = await import("./about-claims");
          return { element: <AboutClaims /> };
        },
      },
      {
        handle: { breadcrumb: returnBreadcrumb },
        path: ":returnId",
        children: [
          {
            index: true,
            async lazy() {
              const { ReturnPage: Return } = await import("./return");
              return {
                element: (
                  <Return breadcrumb={returnBreadcrumb} returnType="claim" />
                ),
              };
            },
          },
          {
            handle: { breadcrumb: "Edit" },
            path: "edit",
            async lazy() {
              const { EditReturnPage: EditReturn } = await import(
                "./edit-return"
              );
              return {
                element: (
                  <EditReturn
                    breadcrumb={returnBreadcrumb}
                    returnType="claim"
                  />
                ),
              };
            },
          },
        ],
      },
    ],
  },
];

export const warrantyRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Warranties" },
    async lazy() {
      const { Page } = await import("../app/page");
      return { Component: Page };
    },
    children: [
      {
        index: true,
        async lazy() {
          const { Warranties } = await import("./warranties");
          return { element: <Warranties /> };
        },
      },
      {
        handle: { breadcrumb: returnBreadcrumb },
        path: ":returnId",
        children: [
          {
            index: true,
            async lazy() {
              const { ReturnPage: Return } = await import("./return");
              return {
                element: (
                  <Return breadcrumb={returnBreadcrumb} returnType="warranty" />
                ),
              };
            },
          },
          {
            handle: { breadcrumb: "Edit" },
            path: "edit",
            async lazy() {
              const { EditReturnPage: EditReturn } = await import(
                "./edit-return"
              );
              return {
                element: (
                  <EditReturn
                    breadcrumb={returnBreadcrumb}
                    returnType="warranty"
                  />
                ),
              };
            },
          },
        ],
      },
    ],
  },
];
