import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { Order } from "@redotech/redo-model/order";
import { ReturnType } from "@redotech/redo-model/return";
import DotsHorizontalSvg from "@redotech/redo-web/arbiter-icon/dots-horizontal.svg";
import { ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { ButtonDropdown } from "@redotech/redo-web/button-dropdown";
import { DropdownOption } from "@redotech/redo-web/dropdown";
import { Flex } from "@redotech/redo-web/flex";
import CopyIcon from "@redotech/redo-web/icon-old/copy.svg";
import EditPencilIcon from "@redotech/redo-web/icon-old/edit-pencil2.svg";
import ReturnIcon from "@redotech/redo-web/icon-old/return.svg";
import RefundIcon from "@redotech/redo-web/icon-old/share-money.svg";
import WarningShieldIcon from "@redotech/redo-web/icon-old/warning-shield.svg";
import XIcon from "@redotech/redo-web/icon-old/x-big.svg";
import { ReactNode, memo } from "react";
import { TeamContext } from "../../../app/team";
import * as orderDetailsCss from "./order-details.module.css";

export const OrderDetailsOrderActions = memo(function OrderActions({
  order,
  setCreateType,
  setOrderNeedingAction,
  setCreateClaimModalOpen,
  setEditOrderModalOpen,
  setDuplicateOrderModalOpen,
  setCancelOrderModalOpen,
  setRefundOrderModalOpen,
}: {
  order: Order;
  setCreateType: (type: ReturnType) => void;
  setOrderNeedingAction: (order: Order) => void;
  setCreateClaimModalOpen: (open: boolean) => void;
  setEditOrderModalOpen: (open: boolean) => void;
  setDuplicateOrderModalOpen: (open: boolean) => void;
  setCancelOrderModalOpen: (open: boolean) => void;
  setRefundOrderModalOpen: (open: boolean) => void;
}) {
  const team = useRequiredContext(TeamContext);

  const onCreateReturnClick = (order: Order) => {
    amplitude.logEvent("view-createReturnModal", {
      shopifyOrderId: order.shopify_id,
    });
    setOrderNeedingAction(order);
    setCreateType("return");
    setCreateClaimModalOpen(true);
  };

  const onCreateClaimClick = (order: Order) => {
    amplitude.logEvent("view-createClaimModal", {
      shopifyOrderId: order.shopify_id,
    });
    setOrderNeedingAction(order);
    setCreateType("claim");
    setCreateClaimModalOpen(true);
  };

  const onEditOrderClick = (order: Order) => {
    amplitude.logEvent("view-updateOrderModal", {
      shopifyOrderId: order.shopify_id,
    });
    setOrderNeedingAction(order);
    setEditOrderModalOpen(true);
  };

  const onDuplicateClick = (order: Order) => {
    amplitude.logEvent("view-duplicateOrderModal", {
      shopifyOrderId: order.shopify_id,
    });
    setOrderNeedingAction(order);
    setDuplicateOrderModalOpen(true);
  };

  const onCancelOrderClick = (order: Order) => {
    amplitude.logEvent("view-cancelOrderModal", {
      shopifyOrderId: order.shopify_id,
    });
    setOrderNeedingAction(order);
    setCancelOrderModalOpen(true);
  };

  const onRefundClick = (order: Order) => {
    amplitude.logEvent("view-refundOrderModal", {
      shopifyOrderId: order.shopify_id,
    });
    setOrderNeedingAction(order);
    setRefundOrderModalOpen(true);
  };

  const unfulfilled =
    (!order.shopify?.fulfillment_status ||
      order.shopify.fulfillment_status.toLowerCase() === "unfulfilled") &&
    order.shopify.fulfillments?.length === 0;
  const closed = order.shopify.closed_at !== null;
  const cancelled = order.shopify.cancelled_at !== null;
  const availableActions: {
    buttonContent: ReactNode;
    onClick: any;
  }[] = [];
  // The order actions are pushed determines which show and which are in the dropdown
  // TODO: uncomment when finished
  if (unfulfilled && !closed && !cancelled) {
    availableActions.push({
      buttonContent: (
        <div className={orderDetailsCss.actionButton}>
          <EditPencilIcon className={orderDetailsCss.icon} />
          Edit order
        </div>
      ),
      onClick: () => onEditOrderClick(order),
    });
  }
  if (unfulfilled && !closed && !cancelled) {
    availableActions.push({
      buttonContent: (
        <div className={orderDetailsCss.actionButton}>
          <XIcon className={orderDetailsCss.icon} />
          Cancel
        </div>
      ),
      onClick: () => onCancelOrderClick(order),
    });
  }
  if (!unfulfilled && !cancelled && team.settings.returns?.enabled) {
    availableActions.push({
      buttonContent: (
        <div className={orderDetailsCss.actionButton}>
          <ReturnIcon className={orderDetailsCss.icon} />
          Create return
        </div>
      ),
      onClick: () => onCreateReturnClick(order),
    });
  }
  if (!unfulfilled && !cancelled && team.settings.packageProtection?.enabled) {
    availableActions.push({
      buttonContent: (
        <div className={orderDetailsCss.actionButton}>
          <WarningShieldIcon className={orderDetailsCss.icon} />
          Create claim
        </div>
      ),
      onClick: () => onCreateClaimClick(order),
    });
  }
  availableActions.push({
    buttonContent: (
      <div className={orderDetailsCss.actionButton}>
        <CopyIcon className={orderDetailsCss.icon} />
        Duplicate
      </div>
    ),
    onClick: () => onDuplicateClick(order),
  });
  availableActions.push({
    buttonContent: (
      <div className={orderDetailsCss.actionButton}>
        <RefundIcon className={orderDetailsCss.icon} />
        Refund
      </div>
    ),
    onClick: () => onRefundClick(order),
  });

  const actionsToShow: ReactNode[] = [];
  const dropdownActions: ReactNode[] = [];

  availableActions.forEach((action, index) => {
    dropdownActions.push(
      <DropdownOption action={action.onClick} key={index}>
        {action.buttonContent}
      </DropdownOption>,
    );
  });
  actionsToShow.push(
    <Flex className={orderDetailsCss.orderCollapseButton}>
      <ButtonDropdown
        dropdown={
          <>
            <div className={orderDetailsCss.dropdownTitle}>More actions</div>
            {dropdownActions}
          </>
        }
        restrictDropdownSizeToParent={false}
        size={ButtonSize.NANO}
        theme={ButtonTheme.GHOST}
      >
        <Flex
          align="center"
          className={orderDetailsCss.orderCollapseButtonIcon}
          justify="center"
        >
          <DotsHorizontalSvg />
        </Flex>
      </ButtonDropdown>
    </Flex>,
  );

  return actionsToShow.length > 0 ? (
    <div className={orderDetailsCss.actions}>{actionsToShow}</div>
  ) : null;
});
