// extracted by mini-css-extract-plugin
export var account = "_39-b";
export var accountDropdown = "_39-g";
export var accountMenuList = "_39-h";
export var accountMenuListItem = "_39-i";
export var accountText = "_39-d";
export var avatar = "_39-e";
export var avatarContainer = "_39-f";
export var backArrow = "_39-9";
export var brand = "_39-2";
export var brandContainer = "_39-3";
export var cart = "_39-q";
export var cartContainer = "_39-p";
export var cartSizeBubble = "_39-r";
export var cartTotalText = "_39-m";
export var chevronDown = "_39-j";
export var grayText = "_39-l";
export var imageWrapper = "_39-s";
export var logo = "_39-4";
export var nameText = "_39-k";
export var navBar = "_39-7";
export var navBarContent = "_39-8";
export var navBarLogo = "_39-c";
export var page = "_39-1";
export var pageWrapper = "_39-0";
export var sideButtons = "_39-a";
export var storeReturn = "_39-5";
export var storeReturnBlock = "_39-6";
export var textDanger = "_39-o";
export var textSuccess = "_39-n";