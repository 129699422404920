// extracted by mini-css-extract-plugin
export var arrow = "_6e-e";
export var flex = "_6e-9";
export var grayText = "_6e-c";
export var header = "_6e-2";
export var leftScroll = "_6e-7";
export var mainHeader = "_6e-3";
export var noItems = "_6e-f";
export var order = "_6e-a";
export var orderAndDate = "_6e-4";
export var orderNumber = "_6e-b";
export var page = "_6e-1";
export var pagePadding = "_6e-d";
export var productCards = "_6e-6";
export var redoCovered = "_6e-5";
export var rightScroll = "_6e-8";
export var subheader = "_6e-0";