// extracted by mini-css-extract-plugin
export var emailContainer = "_6t-3";
export var emailContentPaddedContainer = "_6t-9";
export var emailHeader = "_6t-5";
export var emailInfoIcon = "_6t-8";
export var emailShortenedSummary = "_6t-c";
export var infoDropdown = "_6t-0";
export var infoRowBoundedContent = "_6t-b";
export var internal = "_6t-4";
export var merchant = "_6t-7";
export var normal = "_6t-6";
export var replyCard = "_6t-d";
export var subtleButton = "_6t-1";
export var subtleButtonIconContainer = "_6t-2";
export var toggleViewRepliesButton = "_6t-a";
export var wrappingText = "_6t-e";