import { useRequiredContext } from "@redotech/react-util/context";
import { FilterOptions, FiltersStatus } from "@redotech/redo-model/view";
import { ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { ButtonDropdown } from "@redotech/redo-web/button-dropdown";
import { DropdownOption } from "@redotech/redo-web/dropdown";
import AsteriskIcon from "@redotech/redo-web/icon-old/asterisk.svg";
import ChevronDownIcon from "@redotech/redo-web/icon-old/chevron-down.svg";
import FolderCheckIcon from "@redotech/redo-web/icon-old/folder-check.svg";
import InboxIcon from "@redotech/redo-web/icon-old/inbox.svg";
import SnoozeClockIcon from "@redotech/redo-web/icon-old/snooze-clock.svg";
import { SkeletonText } from "@redotech/redo-web/skeleton";
import { titleCase } from "@redotech/web-util/strings";
import { Dispatch, SetStateAction, memo } from "react";
import * as statusDropdownCss from "./status-dropdown.module.css";
import { SetFiltersContext } from "./support";

export const StatusDropdown = memo(function StatusDropdown({
  viewName,
  conversationCounts,
  setStatusFilter,
  statusFilter,
  size = ButtonSize.SMALL,
}: {
  viewName: string;
  conversationCounts: Record<string, number>;
  setStatusFilter: Dispatch<
    SetStateAction<
      FiltersStatus.OPEN | FiltersStatus.CLOSED | FiltersStatus.SNOOZED | "all"
    >
  >;
  statusFilter: string;
  size?: ButtonSize;
}) {
  const setFilters = useRequiredContext(SetFiltersContext);

  return (
    <ButtonDropdown
      dropdown={
        <>
          <DropdownOption
            action={() => {
              setFilters((prevFilters: FilterOptions) => ({
                ...prevFilters,
                status: [FiltersStatus.OPEN],
              }));
              setStatusFilter(FiltersStatus.OPEN);
            }}
          >
            <div className={statusDropdownCss.dropdownOption}>
              <AsteriskIcon className={statusDropdownCss.dropdownIcon} />
              Open{" "}
              <span className={statusDropdownCss.gray}>
                (
                {conversationCounts[`${viewName}-open`] ?? (
                  <SkeletonText length={2} />
                )}
                )
              </span>
            </div>
          </DropdownOption>
          <DropdownOption
            action={() => {
              setFilters((prevFilters: FilterOptions) => ({
                ...prevFilters,
                status: [FiltersStatus.CLOSED],
              }));
              setStatusFilter(FiltersStatus.CLOSED);
            }}
          >
            <div className={statusDropdownCss.dropdownOption}>
              <FolderCheckIcon className={statusDropdownCss.dropdownIcon} />
              Closed{" "}
              <span className={statusDropdownCss.gray}>
                (
                {conversationCounts[`${viewName}-closed`] ?? (
                  <SkeletonText length={2} />
                )}
                )
              </span>
            </div>
          </DropdownOption>
          <DropdownOption
            action={() => {
              setFilters((prevFilters: FilterOptions) => ({
                ...prevFilters,
                status: [FiltersStatus.SNOOZED],
              }));
              setStatusFilter(FiltersStatus.SNOOZED);
            }}
          >
            <div className={statusDropdownCss.dropdownOption}>
              <SnoozeClockIcon className={statusDropdownCss.dropdownIcon} />
              Snoozed{" "}
              <span className={statusDropdownCss.gray}>
                (
                {conversationCounts[`${viewName}-snoozed`] ?? (
                  <SkeletonText length={2} />
                )}
                )
              </span>
            </div>
          </DropdownOption>
          <DropdownOption
            action={() => {
              setFilters((prevFilters: FilterOptions) => ({
                ...prevFilters,
                status: Object.values(FiltersStatus),
              }));
              setStatusFilter("all");
            }}
          >
            <div className={statusDropdownCss.dropdownOption}>
              <InboxIcon className={statusDropdownCss.dropdownIcon} />
              All{" "}
              <span className={statusDropdownCss.gray}>
                (
                {(conversationCounts[`${viewName}-open`] || 0) +
                  (conversationCounts[`${viewName}-closed`] || 0) +
                  (conversationCounts[`${viewName}-snoozed`] || 0) ?? (
                  <SkeletonText length={2} />
                )}
                )
              </span>
            </div>
          </DropdownOption>
        </>
      }
      restrictDropdownSizeToParent={false}
      size={size}
      theme={ButtonTheme.GHOST}
    >
      <div className={statusDropdownCss.dropdownLabel}>
        {titleCase(statusFilter)} (
        {statusFilter === "all"
          ? (conversationCounts[`${viewName}-open`] || 0) +
              (conversationCounts[`${viewName}-closed`] || 0) +
              (conversationCounts[`${viewName}-snoozed`] || 0) ?? (
              <SkeletonText length={2} />
            )
          : conversationCounts[`${viewName}-${statusFilter}`] ?? (
              <SkeletonText length={2} />
            )}
        )
        <ChevronDownIcon className={statusDropdownCss.icon} />
      </div>
    </ButtonDropdown>
  );
});
