import {
  ConversationPlatform,
  ExpandedConversation,
  MessageVisibility,
} from "@redotech/redo-model/conversation";
import {
  Attachment,
  CreateConversationBody,
} from "@redotech/redo-model/createconversationbody";
import { EmailEnvelopeInfo } from "@redotech/redo-model/support/conversations/email-info";
import {
  SortableConversationTableColumn,
  TableSort,
} from "@redotech/redo-model/table";
import { UpdateConversationBody } from "@redotech/redo-model/updateconversationbody";
import { FilterOptions } from "@redotech/redo-model/view";
import { AxiosHeaders } from "axios";
import { RedoMerchantClient } from ".";
import { ACTIVE_CONVERSATION_QUERY_PARAMETER } from "../support/query-parameters";

export const filtersToQueryParams = (filters: FilterOptions | undefined) => {
  if (!filters) {
    return "";
  }
  let filterParams: string[] = [];
  if (filters?.status) {
    filterParams = [
      ...filterParams,
      ...filters.status.map(
        (status: string) => `status[]=${encodeURIComponent(status)}`,
      ),
    ];
  }
  if (filters?.search) {
    filterParams.push(`search=${encodeURIComponent(filters.search)}`);
  }
  if (typeof filters?.read === "boolean") {
    filterParams.push(`read=${encodeURIComponent(filters.read)}`);
  }
  if (filters?.channels) {
    filterParams = [
      ...filterParams,
      ...filters.channels.map(
        (channel: string) => `channels[]=${encodeURIComponent(channel)}`,
      ),
    ];
  }
  if (filters?.assignees !== undefined) {
    filterParams = [
      ...filterParams,
      ...filters.assignees.map(
        (assignee: string | null) =>
          `assignees[]=${encodeURIComponent(assignee || "null")}`,
      ),
    ];
  }
  if (filters?.sort) {
    filterParams = [
      ...filterParams,
      `sort=${filters.sort.key}`,
      `direction=${filters.sort.direction}`,
    ];
  }
  if (filters?.createdDateStart) {
    filterParams.push(
      `createdDateStart=${encodeURIComponent(filters.createdDateStart)}`,
    );
  }
  if (filters?.createdDateEnd) {
    filterParams.push(
      `createdDateEnd=${encodeURIComponent(filters.createdDateEnd)}`,
    );
  }
  if (filters?.closedDateStart) {
    filterParams.push(
      `closedDateStart=${encodeURIComponent(filters.closedDateStart)}`,
    );
  }
  if (filters?.closedDateEnd) {
    filterParams.push(
      `closedDateEnd=${encodeURIComponent(filters.closedDateEnd)}`,
    );
  }
  if (filters?.lastResponseAtStart) {
    filterParams.push(
      `lastResponseAtStart=${encodeURIComponent(filters.lastResponseAtStart)}`,
    );
  }
  if (filters?.lastResponseAtEnd) {
    filterParams.push(
      `lastResponseAtEnd=${encodeURIComponent(filters.lastResponseAtEnd)}`,
    );
  }
  if (filters?.words) {
    filterParams = [
      ...filterParams,
      ...filters.words.map(
        (word: string) => `words[]=${encodeURIComponent(word)}`,
      ),
    ];
  }
  if (filters?.conversationTags) {
    filterParams = [
      ...filterParams,
      ...filters.conversationTags.map(
        (tag: string) => `conversationTags[]=${encodeURIComponent(tag)}`,
      ),
    ];
  }
  if (filters?.customerTags) {
    filterParams = [
      ...filterParams,
      ...filters.customerTags.map(
        (tag: string) => `customerTags[]=${encodeURIComponent(tag)}`,
      ),
    ];
  }
  if (filters?.customerEmail) {
    filterParams.push(
      `customerEmail=${encodeURIComponent(filters.customerEmail)}`,
    );
  }
  return filterParams.join("&");
};

export const queryParamsToFilters = (paramString: string) => {
  const params = decodeURIComponent(paramString).slice(1).split("&");
  // activeConversationId and initialSearch are not filters
  const filteredParams = params.filter(
    (param) =>
      param.slice(0, 20) !== ACTIVE_CONVERSATION_QUERY_PARAMETER &&
      param.slice(0, 13) !== "initialSearch",
  );
  return filteredParams.reduce(
    (filters: Record<string, any>, param: string) => {
      if (param.includes("[]")) {
        const parts = param.split("[]=");
        return {
          ...filters,
          [parts[0]]: [...(filters[parts[0]] || []), parts[1]],
        };
      } else {
        const parts = param.split("=");
        return {
          ...filters,
          [parts[0]]: parts[1],
        };
      }
    },
    {},
  );
};

/**
 * GET /conversations
 */
export async function getConversations(
  client: RedoMerchantClient,
  {
    pageContinue,
    pageStop,
    pageSize,
    filters,
    sort,
    signal,
  }: {
    pageContinue: string | undefined;
    pageStop?: string;
    pageSize: number;
    filters?: FilterOptions | undefined;
    sort?: TableSort<SortableConversationTableColumn>;
    signal?: AbortSignal;
  },
): Promise<{ data: ExpandedConversation[]; pageNext: string | undefined }> {
  const headers = {
    ...client.authorization(),
    "X-Page-Continue": pageContinue,
    "X-Page-Size": pageSize,
    "X-Page-Stop": pageStop,
  };
  const filterQueryParams = filtersToQueryParams({
    ...filters,
    ...(sort ? { sort } : {}),
  });
  const url = filterQueryParams
    ? `team/conversations?${filterQueryParams}`
    : "team/conversations";
  const response = await client.client.get(url, {
    headers,
    signal,
  });
  return {
    data: response.data,
    pageNext:
      <string>(<AxiosHeaders>response.headers).get("X-Page-Next") || undefined,
  };
}

/**
 * GET /conversations/:id
 */
export async function getConversation(
  client: RedoMerchantClient,
  {
    conversationId,
    signal,
  }: {
    conversationId: string;
    signal?: AbortSignal;
  },
): Promise<ExpandedConversation> {
  const response = await client.client.get(`conversations/${conversationId}`, {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

/**
 * POST /conversations/merge
 */
export async function mergeConversations(
  client: RedoMerchantClient,
  {
    selectedConversationIds,
    customer,
    platform,
    subject,
    assignee,
    signal,
  }: {
    selectedConversationIds: string[];
    customer: string;
    platform: Exclude<
      ConversationPlatform,
      | ConversationPlatform.INSTAGRAM_COMMENTS
      | ConversationPlatform.FACEBOOK_COMMENTS
    >;
    subject: string;
    assignee: string | null;
    signal?: AbortSignal;
  },
): Promise<ExpandedConversation> {
  const response = await client.client.post(
    "conversations/merge",
    {
      selectedConversationIds,
      customer,
      platform,
      subject,
      assignee,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * POST /conversations/:id/archive
 */
export async function archiveConversation(
  client: RedoMerchantClient,
  {
    id,
    signal,
  }: {
    id: string;
    signal?: AbortSignal;
  },
) {
  const response = await client.client.post(
    `conversations/${id}/archive`,
    {},
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * POST /conversations/bulk-archive
 */
export async function bulkArchiveConversations(
  client: RedoMerchantClient,
  {
    conversationIds,
    signal,
  }: {
    conversationIds: string[];
    signal?: AbortSignal;
  },
) {
  const response = await client.client.post(
    "conversations/bulk-archive",
    {
      conversationIds,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * PUT /conversations/:id
 */
export async function updateConversation(
  client: RedoMerchantClient,
  conversation: ExpandedConversation,
  body: UpdateConversationBody,
  signal?: AbortSignal,
) {
  const response = await client.client.put(
    `conversations/${conversation._id}`,
    body,
    {
      headers: client.authorization(),
      signal,
    },
  );
  return {
    data: response.data,
  };
}

/**
 * PUT /conversations/bulk-status-update
 */
export async function bulkUpdateConversationsStatus(
  client: RedoMerchantClient,
  {
    conversationIds,
    status,
    signal,
  }: {
    conversationIds: string[];
    status?: string;
    signal?: AbortSignal;
  },
) {
  const response = await client.client.put(
    "conversations/bulk-status-update",
    {
      conversationIds,
      status,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * PUT /conversations/bulk-add-tags
 */
export async function bulkAddTags(
  client: RedoMerchantClient,
  {
    conversationIds,
    tags,
    signal,
  }: {
    conversationIds: string[];
    tags: { name: string }[];
    signal?: AbortSignal;
  },
) {
  const response = await client.client.put(
    "conversations/bulk-add-tags",
    {
      conversationIds,
      tags,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * PUT /conversations/bulk-assign
 */
export async function bulkAssignConversations(
  client: RedoMerchantClient,
  {
    conversationIds,
    assignee,
    signal,
  }: {
    conversationIds: string[];
    assignee: string | null;
    signal?: AbortSignal;
  },
) {
  const response = await client.client.put(
    "conversations/bulk-assign",
    {
      conversationIds,
      assignee,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * PUT /conversations/bulk-update-viewed
 */
export async function bulkUpdateViewed(
  client: RedoMerchantClient,
  {
    conversationIds,
    markRead,
    signal,
  }: {
    conversationIds: string[];
    markRead: boolean;
    signal?: AbortSignal;
  },
) {
  const response = await client.client.put(
    "conversations/bulk-update-viewed",
    {
      conversationIds,
      markRead,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * PUT /conversations/bulk-snooze
 */
export async function bulkSnoozeConversations(
  client: RedoMerchantClient,
  {
    conversationIds,
    snoozedUntil,
    signal,
  }: {
    conversationIds: string[];
    snoozedUntil: string;
    signal?: AbortSignal;
  },
) {
  const response = await client.client.put(
    "conversations/bulk-snooze",
    {
      conversationIds,
      snoozedUntil,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * POST /conversations
 */
export async function createConversation(
  client: RedoMerchantClient,
  body: CreateConversationBody,
  signal?: AbortSignal | undefined,
) {
  const response = await client.client.post("conversations", body, {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

/**
 * POST /conversations/reply
 */
export async function sendMessageMerchant(
  client: RedoMerchantClient,
  {
    conversationId,
    message,
    visibility,
    htmlBody,
    attachments,
    emailEnvelopeInfo = undefined,
    signal,
  }: {
    conversationId: string;
    message: string;
    visibility: MessageVisibility;
    htmlBody?: string;
    attachments?: Attachment[];
    /**
     * We will pick the "from" email from the team integrations.
     */
    emailEnvelopeInfo?: Omit<EmailEnvelopeInfo, "from"> | undefined;
    signal?: AbortSignal;
  },
): Promise<ExpandedConversation> {
  const response = await client.client.post(
    "conversations/reply",
    {
      conversationId,
      message,
      visibility,
      htmlBody,
      attachments,
      emailEnvelopeInfo,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * POST /conversations/private-reply
 */
export async function sendPrivateReply(
  client: RedoMerchantClient,
  {
    commentConversationId,
    commentId,
    message,
    signal,
  }: {
    commentConversationId: string;
    commentId: string;
    message: string;
    signal?: AbortSignal;
  },
): Promise<ExpandedConversation> {
  const response = await client.client.post(
    "conversations/private-reply",
    {
      commentConversationId,
      commentId,
      message,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * DELETE /conversations/:conversationId/file/:fileId
 */
export async function deleteConversationFile(
  client: RedoMerchantClient,
  {
    conversationId,
    fileId,
    signal,
  }: {
    conversationId: string;
    fileId: string;
    signal?: AbortSignal;
  },
): Promise<{ conversation: ExpandedConversation }> {
  const response = await client.client.delete(
    `conversations/${conversationId}/file/${fileId}`,
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * POST /conversations/:id/forward
 */
export async function forwardEmail(
  client: RedoMerchantClient,
  {
    emails,
    conversationId,
    subject,
    messagePrefix,
    ccEmails,
    bccEmails,
    fromEmail,
    fromName,
    integrationKind,
    signal,
  }: {
    emails: string[];
    conversationId: string;
    subject: string;
    messagePrefix?: string;
    ccEmails?: readonly string[];
    bccEmails?: readonly string[];
    fromEmail: string;
    fromName: string;
    integrationKind: string;
    signal?: AbortSignal;
  },
) {
  const response = await client.client.post(
    `conversations/${conversationId}/forward`,
    {
      emails,
      subject,
      messagePrefix,
      ccEmails,
      bccEmails,
      fromEmail,
      fromName,
      integrationKind,
    },
    {
      headers: client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * GET /conversations/profile-pictures
 */
export async function getProfilePictures(
  client: RedoMerchantClient,
  {
    conversationId,
    signal,
  }: {
    conversationId: string;
    signal?: AbortSignal;
  },
): Promise<{ [key: string]: string }> {
  const response = await client.client.get("conversations/profile-pictures", {
    headers: client.authorization(),
    params: { conversationId },
    signal,
  });
  return response.data;
}

/**
 * GET /conversations/customer
 */
export async function getRecentCustomerConversations(
  client: RedoMerchantClient,
  {
    customerEmail,
    signal,
  }: {
    customerEmail: string;
    signal?: AbortSignal;
  },
): Promise<ExpandedConversation[]> {
  const response = await client.client.get(`conversations/customer`, {
    headers: client.authorization(),
    params: { customerEmail },
    signal,
  });
  return response.data;
}
