import { Condition } from "@redotech/redo-model/return-flow/condition";
import { Checkbox } from "@redotech/redo-web/checkbox";
import { ChipDelimiter, ChipInput } from "@redotech/redo-web/chip-input";
import { Flex } from "@redotech/redo-web/flex";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import { ConditionType } from "../../return-flow/condition";

const TEXT_SUBJECT_DISPLAY_LENGTH = 15;

type State = Omit<Condition.MessageBodyMatches, "type">;

const messageBodyMatchesMsg = (state: State) =>
  `Message body ${state.matchMode === "INCLUDES" ? "includes" : "is exactly"}: ${
    !state.queryStrings
      ? ""
      : `"${state.queryStrings.join(", ").substring(0, TEXT_SUBJECT_DISPLAY_LENGTH)}${state.queryStrings.join(", ").length > TEXT_SUBJECT_DISPLAY_LENGTH ? "..." : ""}"`
  }`;

export const MESSAGE_BODY_MATCHES: ConditionType<
  State,
  Condition.MessageBodyMatches
> = {
  name: "Message body",
  description(state) {
    return messageBodyMatchesMsg(state);
  },
  Details({ state, setState, disabled }) {
    return (
      <>
        <LabeledInput label="Match mode">
          <SelectDropdown
            disabled={false}
            options={["INCLUDES", "IS_EXACTLY"]}
            value={state.matchMode}
            valueChange={(e) => {
              if (e) {
                setState({ ...state, matchMode: e as typeof state.matchMode });
              }
            }}
          >
            {(topic) => (
              <p>
                {topic === "IS_EXACTLY"
                  ? "entire message is exactly"
                  : "contains"}
              </p>
            )}
          </SelectDropdown>
        </LabeledInput>
        <LabeledInput label="Text to match">
          <ChipInput
            delimiter={ChipDelimiter.NEWLINE}
            showPlaceholderWithoutFocus
            value={state.queryStrings || []}
            valueChange={(value) => {
              setState({ ...state, queryStrings: [...value] });
            }}
          />
        </LabeledInput>

        <Flex>
          <LabeledInput label="Case sensitive">
            <Checkbox
              onChange={(v) => setState({ ...state, caseSensitive: v })}
              value={state.caseSensitive}
            />
          </LabeledInput>{" "}
        </Flex>
      </>
    );
  },
  empty: { queryString: "", caseSensitive: false, matchMode: "INCLUDES" },
  fromModel(model) {
    return {
      queryStrings: model.queryStrings || [model.queryString || ""],
      caseSensitive: model.caseSensitive,
      matchMode: model.matchMode,
    };
  },
  toModel(state) {
    return { type: Condition.MESSAGE_BODY_MATCHES, ...state };
  },
  valid(state) {
    return (state.queryStrings?.length || 0) > 0;
  },
};
