import { breadcrumbSlot } from "@redotech/redo-web/breadcrumb";
import { RouteObject } from "react-router-dom";

const reportBreadcrumb = breadcrumbSlot();

export const reportsRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Reports" },
    children: [
      {
        index: true,
        async lazy() {
          const { Reports } = await import("../new-reports");
          return { Component: Reports };
        },
      },
      {
        path: ":reportId",
        handle: { breadcrumb: reportBreadcrumb },
        async lazy() {
          const { CustomizableReport } = await import("../customizable-report");
          return {
            element: <CustomizableReport breadcrumb={reportBreadcrumb} />,
          };
        },
      },
    ],
  },
];
