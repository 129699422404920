import { z } from "zod";
import { reviewBodySchema } from "./review-body-schema";

export const getReviewsSchema = {
  input: z.object({
    teamId: z.string(),
    status: z.optional(z.string()),
    date: z.optional(z.date()),
    verifiedPurchase: z.optional(z.boolean()),
    shopifyProductId: z.optional(z.string()),
    customer: z.optional(z.object({ email: z.string() })),
    rating: z.optional(z.string()),
  }),
  output: z.array(reviewBodySchema),
};
