// extracted by mini-css-extract-plugin
export var blink = "_13-b";
export var controls = "_13-0";
export var controlsRight = "_13-1";
export var fakeCursor = "_13-a";
export var hidden = "_13-9";
export var quillContainer = "_13-5";
export var quillContainerSmall = "_13-6";
export var quillFormatButtons = "_13-8";
export var quillToolbar = "_13-7";
export var removePadding = "_13-2";
export var state = "_13-3";
export var stateTitle = "_13-4";