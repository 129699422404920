import {
  StyledTextField,
  StyledTextFieldProps,
} from "@redotech/redo-web/styled-text-field";
import { Input } from "@redotech/ui/form";
import { memo } from "react";

export const ReturnAppTextField = memo(function ReturnAppTextField({
  value,
  ...props
}: {
  value: string;
} & StyledTextFieldProps) {
  return (
    <StyledTextField
      fontFamily="var(--return-app-body-font-family)"
      value={value}
      {...props}
    />
  );
});

export const ReturnAppFormTextField = memo(function ReturnAppFormTextField({
  input,
  ...props
}: {
  input: Input<string>;
} & Omit<StyledTextFieldProps, "onChange" | "value" | "error">) {
  return (
    <StyledTextField
      error={input.errors?.[0]}
      fontFamily="var(--return-app-body-font-family)"
      onChange={(e) => input.setValue(e.target.value)}
      value={input.value}
      {...props}
    />
  );
});
