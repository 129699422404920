// extracted by mini-css-extract-plugin
export var badgeColor = "_35-f";
export var badgeModern = "_35-g";
export var black = "_35-1";
export var blue = "_35-b";
export var blueLight = "_35-a";
export var brand = "_35-5";
export var error = "_35-7";
export var gray = "_35-3";
export var grayBlue = "_35-9";
export var iconWrapper = "_35-4";
export var indigo = "_35-c";
export var orange = "_35-e";
export var pillColor = "_35-0";
export var pink = "_35-d";
export var purple = "_35-6";
export var success = "_35-2";
export var warning = "_35-8";