import { Navigate, RouteObject } from "react-router-dom";
import { reportsRoutes } from "./reports/route";

export const analysisRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Analytics", breadcrumbNavigate: false },
    async lazy() {
      const { Page } = await import("../app/page");
      return { Component: Page };
    },
    children: [
      {
        index: true,
        element: <Navigate replace to="reports" />,
      },
      {
        path: "insights",
        handle: { breadcrumb: "AI Insights (Beta)" },
        async lazy() {
          const { Insights } = await import("./insights");
          return { Component: Insights };
        },
      },
      {
        path: "reports",
        children: reportsRoutes,
      },
      {
        path: "returns",
        handle: { breadcrumb: "Returns Analytics" },
        async lazy() {
          const { ReturnAnalytics: Analytics } = await import(
            "./return-analytics"
          );
          return { Component: Analytics };
        },
      },
      {
        handle: { breadcrumb: "Claims" },
        path: "claims-summary",
        lazy: async () => {
          const { ClaimsSummary } = await import("./claims-summary");
          return { Component: ClaimsSummary };
        },
      },
      {
        path: "support",
        handle: { breadcrumb: "Support Analytics" },
        async lazy() {
          const { SupportAnalytics: Analytics } = await import(
            "./support-analytics"
          );
          return { Component: Analytics };
        },
      },
      {
        path: "order-tracking",
        handle: { breadcrumb: "Order Tracking Analytics" },
        async lazy() {
          const { OrderTrackingAnalytics: Analytics } = await import(
            "./order-tracking-analytics"
          );
          return { Component: Analytics };
        },
      },
      {
        path: "order-fulfillment",
        handle: { breadcrumb: "Order Fulfillment Analytics" },
        async lazy() {
          const { OutboundLabelsAnalytics: Analytics } = await import(
            "./outbound-labels-analytics"
          );
          return { Component: Analytics };
        },
      },
    ],
  },
];
