// extracted by mini-css-extract-plugin
export var adCard = "_4n-a";
export var adCardBorder = "_4n-7";
export var container = "_4n-k";
export var deliveryDate = "_4n-2";
export var deliveryStatus = "_4n-3";
export var editMode = "_4n-g";
export var empty = "_4n-c";
export var errorIcon = "_4n-j";
export var filledCircle = "_4n-6";
export var highlighted = "_4n-8";
export var historyEntry = "_4n-5";
export var orderDetailsContainer = "_4n-m";
export var packagesContainer = "_4n-h";
export var product = "_4n-e";
export var productImage = "_4n-f";
export var title = "_4n-b";
export var trackerCard = "_4n-1";
export var trackerCardWrapper = "_4n-0";
export var trackingContainer = "_4n-l";
export var trackingHistory = "_4n-4";
export var trackingTitle = "_4n-i";
export var upsellProductsCard = "_4n-d";
export var upsellProductsCardBorder = "_4n-9";