import {
  arrayJsonFormat,
  nullableJsonFormat,
  numberJsonFormat,
  objectJsonFormat,
  stringJsonFormat,
} from "@redotech/json/format";
import {
  Cart,
  CartCost,
  CartLine,
  CartLineCost,
  Customer,
  Image,
  MoneyV2,
  Product,
  ProductVariant,
} from "@shopify/web-pixels-extension/build/ts/types/PixelEvents";

/**
 * @shopify/web-pixels-extension/build/ts/types/PixelEvents
 * Customer
 */
const _customerJsonFormat = {
  email: stringJsonFormat,
  firstName: nullableJsonFormat(stringJsonFormat),
  id: stringJsonFormat,
  lastName: nullableJsonFormat(stringJsonFormat),
  ordersCount: nullableJsonFormat(numberJsonFormat),
  phone: nullableJsonFormat(stringJsonFormat),
};
export const customerJsonFormat = objectJsonFormat<Customer>(
  _customerJsonFormat,
  {},
);
export const partialCustomerJsonFormat = objectJsonFormat<Partial<Customer>>(
  {},
  _customerJsonFormat,
);

/**
 * @shopify/web-pixels-extension/build/ts/types/PixelEvents
 * Image
 */
export const imageJsonFormat = objectJsonFormat<Image>(
  {
    src: stringJsonFormat,
  },
  {},
);

/**
 * @shopify/web-pixels-extension/build/ts/types/PixelEvents
 * MoneyV2
 */
export const moneyV2JsonFormat = objectJsonFormat<MoneyV2>(
  {
    amount: numberJsonFormat,
    currencyCode: stringJsonFormat,
  },
  {},
);

/**
 * @shopify/web-pixels-extension/build/ts/types/PixelEvents
 * Product
 */
export const productJsonFormat = objectJsonFormat<Product>(
  {
    id: stringJsonFormat,
    title: stringJsonFormat,
    type: nullableJsonFormat(stringJsonFormat),
    untranslatedTitle: stringJsonFormat,
    url: stringJsonFormat,
    vendor: stringJsonFormat,
  },
  {},
);

/**
 * @shopify/web-pixels-extension/build/ts/types/PixelEvents
 * ProductVariant
 */
export const productVariantJsonFormat = objectJsonFormat<ProductVariant>(
  {
    id: stringJsonFormat,
    image: nullableJsonFormat(imageJsonFormat),
    price: moneyV2JsonFormat,
    product: productJsonFormat,
    sku: nullableJsonFormat(stringJsonFormat),
    title: stringJsonFormat,
    untranslatedTitle: stringJsonFormat,
  },
  {},
);

/**
 * @shopify/web-pixels-extension/build/ts/types/PixelEvents
 * CartCost
 */
export const cartCostJsonFormat = objectJsonFormat<CartCost>(
  {
    totalAmount: moneyV2JsonFormat,
  },
  {},
);

/**
 * @shopify/web-pixels-extension/build/ts/types/PixelEvents
 * CartLineCost
 */
export const cartLineCostJsonFormat = objectJsonFormat<CartLineCost>(
  {
    totalAmount: moneyV2JsonFormat,
  },
  {},
);

/**
 * @shopify/web-pixels-extension/build/ts/types/PixelEvents
 * CartLine
 */
export const cartLineJsonFormat = objectJsonFormat<CartLine>(
  {
    cost: cartLineCostJsonFormat,
    merchandise: productVariantJsonFormat,
    quantity: numberJsonFormat,
  },
  {},
);

/**
 * @shopify/web-pixels-extension/build/ts/types/PixelEvents
 * Cost
 */
const _cartJsonFormat = {
  cost: cartCostJsonFormat,
  id: stringJsonFormat,
  lines: arrayJsonFormat(cartLineJsonFormat),
  totalQuantity: numberJsonFormat,
};
export const partialCartJsonFormat = objectJsonFormat<Partial<Cart>>(
  {},
  _cartJsonFormat,
);
export const cartJsonFormat = objectJsonFormat<Cart>(_cartJsonFormat, {});
