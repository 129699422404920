import { IterableMap } from "@redotech/react-util/component";
import { memo, useContext } from "react";
import { Button, ButtonSize, ButtonTheme } from "../../button";
import { Card } from "../../card";
import { CurrencyContext } from "../../currency";
import { Flex } from "../../flex";
import { Text } from "../../text";
import * as css from "./order.module.css";

/**
 * Intended as a fallback and not the primary way to show the status of an order.
 */
const shipmentStatusToText = (status: string) => {
  switch (status) {
    case "label_printed":
    case "label_purchased":
    case "confirmed":
      return "Your order is being prepared";
    case "failure":
    case "attempted_delivery":
      return "Your order could not be delivered";
    case "ready_for_pickup":
      return "Your order is ready for pickup";
    case "in_transit":
      return "Your order has shipped";
    case "out_for_delivery":
      return "Your order is out for delivery";
    case "delivered":
      return "Your order has been delivered";
    default:
      return "Your order is being prepared";
  }
};

export const OrderOrFulfillments = memo(function OrderOrFulfillments({
  order,
  merchantApp,
  largeTitle,
  showTracking = true,
  handleTrackingPageButtonClick: handleTrackingPageButtonClick,
}: {
  order: any;
  merchantApp?: boolean;
  largeTitle?: boolean;
  showTracking?: boolean;
  handleTrackingPageButtonClick: (event: React.MouseEvent) => void;
}) {
  return order?.shopify?.fulfillments?.length ? (
    <>
      {order.shopify?.fulfillments?.map((fulfillment: any) => (
        <FulfillmentDetailsCard
          fulfillment={fulfillment}
          handleTrackingPageButtonClick={handleTrackingPageButtonClick}
          key={fulfillment.id}
          largeTitle={largeTitle}
          order={order}
          showTracking={showTracking}
          title={merchantApp ? "Fulfillment details" : undefined}
        />
      ))}
    </>
  ) : (
    <OrderDetailsCard
      handleTrackingPageButtonClick={handleTrackingPageButtonClick}
      largeTitle={largeTitle}
      order={order}
      title={merchantApp ? "Order details" : undefined}
    />
  );
});

export const LineItem = memo(function LineItem({
  item,
  order,
  priceFormatter,
}: {
  item: any;
  order: any;
  priceFormatter?: (arg: number) => string;
}) {
  if (item.vendor === "re:do") {
    return null;
  }
  const image = order.shopify?.line_items?.find(
    (li: any) => li.variant_id === +item.variant_id,
  )?.image?.src;
  return (
    <Flex className={css.product} pt="3xl">
      <img className={css.productImage} src={image} />
      <Flex dir="column" flex={1} gap="xl" w="full">
        <Text as="div" fontSize="md" fontWeight="medium">
          {item.title ?? item.product_title}
        </Text>
        <Flex dir="row" fontSize="xs" justify="space-between">
          <Flex dir="column" flex={1}>
            <div>{item.variant_title}</div>
            <div>
              {`Quantity: ${
                item.fulfillable_quantity !== 0
                  ? item.fulfillable_quantity
                  : item.quantity
              }`}
            </div>
          </Flex>
          {priceFormatter && (
            <Flex flex={1} mt="auto" mx="auto">
              {priceFormatter(item.price)}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
});

export const OrderDetailsCard = memo(function OrderDetailsCard({
  order,
  title,
  largeTitle,
  handleTrackingPageButtonClick,
}: {
  order: any;
  title?: string;
  largeTitle?: boolean;
  handleTrackingPageButtonClick: (event: React.MouseEvent) => void;
}) {
  const { formatCurrency } = useContext(CurrencyContext);
  return (
    <Card gap="none">
      <Flex align="center" justify="space-between">
        <Flex dir="column" gap="xs">
          <Text fontSize={largeTitle ? "lg" : "md"} fontWeight="semibold">
            {title ?? "Your order has not yet been fulfilled"}
          </Text>
        </Flex>
        <Button
          onClick={handleTrackingPageButtonClick}
          size={ButtonSize.MICRO}
          theme={ButtonTheme.PRIMARY}
        >
          Track package
        </Button>
      </Flex>
      <Flex dir="column" gap="none">
        <IterableMap
          items={(order.shopify?.line_items || []) as any[]}
          keyFn={(item) => item.id}
        >
          {(item, i) => (
            <LineItem
              item={item}
              order={order}
              priceFormatter={formatCurrency}
            />
          )}
        </IterableMap>
      </Flex>
    </Card>
  );
});

export const FulfillmentDetailsCard = memo(function FulfillmentDetailsCard({
  fulfillment,
  order,
  title,
  largeTitle,
  showTracking = true,
  handleTrackingPageButtonClick,
}: {
  fulfillment: any;
  order: any;
  title?: string;
  largeTitle?: boolean;
  showTracking?: boolean;
  handleTrackingPageButtonClick: (event: React.MouseEvent) => void;
}) {
  const { formatCurrency } = useContext(CurrencyContext);

  const fulfillmentIndex = order.shopify?.fulfillments.indexOf(fulfillment);

  const tracker = order.trackers[0]?._tracker;

  return (
    <Card gap="none">
      <Flex align="center" justify="space-between">
        <Flex dir="column" gap="xs">
          <Text fontSize={largeTitle ? "lg" : "md"} fontWeight="semibold">
            {title ?? shipmentStatusToText(fulfillment.shipment_status)}
          </Text>
          <Text fontSize="sm">
            Package {fulfillmentIndex + 1} of{" "}
            {order.shopify?.fulfillments?.length}
          </Text>
        </Flex>
        {showTracking && (
          <Button
            onClick={handleTrackingPageButtonClick}
            size={ButtonSize.MICRO}
            theme={ButtonTheme.PRIMARY}
          >
            Track package
          </Button>
        )}
      </Flex>
      <Flex dir="column" gap="none">
        <div>
          <IterableMap
            items={(fulfillment.line_items || []) as any[]}
            keyFn={(item) => item.id}
          >
            {(item, i) => (
              <LineItem
                item={item}
                order={order}
                priceFormatter={formatCurrency}
              />
            )}
          </IterableMap>
        </div>
        <Flex className={css.fulfillmentFooter} mt="3xl" pt="3xl">
          <Flex dir="column" flex={1}>
            <Text fontSize="md" fontWeight="semibold">
              Delivery Method
            </Text>
            <Text fontSize="sm">
              {order.shopify?.shipping_lines[0]?.title ?? "Unknown"}
            </Text>
          </Flex>
          {tracker && (
            <Flex dir="column" flex={1}>
              <Text fontSize="md" fontWeight="semibold">
                Carrier
              </Text>
              <Text fontSize="sm">
                {tracker.carrier ?? "Unknown carrier"}:{" "}
                <a href={tracker.public_url}>{tracker.tracking_code}</a>
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Card>
  );
});
