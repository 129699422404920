import { useHandler } from "@redotech/react-util/hook";
import { useTriggerLoad } from "@redotech/react-util/load";
import { useObjectUrl } from "@redotech/react-util/url";
import { Input } from "@redotech/ui/form";
import * as classNames from "classnames";
import {
  ChangeEventHandler,
  createContext,
  DragEventHandler,
  memo,
  useContext,
  useId,
  useState,
} from "react";
import PlusCircleIcon from "./arbiter-icon/plus-circle.svg";
import CircleSpinner from "./circle-spinner.svg";
import XIcon from "./icon-old/x.svg";
import * as imageUploadCss from "./image-upload.module.css";
import { LabeledInput } from "./labeled-input";

export interface ImageUploadProps {
  id?: string;
  value: URL | undefined;
  onChange?(value: URL | undefined): void;
  disabled?: boolean;
  className?: string;
}

/**
 * Input an image which is immediately uploaded.
 */
export const ImageUpload = memo(function ImageUpload({
  id,
  onChange,
  value,
  className,
  disabled = false,
}: ImageUploadProps) {
  const uploader = useContext(UploaderContext)!;

  const [inputElement, setInputElement] = useState<HTMLInputElement | null>();

  const [file, setFile] = useState<File | undefined>();

  const [uploadLoad, doUpload] = useTriggerLoad(async (signal) => {
    const newValue = file ? await uploader(file, signal) : undefined;
    setFile(undefined);
    onChange && onChange(newValue);
  });

  const objectUrl = useObjectUrl(file);

  const handleRemove = useHandler(() => {
    setFile(undefined);
    doUpload();
  });
  const handleUpload = useHandler(() => inputElement?.click());

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setFile(event.target.files![0]);
    doUpload();
  };

  const dropHandler: DragEventHandler = (event) => {
    event.preventDefault();
    if (event.dataTransfer.items) {
      event.dataTransfer.items[0];
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        const item = event.dataTransfer.items[i];
        const file = item.getAsFile();
        if (file) {
          setFile(file);
          doUpload();
        }
      }
    } else if (event.dataTransfer.files) {
      for (let i = 0; i < event.dataTransfer.files.length; i++) {
        const file = event.dataTransfer.files[i];
        setFile(file);
        doUpload();
      }
    }
  };

  const cancelDrag: DragEventHandler = (event) => {
    event.preventDefault();
  };

  const url = objectUrl || value?.toString();

  return (
    <div
      className={classNames(className, imageUploadCss.container)}
      onDragOver={cancelDrag}
      onDrop={dropHandler}
    >
      <input
        className={imageUploadCss.input}
        id={id}
        onChange={handleChange}
        ref={setInputElement}
        type="file"
      />

      <button
        className={imageUploadCss.imageButton}
        disabled={disabled}
        onClick={handleUpload}
        tabIndex={-1}
        type="button"
      >
        {url ? (
          <img className={imageUploadCss.image} src={url} />
        ) : (
          <div className={imageUploadCss.image}>
            <PlusCircleIcon />
            <div className={imageUploadCss.text}>Add Image</div>
          </div>
        )}
      </button>
      {url && (
        <button
          className={imageUploadCss.iconButton}
          disabled={disabled}
          onClick={handleRemove}
          tabIndex={-1}
          type="button"
        >
          <XIcon className={imageUploadCss.icon} />
        </button>
      )}
      {uploadLoad.pending && (
        <div className={imageUploadCss.spinnerContainer}>
          <CircleSpinner className={imageUploadCss.spinner} />
        </div>
      )}
    </div>
  );
});

export const FormImageUpload = memo(function FormImageUpload({
  label,
  description,
  input,
  disabled = false,
  manualErrors,
  className,
  ...props
}: {
  description?: string;
  input: Input<URL | undefined>;
  label: string;
  disabled?: boolean;
  manualErrors?: string[];
  className?: string;
} & Omit<ImageUploadProps, "value" | "onChange">) {
  const id = useId();
  return (
    <LabeledInput
      description={description}
      errors={
        manualErrors?.length ? manualErrors : input.changed ? input.errors : []
      }
      id={id}
      label={label}
    >
      <ImageUpload
        className={className}
        disabled={disabled}
        id={id}
        onChange={input.setValue}
        value={input.value}
        {...props}
      />
    </LabeledInput>
  );
});

/**
 * Upload image
 */
export interface Uploader {
  (file: File, signal: AbortSignal): Promise<URL>;
}

export const UploaderContext = createContext<Uploader | undefined>(undefined);
