import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import {
  ConversationTagId,
  ExpandedConversation,
} from "@redotech/redo-model/conversation";
import { Permission, permitted } from "@redotech/redo-model/user";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { Flex } from "@redotech/redo-web/flex";
import PlusIcon from "@redotech/redo-web/icon-old/plus.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { Modal, ModalSize, PaddingAmount } from "@redotech/redo-web/modal";
import { ProgressBar } from "@redotech/redo-web/progress-bar";
import { memo, useContext, useEffect, useState } from "react";
import { UserContext } from "../app/user";
import { RedoMerchantClientContext } from "../client/context";
import { bulkAddTags, updateConversation } from "../client/conversations";
import { ConversationTagInput } from "./conversation-tags/conversation-tag-input";
import { CreateConversationTagModal } from "./conversation-tags/create-conversation-tag-modal";
import * as tagsModalCss from "./tags-modal.module.css";
import { getNextBatch } from "./utils";

export const TagsModal = memo(function TagsModal({
  open,
  setOpen,
  conversations,
  cleanupFn,
  selectAllInfo,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  conversations: ExpandedConversation[];
  cleanupFn?: (clearSelected?: boolean) => void;
  selectAllInfo?: {
    count: number;
    deselectedConversations: ExpandedConversation[];
    bulkActionIterator: AsyncIterator<{
      conversations: ExpandedConversation[];
    }>;
    setBlockRefresh: (val: boolean) => void;
  };
}) {
  const client = useRequiredContext(RedoMerchantClientContext);

  const [createTagModalOpen, setCreateTagModalOpen] = useState(false);

  const user = useContext(UserContext);
  const canCreateTags =
    !!user && permitted(user.permissions, Permission.MANAGE_TAG);
  const [updatingSelectAll, setUpdatingSelectAll] = useState(false);
  const [numDone, setNumDone] = useState(0);
  // If this is a bulk or select all action, this only adds tags. If it's a single conversation, it edits tags.
  const [tagsToAdd, setTagsToAdd] = useState<ConversationTagId[]>(
    conversations.length === 1 ? conversations[0].tagIds || [] : [],
  );
  const [hasChanged, setHasChanged] = useState(false);

  const handleConfirm = async () => {
    if (conversations.length === 1) {
      await updateConversation(client, conversations[0], {
        tags: tagsToAdd.map((tag) => ({ name: tag.name })),
      });
      amplitude.logEvent("assign-conversationTag", {
        mode: "single",
        conversationIds: [conversations[0]._id],
        channels: [conversations[0].platform],
        tags: tagsToAdd,
      });
    } else if (conversations.length > 1) {
      const conversationIds = conversations.map(
        (conversation) => conversation._id,
      );
      if (conversationIds.length > 0) {
        await bulkAddTags(client, {
          conversationIds,
          tags: tagsToAdd.map((tag) => ({ name: tag.name })),
        });
        const channels = [
          ...new Set(
            conversations.map((conversation) => conversation.platform),
          ),
        ];
        amplitude.logEvent("assign-conversationTag", {
          mode: "multiple",
          conversationIds,
          channels,
          tags: tagsToAdd,
        });
      }
    }
    cleanupFn && cleanupFn(true);
    setOpen(false);
  };

  const addTagsToBatch = async (): Promise<{
    batchSize: number;
    done: boolean;
  }> => {
    if (!selectAllInfo) return { batchSize: 0, done: true };
    const { done, value } = await getNextBatch(
      selectAllInfo.bulkActionIterator,
    );
    if (done) {
      return { batchSize: 0, done };
    }
    const conversationIds = value.conversations
      .filter(
        (conversation) =>
          !selectAllInfo.deselectedConversations.some(
            (deselectedConversation) =>
              deselectedConversation._id === conversation._id,
          ),
      )
      .map((conversation) => conversation._id);
    if (conversationIds.length > 0) {
      await bulkAddTags(client, {
        conversationIds,
        tags: tagsToAdd.map((tag) => ({ name: tag.name })),
      });
    }
    return { batchSize: value.conversations.length, done: false };
  };

  // This function synchronously adds tags to each page of conversations.
  // If speed becomes an issue, we could asynchronously add tags to each page.
  const handleAddTagsSelectAll = async () => {
    if (!selectAllInfo) return;
    setUpdatingSelectAll(true);
    selectAllInfo.setBlockRefresh(true);
    let done = false;
    do {
      const result = await addTagsToBatch();
      setNumDone((prevNumDone) => prevNumDone + result.batchSize);
      done = result.done;
    } while (!done);
    amplitude.logEvent("assign-conversationTag", {
      mode: "all",
      tags: tagsToAdd,
    });
    setUpdatingSelectAll(false);
    selectAllInfo.setBlockRefresh(false);
    cleanupFn && cleanupFn(true);
    setOpen(false);
  };

  useEffect(() => {
    if (plural) {
      setHasChanged(tagsToAdd.length > 0);
    } else {
      setHasChanged(
        tagsToAdd.length !== conversations[0].tagIds?.length ||
          tagsToAdd.some(
            (tag) =>
              !conversations[0].tagIds?.some(
                (conversationTag) => conversationTag.name === tag.name,
              ),
          ),
      );
    }
  }, [tagsToAdd]);

  const plural = conversations.length > 1 || !!selectAllInfo;

  const footer = (
    <Flex
      className={tagsModalCss.footerContainer}
      justify="space-between"
      p="lg"
    >
      {canCreateTags && (
        <Button
          icon={(props) => <PlusIcon {...props} />}
          onClick={() => setCreateTagModalOpen(true)}
          theme={ButtonTheme.OUTLINED}
        >
          Create tag
        </Button>
      )}
      <div className={tagsModalCss.actionButtonsContainer}>
        <Button onClick={() => setOpen(false)} theme={ButtonTheme.OUTLINED}>
          Cancel
        </Button>
        <Button
          disabled={!hasChanged}
          onClick={async () => {
            selectAllInfo
              ? await handleAddTagsSelectAll()
              : await handleConfirm();
          }}
          theme={ButtonTheme.PRIMARY}
        >
          {plural ? `Add tag${tagsToAdd.length > 1 ? "s" : ""}` : "Confirm"}
        </Button>
      </div>
    </Flex>
  );

  const tagsModal = (
    <Modal
      footer={updatingSelectAll ? undefined : footer}
      onClose={() => {
        cleanupFn && cleanupFn();
        setOpen(false);
      }}
      open={open}
      paddingAmount={PaddingAmount.NONE}
      showFooterBorder
      showHeaderBorder={plural}
      size={ModalSize.SMALL}
      title={
        plural ? (updatingSelectAll ? "Adding tags" : "Add tags") : "Edit tags"
      }
    >
      {updatingSelectAll ? (
        <div className={tagsModalCss.actionModalContentContainer}>
          <ProgressBar
            value={(numDone / (selectAllInfo?.count || numDone)) * 100}
          />
        </div>
      ) : (
        <div className={tagsModalCss.actionModalContentContainer}>
          {plural ? (
            <LabeledInput label="These tags will be added to all selected tickets">
              <ConversationTagInput
                currentTags={tagsToAdd}
                setCurrentTags={setTagsToAdd}
              />
            </LabeledInput>
          ) : (
            <ConversationTagInput
              currentTags={tagsToAdd}
              setCurrentTags={setTagsToAdd}
            />
          )}
        </div>
      )}
    </Modal>
  );

  return createTagModalOpen ? (
    <CreateConversationTagModal
      cancelClicked={() => setCreateTagModalOpen(false)}
      tagCreated={() => setCreateTagModalOpen(false)}
    />
  ) : (
    tagsModal
  );
});
