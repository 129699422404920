// extracted by mini-css-extract-plugin
export var actionButton = "_4t-t";
export var buttonContainer = "_4t-0";
export var buttonContainer2 = "_4t-1";
export var buttonSection = "_4t-2";
export var details = "_4t-3";
export var dropdownItem = "_4t-5";
export var dropdownItemsContainer = "_4t-4";
export var fileInput = "_4t-r";
export var headerButton = "_4t-6";
export var icon = "_4t-7";
export var input = "_4t-8";
export var macroListItem = "_4t-9";
export var mainContent = "_4t-a";
export var microText = "_4t-b";
export var modal = "_4t-w";
export var optionsButton = "_4t-c";
export var paperclip = "_4t-s";
export var pillContent = "_4t-d";
export var preview = "_4t-e";
export var preview2 = "_4t-f";
export var quillContainer = "_4t-q";
export var selectedMacro = "_4t-g";
export var sidebar = "_4t-h";
export var sidebarHeader = "_4t-j";
export var sidebarList = "_4t-i";
export var sidebarListItem = "_4t-k";
export var sidebarSearch = "_4t-l";
export var snoozeDurationWrapper = "_4t-v";
export var statusPillContainer = "_4t-m";
export var tagsContainer = "_4t-n";
export var unselectedMacro = "_4t-o";
export var variable = "_4t-u";
export var wrapper = "_4t-p";