import * as amplitude from "@amplitude/analytics-browser";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { countries } from "@redotech/locale/countries";
import { useHandler } from "@redotech/react-util/hook";
import { Address } from "@redotech/redo-model/return-flow";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { isValidEmail } from "@redotech/util/email";
import { memo, useContext, useEffect, useState } from "react";
import { verifyAddress } from "../../api";
import { SetUserContext, UserContext } from "../../app/user";
import { ReturnAppTextField } from "../../return-app-text-field";
import * as addressEditModal from "./address-edit-modal.module.css";
import * as review from "./review.module.css";

export const AddressEditModal = memo(function AddressEditModal({
  open,
  setOpen,
  updateEmail,
  address = null,
  setAddress,
  prepopulateFromUser = true,
  setLoading,
  phoneNumberRequired = true,
  pickupNotAvailable,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  updateEmail?: (email: string) => void;
  address?: Address;
  setAddress?: (address: Address) => void;
  prepopulateFromUser?: boolean;
  setLoading?: (loading: boolean) => void;
  phoneNumberRequired?: boolean;
  pickupNotAvailable?: boolean;
}) {
  const user = useContext(UserContext);
  const setUser = useContext(SetUserContext);

  const [street1, setStreet1] = useState("");
  const [street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("United States");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const [error, setError] = useState("");
  const [verifying, setVerifying] = useState(false);
  const [isAddressModified, setIsAddressModified] = useState(false);

  const handleInputChange = useHandler(
    (setter: React.Dispatch<React.SetStateAction<string>>, value: string) => {
      setter(value);
      setIsAddressModified(true);
    },
  );

  const [proposedAddress, setProposedAddress] = useState<Address | null>(null);

  const ableToBypassVerification = error && !isAddressModified;

  useEffect(() => {
    if (address) {
      setStreet1(address.street1);
      setStreet2(address.street2);
      setCity(address.city);
      setState(address.state);
      setCountry(address.country || "United States");
      setZip(address.zip);
      setPhone(address.phone);
      setEmail(address.email.toLowerCase());
      setName(address.name);

      return;
    }
    if (user?.address && prepopulateFromUser) {
      const address = JSON.parse(user.address);
      if (address) {
        // Address has 'address1 and 2' when modal first opens up, but gets set to 'street1 and 2' after first change.
        address.address1 && setStreet1(address.address1);
        address.street1 && setStreet1(address.street1);
        address.address2 && setStreet2(address.address2);
        address.street2 && setStreet2(address.street2);
        address.city && setCity(address.city);
        address.state && setState(address.state);
        address.country && setCountry(address.country || "United States");
        address.zip && setZip(address.zip);
        address.phone && setPhone(address.phone);
      }
    }
    const name = user?.guestName || user?.name;
    if (name && prepopulateFromUser) {
      setName(name);
    }
    const userEmail = user?.guestEmail || user?.email;
    if (userEmail && prepopulateFromUser) {
      setEmail(userEmail.toLowerCase());
    }
  }, [user]);

  const getVerifiedAddress = async () => {
    const newAddress = {
      name,
      street1,
      street2,
      city,
      state,
      country,
      zip,
      phone,
    };
    setVerifying(true);
    const verifiedAddress = await verifyAddress({
      ...newAddress,
      name,
      email: email.toLowerCase(),
    });
    setVerifying(false);
    const _verifiedAddress = {
      name: verifiedAddress.name,
      street1: verifiedAddress.street1,
      street2: verifiedAddress.street2,
      city: verifiedAddress.city,
      state: verifiedAddress.state,
      country: verifiedAddress.country,
      zip: verifiedAddress.zip,
      phone: verifiedAddress.phone,
    };
    if (!verifiedAddress.verifications?.delivery?.success) {
      if (!ableToBypassVerification) {
        setError(
          "Address could not be verified. Please confirm it is entered correctly. If you are sure it is correct, you can proceed without verification.",
        );
        setIsAddressModified(false);
        return null;
      }
    }
    // they've either verified the address or they've chosen to proceed without verification
    setError("");
    return _verifiedAddress;
  };

  const handleSaveChanges = async () => {
    amplitude.logEvent("saved-address-changes");
    if (!proposedAddress) {
      return;
    }
    setAddress
      ? setAddress(proposedAddress)
      : setUser({
          ...user,
          address: JSON.stringify(proposedAddress),
        });
    updateEmail && updateEmail(email.toLowerCase());
    setOpen(false);
    setLoading && setLoading(true);
    setProposedAddress(null);
    setIsAddressModified(false);
  };

  const beginConfirmation = async () => {
    const verifiedAddress = await getVerifiedAddress();
    if (!verifiedAddress) {
      return;
    }
    setProposedAddress({
      ...verifiedAddress,
      name,
      email: email.toLowerCase(),
    });
  };

  // boot up with an error message if necessary
  useEffect(() => {
    if (!open) return;
    void getVerifiedAddress();
  }, [open]);

  const footer = (
    <>
      {proposedAddress && (
        <Button
          onClick={() => setProposedAddress(null)}
          theme={ButtonTheme.OUTLINED}
        >
          Back
        </Button>
      )}
      {!proposedAddress && (
        <Button onClick={() => setOpen(false)} theme={ButtonTheme.OUTLINED}>
          Cancel
        </Button>
      )}
      <Button
        disabled={
          !street1 ||
          !city ||
          // state is optional for some countries
          !country ||
          !zip ||
          (phoneNumberRequired && !phone) ||
          !isValidEmail(email.toLowerCase())
        }
        onClick={proposedAddress ? handleSaveChanges : beginConfirmation}
        pending={verifying}
        theme={ButtonTheme.PRIMARY}
      >
        {ableToBypassVerification ? "Continue Anyway" : "Save Changes"}
      </Button>
    </>
  );

  const formError = (value: string, fieldName: string) => {
    return value ? undefined : `${fieldName} is required`;
  };

  const form = (
    <>
      {setAddress && (
        <ReturnAppTextField
          fullWidth
          label="Name"
          onChange={(e) => handleInputChange(setName, e.target.value)}
          value={name}
        />
      )}
      <ReturnAppTextField
        error={formError(street1, "Address")}
        fullWidth
        label="Address"
        onChange={(e) => handleInputChange(setStreet1, e.target.value)}
        required
        value={street1}
      />
      <ReturnAppTextField
        fullWidth
        label="Apartment, suite, etc. (optional)"
        onChange={(e) => handleInputChange(setStreet2, e.target.value)}
        value={street2}
      />
      <div style={{ display: "flex", gap: "8px" }}>
        <ReturnAppTextField
          error={formError(city, "City")}
          fullWidth
          label="City"
          onChange={(e) => handleInputChange(setCity, e.target.value)}
          required
          value={city}
        />
        <ReturnAppTextField
          fullWidth
          label="State/Province"
          onChange={(e) => handleInputChange(setState, e.target.value)}
          value={state}
        />
        <ReturnAppTextField
          error={formError(zip, "Zip/Postal")}
          fullWidth
          label="Zip/Postal"
          onChange={(e) => handleInputChange(setZip, e.target.value)}
          required
          value={zip}
        />
      </div>
      <FormControl>
        <InputLabel
          id="country-dropdown-label"
          sx={{
            fontFamily: "var(--return-app-body-font-family)",
            fontSize: "14px",
          }}
        >
          Country
        </InputLabel>
        <Select
          label="Country"
          labelId="country-dropdown-label"
          onChange={(e) => handleInputChange(setCountry, e.target.value)}
          required
          style={{
            width: "100%",
            borderRadius: "10px",
            fontFamily: "var(--return-app-body-font-family)",
          }}
          value={country}
        >
          {countries.map(({ name, code }) => (
            <MenuItem
              key={code}
              style={{
                fontFamily: "var(--return-app-body-font-family)",
              }}
              value={code}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ReturnAppTextField
        error={formError(phone, "Phone")}
        fullWidth
        label="Phone"
        onChange={(e) => handleInputChange(setPhone, e.target.value)}
        required
        value={phone}
      />
      <ReturnAppTextField
        error={
          isValidEmail(email.toLowerCase()) ? undefined : "Email is invalid"
        }
        fullWidth
        label="Email"
        onChange={(e) => handleInputChange(setEmail, e.target.value)}
        required
        value={email}
      />
    </>
  );

  const confirmation = (
    <div>
      <div className={addressEditModal.confirmationTitle}>
        We attempted to standardize your address. Is this correct?
      </div>
      {proposedAddress?.name && <div>{proposedAddress?.name}</div>}
      <div>{proposedAddress?.email}</div>
      {phone && <div>{proposedAddress?.phone}</div>}
      <br />
      <div>{proposedAddress?.street1}</div>
      {proposedAddress?.street2 && <div>{proposedAddress?.street2}</div>}
      <div>
        {proposedAddress?.city},{" "}
        {proposedAddress?.state ? proposedAddress.state + " " : ""}
        {proposedAddress?.zip}
      </div>
      <div>{proposedAddress?.country}</div>
    </div>
  );

  return (
    <div>
      <Modal
        footer={footer}
        onClose={() => setOpen(false)}
        open={open}
        size={ModalSize.SMALL}
        title={proposedAddress ? "Confirm Address" : "Edit Pickup Address"}
      >
        <div className={review.modalContainer}>
          <div className={review.addressFields}>
            {proposedAddress ? confirmation : form}
          </div>
        </div>
        {error && <div className={addressEditModal.error}>{error}</div>}
        {pickupNotAvailable && (
          <div className={addressEditModal.disclaimer}>
            Pickup is not available at this address
          </div>
        )}
      </Modal>
    </div>
  );
});
