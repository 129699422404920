import {
  Button,
  ButtonBorder,
  ButtonSize,
  ButtonTheme,
} from "@redotech/redo-web/button";
import Printer from "@redotech/redo-web/icon-old/printer-small.svg";
import QrCode from "@redotech/redo-web/icon-old/qr-code.svg";
import { openUrl } from ".";

import * as returnStatus from "./return-status.module.css";

export const LabelButtons = ({
  returnData,
  carrier,
  commercialInvoice,
}: {
  returnData: any;
  carrier: string;
  commercialInvoice: string;
}) => {
  return (
    <div className={returnStatus.stepButtons}>
      {!!returnData.form_label && !returnData.pickup && carrier != "UPS" && (
        <Button
          border={ButtonBorder.LIGHT}
          onClick={() => openUrl(returnData.form_label)}
          size={ButtonSize.MICRO}
          theme={ButtonTheme.OUTLINED}
        >
          <QrCode className={returnStatus.buttonIcon} /> Open QR Code
        </Button>
      )}
      {!!returnData.postage_label && (
        <Button
          border={ButtonBorder.LIGHT}
          onClick={() => openUrl(returnData.postage_label)}
          size={ButtonSize.MICRO}
          theme={ButtonTheme.OUTLINED}
        >
          <Printer className={returnStatus.buttonIcon} /> Print label
        </Button>
      )}
      {!!commercialInvoice && (
        <Button
          border={ButtonBorder.LIGHT}
          onClick={() => openUrl(commercialInvoice)}
          size={ButtonSize.MICRO}
          theme={ButtonTheme.OUTLINED}
        >
          <Printer className={returnStatus.buttonIcon} /> Print commercial
          invoice
        </Button>
      )}
    </div>
  );
};
