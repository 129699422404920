import { IterableMap } from "@redotech/react-util/component";
import { useRequiredContext } from "@redotech/react-util/context";
import { LoadState, useLoad, useTriggerLoad } from "@redotech/react-util/load";
import { AdLinkTypes } from "@redotech/redo-model/ad-link";
import { ok } from "@redotech/redo-model/fp/coerced-result";
import {
  MessagingConsent,
  defaultMessagingConsent,
} from "@redotech/redo-model/messaging-consent";
import { ITracker, ITrackingDetail, Order } from "@redotech/redo-model/order";
import { Return } from "@redotech/redo-model/return";
import { FulfillmentTracker } from "@redotech/redo-model/shipment";
import {
  OrderTrackingAd,
  UpsellProductSection,
} from "@redotech/redo-model/team";
import AlertCircleIcon from "@redotech/redo-web/arbiter-icon/alert-circle.svg";
import ChevronDown from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import ChevronUp from "@redotech/redo-web/arbiter-icon/chevron-up_filled.svg";
import { Breadcrumbs } from "@redotech/redo-web/breadcrumb";
import { Button, ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { Card } from "@redotech/redo-web/card";
import { Checkbox } from "@redotech/redo-web/checkbox";
import { Flex } from "@redotech/redo-web/flex";
import ImageIcon from "@redotech/redo-web/icon-old/image.svg";
import MobileNotificationIcon from "@redotech/redo-web/icon-old/mobile-notification.svg";
import { ExternalLink } from "@redotech/redo-web/link";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { SectionToolbar } from "@redotech/redo-web/section-toolbar";
import { prettyTrackerStatus } from "@redotech/redo-web/settings-elements/order-tracking/delivery-estimation";
import { LineItem } from "@redotech/redo-web/settings-elements/order/order-details";
import { Text } from "@redotech/redo-web/text";
import { TextInput } from "@redotech/redo-web/text-input";
import { orderExceptRedo } from "@redotech/redo-web/utils/general-utils";
import { ShopifyStorefrontClient } from "@redotech/shopify-storefront";
import * as classNames from "classnames";
import {
  Dispatch,
  SetStateAction,
  memo,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDeliveryEstimate,
  getMessagingConsent,
  getSubscriptionUrls,
  logPageClick,
  logPageView,
  updateMessagingConsent,
} from "../../../api";
import {
  ReturnAppSettings,
  SettingsContext,
  StorefrontClientContext,
} from "../../../app/settings";
import { OrderContext } from "../../order";
import { ReturnStepsCard } from "../../return-status/return-steps-card";
import * as trackingCss from "../order-tracking.module.css";
import {
  exceptionalStatusVerbiage,
  getMostRecentUnrecoveredExceptionalStatus,
} from "../shipment-exceptions";
import * as fulfillmentCss from "./fulfillment.module.css";
import { UpsellProductsCard } from "./upsell-products-card";

export enum TrackingType {
  ORDER = "order",
  RETURN = "return",
}

export const Fulfillment = memo(function Fulfillment() {
  const order = useRequiredContext(OrderContext);
  const settings = useRequiredContext(SettingsContext);
  const storefrontClient = useRequiredContext(StorefrontClientContext);
  const subscribeUrlLoad = useLoad(async () => {
    return await getSubscriptionUrls(order.shopify.customer.id);
  }, []);

  if (!subscribeUrlLoad?.value) {
    return (
      <Flex
        dir="column"
        gap="4xl"
        maxW="3xl"
        mx="auto"
        my="none"
        p="xl"
        pt="4xl"
        w="full"
        wrap="wrap"
      >
        <LoadingRedoAnimation />
      </Flex>
    );
  }

  return (
    <FulfillmentDisplay
      editMode={false}
      fullOrder={order}
      settings={settings}
      storefrontClient={storefrontClient}
      subscribeUrl={subscribeUrlLoad.value.subscribeUrl}
    />
  );
});

export const EditModeFulfillment = memo(function EditModeFulfillment({
  settings,
  onInspectAd,
  onInsertAfter,
  highlightedIndex,
  upsellText,
  className,
  onUpsellSectionSelect,
  upsellSectionHighlighted,
  fullOrder,
  storefrontClient,
  upsellSectionSettings,
  setAdSections,
  setModified,
}: {
  settings: any;
  onInspectAd: (index: number) => any;
  onInsertAfter: (index: number) => any;
  highlightedIndex?: number;
  upsellText?: string;
  className?: string;
  onUpsellSectionSelect: () => void;
  upsellSectionHighlighted: boolean;
  fullOrder: Order;
  storefrontClient?: ShopifyStorefrontClient;
  upsellSectionSettings?: UpsellProductSection;
  setAdSections?: Dispatch<SetStateAction<Map<string, OrderTrackingAd>>>;
  setModified?: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <FulfillmentDisplay
      className={className}
      editMode
      fullOrder={fullOrder}
      highlightedIndex={highlightedIndex}
      onAddAfter={onInsertAfter}
      onInspectAd={onInspectAd}
      onUpsellSectionSelect={onUpsellSectionSelect}
      setAdSections={setAdSections}
      setModified={setModified}
      settings={settings}
      storefrontClient={storefrontClient}
      upsellSectionHighlighted={upsellSectionHighlighted}
      upsellSectionSettings={upsellSectionSettings}
      upsellText={upsellText}
    />
  );
});

export const FulfillmentDisplay = memo(function FulfillmentDisplay({
  settings,
  fullOrder,
  editMode,
  onInspectAd,
  onAddAfter,
  highlightedIndex,
  upsellText,
  subscribeUrl,
  className,
  onUpsellSectionSelect,
  upsellSectionHighlighted,
  storefrontClient,
  upsellSectionSettings,
  trackingType = TrackingType.ORDER,
  returnData,
  setAdSections,
  setModified,
}: {
  settings: ReturnAppSettings;
  fullOrder: any;
  editMode: boolean;
  onInspectAd?: (index: number) => any;
  onAddAfter?: (index: number) => any;
  highlightedIndex?: number;
  upsellText?: string;
  subscribeUrl?: string;
  className?: string;
  onUpsellSectionSelect?: () => void;
  upsellSectionHighlighted?: boolean;
  storefrontClient: ShopifyStorefrontClient;
  upsellSectionSettings?: UpsellProductSection;
  trackingType?: TrackingType;
  returnData?: Return;
  setAdSections?: Dispatch<SetStateAction<Map<string, OrderTrackingAd>>>;
  setModified?: Dispatch<SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const order = orderExceptRedo(fullOrder);

  const messagingConsentLoad = useLoad(async () => {
    return await getMessagingConsent(order.shopify.customer.id);
  }, []);

  const messagingConsent =
    messagingConsentLoad.value || defaultMessagingConsent;

  useEffect(() => {
    const adsVisible = settings.orderTracking.ads.filter(
      (ad) =>
        !ad.showOnlyNonSubscribed ||
        !messagingConsent.emailMarketingConsent.consented ||
        !messagingConsent.smsMarketingConsent.consented,
    );
    if (!editMode) {
      void logPageView({
        trackableId:
          trackingType === TrackingType.ORDER ? order._id : returnData._id,
        trackableType: trackingType,
        images: adsVisible.map((ad) => ad.imageUrl.toString()),
      });
    }
  }, [order?._id, returnData?._id]);

  const { fulfillmentIndex } = useParams();

  const selectedFulfillment =
    order.shopify?.fulfillments[editMode ? 0 : parseInt(fulfillmentIndex)] ||
    [];

  const handleNavigate = (i: number) => {
    navigate(`/returns-portal/orders/${order._id}/track/${i}`);
    window.scrollTo(0, 0);
  };

  if (!selectedFulfillment && trackingType === TrackingType.ORDER) {
    return null;
  }

  const trackers =
    trackingType === TrackingType.RETURN
      ? returnData?.shipments.map((shipment) => shipment._shipment.tracker)
      : order.trackers;

  const trackersForFulfillment = (trackers as FulfillmentTracker[]).filter(
    (tracker) =>
      tracker.fulfillmentID?.toString() === selectedFulfillment?.id?.toString(),
  );

  const trackersWithDefault = trackersForFulfillment.length
    ? trackersForFulfillment
    : [undefined];

  const upsellProducts =
    trackingType === TrackingType.ORDER
      ? settings?.orderTracking?.upsellProducts
      : settings?.returnTracking?.upsellProducts;
  const ads =
    trackingType === TrackingType.ORDER
      ? settings?.orderTracking?.ads || []
      : settings?.returnTracking?.ads || [];

  const setSelected = (index: string | undefined) => {
    onInspectAd(index ? parseInt(index) : undefined);
  };

  return (
    <Flex
      className={className}
      dir="column"
      gap="4xl"
      maxW="3xl"
      mx="auto"
      my="none"
      p="xl"
      pt="4xl"
      wrap="wrap"
    >
      {!editMode && <Breadcrumbs size="small" />}
      <Flex className={trackingCss.mobileColumn} gap="xl">
        <Flex gap="xl">
          <Flex dir="column" gap="xl" w="full">
            <Flex className={trackingCss.mobileColumn} gap="xl">
              <Flex
                className={fulfillmentCss.trackerCardWrapper}
                dir="row"
                gap="xl"
                inert={editMode}
              >
                {trackersWithDefault.map((tracker, i) => (
                  <TrackerCard
                    fulfillment={selectedFulfillment}
                    key={i}
                    merchantName={settings.merchantName}
                    orderCreatedAt={order.shopify.created_at}
                    orderIsUnfulfilled={selectedFulfillment.length === 0}
                    returnData={returnData}
                    settings={settings}
                    shippingAddress={order.shopify.shipping_address}
                    tracker={tracker?._tracker}
                    trackingType={trackingType}
                  />
                ))}
              </Flex>
              <Flex dir="column" flex={1.5} gap="xl">
                <UpsellProductsCard
                  editMode={editMode}
                  onUpsellSectionSelect={onUpsellSectionSelect}
                  order={order}
                  returnData={returnData}
                  settings={settings}
                  storefrontClient={storefrontClient}
                  trackingType={trackingType}
                  upsellSectionHighlighted={upsellSectionHighlighted}
                  upsellSectionSettings={
                    editMode ? upsellSectionSettings : upsellProducts
                  }
                  upsellText={upsellText}
                />
                {settings?.orderTracking?.billing?.texts?.enabled && (
                  <NotificationsCard
                    customerId={order?.shopify?.customer?.id}
                    editMode={editMode}
                    messagingConsent={messagingConsent}
                  />
                )}
              </Flex>
            </Flex>
            {renderItemCards(
              trackingType,
              editMode,
              order,
              fulfillmentIndex,
              selectedFulfillment,
              handleNavigate,
              returnData,
            )}
          </Flex>
        </Flex>
        <Flex dir="column" flex={1} gap="xl" inert={false}>
          {ads.map((ad, index) => (
            <Flex dir="row" gap="sm" key={index} position="relative">
              <AdCard
                ad={{
                  ...ad,
                  ...(onInspectAd && onAddAfter
                    ? {
                        onInspectAd: () => onInspectAd(index),
                        onInsertAd: () => onAddAfter(index),
                      }
                    : {}),
                }}
                highlighted={highlightedIndex === index}
                isSubscribed={
                  messagingConsent?.emailMarketingConsent?.consented
                }
                orderId={order._id}
                subscribeUrl={subscribeUrl}
                trackingType={trackingType}
              />
              <SectionToolbar<OrderTrackingAd>
                canDuplicate={false}
                index={index}
                open={index === highlightedIndex}
                setModified={setModified}
                setSections={setAdSections}
                setSelected={setSelected}
                totalSections={ads.length}
              />
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
});

const renderItemCards = (
  trackingType,
  editMode,
  order,
  fulfillmentIndex,
  selectedFulfillment,
  handleNavigate,
  returnData: Return,
) => {
  const redoItemsCount = order.shopify.line_items.filter(
    (item) => item.vendor === "re:do",
  ).length;
  if (trackingType === TrackingType.ORDER) {
    return (
      <Flex dir="column" gap="xl" inert={editMode}>
        {order.shopify.fulfillments.length > redoItemsCount &&
        trackingType === TrackingType.ORDER ? (
          <>
            <Card>
              <Text fontSize="lg" fontWeight="semibold">
                Items in this package
                {order?.shopify?.fulfillments?.[fulfillmentIndex]
                  ?.tracking_numbers?.length > 1 && (
                  <Text fontSize="sm">
                    Some items are being shipped in multiple parts
                  </Text>
                )}
              </Text>
              <IterableMap
                items={(selectedFulfillment.line_items || []) as any[]}
                keyFn={(item) => item.id}
              >
                {(item, i) => <LineItem item={item} order={order} />}
              </IterableMap>
            </Card>
            <IterableMap
              items={order?.shopify?.fulfillments || []}
              keyFn={(f: any) => f.id}
            >
              {(fulfillment, i) =>
                fulfillment.id === selectedFulfillment.id ? null : (
                  <Card gap="none" key={i}>
                    <Flex justify="space-between">
                      <Text fontSize="lg" fontWeight="semibold">
                        Items in another package
                      </Text>
                      <Button
                        onClick={() => handleNavigate(i)}
                        size={ButtonSize.MICRO}
                        theme={ButtonTheme.OUTLINED}
                      >
                        Track Package
                      </Button>
                    </Flex>
                    <IterableMap
                      items={(fulfillment.line_items || []) as any[]}
                      keyFn={(item) => item.id}
                    >
                      {(item, i) => <LineItem item={item} order={order} />}
                    </IterableMap>
                  </Card>
                )
              }
            </IterableMap>
          </>
        ) : (
          <Card>
            <Text fontSize="lg" fontWeight="semibold">
              Items in this order
            </Text>
            <IterableMap
              items={(order.shopify.line_items || []) as any[]}
              keyFn={(item) => item.id}
            >
              {(item, i) => <LineItem item={item} order={order} />}
            </IterableMap>
          </Card>
        )}
      </Flex>
    );
  } else {
    return (
      <Flex dir="column" gap="xl" inert={editMode}>
        <Card>
          <Text fontSize="lg" fontWeight="semibold">
            What you're returning
          </Text>
          <IterableMap
            items={(returnData.products || []) as any[]}
            keyFn={(item) => item.id}
          >
            {(item, i) => <LineItem item={item} order={order} />}
          </IterableMap>
        </Card>
      </Flex>
    );
  }
};

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const dayOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

// If the estimated delivery date is in the past, set it to now
// Since we don't get updates when the estimated delivery date changes
function shouldHaveBeenDeliveredButWasnt(tracker: ITracker | undefined) {
  return !!(
    tracker?.status !== "delivered" &&
    tracker?.est_delivery_date &&
    Temporal.Instant.compare(
      Temporal.Now.zonedDateTimeISO().toInstant(),
      new Date(tracker.est_delivery_date).toTemporalInstant(),
    ) > 0
  );
}

function returnDeliveredDateIfStatusIsDelivered(
  tracker: ITracker | undefined,
  fallbackEstimatedDeliveryLoad: LoadState<Date | undefined>,
) {
  return tracker?.status === "delivered"
    ? new Date(
        tracker.tracking_details?.find(
          (detail) => detail.status === "delivered",
        ).datetime,
      )
    : fallbackEstimatedDeliveryLoad.value;
}

const TrackerCard = memo(function TrackerCard({
  tracker,
  shippingAddress,
  orderCreatedAt,
  merchantName,
  trackingType,
  orderIsUnfulfilled,
  returnData,
  settings,
  fulfillment,
}: {
  tracker: ITracker | undefined;
  shippingAddress?: {
    country_code: string;
    province_code?: string;
  };
  orderCreatedAt: string;
  merchantName: string;
  trackingType: TrackingType;
  orderIsUnfulfilled: boolean;
  returnData?: Return;
  settings: ReturnAppSettings;
  fulfillment: any;
}) {
  const robustTracker = {
    ...tracker,
    est_delivery_date: shouldHaveBeenDeliveredButWasnt(tracker)
      ? Temporal.Now.instant().toString()
      : tracker?.est_delivery_date,
  };

  const primaryEstimatedDelivery = useMemo(
    () =>
      robustTracker?.est_delivery_date
        ? new Date(robustTracker.est_delivery_date)
        : null,
    [robustTracker?.est_delivery_date],
  );

  const fallbackEstimatedDeliveryLoad = useLoad(async () => {
    if (robustTracker?.est_delivery_date || !shippingAddress) {
      return undefined;
    }
    try {
      const res = await getDeliveryEstimate({
        countryCode: shippingAddress.country_code,
        provinceCode: shippingAddress.province_code,
        includeFulfillmentTime: orderIsUnfulfilled,
      });

      return new Date(res.data);
    } catch (e) {
      console.error("Error fetching fallback estimated delivery", e);
    }
    return undefined;
  }, [robustTracker?.est_delivery_date]);

  const getStatusMessage = (
    status: string,
    trackableType: "order" | "return",
    fulfillment: any,
  ) => {
    switch (status) {
      case "unknown": {
        if (fulfillment?.status === "fulfilled") {
          return "Pre-transit";
        } else {
          return trackableType === "order"
            ? "Your order is being prepared"
            : "Awaiting return";
        }
      }
      default:
        return (
          (prettyTrackerStatus[status] || status) +
          (trackableType === "return" ? " to merchant" : "")
        );
    }
  };

  const estimatedOrActualDelivery =
    primaryEstimatedDelivery ??
    returnDeliveredDateIfStatusIsDelivered(
      tracker,
      fallbackEstimatedDeliveryLoad,
    );

  const emptyTrackingLocation = {
    city: "",
    state: "",
    country: "",
    zip: "",
    object: "TrackingLocation" as const,
  };

  const defaultTrackingEvents = [
    {
      datetime: null,
      message: "Delivered",
      tracking_location: emptyTrackingLocation,
      status: "delivered" as any,
      source: "carrier",
      object: "TrackingDetail" as const,
    },
    {
      datetime: null,
      message: "Shipped",
      tracking_location: emptyTrackingLocation,
      status: "in_transit" as any,
      source: "carrier",
      object: "TrackingDetail" as const,
    },
    {
      datetime: robustTracker?.created_at ?? orderCreatedAt,
      message: "Order received",
      tracking_location: emptyTrackingLocation,
      status: "pre_transit" as any,
      source: "carrier",
      object: "TrackingDetail" as const,
      filled: true,
    },
  ];

  const [width, _] = useState(window.innerWidth);
  const [showTracking, setShowTracking] = useState(width > 1000);

  const trackingEvents = tracker?.tracking_details?.length
    ? [...robustTracker.tracking_details].reverse()
    : defaultTrackingEvents;

  const mostRecentUnrecoveredExceptionalStatus =
    getMostRecentUnrecoveredExceptionalStatus(trackingEvents);

  return (
    <Card className={fulfillmentCss.trackerCard} gap="xl" w="full">
      <Flex dir="column" gap="xl">
        <ExternalLink showIcon={false} url={robustTracker.public_url}>
          <Text fontSize="lg" fontWeight="semibold" textColor="secondary">
            Estimated Delivery
          </Text>
        </ExternalLink>
        {mostRecentUnrecoveredExceptionalStatus ? (
          <Flex dir="column" mt="md">
            <Flex
              alignSelf="flex-start"
              bgColor="error-secondary"
              className={fulfillmentCss.errorIcon}
              radius="full"
              textColor="error"
            >
              <AlertCircleIcon height="20" width="20" />
            </Flex>
            <Text fontSize="xl" fontWeight="bold">
              {getStatusMessage(
                mostRecentUnrecoveredExceptionalStatus.status || "unknown",
                trackingType,
                fulfillment,
              )}
            </Text>
            <Flex>
              <Text fontSize="sm">
                {exceptionalStatusVerbiage[
                  mostRecentUnrecoveredExceptionalStatus.status
                ](merchantName)}
              </Text>
              <Button
                onClick={() => setShowTracking(!showTracking)}
                size={ButtonSize.NANO}
                theme={ButtonTheme.OUTLINED}
              >
                {showTracking ? <ChevronUp /> : <ChevronDown />}
              </Button>
            </Flex>
          </Flex>
        ) : (
          <>
            {estimatedOrActualDelivery && (
              <>
                <Text fontSize="md" fontWeight="semibold">
                  {dayOfWeek[estimatedOrActualDelivery.getUTCDay()]},{" "}
                  {month[estimatedOrActualDelivery.getUTCMonth()]}
                </Text>
                <Text
                  className={fulfillmentCss.deliveryDate}
                  fontWeight="regular"
                >
                  {estimatedOrActualDelivery.getUTCDate()}
                </Text>
              </>
            )}
            <Text fontSize="md" fontWeight="semibold">
              Status
            </Text>
            <Flex>
              <Text
                as="span"
                className={fulfillmentCss.deliveryStatus}
                fontWeight="medium"
              >
                {getStatusMessage(
                  robustTracker?.status || "unknown",
                  trackingType,
                  fulfillment,
                )}
              </Text>
              <Button
                onClick={() => setShowTracking(!showTracking)}
                size={ButtonSize.NANO}
                theme={ButtonTheme.OUTLINED}
              >
                {showTracking ? <ChevronUp /> : <ChevronDown />}
              </Button>
            </Flex>
          </>
        )}
        {showTracking && (
          <>
            <Flex
              className={fulfillmentCss.trackingHistory}
              dir="column"
              gap="md"
              reverse
            >
              {trackingType === TrackingType.ORDER ? (
                <IterableMap items={trackingEvents} keyFn={(_, i) => i}>
                  {(detail, index) => (
                    <TrackingEventComponent detail={detail} key={index} />
                  )}
                </IterableMap>
              ) : (
                <ReturnStepsCard
                  cardless
                  returnData={returnData}
                  settings={settings}
                />
              )}
            </Flex>
            <Flex>
              <Flex dir="column" flex={1} gap="xs">
                <Text className={fulfillmentCss.trackingTitle} fontSize="xl">
                  Tracking
                </Text>
                {robustTracker && (
                  <ExternalLink
                    className={classNames(trackingCss.tinyText)}
                    url={
                      fulfillment?.tracking_url &&
                      !(fulfillment.tracking_url as string).includes(`redo`)
                        ? fulfillment.tracking_url
                        : robustTracker.public_url
                    }
                  >
                    <Text fontWeight="semibold" textColor="secondary">
                      {robustTracker.tracking_code}
                    </Text>
                  </ExternalLink>
                )}
              </Flex>
              <Flex dir="column" flex={1} gap="xs">
                <Text fontSize="xl">Carrier</Text>
                {robustTracker && (
                  <Text
                    className={classNames(trackingCss.tinyText)}
                    fontWeight="semibold"
                  >
                    {robustTracker.carrier}
                  </Text>
                )}
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
      {!robustTracker && (
        <Flex>
          <Text
            className={classNames(trackingCss.tinyText)}
            fontWeight="semibold"
          >
            No tracking information available
          </Text>
        </Flex>
      )}
    </Card>
  );
});

const TrackingEventComponent = memo(function TrackingEventComponent({
  detail,
}: {
  detail: ITrackingDetail;
}) {
  const date = useMemo(() => {
    try {
      if (!detail.datetime) return null;
      const date = Temporal.PlainDateTime.from(
        detail.datetime.replace(/Z$/, ""),
      );
      return date.toLocaleString(undefined, {
        dateStyle: "medium",
        timeStyle: "short",
      });
    } catch (e) {
      console.error("Error parsing date", e);
      return "Unknown";
    }
  }, [detail.datetime]);

  const message = useMemo(() => {
    const { city, state, country } = detail.tracking_location;
    const locationParts = [];

    if (city) locationParts.push(city);
    if (state) locationParts.push(state);
    if (country) locationParts.push(country);

    const location =
      locationParts.length > 0 ? `, ${locationParts.join(", ")}` : "";

    return `${detail.message}${location}`;
  }, [detail]);

  return (
    <Flex
      className={fulfillmentCss.historyEntry}
      dir="column"
      gap="xxs"
      justify="center"
      pl="3xl"
      position="relative"
    >
      {"filled" in detail && detail.filled && (
        <span className={fulfillmentCss.filledCircle} />
      )}
      {date && (
        <Text className={trackingCss.tinyText} fontWeight="semibold">
          {date}
        </Text>
      )}
      <span
        className={classNames(
          trackingCss.xTinyText,
          trackingCss.thin,
          trackingCss.grey,
        )}
      >
        {message}
      </span>
    </Flex>
  );
});

export interface EditAdProps extends OrderTrackingAd {
  onInspectAd(ad: OrderTrackingAd): void;
  onInsertAd(ad: OrderTrackingAd): void;
}

const AdCard = memo(function AdCard({
  ad,
  highlighted,
  subscribeUrl,
  isSubscribed,
  orderId,
  trackingType,
}: {
  ad: OrderTrackingAd | EditAdProps;
  highlighted?: boolean;
  subscribeUrl?: string;
  isSubscribed?: boolean;
  orderId?: string;
  trackingType: TrackingType;
}) {
  if (
    ad.type === AdLinkTypes.SUBSCRIBE &&
    ad.showOnlyNonSubscribed &&
    isSubscribed
  ) {
    return null;
  }

  const classes = classNames(
    fulfillmentCss.adCard,
    ...(ad.imageUrl ? [] : [fulfillmentCss.empty]),
  );

  const borderClasses = classNames(
    fulfillmentCss.adCardBorder,
    ...(highlighted ? [fulfillmentCss.highlighted] : []),
  );

  const card = (
    <Card
      bgImage={ad.imageUrl}
      bgImageAlt={ad.altText}
      className={classes}
      onClick={() => {
        // On merchant dashboard edit order tracking page
        if (maybeEditAdProps.onInspectAd && maybeEditAdProps.onInsertAd) {
          return;
        }
        if (orderId) {
          void logPageClick({
            trackableId: orderId,
            trackableType: trackingType,
            eventType: "ad",
            url: ad.url,
            image: ad.imageUrl.toString(),
          });
        }
      }}
      onMouseDown={(e: React.MouseEvent) => e.stopPropagation()}
    >
      {/* Customizable text and button not yet supported
        <span className={fulfillmentCss.title}>New style dropping weekly</span>
        <Button theme={ButtonTheme.SOLID_LIGHT}>Shop weekly drop</Button>
        */}
      {!ad.imageUrl && (
        <>
          <ImageIcon height={32} width={32} />
          {!ad.imageUrl && "Add image"}
        </>
      )}
    </Card>
  );

  const maybeEditAdProps = ad as EditAdProps;
  if (maybeEditAdProps.onInspectAd && maybeEditAdProps.onInsertAd) {
    return (
      <div className={borderClasses}>
        <div
          onClick={(e) => {
            e.stopPropagation();
            maybeEditAdProps.onInspectAd(ad);
          }}
        >
          {card}
        </div>
        <Button
          onClick={() => maybeEditAdProps.onInsertAd(ad)}
          size={ButtonSize.MICRO}
          theme={ButtonTheme.BRAND}
        >
          +
        </Button>
      </div>
    );
  }

  // add utm params to url if not subscribing
  const urlResult = ok(ad?.url).map((url) => (url ? new URL(url) : null));
  const urlWithUtm =
    urlResult.ok === true
      ? urlResult.value
      : (console.error("Error parsing ad URL", urlResult.error), null);
  urlWithUtm?.searchParams.append("utm_source", "redo");
  urlWithUtm?.searchParams.append("utm_medium", "order_tracking");

  // not the intended use of utm parameters, but we can use them to track the order >:)
  urlWithUtm?.searchParams.append("utm_content", "ad");
  urlWithUtm?.searchParams.append("utm_term", orderId);

  if (ad.type === AdLinkTypes.SUBSCRIBE) {
    return (
      <a
        href={subscribeUrl}
        rel="noreferrer"
        style={{ flexGrow: 1 }}
        target="_blank"
      >
        {card}
      </a>
    );
  } else if (urlWithUtm) {
    return (
      <a
        href={urlWithUtm.toString()}
        rel="noreferrer"
        style={{ flexGrow: 1 }}
        target="_blank"
      >
        {card}
      </a>
    );
  }

  return card;
});

const NotificationsCard = memo(function NotificationsCard({
  messagingConsent,
  customerId,
  editMode,
}: {
  messagingConsent: MessagingConsent;
  customerId: string;
  editMode?: boolean;
}) {
  const [phoneNumber, setPhoneNumber] = useState(
    messagingConsent?.smsTransactionalConsent.phoneNumber,
  );
  const [marketingConsent, setMarketingConsent] = useState(
    messagingConsent?.smsMarketingConsent.consented,
  );
  const [originalPhoneNumber, setOriginalPhoneNumber] = useState(
    messagingConsent?.smsTransactionalConsent.phoneNumber,
  );
  const [originalMarketingConsent, setOriginalMarketingConsent] = useState(
    messagingConsent?.smsMarketingConsent.consented,
  );
  const [canSave, setCanSave] = useState(false);

  const [saveLoad, doSave] = useTriggerLoad(async () => {
    try {
      setOriginalMarketingConsent(marketingConsent);
      setOriginalPhoneNumber(phoneNumber);
      await updateMessagingConsent(customerId, {
        smsTransactionalConsent: { phoneNumber, consented: true },
        smsMarketingConsent: { consented: marketingConsent, phoneNumber },
      });
    } catch (e) {
      console.error(e);
    }
  });

  useEffect(() => {
    if (
      (phoneNumber !== originalPhoneNumber ||
        marketingConsent !== originalMarketingConsent) &&
      phoneNumber.length > 9
    ) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }, [
    phoneNumber,
    marketingConsent,
    originalMarketingConsent,
    originalPhoneNumber,
  ]);

  return (
    <Card>
      <Flex dir="column" inert={editMode}>
        <Flex>
          <MobileNotificationIcon height={32} width={32} />
          <Text fontSize="md" fontWeight="semibold">
            Get updates about your delivery.
          </Text>
        </Flex>
        <Flex align="center">
          <TextInput
            fullwidth
            onChange={(e) => setPhoneNumber(e)}
            placeholder="Enter your phone number"
            value={phoneNumber}
          />
          <Button
            disabled={!canSave}
            onClick={() => doSave()}
            pending={saveLoad.pending}
            size={ButtonSize.SMALL}
            theme={ButtonTheme.DARK}
          >
            Sign up
          </Button>
        </Flex>
        <Flex>
          <Checkbox
            onChange={() => {
              setMarketingConsent(!marketingConsent);
            }}
            value={marketingConsent}
          >
            Text me with news and offers
          </Checkbox>
        </Flex>
      </Flex>
    </Card>
  );
});

const exampleOrder = {
  _id: "123",
  shopify: {
    line_items: [
      {
        vendor: "re:do",
        product_id: "1",
      },
      {
        vendor: "re:do",
        product_id: "2",
      },
      {
        vendor: "re:do",
        product_id: "3",
      },
    ],
    fulfillments: [
      {
        id: "1",
        line_items: [
          {
            id: "1",
            title: "Example Product 1",
            quantity: 1,
            price: "10.00",
          },
          {
            id: "2",
            title: "Example Product 2",
            quantity: 1,
            price: "10.00",
          },
        ],
      },
      {
        id: "2",
        line_items: [
          {
            id: "3",
            title: "Example Product 3",
            quantity: 1,
            price: "10.00",
          },
        ],
      },
    ],
  },
  discount: {
    expirationDateTime: Temporal.Now.instant().add({ hours: 12 }).toString(),
  },
  trackers: [],
};
