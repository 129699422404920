// extracted by mini-css-extract-plugin
export var baseCustomerActivityCardArrowRight = "_64-e";
export var baseCustomerActivityCardArrowRightContainer = "_64-d";
export var baseCustomerActivityCardContainer = "_64-b";
export var baseCustomerActivityCardContainerHovered = "_64-c";
export var customerActivityDataClaimIcon = "_64-7";
export var customerActivityDataClaimIconContainer = "_64-6";
export var customerActivityDataConversationIcon = "_64-5";
export var customerActivityDataConversationIconContainer = "_64-4";
export var customerActivityDataOrderIcon = "_64-1";
export var customerActivityDataOrderIconContainer = "_64-0";
export var customerActivityDataReturnIcon = "_64-3";
export var customerActivityDataReturnIconContainer = "_64-2";
export var customerActivityShoppingIconContainer = "_64-8";
export var messageBubble = "_64-9";
export var messageBubbleText = "_64-a";