import { MacroStatusToSet, SnoozeDuration } from "@redotech/redo-model/macro";
import { PillTheme } from "@redotech/redo-model/pill-theme";
import Edit04Icon from "@redotech/redo-web/arbiter-icon/edit-04.svg";
import { IconButton } from "@redotech/redo-web/button";
import AsteriskIcon from "@redotech/redo-web/icon-old/asterisk.svg";
import FolderCheckIcon from "@redotech/redo-web/icon-old/folder-check.svg";
import SnoozeClockIcon from "@redotech/redo-web/icon-old/snooze-clock.svg";
import TagIcon from "@redotech/redo-web/icon-old/tag.svg";
import XIcon from "@redotech/redo-web/icon-old/x.svg";
import { Pill, PillSize } from "@redotech/redo-web/pill";
import * as capitalize from "lodash/capitalize";
import { JSXElementConstructor, memo, useContext } from "react";
import { ConversationTagsContext } from "../../app/conversation-tags-context";
import * as macroAutomationPillCss from "./macro-automation-pills.module.css";

export const TagMacroAutomationPill = memo(function TagMacroAutomationPill({
  tag,
  xClicked,
}: {
  tag: string;
  xClicked?(): void;
}) {
  const teamConversationTags = useContext(ConversationTagsContext);

  const teamTag = teamConversationTags.find((teamTag) => teamTag.name === tag);
  const tagName = teamTag?.name || tag;
  const tagTheme: PillTheme = teamTag?.pillTheme
    ? teamTag.pillTheme
    : PillTheme.PRIMARY_LIGHT;
  return (
    <MacroAutomationPill
      Icon={TagIcon}
      label={tagName}
      theme={tagTheme}
      xClicked={xClicked}
    />
  );
});

export const StatusMacroAutomationPill = memo(
  function StatusMacroAutomationPill({
    statusToSet,
    snoozeDuration,
    xClicked,
  }: {
    statusToSet: MacroStatusToSet;
    snoozeDuration?: SnoozeDuration;
    xClicked?(): void;
  }) {
    return (
      <MacroAutomationPill
        Icon={
          snoozeDuration
            ? SnoozeClockIcon
            : statusToSet === "open"
              ? AsteriskIcon
              : FolderCheckIcon
        }
        label={`Set status: ${capitalize(statusToSet)}`}
        theme={
          snoozeDuration
            ? PillTheme.NEUTRAL_YELLOW
            : statusToSet === "open"
              ? PillTheme.PRIMARY_LIGHT
              : PillTheme.NORMAL
        }
        xClicked={xClicked}
      />
    );
  },
);

export const EmailSubjectMacroAutomationPill = memo(
  function EmailSubjectMacroAutomationPill({
    emailSubject,
    xClicked,
  }: {
    emailSubject: string;
    xClicked?(): void;
  }) {
    return (
      <MacroAutomationPill
        Icon={Edit04Icon}
        label={`Set email subject: ${emailSubject}`}
        theme={PillTheme.NEUTRAL_BLUE}
        xClicked={xClicked}
      />
    );
  },
);

export const MacroAutomationPill = memo(function MacroAutomationPill({
  theme,
  label,
  Icon,
  xClicked,
}: {
  theme: PillTheme;
  label: string;
  Icon: JSXElementConstructor<unknown>;
  xClicked?(): void;
}) {
  return (
    <Pill preventCapitalize size={PillSize.NEAR_SQUARE} theme={theme}>
      <div className={macroAutomationPillCss.automationPillContent}>
        <Icon />
        {label}
        {xClicked && (
          <IconButton
            className={macroAutomationPillCss.editButton}
            onClick={xClicked}
          >
            <XIcon />
          </IconButton>
        )}
      </div>
    </Pill>
  );
});
