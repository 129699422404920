import * as amplitude from "@amplitude/analytics-browser";
import { ClickAwayListener } from "@mui/material";
import { useHandler } from "@redotech/react-util/hook";
import {
  getCustomerTitle,
  removeSimpleHTMLTags,
} from "@redotech/redo-merchant-app/support/utils";
import {
  ConversationPlatform,
  ExpandedConversation,
} from "@redotech/redo-model/conversation";
import {
  SortableConversationTableColumn,
  SortDirection,
  TableSort,
} from "@redotech/redo-model/table";
import { FilterOptions, View } from "@redotech/redo-model/view";
import { ButtonSize } from "@redotech/redo-web/button";
import { ButtonDropdown } from "@redotech/redo-web/button-dropdown";
import { CardClickHandler } from "@redotech/redo-web/card-list";
import { Checkbox } from "@redotech/redo-web/checkbox";
import ThreeDotsHorizontalIcon from "@redotech/redo-web/icon-old/three-dots-horizontal.svg";
import { PillSize } from "@redotech/redo-web/pill";
import { Column, ColumnAlignment, Table } from "@redotech/redo-web/table";
import * as classNames from "classnames";
import { memo, useState } from "react";
import { ActiveUsers } from "./active-users";
import { ConversationFetcher } from "./conversation-fetcher";
import { ConversationPlatformPill } from "./conversation-platform-pill";
import { ConversationTagPill } from "./conversation-tags/conversation-tag-pill";
import * as conversationsTableCss from "./conversations-table.module.css";

export interface ConversationsTableSelectionStateUtil {
  selectedConversations: ExpandedConversation[];
  setSelectedConversations: (conversations: ExpandedConversation[]) => void;
  deselectedConversations: ExpandedConversation[];
  setDeselectedConversations: (conversations: ExpandedConversation[]) => void;
  selectAllMode: boolean;
  setSelectAllMode: (selectAllMode: boolean) => void;
  selectAllCount: number;
}

export interface ConversationTableDropdownAction {
  show: boolean;
  component: JSX.Element;
}

export type ConversationTableDropdownActions = (
  conversation: ExpandedConversation,
  setDropdownOpen: (open: boolean) => void,
) => ConversationTableDropdownAction[];

export const ConversationsTable = memo(function ConversationsTable({
  view,
  handleCardClick,
  fetcher,
  filters,
  tableRef,
  selectionStateUtil,
  dropdownActions,
  onConversationsViewed,
  scrollAreaRef,
  showCustomerColumn = true,
  showMenuColumn = true,
}: {
  view?: View | undefined; // details for custom views
  handleCardClick?:
    | ((conversation: ExpandedConversation | undefined) => void)
    | undefined;
  fetcher: ConversationFetcher;
  filters: FilterOptions;
  tableRef: React.MutableRefObject<any>;
  selectionStateUtil?: ConversationsTableSelectionStateUtil | undefined;
  dropdownActions?: ConversationTableDropdownActions | undefined;
  onConversationsViewed: (
    conversation: ExpandedConversation,
  ) => Promise<ExpandedConversation | undefined>;
  scrollAreaRef: React.MutableRefObject<any>;
  showCustomerColumn?: boolean | undefined;
  showMenuColumn?: boolean | undefined;
}) {
  const sortDefault: TableSort<SortableConversationTableColumn> = view?.filters
    .sort || {
    direction: SortDirection.DESC,
    key: "lastResponse",
  };

  const onSelectAllCheckboxChange = (checked: boolean) => {
    if (!selectionStateUtil) {
      return;
    }

    if (checked) {
      amplitude.logEvent("selectAll-conversation", { view });
      selectionStateUtil.setSelectAllMode(true);
      selectionStateUtil.setDeselectedConversations([]);
      selectionStateUtil.setSelectedConversations([]);
    } else {
      amplitude.logEvent("deselectAll-conversation", { view });
      if (selectionStateUtil.deselectedConversations.length === 0) {
        selectionStateUtil.setSelectAllMode(false);
        selectionStateUtil.setSelectedConversations([]);
        selectionStateUtil.setDeselectedConversations([]);
      }
    }
  };

  const onRowClick = useHandler<CardClickHandler<ExpandedConversation>>(
    async (record) => {
      const newRecord = await onConversationsViewed(record);
      handleCardClick?.(newRecord);
    },
  );

  // conversation ID
  const [currentConversationHovered, setCurrentConversationHovered] = useState<
    string | undefined
  >();

  const onRowHovered = (conversation?: ExpandedConversation) => {
    setCurrentConversationHovered(conversation?._id);
  };

  const isIndeterminate = () => {
    if (!selectionStateUtil) {
      return false;
    }

    return (
      (selectionStateUtil.selectedConversations?.length > 0 &&
        selectionStateUtil.selectedConversations?.length <
          selectionStateUtil.selectAllCount) ||
      (selectionStateUtil.selectAllMode &&
        selectionStateUtil.deselectedConversations.length > 0)
    );
  };

  const selectionColumn: Column<ExpandedConversation> | undefined =
    selectionStateUtil
      ? {
          alignment: ColumnAlignment.LEFT,
          key: "select",
          title:
            selectionStateUtil.selectAllCount !== 0 ? (
              <div className={conversationsTableCss.headerCheckboxContainer}>
                <Checkbox
                  clickStopPropagation
                  indeterminate={isIndeterminate()}
                  onChange={onSelectAllCheckboxChange}
                  value={
                    (selectionStateUtil.selectAllMode &&
                      selectionStateUtil.deselectedConversations.length ===
                        0) ||
                    selectionStateUtil.selectedConversations?.length ===
                      selectionStateUtil.selectAllCount
                  }
                />
              </div>
            ) : (
              ""
            ),
          Cell: (conversation) => {
            const onCheckboxChange = (checked: boolean) => {
              if (selectionStateUtil.selectAllMode) {
                if (checked) {
                  selectionStateUtil.setDeselectedConversations(
                    selectionStateUtil.deselectedConversations.filter(
                      (deselected) => deselected._id !== conversation._id,
                    ),
                  );
                } else {
                  selectionStateUtil.setDeselectedConversations([
                    ...selectionStateUtil.deselectedConversations,
                    conversation,
                  ]);
                }
              } else if (checked) {
                selectionStateUtil.setSelectedConversations([
                  ...selectionStateUtil.selectedConversations,
                  conversation,
                ]);
              } else {
                selectionStateUtil.setSelectedConversations(
                  selectionStateUtil.selectedConversations.filter(
                    (selected) => selected._id !== conversation._id,
                  ),
                );
              }
            };

            const shouldShowCheckbox = () => {
              if (
                selectionStateUtil &&
                (currentConversationHovered === conversation._id ||
                  selectionStateUtil.selectedConversations?.length > 0 ||
                  selectionStateUtil.selectAllMode)
              ) {
                return true;
              }
              return false;
            };

            return (
              <div>
                {shouldShowCheckbox() ? (
                  <div
                    className={conversationsTableCss.checkboxContainer}
                    onClick={() => {
                      const checkbox = document.getElementById(
                        `${conversation._id}-checkbox`,
                      );
                      onCheckboxChange(
                        (checkbox as HTMLInputElement)?.checked || false,
                      );
                    }}
                  >
                    <Checkbox
                      clickStopPropagation
                      id={`${conversation._id}-checkbox`}
                      onChange={onCheckboxChange}
                      value={
                        selectionStateUtil.selectAllMode
                          ? !selectionStateUtil.deselectedConversations.some(
                              (deselectedConversation) =>
                                deselectedConversation._id === conversation._id,
                            )
                          : selectionStateUtil.selectedConversations.some(
                              (selectedConversation) =>
                                selectedConversation._id === conversation._id,
                            )
                      }
                    />
                  </div>
                ) : conversation.read ? null : (
                  <div className={conversationsTableCss.unread} />
                )}
              </div>
            );
          },
          onClick: (event, conversation) => {
            event.stopPropagation();
            const checkbox = document.getElementById(
              `${conversation._id}-checkbox`,
            );
            checkbox?.click();
          },
          width: 32,
        }
      : {
          alignment: ColumnAlignment.LEFT,
          key: "viewed",
          title: "",
          Cell: (conversation) => {
            return conversation.read ? null : (
              <div className={conversationsTableCss.unread} />
            );
          },
          width: 32,
        };

  const customerColumn: Column<ExpandedConversation> | undefined =
    showCustomerColumn
      ? {
          alignment: ColumnAlignment.LEFT,
          key: "customer",
          title: "Customer",
          Cell: (conversation) => {
            return (
              <div
                className={classNames(
                  conversationsTableCss.strong,
                  conversationsTableCss.oneLine,
                )}
              >
                {getCustomerTitle(conversation)}
              </div>
            );
          },
          width: 150,
          sort: SortDirection.ASC,
        }
      : undefined;

  const menuColumn: Column<ExpandedConversation> | undefined = showMenuColumn
    ? {
        alignment: ColumnAlignment.LEFT,
        key: "menu",
        title: "",
        Cell: (conversation) => {
          const [dropdownOpen, setDropdownOpen] = useState(false);
          const ticketActionsDropdown = (
            <>
              <div className={conversationsTableCss.dropdownTitle}>Actions</div>
              {dropdownActions
                ? dropdownActions(conversation, setDropdownOpen)
                    .filter((dropdownAction) => dropdownAction.show)
                    .map((dropdownAction) => dropdownAction.component)
                : null}
            </>
          );

          return (
            <ClickAwayListener onClickAway={() => setDropdownOpen(false)}>
              <div className={conversationsTableCss.menuContainer}>
                {/* Because we need to use stopPropagation to not move to the next page when menu is clicked we need to manually hide the menu */}
                <ButtonDropdown
                  clickStopPropagation
                  dropdown={ticketActionsDropdown}
                  open={dropdownOpen}
                  restrictDropdownSizeToParent={false}
                  setOpen={setDropdownOpen}
                  size={ButtonSize.MICRO}
                >
                  <ThreeDotsHorizontalIcon />
                </ButtonDropdown>
              </div>
            </ClickAwayListener>
          );
        },
        width: 60,
      }
    : undefined;

  const fullColumns: Column<ExpandedConversation>[] = [
    ...(selectionColumn ? [selectionColumn] : []),
    ...(customerColumn ? [customerColumn] : []),
    {
      alignment: ColumnAlignment.LEFT,
      key: "active-users",
      title: "",
      Cell: (conversation) => {
        return <ActiveUsers conversation={conversation} maxAvatars={2} />;
      },
      width: 75,
    },
    {
      alignment: ColumnAlignment.LEFT,
      key: "summary",
      title: "Summary",
      Cell: (conversation) => {
        const latestMessageContent =
          conversation.messages?.length > 0
            ? conversation.messages[conversation.messages.length - 1].content
            : "No Content";
        const contentLines =
          removeSimpleHTMLTags(latestMessageContent).split("\n");
        return (
          <div className={conversationsTableCss.summaryCell}>
            {conversation.platform === ConversationPlatform.EMAIL && (
              <div className={conversationsTableCss.oneLine}>
                <span className={conversationsTableCss.strong}>Subject:</span>{" "}
                {conversation.subject}
              </div>
            )}
            <div
              className={classNames(conversationsTableCss.gray, {
                [conversationsTableCss.oneLine]:
                  conversation.platform === ConversationPlatform.EMAIL,
                [conversationsTableCss.twoLines]:
                  conversation.platform !== ConversationPlatform.EMAIL,
              })}
            >
              {[...contentLines].join(" ")}
            </div>
          </div>
        );
      },
      width: 300,
      sort: SortDirection.ASC,
    },
    {
      alignment: ColumnAlignment.LEFT,
      key: "assignee",
      title: "Assignee",
      Cell: (conversation) => (
        <div className={conversationsTableCss.oneLine}>
          {conversation.assignee?.name || "-"}
        </div>
      ),
      width: 125,
      sort: SortDirection.ASC,
    },
    {
      alignment: ColumnAlignment.LEFT,
      key: "platform",
      title: "Platform",
      Cell: (conversation) => (
        <ConversationPlatformPill
          platform={conversation.platform}
          size={PillSize.NEAR_SQUARE}
        />
      ),
      width: 125,
      sort: SortDirection.ASC,
    },
    {
      alignment: ColumnAlignment.LEFT,
      key: "lastResponse",
      title: "Last response",
      Cell: (conversation) => {
        if (conversation.lastCustomerResponseAt) {
          return new Date(conversation.lastCustomerResponseAt).toLocaleString(
            [],
            {
              month: "short",
              day: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
            },
          );
        }
        return undefined;
      },
      width: 125,
      sort: SortDirection.DESC,
    },
    {
      alignment: ColumnAlignment.LEFT,
      key: "tags",
      title: "Tags",
      Cell: (conversation) => {
        return (
          <div className={conversationsTableCss.tagContainer}>
            {conversation.tagIds?.map((tag) => (
              <ConversationTagPill key={tag.name} showOverflow tag={tag} />
            ))}
          </div>
        );
      },
      width: 175,
    },
    ...(menuColumn ? [menuColumn] : []),
  ];

  return (
    <Table
      columns={fullColumns}
      fetcher={fetcher}
      onRowClick={onRowClick}
      onRowHovered={onRowHovered}
      passThroughValues={filters}
      ref={tableRef}
      scrollAreaRef={scrollAreaRef}
      sortDefault={sortDefault}
    />
  );
});
