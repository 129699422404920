import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import ButtonGroup from "@mui/material/ButtonGroup";
import { genericMemo } from "@redotech/react-util/component";
import { useHandler } from "@redotech/react-util/hook";
import { MouseEvent, useState } from "react";
import { Button, ButtonSize, ButtonTheme } from "./button";
import * as buttonSelectCss from "./button-select.module.css";
import { Dropdown, DropdownOption } from "./dropdown";
import ChevronDownIcon from "./icon-old/chevron-down.svg";

export const ButtonSelect = genericMemo(function ButtonSelect({
  options,
  theme = ButtonTheme.GHOST,
  size = ButtonSize.MEDIUM,
  onClick,
  onValueChange,
  actionDisabled,
  staticValue,
  dropdownDisplay,
}: {
  options: string[];
  theme?: ButtonTheme;
  size?: ButtonSize;
  onClick?: (event: React.ChangeEvent<{ value?: unknown }>) => void;
  onValueChange?: (newValue: string) => void;
  actionDisabled?: boolean;
  staticValue?: string;
  dropdownDisplay?: (option: string) => React.ReactNode;
}) {
  const [open, setOpen] = useState(false);
  const [buttonGroup, setButtonGroup] = useState<HTMLElement | null>(null);
  const [value, setValue] = useState<string>(staticValue || options[0]);

  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    Object.defineProperty(e, "target", { value: { value } });
    if (onClick) {
      onClick(e);
    }
  };

  const onClickAway = useHandler(() => {
    setOpen(false);
  });

  return (
    <>
      <ClickAwayListener onClickAway={onClickAway}>
        <ButtonGroup
          className={buttonSelectCss.buttonGroup}
          ref={setButtonGroup}
        >
          <Button
            className={buttonSelectCss.firstButton}
            disabled={!!actionDisabled}
            onClick={handleButtonClick}
            size={size}
            theme={theme}
          >
            {value}
          </Button>
          <Button
            className={buttonSelectCss.lastButton}
            onClick={() => setOpen(!open)}
            size={size}
            theme={theme}
          >
            <div className={buttonSelectCss.buttonContent}>
              <ChevronDownIcon />
            </div>
          </Button>
        </ButtonGroup>
      </ClickAwayListener>
      <Dropdown anchor={buttonGroup} open={open}>
        <>
          {options.map((option) => {
            return (
              <DropdownOption
                action={() => {
                  if (!staticValue) {
                    setValue(option);
                  }
                  onValueChange && onValueChange(option);
                }}
                key={option}
              >
                {dropdownDisplay ? dropdownDisplay(option) : option}
              </DropdownOption>
            );
          })}
        </>
      </Dropdown>
    </>
  );
});
