// extracted by mini-css-extract-plugin
export var chip = "_26-a";
export var chipLabel = "_26-b";
export var close = "_26-8";
export var closeIcon = "_26-9";
export var container = "_26-3";
export var error = "_26-7";
export var infoTooltip = "_26-2";
export var innerContainer = "_26-1";
export var input = "_26-c";
export var medium = "_26-6";
export var outerContainer = "_26-0";
export var small = "_26-5";
export var xSmall = "_26-4";