import { RouteObject } from "react-router-dom";

export const reviewsSettingsRoutes: RouteObject = {
  handle: { breadcrumb: "Reviews", breadcrumbNavigate: false },
  path: "reviews",
  children: [
    {
      handle: { breadcrumb: "General" },
      path: "reviews-general",
      lazy: async () => {
        const { General } = await import("./general");
        return { Component: General };
      },
    },
    {
      handle: { breadcrumb: "Response" },
      path: "reviews-response",
      lazy: async () => {
        const { Response } = await import("./response/response");
        return { Component: Response };
      },
    },
    {
      handle: { breadcrumb: "Displays & Widgets" },
      path: "reviews-displays-widgets",
      lazy: async () => {
        const { DisplaysWidgets } = await import("./displays-widgets");
        return { Component: DisplaysWidgets };
      },
    },
    {
      handle: { breadcrumb: "Emails" },
      path: "reviews-emails",
      lazy: async () => {
        const { Emails } = await import("./emails");
        return { Component: Emails };
      },
    },
  ],
};
