// extracted by mini-css-extract-plugin
export var danger = "_2d-6";
export var lightPink = "_2d-9";
export var medium = "_2d-i";
export var nearSquare = "_2d-l";
export var neutralBlue = "_2d-8";
export var neutralYellow = "_2d-7";
export var noCapitalize = "_2d-1";
export var normal = "_2d-2";
export var pill = "_2d-0";
export var primary = "_2d-3";
export var primaryLight = "_2d-4";
export var small = "_2d-j";
export var solidBlack = "_2d-h";
export var solidGreen = "_2d-c";
export var solidLightBlue = "_2d-e";
export var solidNavy = "_2d-d";
export var solidOrange = "_2d-g";
export var solidPink = "_2d-f";
export var solidRed = "_2d-a";
export var solidYellow = "_2d-b";
export var success = "_2d-5";
export var xsmall = "_2d-k";