import {
  GetAllCollectionsQuery,
  GetCollectionQuery,
  GetCollectionQueryVariables,
  GetProductQuery,
  GetProductQueryVariables,
  GetProductWithMetafieldQuery,
  GetProductWithMetafieldQueryVariables,
  GetProductWithMetaobjectQuery,
  GetProductWithMetaobjectQueryVariables,
  GetProductsQuery,
  GetProductsQueryVariables,
  SearchProductsQuery,
  SearchProductsQueryVariables,
} from "./shopify-storefront.graphql";
import { ShopifyStorefrontClient } from "./storefront-client";

export const PRODUCT_INFO_FRAGMENT = /* GraphQL */ `
  fragment ProductInfo on Product {
    id
    title
    handle
    vendor
    onlineStoreUrl
    totalInventory
    description
    productType
    collections(first: 100) {
      nodes {
        id
        title
      }
    }
    availableForSale
    description
    productType
    collections(first: 100) {
      nodes {
        id
        title
      }
    }
    tags
    publishedAt
    options {
      id
      name
      values
    }
    priceRange {
      minVariantPrice {
        amount
        currencyCode
      }
      maxVariantPrice {
        amount
        currencyCode
      }
    }
    featuredImage {
      altText
      url
    }
    variants(first: 100) {
      nodes {
        id
        requiresShipping
        currentlyNotInStock
        availableForSale
        price {
          amount
          currencyCode
        }
        id
        sku
        title
        selectedOptions {
          name
          value
        }
        quantityAvailable
        image {
          url
        }
        title
      }
    }
    images(first: 10) {
      nodes {
        altText
        id
        url
      }
    }
  }
`;

const getProductsQuery = /* GraphQL */ `
  ${PRODUCT_INFO_FRAGMENT}
  query getProducts(
    $after: String
    $first: Int
    $query: String
    $sortKey: ProductSortKeys = BEST_SELLING
  ) {
    products(after: $after, first: $first, query: $query, sortKey: $sortKey) {
      nodes {
        ...ProductInfo
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

const getProductQuery = /* GraphQL */ `
  ${PRODUCT_INFO_FRAGMENT}
  query getProduct($id: ID!) {
    product(id: $id) {
      ...ProductInfo
    }
  }
`;

const getProductWithMetafieldQuery = /* GraphQL */ `
  ${PRODUCT_INFO_FRAGMENT}
  query getProductWithMetafield($id: ID!, $key: String!, $namespace: String) {
    product(id: $id) {
      ...ProductInfo
      chosenMetafield: metafield(key: $key, namespace: $namespace) {
        value
        type
      }
    }
  }
`;

const getProductWithMetaobjectQuery = /* GraphQL */ `
  ${PRODUCT_INFO_FRAGMENT}
  query getProductWithMetaobject(
    $id: ID!
    $key: String!
    $namespace: String
    $metaobjectKey: String!
  ) {
    product(id: $id) {
      ...ProductInfo
      chosenMetafield: metafield(key: $key, namespace: $namespace) {
        value
        type
        reference {
          ... on Metaobject {
            field(key: $metaobjectKey) {
              value
              type
            }
          }
        }
      }
    }
  }
`;

export type ProductInfo = GetProductsQuery["products"]["nodes"][number];
export type ProductInfoWithMetafield = GetProductWithMetafieldQuery["product"];
export type ProductInfoWithMetaobject =
  GetProductWithMetaobjectQuery["product"];

export async function* getProducts(
  client: ShopifyStorefrontClient,
  query: Omit<GetProductsQueryVariables, "after">,
): AsyncIterable<ProductInfo> {
  let after: string | undefined = undefined;
  while (true) {
    const { data, errors } = await client.request<
      GetProductsQuery,
      GetProductsQueryVariables
    >(getProductsQuery, { ...query, after, first: query.first ?? 10 });

    if (errors) {
      throw errors;
    }

    if (!data) {
      return;
    }
    // if a store doesn't have products should we return an error?
    if (!data.products) {
      break;
    }
    for (const product of data.products.nodes) {
      yield product;
    }
    if (!data.products.pageInfo.hasNextPage) {
      break;
    }

    after = (data.products.pageInfo.endCursor ?? undefined) as
      | string
      | undefined;
  }
}

export async function getProduct(
  client: ShopifyStorefrontClient,
  id: string,
): Promise<ProductInfo | null> {
  const { data, errors } = await client.request<
    GetProductQuery,
    GetProductQueryVariables
  >(getProductQuery, {
    id: id.includes("gid://") ? id : `gid://shopify/Product/${id}`,
  });

  if (errors) {
    throw errors;
  }

  return data?.product ?? null;
}

export async function getProductWithMetafield(
  client: ShopifyStorefrontClient,
  id: string,
  metafieldKey: string,
  metafieldNamespace?: string,
  metaobjectKey?: string,
): Promise<ProductInfoWithMetafield | ProductInfoWithMetaobject | null> {
  const payload = {
    id: id.includes("gid://") ? id : `gid://shopify/Product/${id}`,
    key: metafieldKey,
    namespace: metafieldNamespace,
  };

  const { data, errors } = await (metaobjectKey
    ? client.request<
        GetProductWithMetaobjectQuery,
        GetProductWithMetaobjectQueryVariables
      >(getProductWithMetaobjectQuery, {
        ...payload,
        metaobjectKey: metaobjectKey,
      })
    : client.request<
        GetProductWithMetafieldQuery,
        GetProductWithMetafieldQueryVariables
      >(getProductWithMetafieldQuery, payload));

  if (errors) {
    throw errors;
  }

  return data?.product ?? null;
}

export async function getRemainingInventory(
  client: ShopifyStorefrontClient,
  id: string,
): Promise<number | null> {
  const { data, errors } = await client.request<
    GetProductQuery,
    GetProductQueryVariables
  >(getProductQuery, {
    id: id.includes("gid://") ? id : `gid://shopify/Product/${id}`,
  });

  if (errors) {
    throw errors;
  }

  const product = data?.product;
  if (!product) {
    return null;
  }

  let remainingInventory = 0;
  for (const variant of product.variants.nodes) {
    remainingInventory += variant.quantityAvailable ?? 0;
  }

  return remainingInventory;
}

const getAllCollectionsQuery = /* GraphQL */ `
  ${PRODUCT_INFO_FRAGMENT}
  query getAllCollections {
    collections(first: 100) {
      nodes {
        id
        products(first: 100) {
          nodes {
            ...ProductInfo
          }
        }
      }
    }
  }
`;

export async function getAllCollections(
  client: ShopifyStorefrontClient,
): Promise<CollectionProduct[]> {
  const { data, errors } = await client.request<GetAllCollectionsQuery, {}>(
    getAllCollectionsQuery,
  );

  if (errors) {
    throw errors;
  }

  return data?.collections?.nodes ?? [];
}

const getCollectionQuery = /* GraphQL */ `
  ${PRODUCT_INFO_FRAGMENT}
  query getCollection($id: ID!) {
    collection(id: $id) {
      id
      products(first: 100) {
        nodes {
          ...ProductInfo
        }
      }
    }
  }
`;

export type CollectionProduct = GetCollectionQuery["collection"] | null;
export type CollectionProductInfo = NonNullable<
  GetCollectionQuery["collection"]
>["products"]["nodes"][number];
export type CollectionProductOptions = NonNullable<
  GetCollectionQuery["collection"]
>["products"]["nodes"][number]["options"];

export async function getCollection(
  client: ShopifyStorefrontClient,
  id: string,
): Promise<CollectionProduct | null> {
  const { data, errors } = await client.request<
    GetCollectionQuery,
    GetCollectionQueryVariables
  >(getCollectionQuery, {
    id: id.includes("gid://") ? id : `gid://shopify/Collection/${id}`,
  });

  if (errors) {
    throw errors;
  }

  return data?.collection ?? null;
}

const searchProductQuery = /* GraphQL */ `
  ${PRODUCT_INFO_FRAGMENT}
  query searchProducts($query: String!, $first: Int) {
    search(query: $query, first: $first, types: PRODUCT) {
      edges {
        node {
          ...ProductInfo
        }
      }
    }
  }
`;

export async function searchProducts(
  client: ShopifyStorefrontClient,
  query: string,
  numResults: number = 100,
): Promise<ProductInfo[]> {
  const { data, errors } = await client.request<
    SearchProductsQuery,
    SearchProductsQueryVariables
  >(searchProductQuery, { query, first: numResults });

  if (errors) {
    throw errors;
  }

  return data?.search.edges.map((edge) => edge.node) ?? [];
}
