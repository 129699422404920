import { Big } from "big.js";
import { z } from "zod";

export const zExt = {
  big: (params?: z.RawCreateParams) =>
    z
      .custom<Big>(
        (data) =>
          data instanceof Big ||
          typeof data === "string" ||
          typeof data === "number",
        params,
      )
      .transform<Big>(Big),
  instant: (params?: z.RawCreateParams) =>
    z.custom<Temporal.Instant>(
      (data) => data instanceof Temporal.Instant,
      params,
    ),
  objectId: (params?: z.RawCreateParams) =>
    z
      .custom<string>(
        (id) =>
          typeof id === "string" ||
          (typeof id === "object" && id && "toString" in id),
        params,
      )
      .transform<string>((id) =>
        typeof id === "string" ? id : (id as any).toString(),
      ),
};
