import * as amplitude from "@amplitude/analytics-browser";
import { ClickAwayListener } from "@mui/material";
import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useLoad } from "@redotech/react-util/load";
import {
  ConversationPlatform,
  ConversationTagId,
} from "@redotech/redo-model/conversation";
import {
  defaultConversationsTableSort,
  sortableConversationsTableColumns,
  sortableConversationsTableColumnTitlesByKey,
  SortableConversationTableColumn,
  SortDirection,
} from "@redotech/redo-model/table";
import { GetUser, Permission, permitted } from "@redotech/redo-model/user";
import { FilterOptions, FiltersStatus, View } from "@redotech/redo-model/view";
import {
  CheckboxValue,
  RedoCheckbox,
  RedoCheckboxSize,
} from "@redotech/redo-web/arbiter-components/checkbox/redo-checkbox";
import { RedoCheckboxGroup } from "@redotech/redo-web/arbiter-components/checkbox/redo-checkbox-group";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { ChipDelimiter, FormChipInput } from "@redotech/redo-web/chip-input";
import { DateRangeInput } from "@redotech/redo-web/date-range";
import { Flex } from "@redotech/redo-web/flex";
import PlusIcon from "@redotech/redo-web/icon-old/plus.svg";
import { Modal, ModalSize, PaddingAmount } from "@redotech/redo-web/modal";
import {
  FormMultiSelectDropdown,
  FormSelectDropdown,
} from "@redotech/redo-web/select-dropdown";
import { TextInput } from "@redotech/redo-web/text-input";
import { groupInput, input, InputProvider } from "@redotech/ui/form";
import { arrayEqual } from "@redotech/util/equal";
import { memo, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConversationTagsContext } from "../app/conversation-tags-context";
import { TeamContext } from "../app/team";
import { UserContext } from "../app/user";
import { RedoMerchantClientContext } from "../client/context";
import { getCustomerTags } from "../client/shopify";
import { ConversationTagInput } from "./conversation-tags/conversation-tag-input";
import * as filterModalCss from "./filter-modal.module.css";
import { TagPicker } from "./tags-dropdown";

const FORBIDDEN_VIEW_NAMES = [
  "assigned",
  "assigned to me",
  "unassigned",
  "all",
  "spam",
];

const filtersForm = groupInput({
  channels: groupInput({
    // input names must match the ConversationPlatform enum values
    email: input<boolean>(),
    redoChat: input<boolean>(),
    instagram: input<boolean>(),
    instagramComments: input<boolean>(),
    facebook: input<boolean>(),
    facebookComments: input<boolean>(),
    attentive: input<boolean>(),
  }),
  privacy: input<boolean>(),
  assignees: input<(string | null)[]>(),
  sort: groupInput({
    key: input<SortableConversationTableColumn>(),
    direction: input<SortDirection>(),
  }),
  read: input<boolean | null>(),
  createdDateStart: input<Date | null>(),
  createdDateEnd: input<Date | null>(),
  closedDateStart: input<Date | null>(),
  closedDateEnd: input<Date | null>(),
  lastResponseAtStart: input<Date | null>(),
  lastResponseAtEnd: input<Date | null>(),
  words: input<string[]>(),
  conversationTags: input<ConversationTagId[]>(),
  customerTags: input<string[]>(),
});

type FiltersFormValue = InputProvider.Value<typeof filtersForm>;

const filterOptionsToInputValues = (
  filters: FilterOptions,
  conversationTags: ConversationTagId[],
  view?: View,
): FiltersFormValue => {
  const teamTagNames = new Set(filters.conversationTags || []);
  const filterTags = conversationTags.filter((tag) =>
    teamTagNames.has(tag.name),
  );

  const formDefaults: FiltersFormValue = {
    channels: {
      email: filters.channels?.includes(ConversationPlatform.EMAIL) || false,
      redoChat:
        filters.channels?.includes(ConversationPlatform.REDO_CHAT) || false,
      instagram:
        filters.channels?.includes(ConversationPlatform.INSTAGRAM) || false,
      instagramComments:
        filters.channels?.includes(ConversationPlatform.INSTAGRAM_COMMENTS) ||
        false,
      facebook:
        filters.channels?.includes(ConversationPlatform.FACEBOOK) || false,
      facebookComments:
        filters.channels?.includes(ConversationPlatform.FACEBOOK_COMMENTS) ||
        false,
      attentive:
        filters.channels?.includes(ConversationPlatform.ATTENTIVE) || false,
    },
    privacy: !!view?.user,
    assignees:
      filters.assignees && filters.assignees.length > 0
        ? filters.assignees.map((id) => (id === "null" ? null : id))
        : [],
    read: filters.read === false ? false : filters.read || null,
    sort: filters.sort || defaultConversationsTableSort,
    createdDateStart: filters.createdDateStart
      ? new Date(filters.createdDateStart)
      : null,
    createdDateEnd: filters.createdDateEnd
      ? new Date(filters.createdDateEnd)
      : null,
    closedDateStart: filters.closedDateStart
      ? new Date(filters.closedDateStart)
      : null,
    closedDateEnd: filters.closedDateEnd
      ? new Date(filters.closedDateEnd)
      : null,
    lastResponseAtStart: filters.lastResponseAtStart
      ? new Date(filters.lastResponseAtStart)
      : null,
    lastResponseAtEnd: filters.lastResponseAtEnd
      ? new Date(filters.lastResponseAtEnd)
      : null,
    words: filters.words || [],
    conversationTags: filterTags,
    customerTags: filters.customerTags || [],
  };

  const atLeastOneChannelSelected = Object.values(formDefaults.channels).some(
    (value) => {
      return value;
    },
  );
  if (!atLeastOneChannelSelected) {
    Object.keys(formDefaults.channels).forEach((key) => {
      (formDefaults.channels as Record<string, any>)[key] = true;
    });
  }

  return formDefaults;
};

export const FilterModal = memo(function FilterModal({
  open,
  setOpen,
  current,
  defaults,
  applyFilters,
  createView,
  updateView,
  editingView,
  allowFiltering = true,
}: {
  open: boolean;
  setOpen(val: boolean): void;
  current: FilterOptions;
  defaults: FilterOptions;
  applyFilters(filters: FilterOptions): void;
  createView(
    filters: FilterOptions,
    name: string,
    isPrivate: boolean,
  ): Promise<void>;
  updateView?: (
    id: string,
    filters: FilterOptions,
    name: string,
    isPrivate: boolean,
  ) => Promise<void>;
  allowFiltering?: boolean;
  editingView?: View;
}) {
  const [showCustomerTagOptions, setShowCustomerTagOptions] = useState(false);
  const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);
  const [viewName, setViewName] = useState<string>(editingView?.name || "");
  const client = useRequiredContext(RedoMerchantClientContext);
  const availableCustomerTagsLoad = useLoad(
    (signal) => getCustomerTags(client, { signal }),
    [client],
  );
  const team = useRequiredContext(TeamContext);
  const user = useContext(UserContext);
  const handleCancel = () => {
    setOpen(false);
  };
  const teamConversationTags = useContext(ConversationTagsContext);
  const navigate = useNavigate();
  const defaultValues = filterOptionsToInputValues(
    defaults,
    teamConversationTags,
    editingView,
  );
  const currentValues = filterOptionsToInputValues(
    current,
    teamConversationTags,
    editingView,
  );
  const canCreateView =
    !!user && permitted(user.permissions, Permission.CREATE_TEAM_VIEW);
  const input = useInput(filtersForm, currentValues);
  const {
    channels,
    privacy,
    assignees,
    read,
    sort,
    createdDateStart,
    createdDateEnd,
    closedDateStart,
    closedDateEnd,
    lastResponseAtStart,
    lastResponseAtEnd,
    words,
    conversationTags,
    customerTags,
  } = input.inputs;

  const multiValueChange = (
    onStartChange: (value: Date | null) => void,
    onEndChange: (value: Date | null) => void,
  ) => {
    // Cannot use useHandler inside a function that is not a React component
    function handler(v: [Date | null, Date | null] | null) {
      if (v && Array.isArray(v)) {
        const [newStart, newEnd] = v;

        onStartChange(newStart);
        onEndChange(newEnd);
      } else {
        onStartChange(null);
        onEndChange(null);
      }
    }
    return handler;
  };

  const isModified = () => {
    return (
      channels.inputs.email.value !== defaultValues.channels.email ||
      channels.inputs.redoChat.value !== defaultValues.channels.redoChat ||
      channels.inputs.instagram.value !== defaultValues.channels.instagram ||
      channels.inputs.instagramComments.value !==
        defaultValues.channels.instagramComments ||
      channels.inputs.facebook.value !== defaultValues.channels.facebook ||
      channels.inputs.facebookComments.value !==
        defaultValues.channels.facebookComments ||
      channels.inputs.attentive.value !== defaultValues.channels.attentive ||
      !arrayEqual((a, b) => a === b)(
        assignees.value,
        defaultValues.assignees,
      ) ||
      read.value !== defaultValues.read ||
      sort.value.key !== defaultValues.sort.key ||
      sort.value.direction !== defaultValues.sort.direction ||
      createdDateStart.value !== defaultValues.createdDateStart ||
      createdDateEnd.value !== defaultValues.createdDateEnd ||
      closedDateStart.value !== defaultValues.closedDateStart ||
      closedDateEnd.value !== defaultValues.closedDateEnd ||
      lastResponseAtStart.value !== defaultValues.lastResponseAtStart ||
      lastResponseAtEnd.value !== defaultValues.lastResponseAtEnd ||
      words.value.toString() !== defaultValues.words.toString() ||
      conversationTags.value.map((tag) => tag.name).toString() !==
        defaultValues.conversationTags.map((tag) => tag.name).toString() ||
      customerTags.value.toString() !== defaultValues.customerTags.toString()
    );
  };

  const atLeastOneChannelSelected = Object.values(channels.value).some(
    (value) => value,
  );

  const convertInputsToFilterOptions: () => FilterOptions = () => {
    const filterOptions = {
      // All saved statuses
      status: [FiltersStatus.OPEN],
      channels: Object.entries(channels.value)
        .filter(([_, value]) => value)
        .map(([key, _]) => key as ConversationPlatform),
      assignees: assignees.value,
      read: read.value,
      sort: sort.value,
      createdDateStart: createdDateStart.value,
      createdDateEnd: createdDateEnd.value,
      closedDateStart: closedDateStart.value,
      closedDateEnd: closedDateEnd.value,
      lastResponseAtStart: lastResponseAtStart.value,
      lastResponseAtEnd: lastResponseAtEnd.value,
      words: words.value,
      conversationTags: conversationTags.value.map((tag) => tag.name),
      customerTags: customerTags.value,
    };
    const nonNullFilterOptions = {
      modified: isModified(),
      ...(filterOptions.status?.length && { status: filterOptions.status }),
      ...(filterOptions.channels?.length && {
        channels: filterOptions.channels,
      }),
      ...(filterOptions.assignees?.length && {
        assignees: filterOptions.assignees,
      }),
      ...((filterOptions.read === false || filterOptions.read) && {
        read: filterOptions.read,
      }),
      sort: filterOptions.sort,
      ...(filterOptions.createdDateStart && {
        createdDateStart: filterOptions.createdDateStart.toISOString(),
      }),
      ...(filterOptions.createdDateEnd && {
        createdDateEnd: filterOptions.createdDateEnd.toISOString(),
      }),
      ...(filterOptions.closedDateStart && {
        closedDateStart: filterOptions.closedDateStart.toISOString(),
      }),
      ...(filterOptions.closedDateEnd && {
        closedDateEnd: filterOptions.closedDateEnd.toISOString(),
      }),
      ...(filterOptions.lastResponseAtStart && {
        lastResponseAtStart: filterOptions.lastResponseAtStart.toISOString(),
      }),
      ...(filterOptions.lastResponseAtEnd && {
        lastResponseAtEnd: filterOptions.lastResponseAtEnd.toISOString(),
      }),
      ...(filterOptions.words?.length && { words: filterOptions.words }),
      ...(filterOptions.conversationTags?.length && {
        conversationTags: filterOptions.conversationTags,
      }),
      ...(filterOptions.customerTags?.length && {
        customerTags: filterOptions.customerTags,
      }),
    };
    return nonNullFilterOptions;
  };

  const handleSubmit = () => {
    const options = convertInputsToFilterOptions();
    applyFilters(options);
    setOpen(false);
  };

  const handleSaveView = () => {
    if (!allowFiltering) {
      // The name input and privacy checkbox is on the main filter modal
      void handleViewDetailsSave();
    } else {
      setViewDetailsModalOpen(true);
    }
  };

  const handleViewDetailsSave = async () => {
    const options = convertInputsToFilterOptions();
    const isPrivate = privacy.value;
    if (editingView && updateView) {
      await updateView(editingView._id, options, viewName, isPrivate);
    } else {
      await createView(options, viewName, isPrivate);
      amplitude.logEvent("create-view", { view: viewName });
    }
    setViewDetailsModalOpen(false);
    setOpen(false);
    navigate(`/stores/${team._id}/support/table/${viewName}`);
  };

  const clearFilters = () => {
    channels.inputs.email.setValue(defaultValues.channels.email);
    channels.inputs.redoChat.setValue(defaultValues.channels.redoChat);
    channels.inputs.instagram.setValue(defaultValues.channels.instagram);
    channels.inputs.instagramComments.setValue(
      defaultValues.channels.instagramComments,
    );
    channels.inputs.facebook.setValue(defaultValues.channels.facebook);
    channels.inputs.facebookComments.setValue(
      defaultValues.channels.facebookComments,
    );
    channels.inputs.attentive.setValue(defaultValues.channels.attentive);
    assignees.setValue(defaultValues.assignees);
    sort.inputs.key.setValue(defaultValues.sort.key);
    sort.inputs.direction.setValue(defaultValues.sort.direction);
    read.setValue(defaultValues.read);
    createdDateStart.setValue(defaultValues.createdDateStart);
    createdDateEnd.setValue(defaultValues.createdDateEnd);
    closedDateStart.setValue(defaultValues.closedDateStart);
    closedDateEnd.setValue(defaultValues.closedDateEnd);
    lastResponseAtStart.setValue(defaultValues.lastResponseAtStart);
    lastResponseAtEnd.setValue(defaultValues.lastResponseAtEnd);
    words.setValue(defaultValues.words);
    conversationTags.setValue(defaultValues.conversationTags);
    customerTags.setValue(defaultValues.customerTags);
  };

  const Footer = memo(function Footer({
    handleSubmit,
    handleSaveView,
  }: {
    handleSubmit(): void;
    handleSaveView(): void;
  }) {
    return (
      <div className={filterModalCss.footer}>
        <div className={filterModalCss.clearAllWrapper}>
          <Button onClick={clearFilters} theme={ButtonTheme.GHOST}>
            Clear all
          </Button>
        </div>
        <div className={filterModalCss.actionsWrapper}>
          {canCreateView && (
            <Button
              disabled={
                FORBIDDEN_VIEW_NAMES.includes(viewName.toLowerCase()) ||
                !atLeastOneChannelSelected
              }
              onClick={handleSaveView}
              theme={
                !allowFiltering ? ButtonTheme.PRIMARY : ButtonTheme.OUTLINED
              }
            >
              <div className={filterModalCss.buttonContent}>
                {!editingView && <PlusIcon className={filterModalCss.icon} />}
                Save {editingView ? "" : "as "}view
              </div>
            </Button>
          )}
          {allowFiltering && (
            <Button
              disabled={!atLeastOneChannelSelected}
              onClick={handleSubmit}
              theme={ButtonTheme.PRIMARY}
            >
              Show tickets
            </Button>
          )}
        </div>
      </div>
    );
  });

  useEffect(() => {
    const currentAsInput = filterOptionsToInputValues(
      current,
      teamConversationTags,
      editingView,
    );
    channels.inputs.email.setValue(currentAsInput.channels.email);
    channels.inputs.redoChat.setValue(currentAsInput.channels.redoChat);
    channels.inputs.instagram.setValue(currentAsInput.channels.instagram);
    channels.inputs.instagramComments.setValue(
      currentAsInput.channels.instagramComments,
    );
    channels.inputs.facebook.setValue(currentAsInput.channels.facebook);
    channels.inputs.facebookComments.setValue(
      currentAsInput.channels.facebookComments,
    );
    channels.inputs.attentive.setValue(currentAsInput.channels.attentive);
    assignees.setValue(currentAsInput.assignees);
    read.setValue(currentAsInput.read);
    sort.inputs.key.setValue(currentAsInput.sort.key);
    sort.inputs.direction.setValue(currentAsInput.sort.direction);
    createdDateStart.setValue(currentAsInput.createdDateStart);
    createdDateEnd.setValue(currentAsInput.createdDateEnd);
    closedDateStart.setValue(currentAsInput.closedDateStart);
    closedDateEnd.setValue(currentAsInput.closedDateEnd);
    lastResponseAtStart.setValue(currentAsInput.lastResponseAtStart);
    lastResponseAtEnd.setValue(currentAsInput.lastResponseAtEnd);
    words.setValue(currentAsInput.words);
    conversationTags.setValue(currentAsInput.conversationTags);
    customerTags.setValue(currentAsInput.customerTags);
  }, [current, teamConversationTags]);

  const assigneesOptions = [
    { id: null, name: "Unassigned" },
    ...team.users
      .map((user) => user.user as GetUser)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((user) => ({
        id: user._id,
        name: user.name,
      })),
    { id: process.env.CHATBOT_USER_ID, name: "Chatbot" },
  ];

  const [channelsCheckboxParentValue, setChannelsCheckboxParentValue] =
    useState<CheckboxValue>(false);

  return (
    <>
      <Modal
        footer={
          <Footer handleSaveView={handleSaveView} handleSubmit={handleSubmit} />
        }
        onClose={handleCancel}
        open={open}
        paddingAmount={PaddingAmount.NONE}
        showFooterBorder
        size={ModalSize.MEDIUM}
        title={editingView ? `Edit view - ${editingView?.name}` : "Filters"}
      >
        <div className={filterModalCss.modalContent}>
          {!allowFiltering && canCreateView && (
            <>
              <div className={filterModalCss.filterType}>
                <div className={filterModalCss.filterTitle}>View name</div>
                <TextInput onChange={setViewName} value={viewName} />
              </div>
              <div className={filterModalCss.filterType}>
                <div className={filterModalCss.filterTitle}>Privacy</div>

                <RedoCheckbox
                  label="Make view private"
                  setValue={privacy.setValue}
                  size={RedoCheckboxSize.SMALL}
                  value={privacy.value}
                />
              </div>
            </>
          )}
          <div className={filterModalCss.filterType}>
            <RedoCheckboxGroup
              parent={{
                value: channelsCheckboxParentValue,
                setValue: setChannelsCheckboxParentValue,
                label: "Channels",
                size: RedoCheckboxSize.SMALL,
              }}
            >
              {[
                {
                  value: channels.inputs.email.value,
                  setValue: channels.inputs.email.setValue,
                  label: "Email",
                },
                {
                  value: channels.inputs.redoChat.value,
                  setValue: channels.inputs.redoChat.setValue,
                  label: "Chat",
                },
                {
                  value: channels.inputs.instagram.value,
                  setValue: channels.inputs.instagram.setValue,
                  label: "Instagram DM",
                },
                {
                  value: channels.inputs.instagramComments.value,
                  setValue: channels.inputs.instagramComments.setValue,
                  label: "Instagram Comments",
                },
                {
                  value: channels.inputs.facebook.value,
                  setValue: channels.inputs.facebook.setValue,
                  label: "Facebook Messenger",
                },
                {
                  value: channels.inputs.facebookComments.value,
                  setValue: channels.inputs.facebookComments.setValue,
                  label: "Facebook comments",
                },
                {
                  value: channels.inputs.attentive.value,
                  setValue: channels.inputs.attentive.setValue,
                  label: "Attentive",
                },
              ]}
            </RedoCheckboxGroup>
          </div>
          <div className={filterModalCss.filterType}>
            <div className={filterModalCss.filterTitle}>Assignees</div>
            <FormMultiSelectDropdown
              display={(value) => {
                if (
                  value.length === 0 ||
                  value.length === assigneesOptions.length
                ) {
                  return "Any assignee";
                }
                const names = value.map((id) => {
                  return assigneesOptions.find((user) => user.id === id)?.name;
                });
                return names.sort().join(", ");
              }}
              input={assignees}
              label=""
              options={assigneesOptions.map((user) => user.id)}
            >
              {(option) => {
                if (option === null) return "Unassigned";
                return assigneesOptions.find((user) => user.id === option)
                  ?.name;
              }}
            </FormMultiSelectDropdown>
          </div>
          <div className={filterModalCss.filterType}>
            <div className={filterModalCss.filterTitle}>Read status</div>
            <FormSelectDropdown
              display={(value) => {
                if (value === true) {
                  return "Read";
                } else if (value === false) {
                  return "Unread";
                }
                return "Read and unread";
              }}
              input={read}
              label=""
              options={[null, true, false]}
              placeholder="Read and unread"
            >
              {(option) => {
                if (option === true) {
                  return "Read";
                } else if (option === false) {
                  return "Unread";
                }
                return "Read and unread";
              }}
            </FormSelectDropdown>
          </div>
          <div className={filterModalCss.filterType}>
            <div className={filterModalCss.filterTitle}>Sorting</div>
            <Flex dir="row" gap="3xl">
              <div className={filterModalCss.sortDropdown}>
                <FormSelectDropdown
                  display={(value) =>
                    sortableConversationsTableColumnTitlesByKey[value]
                  }
                  input={sort.inputs.key}
                  label=""
                  options={sortableConversationsTableColumns}
                >
                  {(column) =>
                    sortableConversationsTableColumnTitlesByKey[column]
                  }
                </FormSelectDropdown>
              </div>
              <div className={filterModalCss.sortDropdown}>
                <FormSelectDropdown
                  display={(value) =>
                    value === SortDirection.ASC ? "Ascending" : "Descending"
                  }
                  input={sort.inputs.direction}
                  label=""
                  options={Object.values(SortDirection)}
                >
                  {(direction) =>
                    direction === SortDirection.ASC ? "Ascending" : "Descending"
                  }
                </FormSelectDropdown>
              </div>
            </Flex>
          </div>
          <div className={filterModalCss.filterType}>
            <div className={filterModalCss.filterTitle}>Created date</div>
            <DateRangeInput
              value={[createdDateStart.value, createdDateEnd.value]}
              valueChange={multiValueChange(
                createdDateStart.setValue,
                createdDateEnd.setValue,
              )}
            />
          </div>
          <div className={filterModalCss.advancedWrapper}>
            <div className={filterModalCss.filterType}>
              <div className={filterModalCss.filterTitle}>Closed date</div>
              <DateRangeInput
                value={[closedDateStart.value, closedDateEnd.value]}
                valueChange={multiValueChange(
                  closedDateStart.setValue,
                  closedDateEnd.setValue,
                )}
              />
            </div>
            <div className={filterModalCss.filterType}>
              <div className={filterModalCss.filterTitle}>
                Last received message date
              </div>
              <DateRangeInput
                value={[lastResponseAtStart.value, lastResponseAtEnd.value]}
                valueChange={multiValueChange(
                  lastResponseAtStart.setValue,
                  lastResponseAtEnd.setValue,
                )}
              />
            </div>
            <div className={filterModalCss.filterType}>
              <div className={filterModalCss.filterTitle}>Customer tags</div>
              <ClickAwayListener
                onClickAway={() => setShowCustomerTagOptions(false)}
              >
                <div onClick={() => setShowCustomerTagOptions(true)}>
                  <TagPicker
                    allowCreate={false}
                    availableTags={availableCustomerTagsLoad.value || []}
                    background="gray"
                    disabled={false}
                    focused={false}
                    onChange={(tagNames) => {
                      customerTags.setValue(tagNames);
                    }}
                    selectedTags={customerTags.value.map((tag) => {
                      return { name: tag };
                    })}
                    showOptions={showCustomerTagOptions}
                    wide
                  />
                </div>
              </ClickAwayListener>
            </div>
            <div className={filterModalCss.filterType}>
              <div className={filterModalCss.filterTitle}>Chat tags</div>
              <ConversationTagInput
                currentTags={conversationTags.value}
                setCurrentTags={(tags) => conversationTags.setValue(tags)}
              />
            </div>
            <div className={filterModalCss.filterType}>
              <div className={filterModalCss.filterTitle}>
                Conversation mentions
              </div>
              <FormChipInput
                delimiter={ChipDelimiter.NEWLINE}
                input={words}
                label=""
                showPlaceholderWithoutFocus
              />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        footer={
          <div className={filterModalCss.footer}>
            <Button
              className={filterModalCss.viewDetailsSave}
              disabled={FORBIDDEN_VIEW_NAMES.includes(viewName.toLowerCase())}
              onClick={handleViewDetailsSave}
              theme={ButtonTheme.PRIMARY}
            >
              Save
            </Button>
          </div>
        }
        onClose={() => setViewDetailsModalOpen(false)}
        open={viewDetailsModalOpen}
        paddingAmount={PaddingAmount.NONE}
        showFooterBorder
        size={ModalSize.SMALL}
        title={editingView ? "Edit view details" : "Enter view details"}
      >
        <div className={filterModalCss.modalContent}>
          <div className={filterModalCss.filterType}>
            <div className={filterModalCss.filterTitle}>View name</div>
            <TextInput onChange={setViewName} value={viewName} />
          </div>
          <div className={filterModalCss.filterType}>
            <div className={filterModalCss.filterTitle}>Privacy</div>
            <RedoCheckbox
              label="Make view private"
              setValue={privacy.setValue}
              size={RedoCheckboxSize.SMALL}
              value={privacy.value}
            />
          </div>
        </div>
      </Modal>
    </>
  );
});
