// extracted by mini-css-extract-plugin
export var addDetails = "_x-l";
export var addEditButton = "_x-b";
export var addressFields = "_x-2";
export var animationContainer = "_x-0";
export var closeButton = "_x-p";
export var customerDetails = "_x-x";
export var customerInformationWrapper = "_x-6";
export var dropdowns = "_x-4";
export var error = "_x-k";
export var fields = "_x-n";
export var flex = "_x-m";
export var grow = "_x-3";
export var icon = "_x-d";
export var imageButton = "_x-q";
export var imagesWrapper = "_x-s";
export var itemDetails = "_x-i";
export var itemSelectionHeader = "_x-9";
export var label = "_x-w";
export var lineItemImage = "_x-j";
export var lineItemReturnedWrapper = "_x-h";
export var lineItemWrapper = "_x-g";
export var modalContent = "_x-f";
export var modalFooter = "_x-1";
export var multipleChoiceAnswer = "_x-u";
export var multipleChoiceAnswersWrapper = "_x-t";
export var orderButton = "_x-z";
export var orderLabel = "_x-10";
export var orderSelection = "_x-y";
export var question = "_x-v";
export var search = "_x-e";
export var selectCheckbox = "_x-a";
export var selectionWrapper = "_x-8";
export var spinner = "_x-c";
export var subheader = "_x-5";
export var title = "_x-7";
export var uploadedImage = "_x-o";
export var uploadedImageWrapper = "_x-r";