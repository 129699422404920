// extracted by mini-css-extract-plugin
export var animationContainer = "_71-0";
export var footer = "_71-1";
export var gray = "_71-2";
export var lineItem = "_71-3";
export var lineItemImage = "_71-4";
export var lineItemInfo = "_71-5";
export var lineItemText = "_71-6";
export var lineItems = "_71-7";
export var modalContent = "_71-8";
export var options = "_71-9";
export var orderName = "_71-a";
export var refundAmount = "_71-b";