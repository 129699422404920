import { useRequiredContext } from "@redotech/react-util/context";
import { useTriggerLoad } from "@redotech/react-util/load";
import { FullConversationsTable } from "@redotech/redo-merchant-app/support/full-conversations-table";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { TeamConversationActivity } from "@redotech/redo-model/conversation-activity";
import { RenderedTeam } from "@redotech/redo-model/team";
import { GetUser } from "@redotech/redo-model/user";
import { FilterOptions, FiltersStatus, View } from "@redotech/redo-model/view";
import * as clone from "lodash/clone";
import {
  Dispatch,
  SetStateAction,
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { TeamContext } from "../app/team";
import { UserContext } from "../app/user";
import { UserCacheContext } from "../app/user-cache";
import { ViewsContext } from "../app/views";
import { RedoMerchantClientContext } from "../client/context";
import { getConversation, queryParamsToFilters } from "../client/conversations";
import { getConversationsCounts } from "../client/team";
import { MerchantAppEventServerContext } from "../events/merchant-app-event-server-provider";
import { ConversationFetcher } from "./conversation-fetcher";
import {
  ACTIVE_CONVERSATION_QUERY_PARAMETER,
  getQueryParameter,
} from "./query-parameters";
import { SingleConversationView } from "./single-conversation-view";
import {
  getPresetViewFilters,
  getConversationActivityStream as getTeamConversationActivityStream,
  listen,
  sendConversationActivityUpdate,
} from "./utils";

const extractViewFilters = (
  view: string | undefined,
  views: View[] | undefined,
  user?: GetUser,
) => {
  const filtersFromQueryParams = location.search
    ? queryParamsToFilters(location.search)
    : null;
  if (filtersFromQueryParams?.read) {
    // need to set read as a boolean instead of string from query params
    filtersFromQueryParams.read =
      filtersFromQueryParams.read === "true"
        ? true
        : filtersFromQueryParams.read === "false"
          ? false
          : null;
  }

  let initialFilters: FilterOptions = {};
  if (view) {
    const presetViewFilters = getPresetViewFilters(view, user);
    if (presetViewFilters) {
      initialFilters = presetViewFilters;
    } else {
      const viewInfo = views?.find((view_) => view_.name === view);
      if (viewInfo) {
        initialFilters = viewInfo.filters;
      }
    }
  } else {
    const pathPieces = location.pathname.split("/");
    const viewNameFromUrl = pathPieces[pathPieces.length - 1];
    if (viewNameFromUrl !== "support") {
      const viewInfo = views?.find(
        (view) => view.name === decodeURIComponent(viewNameFromUrl),
      );
      if (viewInfo) {
        initialFilters = viewInfo.filters;
      }
    }
  }

  // Combine the filters from the view and the query params, giving priority to the query params
  // Putting the query params last allows us to override the view filters
  const allFilters = {
    ...initialFilters,
    ...filtersFromQueryParams,
  };

  return allFilters;
};

export const Support = memo(function Support({ view }: { view?: string }) {
  const team = useContext(TeamContext);
  const user = useContext(UserContext);
  const views = useContext(ViewsContext);
  if (team && user && views) {
    return <SupportComponent view={view} />;
  } else {
    return null;
  }
});

const getViewName = () => {
  return decodeURIComponent(
    window.location.pathname.substring(
      window.location.pathname.lastIndexOf("/") + 1,
    ),
  );
};

export const TeamConversationActivityContext =
  createContext<TeamConversationActivity>({});

export const ConversationCountsContext = createContext<
  Record<string, number> | undefined
>({});

export const FiltersContext = createContext<FilterOptions | undefined>({});

export const SetFiltersContext = createContext<
  Dispatch<SetStateAction<FilterOptions>> | undefined
>(() => {});

const SupportComponent = memo(function SupportComponent({
  view: viewProp,
}: {
  view?: string;
}) {
  const view = viewProp || getViewName();
  const navigate: NavigateFunction = useNavigate();
  const team = useRequiredContext(TeamContext) as RenderedTeam;
  const user = useRequiredContext(UserContext);
  const views = useRequiredContext(ViewsContext);
  const client = useRequiredContext(RedoMerchantClientContext);
  const eventServer = useRequiredContext(MerchantAppEventServerContext);
  const userCache = useContext(UserCacheContext);
  // The conversation that is currently being viewed in the detail view
  const [activeConversation, setActiveConversationState] =
    useState<ExpandedConversation>();
  const [prevConversation, setPrevConversation] =
    useState<ExpandedConversation>();
  const activeConversationRef = useRef<ExpandedConversation>();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [currentPathname, setCurrentPathname] = useState(location.pathname);
  const extractedFilters = extractViewFilters(
    view,
    [...(views.team || []), ...(views.private || [])],
    user,
  );
  const [filters, setFilters] = useState<FilterOptions>(extractedFilters);
  const [initialFilters, setInitialFilters] =
    useState<FilterOptions>(extractedFilters);
  const [selectedConversations, setSelectedConversations] = useState<
    ExpandedConversation[]
  >([]);
  const [deselectedConversations, setDeselectedConversations] = useState<
    ExpandedConversation[]
  >([]);
  const [selectAllMode, setSelectAllMode] = useState(false);
  const [blockRefresh, setBlockRefresh] = useState(false);
  const [statusFilter, setStatusFilter] = useState<FiltersStatus | "all">(
    FiltersStatus.OPEN,
  );
  const ref = useRef<any>();

  const [conversationCountsLoad, doConversationCountsLoad] = useTriggerLoad(
    async (signal) => {
      const currentView = [
        ...(views.team || []),
        ...(views.private || []),
      ].find((v) => v.name === view);
      const closedFilter = {
        ...(filters || currentView?.filters || initialFilters),
        status: [FiltersStatus.CLOSED],
      };
      const openFilter = {
        ...(filters || currentView?.filters || initialFilters),
        status: [FiltersStatus.OPEN],
      };
      const snoozedFilter = {
        ...(filters || currentView?.filters || initialFilters),
        status: [FiltersStatus.SNOOZED],
      };

      const currentViewFilters = [
        {
          _id: currentView?._id || "defaultView",
          team: team?._id,
          name: `${view}-closed`,
          filters: closedFilter,
        },
        {
          _id: currentView?._id || "defaultView",
          team: team?._id,
          name: `${view}-open`,
          filters: openFilter,
        },
        {
          _id: currentView?._id || "defaultView",
          team: team?._id,
          name: `${view}-snoozed`,
          filters: snoozedFilter,
        },
      ];

      const conversationCounts = await getConversationsCounts(client, {
        views: currentViewFilters,
      });

      return {
        ...conversationCounts,
        [`${view}-all`]: Object.values(conversationCounts).reduce(
          (acc, val) => acc + val,
          0,
        ),
      };
    },
  );

  const [teamConversationActivity, setTeamConversationActivity] =
    useState<TeamConversationActivity>({});
  const teamConversationActivityRef = useRef<TeamConversationActivity>({});

  useEffect(() => {
    activeConversationRef.current = activeConversation;
  }, [activeConversation]);

  useEffect(() => {
    setStatusFilter(FiltersStatus.OPEN);
  }, [view]);

  useEffect(() => {
    teamConversationActivityRef.current = teamConversationActivity;
  }, [teamConversationActivity]);

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      try {
        for await (const data of listen({
          query: async () => {
            return await getTeamConversationActivityStream({
              authorization: client.authorization() as {
                Authorization: string;
              },
              signal: abortController.signal,
            });
          },
          loopCondition: true,
        })) {
          const newTeamConversationActivity: TeamConversationActivity =
            data as any;
          setTeamConversationActivity(newTeamConversationActivity);
        }
      } catch (e) {
        if (abortController.signal.aborted) {
          return;
        }
        throw e;
      }
    })();
    return () => {
      if (team.users.map((user) => user.user._id).includes(user._id))
        void sendConversationActivityUpdate({
          authorization: client.authorization() as {
            Authorization: string;
          },
          prevConversationId: activeConversationRef.current?._id,
        });
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (team.users.map((user) => user.user._id).includes(user._id)) {
      if (!activeConversation) return;
      const abortController = new AbortController();
      const sendUpdate = async ({
        prevConversationId,
      }: {
        prevConversationId?: string;
      } = {}) => {
        await sendConversationActivityUpdate({
          authorization: client.authorization() as {
            Authorization: string;
          },
          activeConversationId: activeConversation._id,
          prevConversationId,
          signal: abortController.signal,
        });
      };

      // Optimistically update team conversation activity
      const newTeamConversationActivity = clone(
        teamConversationActivityRef.current,
      );
      // Remove from old conversation
      if (
        prevConversation &&
        newTeamConversationActivity[prevConversation._id]
      ) {
        newTeamConversationActivity[prevConversation._id].viewing =
          newTeamConversationActivity[prevConversation._id].viewing.filter(
            (userId) => userId !== user._id,
          );
        newTeamConversationActivity[prevConversation._id].typing =
          newTeamConversationActivity[prevConversation._id].typing.filter(
            (userId) => userId !== user._id,
          );
        if (
          newTeamConversationActivity[prevConversation._id].viewing.length ===
            0 &&
          newTeamConversationActivity[prevConversation._id].typing.length === 0
        ) {
          delete newTeamConversationActivity[prevConversation._id];
        }
      }
      // Add to new conversation
      if (!newTeamConversationActivity[activeConversation._id]) {
        newTeamConversationActivity[activeConversation._id] = {
          viewing: [],
          typing: [],
        };
      }
      const newViewing = [
        ...(newTeamConversationActivity[activeConversation._id].viewing || []),
        user._id,
      ];
      newTeamConversationActivity[activeConversation._id] = {
        viewing: [...newViewing],
        typing: newTeamConversationActivity[activeConversation._id].typing,
      };
      setTeamConversationActivity(newTeamConversationActivity);

      setPrevConversation(activeConversation);
      void sendUpdate({ prevConversationId: prevConversation?._id });
      const interval = setInterval(sendUpdate, 5 * 1000);
      return () => {
        clearInterval(interval);
        abortController.abort("activeConversation changed");
      };
    }
    return;
  }, [activeConversation?._id]);

  useEffect(() => {
    if (location.pathname !== currentPathname) {
      const extractedFilters = extractViewFilters(
        view,
        [...(views.team || []), ...(views.private || [])],
        user,
      );
      setFilters(extractedFilters);
      setInitialFilters(extractedFilters);
    }
    setCurrentPathname(location.pathname);

    // Handle the case where we went back to the view without clicking the back button
    // (i.e. by clicking the view in the navigation sidebar)
    if (!location.search && activeConversation) {
      void setActiveConversation(undefined);
    }

    const activeConversationId = getQueryParameter(
      location,
      ACTIVE_CONVERSATION_QUERY_PARAMETER,
    );
    if (
      activeConversationId &&
      activeConversation?._id !== activeConversationId
    ) {
      const updateActiveConversation = async () => {
        const conversation = await getConversation(client, {
          conversationId: activeConversationId,
        });
        await setActiveConversation(conversation);
      };
      void updateActiveConversation();
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    const unlistenCallback = eventServer.subscribeAndCallOnce(async () => {
      if (blockRefresh) {
        return;
      }
      doConversationCountsLoad();
      if (ref.current) {
        await ref.current.refresh();
      }
    });
    return () => unlistenCallback();
  }, [blockRefresh]);

  useEffect(() => {
    doConversationCountsLoad();
  }, [view, filters]);

  const fetcher: ConversationFetcher = useMemo(
    () => new ConversationFetcher(client, userCache, () => setPageLoaded(true)),
    [client],
  );

  const setActiveConversation = async (
    conversation: ExpandedConversation | undefined,
  ) => {
    navigateToConversationIfNeeded(activeConversation, conversation);
    setActiveConversationState(conversation);
  };

  const navigateToConversationIfNeeded = (
    activeConversation: ExpandedConversation | undefined,
    newConversation: ExpandedConversation | undefined,
  ): void => {
    if (activeConversation?._id === newConversation?._id) {
      return;
    }
    const locationActiveConversationId = getQueryParameter(
      location,
      ACTIVE_CONVERSATION_QUERY_PARAMETER,
    );
    if (newConversation?._id === locationActiveConversationId) {
      return;
    }
    const searchParams = new URLSearchParams(location.search);

    if (newConversation?._id) {
      searchParams.set(
        ACTIVE_CONVERSATION_QUERY_PARAMETER,
        newConversation._id,
      );
      navigate(`${location.pathname}?${searchParams}`);
    } else {
      searchParams.delete(ACTIVE_CONVERSATION_QUERY_PARAMETER);
      navigate(`${location.pathname}?${searchParams}`);
    }
  };

  if (!team.settings.support) {
    navigate(`/stores/${team._id}/summary`);
  }

  return user ? (
    <FiltersContext.Provider value={filters}>
      <ConversationCountsContext.Provider value={conversationCountsLoad.value}>
        <SetFiltersContext.Provider value={setFilters}>
          <TeamConversationActivityContext.Provider
            value={teamConversationActivity}
          >
            {activeConversation ? (
              <SingleConversationView
                activeConversation={activeConversation}
                cardListRef={ref}
                fetcher={fetcher}
                setActiveConversation={setActiveConversation}
                setStatusFilter={setStatusFilter}
                statusFilter={statusFilter}
                viewName={view}
              />
            ) : (
              <FullConversationsTable
                deselectedConversations={deselectedConversations}
                fetcher={fetcher}
                initialFilters={initialFilters}
                pageLoaded={pageLoaded}
                selectAllMode={selectAllMode}
                selectedConversations={selectedConversations}
                setActiveConversation={setActiveConversation}
                setBlockRefresh={setBlockRefresh}
                setDeselectedConversations={setDeselectedConversations}
                setSelectAllMode={setSelectAllMode}
                setSelectedConversations={setSelectedConversations}
                setStatusFilter={setStatusFilter}
                statusFilter={statusFilter}
                tableRef={ref}
                view={[...(views.team || []), ...(views.private || [])].find(
                  (v) => v.name === view,
                )}
                viewName={view}
              />
            )}
          </TeamConversationActivityContext.Provider>
        </SetFiltersContext.Provider>
      </ConversationCountsContext.Provider>
    </FiltersContext.Provider>
  ) : (
    <div />
  );
});
