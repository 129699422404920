import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { IterableMap } from "@redotech/react-util/component";
import { useSearch } from "@redotech/react-util/search";
import { ConversationTagId } from "@redotech/redo-model/conversation";
import { RedoBadge } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import PlusIcon from "@redotech/redo-web/arbiter-icon/plus.svg";
import { Dropdown } from "@redotech/redo-web/dropdown";
import { Flex } from "@redotech/redo-web/flex";
import { EnterKey } from "@redotech/web-util/key";
import * as classNames from "classnames";
import Fuse from "fuse.js";
import { memo, useContext, useEffect, useState } from "react";
import { ConversationTagsContext } from "../../app/conversation-tags-context";
import { AvailableConversationTagsDropdown } from "./available-conversation-tags-dropdown";
import * as conversationTagInputCss from "./conversation-tag-input.module.css";
import { ConversationTagPill } from "./conversation-tag-pill";
import { CreateConversationTagModal } from "./create-conversation-tag-modal";
import { EditConversationTagModal } from "./edit-conversation-tag-modal";

const searcher = new Fuse<ConversationTagId>([], {
  keys: ["name"],
  threshold: 0.3,
});

export const ConversationTagInput = memo(function ConversationTagInput({
  currentTags,
  setCurrentTags,
  disabled = false,
  showBorder = true,
  showAddButonToEnterInput: showAddButtonToEnterInput = false,
}: {
  currentTags: ConversationTagId[];
  setCurrentTags(tags: ConversationTagId[]): void;
  disabled?: boolean;
  showBorder?: boolean;
  showAddButonToEnterInput?: boolean;
}) {
  const [availableTags, setAvailableTags] = useState<ConversationTagId[]>([]);

  const conversationTags = useContext(ConversationTagsContext);

  useEffect(() => {
    setAvailableTags(conversationTags);
  }, [conversationTags]);

  const [dropdownAnchor, setDropdownAnchor] = useState<HTMLElement | null>(
    null,
  );

  function tagIsSelected(tag: ConversationTagId) {
    return currentTags.some((current) => current.name === tag.name);
  }

  const [searchText, setSearchText] = useState<string>("");

  const [selectDropdownOpen, setSelectDropdownOpen] = useState(false);
  const [tagBeingEdited, setTagBeingEdited] = useState<
    ConversationTagId | undefined
  >(undefined);

  const [createTagModalOpen, setCreateTagModalOpen] = useState(false);

  const filteredAvailableTags = useSearch(searcher, availableTags, searchText);

  function addTagToConversation(tag: ConversationTagId) {
    if (currentTags.find((usedTag) => usedTag.name === tag.name)) {
      return;
    }
    // Eagerly update
    const newTags = [...currentTags, tag];
    setCurrentTags(newTags);
    setSearchText("");
  }

  function removeTagFromConversation(tag: ConversationTagId) {
    // Eagerly update
    const updatedTags = currentTags.filter(
      (current) => current.name !== tag.name,
    );
    setCurrentTags(updatedTags);
  }

  const existingTags = currentTags.length > 0 && (
    <Flex className={conversationTagInputCss.tagsFlex} wrap="wrap">
      <IterableMap items={currentTags} keyFn={(value) => value.name}>
        {(tag) => (
          <ConversationTagPill
            tag={tag}
            xClicked={
              disabled
                ? undefined
                : () => {
                    removeTagFromConversation(tag);
                  }
            }
          />
        )}
      </IterableMap>
    </Flex>
  );

  function handleInputEnter(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key !== EnterKey) {
      return;
    }
    const firstTag = filteredAvailableTags[0];
    if (!firstTag) {
      return;
    }
    addTagToConversation(firstTag);
    setSelectDropdownOpen(false);
  }

  useEffect(() => {
    if (selectDropdownOpen) {
      inputRef?.focus();
    }
  }, [selectDropdownOpen]);

  const addNewTagButton = (
    <Flex pb="md" pt="md">
      <RedoBadge
        color="gray"
        iconLeading={{
          Icon: PlusIcon,
          onClick: () => setSelectDropdownOpen(true),
        }}
        size="sm"
      />
    </Flex>
  );

  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

  const tagInput = (
    <input
      className={classNames(
        conversationTagInputCss.input,
        showBorder && conversationTagInputCss.border,
        !selectDropdownOpen &&
          showAddButtonToEnterInput &&
          conversationTagInputCss.hide,
      )}
      disabled={disabled}
      onChange={(event) => setSearchText(event.target.value)}
      onClick={(event) => {
        event.stopPropagation();
        setSelectDropdownOpen(true);
      }}
      onFocus={(event) => {
        event.stopPropagation();
        setSelectDropdownOpen(true);
      }}
      onKeyUp={handleInputEnter}
      placeholder="Search tags"
      ref={setInputRef}
      value={searchText}
    />
  );

  const wrappedInput = (
    <Flex ref={setDropdownAnchor}>
      <div className={conversationTagInputCss.inputWrapper}>
        {existingTags}
        {!selectDropdownOpen && showAddButtonToEnterInput && addNewTagButton}
        {tagInput}
      </div>
    </Flex>
  );

  const dropdown = (
    <ClickAwayListener onClickAway={() => setSelectDropdownOpen(false)}>
      <Dropdown anchor={dropdownAnchor} open={selectDropdownOpen}>
        <AvailableConversationTagsDropdown
          availableTags={filteredAvailableTags}
          createTagRequested={() => {
            setCreateTagModalOpen(true);
            setSelectDropdownOpen(false);
          }}
          editTagRequested={(tag) => {
            setTagBeingEdited(tag);
            setSelectDropdownOpen(false);
          }}
          selectedTags={currentTags}
          tagSelected={(tag) => {
            if (tagIsSelected(tag)) {
              removeTagFromConversation(tag);
            } else {
              addTagToConversation(tag);
            }
          }}
        />
      </Dropdown>
    </ClickAwayListener>
  );

  const editModal = tagBeingEdited && (
    <EditConversationTagModal
      resolved={() => {
        setTagBeingEdited(undefined);
        setSelectDropdownOpen(true);
      }}
      tag={tagBeingEdited}
    />
  );

  const createModal = createTagModalOpen && (
    <CreateConversationTagModal
      cancelClicked={() => {
        setSelectDropdownOpen(true);
        setCreateTagModalOpen(false);
      }}
      tagCreated={(newTag) => {
        setSelectDropdownOpen(true);
        setCreateTagModalOpen(false);
        setAvailableTags([...availableTags, newTag]);
      }}
    />
  );

  return (
    <div className={conversationTagInputCss.fullWidth}>
      {wrappedInput}
      {dropdown}
      {editModal}
      {createModal}
    </div>
  );
});
