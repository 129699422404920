import { useHandler } from "@redotech/react-util/hook";
import jwtDecode from "jwt-decode";
import {
  ReactNode,
  createContext,
  memo,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

export interface Auth {
  expiration: Temporal.Instant;
  teamId: string;
  userId: string;
  token: string;
}

export const AuthContext = createContext<Auth | undefined>(undefined);

export interface SetAuth {
  (auth: Auth | undefined): void;
}

export const SetAuthContext = createContext<SetAuth | undefined>(undefined);

export const TOKEN_KEY = "redo.auth_token";

export function parseAuth(token: string): Auth | undefined {
  try {
    const decoded: unknown = jwtDecode(token);
    if (
      !decoded ||
      typeof decoded !== "object" ||
      !("exp" in decoded) ||
      typeof decoded.exp !== "number" ||
      !("team_id" in decoded) ||
      typeof decoded.team_id !== "string" ||
      !("id" in decoded) ||
      typeof decoded.id !== "string"
    ) {
      throw new TypeError("Not a valid Auth object");
      // Do not print sensitive information - only uncomment this to test locally
      // throw new TypeError(`Not a valid Auth object: ${JSON.stringify(decoded)}`);
    }
    return {
      expiration: Temporal.Instant.fromEpochSeconds(decoded.exp),
      teamId: decoded.team_id,
      token,
      userId: decoded.id,
    };
  } catch (error) {
    console.error("Failed to parse token", error);
    // Do not print sensitive information - only uncomment this to test locally
    // console.error(`Failed to parse invalid token ${token}`, error);
    return undefined;
  }
}

/** @returns whether the auth is expired (or expires within the next second) */
function isExpired(expiration: Temporal.Instant): boolean {
  const duration = expiration.since(Temporal.Now.instant());
  // Give ourselves 1 second leeway so it doesn't expire while trying to load the page
  return (
    Temporal.Duration.compare(
      Temporal.Duration.from({ seconds: 1 }),
      duration,
    ) > 0
  );
}

export const AuthRequired = memo(function AuthRequired({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  useLayoutEffect(() => {
    if (!auth) {
      const next = `${location.pathname}${location.search}${location.hash}`;
      navigate(`/login?next=${encodeURIComponent(next)}`);
    }
  }, [auth]);

  return auth ? <>{children}</> : null;
});

export const AuthProvider = memo(function AuthProvider({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const parts = window.location.pathname.split("/");
  let teamId = parts[1] === "stores" ? parts[2] : null;
  let team_token_key = teamId ? `${TOKEN_KEY}.${teamId}` : TOKEN_KEY;

  const [auth, setAuth] = useState<Auth | undefined>(() => {
    // Delete expired or invalid tokens
    Object.keys(localStorage)
      .filter((key) => key.startsWith(TOKEN_KEY))
      .filter((key) => {
        const token = localStorage.getItem(key);
        if (!token) {
          return true;
        }
        const parsed = parseAuth(token);
        if (!parsed) {
          return true;
        }
        return isExpired(parsed.expiration);
      })
      .forEach((key) => localStorage.removeItem(key));

    // Fetch the team's token
    const token = localStorage.getItem(team_token_key);
    if (!token) {
      return undefined;
    }
    return parseAuth(token);
  });

  useEffect(() => {
    // Clear the local storage if the auth is undefined
    if (!auth) {
      localStorage.removeItem(team_token_key);
      return;
    }

    // Delete the auth when it expires
    const duration = auth.expiration.since(Temporal.Now.instant());
    const timeout = setTimeout(
      () => setAuth(undefined),
      Math.min(2147483647, duration.total("milliseconds")),
    );
    return () => clearTimeout(timeout);
  }, [auth]);

  const setAuth_ = useHandler<SetAuth>((auth) => {
    if (auth) {
      if (!teamId) {
        teamId = auth.teamId;
        team_token_key = teamId ? `${TOKEN_KEY}.${teamId}` : TOKEN_KEY;
      }
      localStorage.setItem(team_token_key, auth.token);
    }
    setAuth(auth);
  });

  return (
    <AuthContext.Provider value={auth}>
      <SetAuthContext.Provider value={setAuth_}>
        {children}
      </SetAuthContext.Provider>
    </AuthContext.Provider>
  );
});
