// extracted by mini-css-extract-plugin
export var border = "_4u-5";
export var check = "_4u-d";
export var danger = "_4u-3";
export var footerContainer = "_4u-e";
export var fullWidth = "_4u-7";
export var hide = "_4u-6";
export var icon = "_4u-0";
export var input = "_4u-4";
export var inputWrapper = "_4u-8";
export var manualFocus = "_4u-2";
export var optionButton = "_4u-1";
export var pillWrapper = "_4u-a";
export var showOverflow = "_4u-b";
export var tagsFlex = "_4u-9";
export var xButton = "_4u-c";