import { ITrackerStatus } from "@easypost/api";

export const prettyTrackerStatus: { [key in ITrackerStatus]: string } = {
  unknown: "Unknown",
  pre_transit: "Pre-transit",
  in_transit: "In transit",
  out_for_delivery: "Out for delivery",
  delivered: "Delivered",
  available_for_pickup: "Available for pickup",
  return_to_sender: "Return to sender",
  failure: "Failure",
  cancelled: "Cancelled",
  error: "Error",
};

export const shipmentStatusList: Record<string, string> = {
  unknown: "Unknown",
  pre_transit: "Pre-transit",
  in_transit: "In transit",
  out_for_delivery: "Out for delivery",
  delivered: "Delivered",
  available_for_pickup: "Available for pickup",
  return_to_sender: "Return to sender",
  failure: "Failure",
  cancelled: "Cancelled",
  error: "Error",
  stalled_in_transit: "Stalled",
};
