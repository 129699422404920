import { getResource } from "@redotech/redo-model/localization/resource";
import { Address } from "@redotech/redo-model/return-flow";
import { Card } from "@redotech/redo-web/card";
import EditPencil from "@redotech/redo-web/icon-old/edit-pencil2.svg";
import * as classNames from "classnames";
import { memo, useState } from "react";
import { updateReturnNewOrderAddress } from "../../api";
import { AddressEditModal } from "../return-flow/address-edit-modal";

import { IconButton } from "@redotech/redo-web/button";
import * as returnStatus from "./return-status.module.css";

export const ShippingCard = memo(function ShippingCard({
  returnData,
}: {
  returnData: any;
}) {
  const [addressModalOpen, setAddressModalOpen] = useState(false);
  const address = returnData.shipping_address;
  const setAddress = async (address: Address) => {
    returnData.shipping_address = {
      address1: address.street1,
      address2: address.street2,
      city: address.city,
      country_code: address.country,
      email: address.email,
      name: address.name,
      phone_number: address.phone,
      province: address.state,
      zip: address.zip,
    };

    await updateReturnNewOrderAddress(returnData._id, address);
  };
  const copyResource = getResource({
    variant: returnData.type,
  });

  return (
    <>
      <AddressEditModal
        address={
          address
            ? {
                street1: address.address1,
                street2: address.address2,
                city: address.city,
                country: address.country_code,
                email: address.email,
                name: address.name,
                phone: address.phone,
                state: address.province,
                zip: address.zip,
              }
            : undefined
        }
        open={addressModalOpen}
        prepopulateFromUser={false}
        setAddress={setAddress}
        setOpen={setAddressModalOpen}
      />
      <Card
        className={classNames(returnStatus.card, returnStatus.cardWhite)}
        headerExtra={
          <div className={returnStatus.editShippingButtonContainer}>
            <IconButton onClick={() => setAddressModalOpen(true)}>
              <EditPencil />
            </IconButton>
          </div>
        }
        subtitle={copyResource.STATUS_SHIPPING_TEXT}
        title="Shipping information"
      >
        <div className={returnStatus.shippingInfoBody}>
          <div className={returnStatus.personalInfo}>
            <div className={returnStatus.customerName}>
              {returnData.shipping_address.name}
            </div>
            <div>{address.email}</div>
            <div>{address.phone || ""}</div>
          </div>
          <div className={returnStatus.address}>
            <div>{address.address1}</div>
            <div>{address.address2}</div>
            <div>
              {address.city}
              {!!address.province && `, ${address.province}`} {address.zip}
            </div>
            <div>{address.country_code}</div>
          </div>
        </div>
      </Card>
    </>
  );
});
