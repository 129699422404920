import { Permission, permitted } from "@redotech/redo-model/user";
import { ChipDelimiter, ChipInput } from "@redotech/redo-web/chip-input";
import { Pill, PillSize } from "@redotech/redo-web/pill";
import * as classNames from "classnames";
import { memo, useContext } from "react";
import { UserContext } from "../app/user";
import * as tagsDropdownCss from "./tags-dropdown.module.css";

export interface Tag {
  name: string;
}

export const TagPicker = memo(function TagPicker({
  availableTags,
  selectedTags,
  onChange,
  disabled,
  showOptions,
  allowCreate = true,
  background = "white",
  wide = false,
  focused = true,
  pending = false,
}: {
  availableTags: Tag[];
  selectedTags: Tag[];
  onChange(vals: string[]): void;
  disabled: boolean;
  showOptions: boolean;
  allowCreate?: boolean;
  background?: "white" | "gray";
  wide?: boolean;
  focused?: boolean;
  pending?: boolean;
}) {
  const user = useContext(UserContext);
  const canManageTag =
    !!user && permitted(user.permissions, Permission.MANAGE_TAG);
  const handleTagListClick = (tag: Tag) => {
    if (!selectedTags?.length) {
      onChange([tag.name]);
    } else if (!selectedTags.some((selected) => selected.name === tag.name)) {
      onChange(selectedTags.concat(tag).map((selected) => selected.name));
    }
  };

  return (
    <div
      className={
        wide
          ? classNames(tagsDropdownCss.wrapper, tagsDropdownCss.wide)
          : tagsDropdownCss.wrapper
      }
    >
      <div>
        <ChipInput
          delimiter={ChipDelimiter.NEWLINE}
          disabled={disabled}
          focused={focused}
          showPlaceholderWithoutFocus
          value={selectedTags.map((tag) => tag.name)}
          valueChange={onChange}
        />
      </div>
      {showOptions && (
        <div
          className={
            background === "gray"
              ? classNames(
                  tagsDropdownCss.dropdown,
                  tagsDropdownCss.grayBackground,
                )
              : tagsDropdownCss.dropdown
          }
        >
          {!pending && allowCreate && availableTags.length > 0 && (
            <div className={tagsDropdownCss.instruction}>
              {canManageTag
                ? "Select an option or create one"
                : "Select an option"}
            </div>
          )}
          {pending && (
            <div className={tagsDropdownCss.instruction}>
              Applying changes...
            </div>
          )}
          <div>
            {!pending && availableTags.length
              ? availableTags.map((tag) => {
                  return (
                    <div
                      className={
                        selectedTags?.some(
                          (selected) => selected.name === tag.name,
                        )
                          ? tagsDropdownCss.selectedTag
                          : tagsDropdownCss.unselectedTag
                      }
                      key={tag.name}
                      onClick={() => {
                        if (!disabled) {
                          handleTagListClick(tag);
                        }
                      }}
                    >
                      <Pill preventCapitalize size={PillSize.NEAR_SQUARE}>
                        {tag.name}
                      </Pill>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      )}
    </div>
  );
});
